// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { GknRoutePath } from 'src/app/app-routing.enum';

/**
 * Enumerates static UI that can be favorited. Dynamic favorites from the CMS are not included here.
 *
 * Updating the values will break existing favorites (backwards compatibility).
 *
 * Entries are purposefully alphabetical to allow for deterministic
 * usage of functions like Object.values()
 */
export enum FavoriteId {
    APP_FOUNDRY = 'app-foundry',
    ARCHIVES = 'archives',
    BEYOND = 'genesys-beyond',
    COMMUNITY_QA_SHOW = 'community-qa-show',
    CS_ACADEMY = 'cs-academy-training',
    CUSTOMER_SUCCESS = 'customer-success',
    CX_EVOLUTION = 'cx-evolution',
    /** Dev center for both GC CX and GC MCX */
    DEVELOPER_CENTER = 'developer-center',
    /** @deprecated do not use -- was used for Dev Center Genesys Engage On-prem, which has been permanently taken offline. */
    DEVELOPER_CENTER_POPULAR = 'developer-center-popular',
    /** Dev center for Pureconnect. Do not change string value. It remains "-popular" for backward compatibility. */
    DEVELOPER_PORTAL_PURECONNECT = 'developer-portal-popular',
    EXPERT_APPS = 'expert-apps',
    GET_STARTED = 'get-started',
    MARKETING_EVENTS = 'marketing-events',
    LATEST_DISCUSSIONS = 'latest-discussions',
    NEWS_FROM_GENESYS = 'news-from-genesys',
    PRODUCT_FUNDAMENTALS = 'product-fundamentals',
    PRODUCT_HELP = 'product-help',
    RELATED_COMMUNITIES = 'related-communities',
    PRODUCT_SUPPORT_CENTER = 'product-support-center',
    SUPPORT_CASES = 'support-cases',

    EDU_EVENTS_AND_WEBINARS = 'webinars',
    MY_CONTACTS = 'my-contacts',

    NEWS_FROM_GENESYS_ALL = 'news-from-genesys-all',
    NEWS_FROM_GENESYS_ANNOUNCEMENTS = 'news-from-genesys-announcements',
    NEWS_FROM_GENESYS_BLOG = 'news-from-genesys-blog',
    NEWS_FROM_GENESYS_SOCIAL = 'news-from-genesys-social',

    GET_STARTED_MY_PERSONALIZED_VIDEO = 'get-started-my-personalized-video',
    GET_STARTED_STARTER_KIT = 'get-started-starter-kit',
    GET_STARTED_ONBOARDING = 'get-started-onboarding',
    GET_STARTED_SHORT_TRACKS = 'get-started-short-tracks',
    GET_STARTED_USEFUL_LINKS = 'get-started-useful-links',
    GET_STARTED_TIPS_AND_TOOLS = 'get-started-tips-and-tools',

    CERTIFICATIONS_MY_AWARDS = 'certifications-my-awards',
    CERTIFICATIONS_GET_CERTIFIED = 'certifications-get-certified',

    CX_EVOLUTION_GET_STARTED = 'cx-evolution-get-started',
    CX_EVOLUTION_CONNECT = 'cx-evolution-connect',
    CX_EVOLUTION_LEARN_FROM_OTHERS = 'cx-evolution-learn-from-others',

    PRODUCT_HELP_ARTICLES = 'product-help-articles',
    PRODUCT_HELP_VIDEOS = 'product-help-videos',
    PRODUCT_HELP_USE_CASES = 'product-help-use-cases',
    PRODUCT_HELP_DOCUMENTATION = 'product-help-documentation',

    RECOMMENDATIONS = 'recommendations',
    DEVELOPER_CENTER_TOOLS = 'developer-center-tools',
    DEVELOPER_CENTER_POPULAR_TOPICS = 'developer-center-popular-topics',
    DEVELOPER_CENTER_BLOG = 'developer-center-blog',

    OWTGI_WRITE_A_REVIEW = 'owtgi-write-a-review',
    OWTGI_CUSTOMER_MONTHLY_NEWSLETTER = 'owtgi-customer-monthly-newsletter',
    OWTGI_SUPER_HUMAN_SERVICE = 'owtgi-super-human-service',
    OWTGI_PRODUCT_IDEAS_LAB = 'owtgi-product-ideas-lab',
    OWTGI_GENESYS_USER_GROUPS = 'owtgi-genesys-user-groups',
    OWTGI_COLLABORATE_WITH_OUR_UX_TEAM = 'owtgi-collaborate-with-our-ux-team',
    OWTGI_DEVELOPER_NEWSLETTER = 'owtgi-developer-newsletter',
    OWTGI_ADVOCACY_PAYS = 'owtgi-advocacy-pays',

    // "Dummy" favorites. Required in order to make filtering on "Just For Me" section work
    ADDITIONAL_SITES = 'additional-sites',
    MY_FAVORITES = 'my-favorites',
}

/**
 * Maps a favorite ID for _static_ content to a GKN route path.
 * Dynamic content from the CMS is not included in this mapping.
 */
export const StaticFavoriteRoutePath: { [key in FavoriteId]?: GknRoutePath } = {
    [FavoriteId.APP_FOUNDRY]: GknRoutePath.RESOURCES,
    [FavoriteId.ARCHIVES]: GknRoutePath.HOME_ARCHIVES,
    [FavoriteId.BEYOND]: GknRoutePath.EDUCATION_AND_TRAINING,
    /** contains temporary fix, check {@link BadgesComponent.toggleFilterToFavoriteItem} for more details. */
    [FavoriteId.COMMUNITY_QA_SHOW]: GknRoutePath.COMMUNITY,
    [FavoriteId.CS_ACADEMY]: GknRoutePath.EDUCATION_AND_TRAINING,
    [FavoriteId.DEVELOPER_CENTER]: GknRoutePath.RESOURCES,
    [FavoriteId.DEVELOPER_CENTER_POPULAR]: GknRoutePath.RESOURCES,
    [FavoriteId.EXPERT_APPS]: GknRoutePath.RESOURCES,
    [FavoriteId.CUSTOMER_SUCCESS]: GknRoutePath.RESOURCES,
    [FavoriteId.DEVELOPER_PORTAL_PURECONNECT]: GknRoutePath.RESOURCES,
    [FavoriteId.GET_STARTED]: GknRoutePath.RESOURCES,
    [FavoriteId.CX_EVOLUTION]: GknRoutePath.RESOURCES,
    [FavoriteId.MARKETING_EVENTS]: GknRoutePath.GET_INVOLVED,
    [FavoriteId.LATEST_DISCUSSIONS]: GknRoutePath.COMMUNITY,
    [FavoriteId.NEWS_FROM_GENESYS]: GknRoutePath.HOME,
    [FavoriteId.PRODUCT_FUNDAMENTALS]: GknRoutePath.HOME,
    [FavoriteId.PRODUCT_HELP]: GknRoutePath.RESOURCES,
    [FavoriteId.RELATED_COMMUNITIES]: GknRoutePath.COMMUNITY,
    [FavoriteId.PRODUCT_SUPPORT_CENTER]: GknRoutePath.SUPPORT,
    [FavoriteId.SUPPORT_CASES]: GknRoutePath.SUPPORT,
    [FavoriteId.EDU_EVENTS_AND_WEBINARS]: GknRoutePath.EDUCATION_AND_TRAINING,
    [FavoriteId.MY_CONTACTS]: GknRoutePath.SUPPORT,
    [FavoriteId.RECOMMENDATIONS]: GknRoutePath.SUPPORT,

    [FavoriteId.NEWS_FROM_GENESYS_ALL]: GknRoutePath.HOME,
    [FavoriteId.NEWS_FROM_GENESYS_ANNOUNCEMENTS]: GknRoutePath.HOME,
    [FavoriteId.NEWS_FROM_GENESYS_BLOG]: GknRoutePath.HOME,
    [FavoriteId.NEWS_FROM_GENESYS_SOCIAL]: GknRoutePath.HOME,

    [FavoriteId.GET_STARTED_MY_PERSONALIZED_VIDEO]: GknRoutePath.RESOURCES,
    [FavoriteId.GET_STARTED_STARTER_KIT]: GknRoutePath.RESOURCES,
    [FavoriteId.GET_STARTED_ONBOARDING]: GknRoutePath.RESOURCES,
    [FavoriteId.GET_STARTED_SHORT_TRACKS]: GknRoutePath.RESOURCES,
    [FavoriteId.GET_STARTED_USEFUL_LINKS]: GknRoutePath.RESOURCES,
    [FavoriteId.GET_STARTED_TIPS_AND_TOOLS]: GknRoutePath.RESOURCES,

    [FavoriteId.CERTIFICATIONS_MY_AWARDS]: GknRoutePath.EDUCATION_AND_TRAINING,
    [FavoriteId.CERTIFICATIONS_GET_CERTIFIED]: GknRoutePath.EDUCATION_AND_TRAINING,

    [FavoriteId.CX_EVOLUTION_GET_STARTED]: GknRoutePath.RESOURCES,
    [FavoriteId.CX_EVOLUTION_CONNECT]: GknRoutePath.RESOURCES,
    [FavoriteId.CX_EVOLUTION_LEARN_FROM_OTHERS]: GknRoutePath.RESOURCES,

    [FavoriteId.PRODUCT_HELP_ARTICLES]: GknRoutePath.RESOURCES,
    [FavoriteId.PRODUCT_HELP_VIDEOS]: GknRoutePath.RESOURCES,
    [FavoriteId.PRODUCT_HELP_USE_CASES]: GknRoutePath.RESOURCES,
    [FavoriteId.PRODUCT_HELP_DOCUMENTATION]: GknRoutePath.RESOURCES,

    [FavoriteId.DEVELOPER_CENTER_TOOLS]: GknRoutePath.RESOURCES,
    [FavoriteId.DEVELOPER_CENTER_POPULAR_TOPICS]: GknRoutePath.RESOURCES,
    [FavoriteId.DEVELOPER_CENTER_BLOG]: GknRoutePath.RESOURCES,

    [FavoriteId.OWTGI_WRITE_A_REVIEW]: GknRoutePath.GET_INVOLVED,
    [FavoriteId.OWTGI_CUSTOMER_MONTHLY_NEWSLETTER]: GknRoutePath.GET_INVOLVED,
    [FavoriteId.OWTGI_SUPER_HUMAN_SERVICE]: GknRoutePath.GET_INVOLVED,
    [FavoriteId.OWTGI_PRODUCT_IDEAS_LAB]: GknRoutePath.GET_INVOLVED,
    [FavoriteId.OWTGI_GENESYS_USER_GROUPS]: GknRoutePath.GET_INVOLVED,
    [FavoriteId.OWTGI_COLLABORATE_WITH_OUR_UX_TEAM]: GknRoutePath.GET_INVOLVED,
    [FavoriteId.OWTGI_DEVELOPER_NEWSLETTER]: GknRoutePath.GET_INVOLVED,
    [FavoriteId.OWTGI_ADVOCACY_PAYS]: GknRoutePath.GET_INVOLVED,
};
