import { environment as prodEnvironment } from './environment.prod';

/**
 * UAT environment inherits as much of the environment.prod.ts file as possible
 */
export const environment = {
    ...prodEnvironment,
    name: 'uat',
    oidc: {
        ...prodEnvironment.oidc,
        redirectUri: 'https://know-uat.genesyscsdt.com/login/callback',
    },
    api: {
        ...prodEnvironment.api,
        community: {
            ...prodEnvironment.api.community,
            returnUrl: 'https://know-uat.genesyscsdt.com/community',
        },
        cms: {
            strapi: 'https://know-cms.genesyscsdteng.com/api',
            wp: 'https://uat.gkncms.genesyscsdt.com/wp-json/wp/v2',
        },
    },
    pendo: {
        userIdPrefix: 'UAT', // use a prefix in uat so Pendo can filter out these analytics
        cookieDomain: '.genesyscsdt.com', // dot prefix is required!
    },
};
