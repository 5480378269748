import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { DirectivesModule } from '@directives';
import { FundamentalsCardModule } from '../fundamentals-card/fundamentals-card.module';
import { RoadmapsWysiwygModule } from './roadmaps-wysiwyg/roadmaps-wysiwyg.module';
import { RoadmapsComponent } from './roadmaps.component';

@NgModule({
    declarations: [RoadmapsComponent],
    imports: [
        CommonModule,
        DirectivesModule,
        FundamentalsCardModule,
        MatButtonModule,
        RoadmapsWysiwygModule,
        RouterModule,
    ],
    exports: [RoadmapsComponent],
})
export class RoadmapsModule {}
