import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'datesAgo',
})
export class DatesAgoPipe implements PipeTransform {
    transform(dateStr: Date): string {
        if (dateStr) {
            const seconds = Math.floor(Math.abs(+new Date() - +new Date(dateStr)) / 1000);
            // less than 30 seconds ago will show as 'Just now'
            if (seconds < 29) return 'Just now';

            const intervals = {
                year: 31536000,
                month: 2592000,
                week: 604800,
                day: 86400,
                hour: 3600,
                minute: 60,
                second: 1,
            };

            let counter: number;
            for (const i in intervals) {
                counter = Math.floor(seconds / intervals[i]);
                if (counter > 0) {
                    if (counter === 1) {
                        return counter + ' ' + i + ' ago';
                    } else {
                        return counter + ' ' + i + 's ago';
                    }
                }
            }
        }
    }
}
