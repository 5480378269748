import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ComponentsModule } from '../@shared/components/components.module';
import { ServicesModule } from '../@shared/services/services.module';
import { ProfileRoutingModule } from './profile-routing.module';
import { ProfileComponent } from './profile.component';

@NgModule({
    declarations: [ProfileComponent],
    imports: [CommonModule, ComponentsModule, ProfileRoutingModule, ServicesModule],
    exports: [ProfileComponent], // unlike other "page" modules, since the profile component is shown in special cases in AppComponent for mobile devices.
})
export class ProfileModule {}
