import { Component, Input } from '@angular/core';

import { StarterKitStep } from '../starter-kit.interface';

@Component({
    selector: 'app-starter-kit-step',
    templateUrl: './starter-kit-step.component.html',
    styleUrls: ['./starter-kit-step.component.css'],
})
export class StarterKitStepComponent {
    @Input() step: StarterKitStep;
    @Input() accessible: boolean;
    @Input() selected: boolean;
}
