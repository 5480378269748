/**
 * WordPress CMS specific parameters to order CMS content and paging
 */
export enum WordpressPaging {
    ORDER_BY_KEY = 'orderby', // Order by property key
    ORDER_BY_VALUE = 'menu_order', // Order by value default
    ORDER_KEY = 'order', // Order type property key
    ORDER_VALUE = 'asc', // Ascending Order for wordpress results
    PER_PAGE_KEY = 'per_page', // Per page property key
    PER_PAGE_VALUE = '100', // Word press results per page value
}
