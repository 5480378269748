<nav>
    <div class="navbar-menu">
        <a
            *ngFor="let routeKey of navbarRouteKeys; trackBy: trackByItem"
            [routerLink]="routeKey.routerConfig.path"
            class="navbar-item"
            [queryParams]="{ product: (selectedProduct$ | async).uid }"
            routerLinkActive="is-active"
            (click)="handleCloseMenu($event)"
            data-testId="router-link">
            {{ routeKey.label }}
        </a>
    </div>
    <hr />
    <ng-container *ngIf="isAuthenticated$ | async">
        <div class="my-profile">
            <div class="outer-circle">
                <a class="profile-icon" (click)="handleOpenMobileProfile($event)">
                    <span translate="no" class="padding-initials">{{ initials$ | async }}</span>
                </a>
            </div>
            <a class="profile-link primary-link" (click)="handleOpenMobileProfile($event)">
                <h4 class="navigation-mobile-menu-title">My Profile</h4>
            </a>
            <input
                type="image"
                src="/assets/icons/orange-right-arrow.svg"
                alt="Go to profile arrow icon"
                width="10px"
                height="12px"
                class="margin-left-smaller" />
        </div>
        <hr />
    </ng-container>
    <div class="language-translator">
        <div id="google_translate_element"></div>
        <h4 class="navigation-mobile-menu-title">Language</h4>
        <input
            type="image"
            src="/assets/icons/orange-right-arrow.svg"
            alt="Translate arrow icon"
            width="10px"
            height="12px"
            class="margin-left-smaller" />
    </div>
    <hr />
    <ul>
        <ng-container *ngIf="isAuthenticated$ | async; else signInInput">
            <li>
                <input
                    class="btn btn-primary cursor margin-left-small margin-bottom-large"
                    type="button"
                    (click)="handleSignOut($event)"
                    data-testId="signout-btn"
                    value="Sign Out" />
            </li>
        </ng-container>
        <ng-template #signInInput>
            <li>
                <input
                    class="btn btn-primary sign-in-button cursor margin-left-small margin-bottom-large"
                    type="button"
                    (click)="handleSignIn($event)"
                    data-testId="signin-btn"
                    value="Sign In" />
            </li>
        </ng-template>
    </ul>
    <!--NOTE: Footer links will appear in the header when they are hidden in the footer-->
    <ul>
        <li class="margin-bottom-small">
            <!--GENESYS TERMS OF USE LINK-->
            <ng-container *ngIf="termsOfUse$ | async as termsOfUse">
                <a
                    id="terms-of-use-link"
                    class="secondary-link no-wrap margin-left-small"
                    rel="nofollow"
                    [href]="termsOfUse.url"
                    target="_blank">
                    Terms of Use
                </a>
            </ng-container>
            <!--END GENESYS TERMS OF USE LINK-->
        </li>
        <li class="margin-bottom-small">
            <!--GENESYS PRIVACY POLICY LINK-->
            <ng-container *ngIf="privacyPolicy$ | async as privacyPolicy">
                <a
                    id="privacy-policy-link"
                    class="secondary-link no-wrap margin-left-small"
                    rel="nofollow"
                    [href]="privacyPolicy.url"
                    target="_blank">
                    Privacy Policy
                </a>
            </ng-container>
            <!--END GENESYS PRIVACY POLICY LINK-->
        </li>
        <li class="margin-bottom-small">
            <!--GENESYS EMAIL SUBSCRIPTION LINK-->
            <a
                id="email-subscription-link"
                class="secondary-link no-wrap margin-left-small"
                rel="nofollow"
                href="https://www.genesys.com/campaign/subscribe-genesys-communications"
                target="_blank">
                Email Subscription
            </a>
            <!--END GENESYS EMAIL SUBSCRIPTION LINK-->
        </li>
        <li class="margin-bottom-small">
            <app-cookie-consent></app-cookie-consent>
        </li>
    </ul>
</nav>
