import { ComponentRef, Injectable, ViewContainerRef } from '@angular/core';
import { ToastNotificationComponent } from '../../components/toast-notification/toast-notification.component';

export enum ToastColor {
    INFO = 'info',
    CRITICAL = 'critical',
}

export interface ToastOptions {
    /** HTML content of the toast notification */
    innerHtml: string;
    /** Outline color to indicate notication severity */
    color: ToastColor;
}

@Injectable({
    providedIn: 'root',
})
export class ToastNotificationService {
    /**
     * ViewRef of the parent element in which to show the toast. this gets set by AppComponent
     * on start-up and should never be nullified or set to another ViewRef, but must be public
     * so that AppComponent can access it
     */
    viewRef!: ViewContainerRef;

    /** Component reference to the toast */
    private toastRef: ComponentRef<ToastNotificationComponent>;

    /**
     * Dymically adds a toast component to the DOM. After 7 seconds, or manual
     * button click, the toast is dismissed and removed from the DOM. If a toast
     * is already present, calling this function will remove it before displaying
     * the new notification.
     *
     * @param toastOptions config options this specific notification.
     */
    notify(toastOptions: ToastOptions): void {
        if (this.toastRef) {
            // Only show one toast at a time
            this.dismiss();
        }

        this.toastRef = this.viewRef.createComponent(ToastNotificationComponent);

        this.toastRef.instance.innerHtml = toastOptions.innerHtml;
        this.toastRef.instance.color = toastOptions.color;

        // This sub will be destroyed when this service is destroyed. Since it's
        // a singleton service injected into root, it is only destroyed once a user
        // refreshes/leaves the app. It does not need to be manally unsubscribed.
        this.toastRef.instance.onClose$.subscribe(() => this.dismiss());
    }

    /**
     * Removes the toast from the DOM by destroying the component
     */
    private dismiss() {
        this.toastRef.destroy();
    }
}
