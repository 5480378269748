<app-tile-layout [maxColumns]="2" *ngIf="!isMobile">
    <li class="modal-container" class="iteration-{{ selectedIndex % 5 }}">
        <app-video-container
            *ngIf="step.type == 0"
            [containerCss]="['tile-border-radius', 'mat-elevation-z6']"
            [title]="title"
            iframeAllow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            (sendClick)="complete()"
            [embedUrl]="step.link"
            [dataAnalytics]="step?.dataAnalytics">
        </app-video-container>

        <app-tile-vertical
            *ngIf="step.type == 1 || step.type == 2 || step.type == 3"
            (click)="openModal()"
            [showImage]="false"
            [bigImage]="true"
            [border]="false"
            [isCustomTileLink]="true"
            [isCustomTitleLink]="true"
            [isCustomFooterLink]="true"
            [dataAnalytics]="step?.dataAnalytics">
            <div customTileLink>
                <div>
                    <div
                        class="default-image cursor tile-background-color"
                        [attr.data-analytics]="step?.dataAnalytics.join('-')">
                        <img
                            class="article-icon"
                            src="{{ step.articleIcon || '/assets/icons/content-tags-article-medium.svg' }}"
                            alt="Article icon"
                            width="39px"
                            height="50px" />
                    </div>
                </div>
            </div>
        </app-tile-vertical>
    </li>
    <li class="info-container">
        <h2 class="h2-alt margin-bottom-small">{{ step.title }}</h2>
        <p class="p-body-large" [innerHTML]="step.description"></p>
        <div class="external-icons">
            <button
                (click)="openModal()"
                class="continue cursor link-n btn-link"
                *ngIf="step.type !== 3"
                [attr.data-analytics]="step?.dataAnalytics.join('-') + '-view'"
                >{{ continueMessage }}</button
            >
            <button
                (click)="openLink()"
                class="continue cursor link-n btn-link"
                *ngIf="step.type === 3"
                [attr.data-analytics]="step?.dataAnalytics.join('-') + '-view'"
                >{{ continueMessage }}</button
            >
            <app-share [link]="step.link" class="external-icon"></app-share>
            <app-external-url (click)="complete()" [url]="step.link"></app-external-url>
        </div>
        <div class="nav-buttons">
            <button class="previous" *ngIf="!firstStep" (click)="navigate.emit(-1)">Previous Step</button>
            <button *ngIf="!lastStep && (step.completed || !tab.order)" class="next complete" (click)="navigate.emit(1)"
                >Next Step</button
            >
            <button *ngIf="!lastStep && !step.completed && tab.order" class="next">Next Step</button>
        </div>
    </li>
</app-tile-layout>

<ng-container *ngIf="showModal">
    <app-article
        *ngIf="step.type == 0"
        [title]="step.title"
        [isCustomContent]="true"
        [customContainerCss]="'full-width-container'"
        [customModalCss]="['dark-mode-modal', 'text-white', 'auto-height', 'half-max-width']"
        [customIconCss]="'white-icon'"
        [showShare]="false"
        (closeArticle)="closeModal()"
        [dataAnalytics]="step?.dataAnalytics">
        <div customContent>
            <app-video-container
                [title]="step.name"
                iframeAllow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                containerCss="mat-elevation-z6"
                [embedUrl]="step.link"
                [dataAnalytics]="step?.dataAnalytics">
            </app-video-container>
        </div>
    </app-article>

    <app-article
        *ngIf="step.type == 1"
        [isCustomContent]="true"
        [customModalCss]="['light-mode-modal', 'text-black', 'auto-height']"
        [customIconCss]="['black-icon', 'close-align']"
        [customSecondaryHeaderCSS]="'header-secondary'"
        [customShareLink]="step.link"
        (closeArticle)="closeModal()"
        [dataAnalytics]="step?.dataAnalytics">
        <div customContent>
            <iframe
                id="productHelpArticleBlobFrame"
                [src]="step.link | safe: 'resourceUrl'"
                class="article-iframe"></iframe>
        </div>
    </app-article>
</ng-container>
