import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Observable, ReplaySubject, Subject, Subscription, catchError, map, of, tap } from 'rxjs';
import { StarterKitTab } from './starter-kit.interface';

import { TrackByItem } from '@components';
import { UserService } from '@services';
import { KeplerDbService } from 'src/app/@shared/services/kepler-db/kepler-db.service';
import { starterKitData } from './starter-kit-data';

@Component({
    selector: 'app-starter-kit',
    templateUrl: './starter-kit.component.html',
    styleUrls: ['./starter-kit.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class StarterKitComponent implements OnInit, OnDestroy, TrackByItem<StarterKitTab> {
    loaded$: ReplaySubject<boolean> = new ReplaySubject(1);

    readonly location = 'starter-kit';

    updateToLatestStep$: Subject<boolean> = new Subject<boolean>();
    private subs: Subscription[] = [];

    tabs: StarterKitTab[] = starterKitData;
    selectedTabIndex = 0;
    progress$: Observable<StarterKitTab[]>;

    unauthenticatedView$: ReplaySubject<boolean> = new ReplaySubject(1);

    private lastEmail = '';

    isDevMode$: Observable<boolean>;

    constructor(
        private dbService: KeplerDbService,
        private cdr: ChangeDetectorRef,
        private userService: UserService,
        private route: Router,
        private routes: ActivatedRoute,
    ) {}

    ngOnInit(): void {
        this.subs.push(
            this.userService.isAuthenticated$.subscribe((authenticated) => {
                if (authenticated) {
                    this.subs.push(
                        this.userService.claims$.subscribe((claims) => {
                            if (claims && claims.email && claims.email != this.lastEmail) {
                                this.lastEmail = claims.email;
                                this.unauthenticatedView$.next(false);

                                this.progress$ = this.dbService.getStarterKitProgress(claims.email).pipe(
                                    map((response) => {
                                        response.progress.forEach((progress) => {
                                            const tab = this.tabs.find((tab) => tab.id == progress.productId);
                                            progress.stepProgress.forEach((stepProgress) => {
                                                const step = tab.steps.find((step) => step.id == stepProgress.stepId);
                                                step.completed = stepProgress.completed;
                                            });
                                        });
                                        this.updateToLatestStep$.next(false);

                                        this.tabs = JSON.parse(JSON.stringify(this.tabs));
                                        this.cdr.detectChanges();
                                        this.loaded$.next(true);
                                        return this.tabs;
                                    }),
                                    catchError((err) => {
                                        if (err.status == 404) {
                                            this.subs.push(
                                                this.dbService
                                                    .addStarterKitProgress(claims.email, {
                                                        productId: this.tabs[this.selectedTabIndex].id,
                                                        stepName: this.tabs[this.selectedTabIndex].steps[0].name,
                                                        stepId: this.tabs[this.selectedTabIndex].steps[0].id,
                                                        completed: false,
                                                    })
                                                    .pipe(tap(() => this.loaded$.next(true)))
                                                    .subscribe(),
                                            );
                                        }
                                        return of(this.tabs);
                                    }),
                                    tap(() => this.loaded$.next(true)),
                                );
                            }
                        }),
                    );
                } else {
                    this.unauthenticatedView$.next(true);
                    this.loaded$.next(true);
                }
            }),
        );
        const selectedProduct = new URLSearchParams(window.location.search)
            .get('location')
            ?.split(this.location + '-')[1];
        if (selectedProduct) {
            this.selectTab(this.tabs.findIndex((tab) => tab.filter === selectedProduct));
        }

        this.isDevMode$ = this.routes.queryParams.pipe(map((params) => params?.dev === 'true'));
    }

    trackByItem(_index: number, item: StarterKitTab): NonNullable<number | string> {
        return item.name;
    }

    ngOnDestroy(): void {
        this.subs?.forEach((sub) => sub?.unsubscribe());
    }

    selectTab(index: number) {
        this.selectedTabIndex = index;
        this.updateURL(this.tabs[this.selectedTabIndex].filter);
        this.cdr.detectChanges();
        this.updateToLatestStep$.next(true);
    }

    /**
     * Update the URL based on the filter button clicked
     * @param starterKit - the button's value
     */
    updateURL(starterKit: string): void {
        if (starterKit) {
            const navigationExtras: NavigationExtras = {
                fragment: this.location + '-' + `${starterKit}`.toLowerCase(),
            };
            this.route.navigate([], navigationExtras);
        }
    }

    /**
     * Delete the progress for all products.
     */
    deleteAllProgress(): void {
        this.subs.push(
            this.userService.claims$.subscribe((claims) => {
                this.subs.push(
                    this.dbService.deleteAllStarterKitProgress(claims.email).subscribe(() => {
                        this.subs.push(this.progress$.subscribe());
                        window.location.reload();
                    }),
                );
            }),
        );
    }

    /**
     * Delete the progress for selected product.
     */
    deleteProductProgress(): void {
        this.subs.push(
            this.userService.claims$.subscribe((claims) => {
                this.subs.push(
                    this.dbService
                        .deleteStarterKitProgress(claims.email, this.tabs[this.selectedTabIndex].id)
                        .subscribe(() => {
                            this.subs.push(this.progress$.subscribe());
                            window.location.reload();
                        }),
                );
            }),
        );
    }

    /**
     * Delete the progress step for selected product.
     */
    deleteStepProgress(): void {
        const completedSteps = this.tabs[this.selectedTabIndex].steps.filter((step) => step.completed);
        this.subs.push(
            this.userService.claims$.subscribe((claims) => {
                this.subs.push(
                    this.dbService
                        .deleteStepStarterKitProgress(
                            claims.email,
                            this.tabs[this.selectedTabIndex].id,
                            completedSteps[completedSteps.length - 1].id,
                        )
                        .subscribe(() => {
                            this.subs.push(this.progress$.subscribe());
                            window.location.reload();
                        }),
                );
            }),
        );
    }
}
