import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ComponentsModule } from '@components';
import { PipesModule } from '@pipes';
import { BeyondVideoContentComponent } from './beyond-video-content.component';

@NgModule({
    declarations: [BeyondVideoContentComponent],
    imports: [CommonModule, ComponentsModule, PipesModule],
    exports: [BeyondVideoContentComponent],
})
export class BeyondVideoContentModule {}
