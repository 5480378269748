import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { AuthService, ToastColor, ToastNotificationService } from '@services';
import { Observable, catchError, map, of, switchMap, tap } from 'rxjs';
import { KeplerSfdcUserDetail, KeplerSfdcUserDetailResponse } from './interfaces';

// TODO Move all of this code directly into UserService.
// No other business logic should be directly accessing the sfdcUserDetail$ observable through this entry point.
@Injectable({
    providedIn: 'root',
})
export class KeplerSfdcService {
    constructor(private authService: AuthService, private http: HttpClient, private toast: ToastNotificationService) {}

    /**
     * Gets the Salesforce user detail for the current user.
     * It is not recommended to call this data directly. Use the
     * UserService, which caches this data after a user first logins in
     *
     * @param email
     * @returns an Observable {@link KeplerSfdcUserDetail}
     */
    get sfdcUserDetail$(): Observable<KeplerSfdcUserDetail> {
        // In this special case, we get the user's email from the AuthService.
        // In general, it should be retrieved from the UserService, but that
        // introduces a cyclical dependency if introduced into this class
        return this.authService.claims$.pipe(
            switchMap((claims) =>
                this.http.post<KeplerSfdcUserDetailResponse>(`${environment.api.kepler.sfdc.url}/user-detail`, {
                    email: claims.email,
                }),
            ),
            map((sfdcUserDetails) => {
                return this.fixSfdcApiSupportAccessObject(sfdcUserDetails);
            }),
            map((sfdcUserDetails) => {
                let mapped: KeplerSfdcUserDetailResponse;
                if (sfdcUserDetails?.data?.Contacts) {
                    // If the newer `Contacts` is present, simply discard the deprecated `Contact` redundancy
                    delete (<any>sfdcUserDetails)?.data?.Contact;
                    mapped = sfdcUserDetails;
                } else {
                    mapped = this.handleDeprecatedContactProperty(sfdcUserDetails);
                }

                return mapped.data;
            }),
            tap((sfdcUserDetails: KeplerSfdcUserDetail) => {
                if (!sfdcUserDetails) {
                    console.debug('KeplerSfdcService could not find Salesforce data');
                }
            }),
            catchError((err) => {
                console.error('Failed to fetch Salesforce user data.', { cause: err });
                this.toast.notify({
                    innerHtml:
                        'An unexpected error occurred while fetching user data. Please contact <a href="mailto:knowledgenetwork@genesys.com">knowledgenetwork@genesys.com</a> for further assistance.',
                    color: ToastColor.CRITICAL,
                });
                return of(undefined);
            }),
        );
    }

    /**
     * Temporary work-around to ensure that data that is returned is in a proper format. This should be removed once the SFDC API is fixed.
     * @param {object} userDetails SFDC User Details object, handled as `any` for unmapped/deprecated fields
     * @returns {KeplerSfdcUserDetail} userDetails object
     */
    private fixSfdcApiSupportAccessObject(userDetails: KeplerSfdcUserDetailResponse): KeplerSfdcUserDetailResponse {
        const userDetailsCopy = Object.assign({}, userDetails);

        // The API could return a single object or an array for these two key/values
        // We want to logically handle the array format, so we'll ensure its turned into an array if its not already
        const supportAccess = userDetailsCopy?.data?.Support_Access;
        if (supportAccess && !Array.isArray(supportAccess)) {
            userDetailsCopy.data.Support_Access = [Object.assign({}, supportAccess)];
        }
        const supportAccessCloud = userDetailsCopy?.data?.Support_Access_Cloud;
        if (supportAccessCloud && !Array.isArray(supportAccessCloud)) {
            userDetailsCopy.data.Support_Access_Cloud = [Object.assign({}, supportAccessCloud)];
        }
        return userDetailsCopy;
    }

    /**
     * Temporary work-around to ensure that data that is returned is in a proper format. This should be removed once the SFDC API is fixed.
     * @param {object} userDetails SFDC User Details object, handled as `any` for unmapped/deprecated fields
     * @returns {KeplerSfdcUserDetail} userDetails object
     */
    private handleDeprecatedContactProperty(userDetails: KeplerSfdcUserDetailResponse): KeplerSfdcUserDetailResponse {
        const userDetailsCopy = Object.assign({}, userDetails);

        // KNOW-1476 Previous versions of the Apex KnowUserInfo service returned
        // either a single or multiple values in the Contact property. We want to
        // logically handle the array format, so we'll ensure its turned into an
        // array if its not already
        const contact = userDetailsCopy?.data?.Contact;
        if (contact && !Array.isArray(contact)) {
            userDetailsCopy.data.Contacts = [Object.assign({}, contact)];
        } else if (contact && Array.isArray(contact)) {
            userDetailsCopy.data.Contacts = [...contact];
        }

        delete userDetailsCopy?.data?.Contact;

        return userDetailsCopy;
    }
}
