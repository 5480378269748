import { Html } from '../html/html.interface';

/**
 * Abstract class representing an overlay.
 *
 * Overlays can be converted to {@link Html}, and have interactions like {@link show} and {@link hide}
 */
export abstract class Overlay implements Html {
    /**
     * Constructor
     *
     * @param domId the ID to use for highest level element of the {@link html}
     * @param options
     */
    constructor(private domId: string, protected options: { animateInClass: string; animateOutClass: string }) {}

    /**
     * Passthrough for {@link Html}
     */
    abstract html(): HTMLElement;

    /**
     * Adds this overlay as a child node on the given container element. If the overlay already
     * exists on the DOM, it will simply be revealed rather than redudandtly added.
     *
     * @param container the element to append this overlay to
     */
    show(container): void {
        let element = document.getElementById(this.domId);
        if (element === null) {
            element = this.html();
            container.appendChild(element);
        }

        element.removeAttribute('hidden');

        element.classList.remove(`animate__${this.options.animateOutClass}`);
        element.classList.remove('animate__animated');

        element.classList.add('animate__animated');
        element.classList.add(`animate__${this.options.animateInClass}`);
    }

    /**
     * Animates the overlay to a hidden state, then removes it from the DOM
     */
    hide(): void {
        const element = document.getElementById(this.domId);

        element.classList.remove(`animate__${this.options.animateInClass}`);
        element.classList.remove('animate__animated');

        element.classList.add('animate__animated');
        element.classList.add(`animate__${this.options.animateOutClass}`);

        setTimeout(() => {
            this.remove();
        }, 500);
    }

    /**
     * Removes this overlay from the DOM
     */
    remove(): void {
        const element = document.getElementById(this.domId);
        if (element != null) {
            element.remove();
        }
    }
}
