import { Component, OnInit } from '@angular/core';
import { GknTipsAndTools, MarketingWpService } from '@services';
import { Observable, ReplaySubject, tap } from 'rxjs';

@Component({
    selector: 'app-tips-and-tools',
    templateUrl: './tips-and-tools.component.html',
    styleUrls: ['./tips-and-tools.component.css'],
})
export class TipsAndToolsComponent implements OnInit {
    loaded$: ReplaySubject<boolean> = new ReplaySubject(1);
    tipsAndTools$: Observable<GknTipsAndTools[]>;

    constructor(private keplerMarketingService: MarketingWpService) {}

    ngOnInit(): void {
        this.tipsAndTools$ = this.keplerMarketingService.tipsAndTools$.pipe(
            tap(() => {
                this.loaded$.next(true);
            }),
        );
    }
}
