import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class ContentInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const urlToMatch = environment.api.cms.wp;
        if (req.url.startsWith(urlToMatch)) {
            const clone = req.clone({
                setParams: {
                    per_page: '100', // max wordpress paging size without modifying server/theme. filtering REST API response data is not supported natively.
                    acf_format: 'standard', // format advanced custom fields using the standard format, which includes full thumbnail URLs instead of just IDs for media.
                },
            });
            return next.handle(clone);
        } else {
            return next.handle(req);
        }
    }
}
