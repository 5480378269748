import { aspectRatio } from '../../utils/aspect-ratio';

/**
 * Properties for an overlay component
 */
export type OverlayProps = {
    alignItems?: string;
    bottom?: string;
    borderRadius?: string;
    childNodes: HTMLElement[];
    flexDirection?: string;
    height?: string;
    hidden: boolean;
    id: string;
    justifyContent?: string;
    left?: string;
    margin?: string;
    padding?: string;
    right?: string;
    top?: string;
    width?: string;
    opacity?: string;
};

/**
 * Service for overlays
 */
export class OverlayHtmlElement {
    /**
     * Builds an overlay component to show content on top of the video.
     *
     * By default, the overlay has the following properties:
     * - Positioned in the top-left corner of the video
     * - 100% the width of the video
     * - Displays {@link OverlayProps.childNodes} using flex box
     *    - Column. Use {@link OverlayProps.flexDirection} to override
     *    - Centered. Use {@link OverlayProps.alignItems} and {@link OverlayProps.justifyContent} to override.
     *
     * The overlay background is fixed to an 80% opaque, white color.
     *
     * @param {OverlayProps} props {@link OverlayProps} for this component
     * @returns {HTMLElement} an {@link HTMLElement}
     */
    buildHtml(props: OverlayProps): HTMLElement {
        aspectRatio.updateCurrentOverlay(props);

        const root = document.createElement('div');
        root.id = props.id;
        root.hidden = props.hidden === true ? true : false;
        root.style.position = 'absolute';
        root.style.display = 'flex';
        root.style.flexDirection = props.flexDirection || 'column';
        root.style.alignItems = props.alignItems || 'center';
        root.style.justifyContent = props.justifyContent || 'center';
        root.style.height = props.height || 'fit-content';
        root.style.width = props.width || '100%';
        root.style.padding = props.padding || '';
        root.style.margin = props.margin || '';
        root.style.top = props.top || '0';
        root.style.right = props.right || '';
        root.style.bottom = props.bottom || '';
        root.style.left = props.left || '0';
        root.style.borderRadius = props.borderRadius || '';
        root.style.background = `rgba(255, 255, 255, ${props.opacity || 0.8})`;
        root.style.boxShadow = '0 5px 4px rgba(0, 0, 0, 0.5)';

        props.childNodes.forEach((child) => root.appendChild(child));

        return root;
    }
}
