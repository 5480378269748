import { Observable } from 'rxjs';

export enum SECTION_LINKS {
    // TODO: reference favoriteId enum instead of duplicating strings statically
    // ex: MY_FAVORITES = FavoriteId.PRODUCT_FUNDAMENTALS
    MY_FAVORITES = 'my-favorites',
    JUST_FOR_ME = 'just-for-me',
    PRODUCT_FUNDAMENTALS = 'product-fundamentals',
    NEWS_FROM_GENESYS = 'news-from-genesys',
    LATEST_DISCUSSIONS = 'latest-discussions',
    COMMUNITY_QA_SHOW = 'community-qa-show',
    RELATED_COMMUNITIES = 'related-communities',
    VIRTUAL_AND_PHYSICAL_EVENTS = 'marketing-events',
    CONNECT_WITH_GENESYS = 'connect-with-genesys',
    BEYOND_TRAINING = 'genesys-beyond',
    CERTIFICATIONS_AND_BADGES = 'certifications',
    CS_ACADEMY_TRAINING = 'cs-academy-training',
    EDU_EVENTS_AND_WEBINARS = 'webinars',
    GET_STARTED = 'get-started',
    CX_EVOLUTION = 'cx-evolution',
    PRODUCT_HELP = 'product-help',
    APP_FOUNDRY = 'app-foundry',
    CUSTOMER_SUCCESS = 'customer-success',
    DEVELOPER_CENTER = 'developer-center',
    DEVELOPER_PORTAL_PURECONNECT = 'developer-portal-popular', // KNOW-2148 Do not change string value. it is "popular" for backward compatibility.
    GENESYS_EXPERT_APPS = 'expert-apps',
    MY_CASES = 'support-cases',
    PRODUCT_SUPPORT_CENTER = 'product-support-center',
    MY_CONTACTS = 'my-contacts',
    GKN_STORIES = 'gkn-stories',
    ARCHIVES = 'archives',
    RECOMMENDATIONS = 'recommendations',
}

export interface SubmenuLink {
    url: string;
    sections: SubmenuLinkSection[];
}

export interface SubmenuLinkSection {
    label$: Observable<string>;
    location: SECTION_LINKS;
    hidden$: Observable<boolean>;
}
