<div class="beyond-icon-container">
    <img class="beyond-icon" src="/assets/icons/beyond/logo-beyond.svg" width="32px" height="32px" alt="Beyond icon" />
    <app-tile-vertical
        [title]="beyondTrainingTile.title | stripHtml"
        [titleLink]="true"
        [titleMinHeight]="false"
        [bigImage]="bigImage"
        [vertical]="vertical"
        [isCustomImage]="true"
        [customImageCss]="!isMobileView ? customImageCss : customMobileImageCss"
        [url]="beyondTrainingTile.url"
        [urlLabel]="beyondTrainingTile.cta"
        [description]="beyondTrainingTile.description | stripHtml"
        [icon]="beyondTrainingTile.subtitleIcon"
        [details]="subtitle$ | async"
        [border]="false"
        [dataAnalytics]="dataAnalytics">
    </app-tile-vertical>
</div>
