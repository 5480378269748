<app-fundamentals-card title="Release Notes" icon="/assets/icons/radio-tower.svg" [isLoading]="isLoading">
    <ul>
        <ng-container *ngFor="let link of releaseNotes$ | async; let i = index">
            <li *ngIf="link.isButton">
                <button
                    mat-flat-button
                    class="btn"
                    [color]="colors[i] ? colors[i] : (colors[i] = 'accent')"
                    (mouseover)="colors[i] = 'primary'"
                    (mouseout)="colors[i] = 'accent'"
                    (click)="handleOpenDialog(link)"
                    [attr.data-analytics]="link.dataAnalytics?.join('-')">
                    {{ link.text }}
                </button>
            </li>

            <ng-container>
                <li *ngIf="!link.isButton">
                    <button
                        mat-button
                        class="mat-button-link"
                        *products="[
                            productEnum.GENESYS_CLOUD_CX,
                            productEnum.GENESYS_MULTICLOUD,
                            productEnum.GENESYS_ENGAGE_ON_PREM
                        ]"
                        (click)="handleOpenDialog(link)"
                        [attr.data-analytics]="link.dataAnalytics?.join('-')">
                        {{ link.text }}
                    </button>
                    <a
                        mat-button
                        color="accent"
                        class="mat-button-link"
                        *products="[productEnum.POINTILLIST, productEnum.PURECONNECT]"
                        [href]="link.url"
                        target="_blank"
                        [attr.data-analytics]="link.dataAnalytics?.join('-')">
                        {{ link.text }}
                    </a>
                </li>
            </ng-container>

            <ng-container *products="[productEnum.GENESYS_MULTICLOUD, productEnum.GENESYS_ENGAGE_ON_PREM]">
                <li *ngIf="i == 1">
                    <h6 class="sub-title-3 text-brand-navy m-0">Private Edition</h6>
                </li>
            </ng-container>
        </ng-container>
    </ul>
</app-fundamentals-card>
