import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TrackByItem } from '@components';
import { ContentService, GknFooter, Product, ProductService, UserService } from '@services';
import { Observable, lastValueFrom, map, timer } from 'rxjs';
import { GknRouteConfig } from 'src/app/app-routing.config';

@Component({
    selector: 'app-mobile-viewport-menu',
    templateUrl: './mobile-viewport-menu.component.html',
    styleUrls: ['./mobile-viewport-menu.component.css'],
})
export class MobileViewportMenuComponent
    implements OnInit, AfterViewInit, TrackByItem<GknRouteConfig[keyof GknRouteConfig]>
{
    /** router link config */
    @Input() navbarRouteKeys: GknRouteConfig[keyof GknRouteConfig][];
    /** event emitter to handle sign in */
    @Output() signIn = new EventEmitter<void>();
    /** event emitter to handle sign out */
    @Output() signOut = new EventEmitter<void>();
    /** event emitter to handle closing this component */
    @Output() closeMenu = new EventEmitter<void>();
    /** event emitter to open the profile component as an overlay (not profile page) */
    @Output() openMobileProfile = new EventEmitter<void>();
    /** event emitter to signal initialization for the google translate widget */
    @Output() cleanAndInitGoogleTranslateWidgetEmitter = new EventEmitter<void>();
    /** indicates whether the current user is authenticated */
    isAuthenticated$: Observable<boolean>;
    /** the currently selected product */
    selectedProduct$: Observable<Product>;
    /** logged in user's initials */
    initials$: Observable<string>;
    /** genesys privacy policy */
    privacyPolicy$: Observable<GknFooter>;
    /** genesys terms of use */
    termsOfUse$: Observable<GknFooter>;

    constructor(
        private contentService: ContentService,
        private productService: ProductService,
        private userService: UserService,
    ) {}

    ngOnInit(): void {
        this.selectedProduct$ = this.productService.selectedProduct$;
        this.isAuthenticated$ = this.userService.isAuthenticated$;
        this.initials$ = this.userService.initials$;
        this.privacyPolicy$ = this.contentService.footers$.pipe(
            map((footers) => footers.find((f) => f.type == 'privacy_policy')),
        );
        this.termsOfUse$ = this.contentService.footers$.pipe(
            map((footers) => footers.find((f) => f.type == 'terms_of_use')),
        );
    }

    ngAfterViewInit(): void {
        this.cleanAndInitGoogleTranslateWidgetEmitter.emit();
    }

    handleSignIn(event?: Event): void {
        event?.stopPropagation();
        this.signIn.emit();
    }

    handleSignOut(event?: Event): void {
        event?.stopPropagation();
        this.signOut.emit();
    }

    handleCloseMenu(event?: Event): void {
        event?.stopPropagation();
        this.closeMenu.emit();
    }

    async handleOpenMobileProfile(event?: Event): Promise<void> {
        event?.stopPropagation();
        this.openMobileProfile.emit();

        // Always close the primary menu after opening the mobile profile page (component).
        // 600ms is the same as the css transition in app.component.css
        return lastValueFrom(timer(600)).then(() => {
            this.handleCloseMenu();
        });
    }

    trackByItem(_index: number, item: GknRouteConfig[keyof GknRouteConfig]): NonNullable<number | string> {
        return item.label;
    }
}
