import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import { GknVirtualAndPhysicalEvent, KeplerMarketingVpEvent } from './interfaces';

@Injectable({
    providedIn: 'root',
})
export class KeplerMarketingAdapter {
    private readonly baseAssetsPath = 'assets/icons/marketing-events';

    adaptVpEvent(vpEvent: KeplerMarketingVpEvent): GknVirtualAndPhysicalEvent {
        return {
            title: vpEvent?.title,
            subtitle: this.capitalize(vpEvent),
            subtitleIcon: this.svg(vpEvent, false),
            excerpt: vpEvent?.excerpt,
            url: vpEvent?.url,
            cta: this.cta(vpEvent),
            thumbnail:
                vpEvent?.thumbnails?.large ??
                vpEvent?.thumbnails?.full ??
                vpEvent?.thumbnails?.medium ??
                this.svg(vpEvent, true),
            location: {
                city: vpEvent?.location?.city,
                state: vpEvent?.location?.state,
                country: vpEvent?.location?.country,
                rendered: vpEvent?.location?.rendered,
            },
            duration: {
                startDate: vpEvent?.duration?.startDateTime ? dayjs(vpEvent?.duration?.startDateTime) : null,
                endDate: vpEvent?.duration?.endDateTime ? dayjs(vpEvent?.duration?.endDateTime) : null,
            },
        };
    }

    private capitalize(vpEvent: KeplerMarketingVpEvent): string {
        return vpEvent?.type?.charAt(0).toUpperCase() + vpEvent?.type?.substring(1).toLowerCase();
    }

    private svg(vpEvent: KeplerMarketingVpEvent, whiteFill: boolean): string {
        switch (vpEvent?.type) {
            case 'webinar':
            // fall-through
            case 'on-demand':
                return `${this.baseAssetsPath}/content-tags-webinar${whiteFill ? '_white' : ''}.svg`;
            case 'podcast':
                return `${this.baseAssetsPath}/content-tags-tech-talks${whiteFill ? '_white' : ''}.svg`;
            case 'event':
                return `${this.baseAssetsPath}/content-tags-inperson${whiteFill ? '_white' : ''}.svg`;
            default:
                console.warn(`Unhandled virtual and physical event type "${vpEvent?.type}".`);
                return `${this.baseAssetsPath}/content-tags-inperson${whiteFill ? '_white' : ''}.svg`;
        }
    }

    private cta(vpEvent: KeplerMarketingVpEvent): string {
        switch (vpEvent?.type) {
            case 'on-demand':
                return 'Watch now';
            case 'podcast':
                return 'Tech Talks in Twenty';
            case 'event':
            // fall-through
            case 'webinar':
                return 'Learn more';
            default:
                console.warn(`Unhandled virtual and physical event type "${vpEvent?.type}".`);
                return 'Learn more';
        }
    }
}
