import { Component, OnInit } from '@angular/core';

import { ContentService, GknUsefulLink } from '@services';
import { Observable, ReplaySubject, tap } from 'rxjs';

@Component({
    selector: 'app-useful-links',
    templateUrl: './useful-links.component.html',
    styleUrls: ['./useful-links.component.css'],
})
export class UsefulLinksComponent implements OnInit {
    loaded$: ReplaySubject<boolean> = new ReplaySubject(1);
    usefulLinks$: Observable<GknUsefulLink[]>;

    constructor(private contentService: ContentService) {}

    ngOnInit(): void {
        this.usefulLinks$ = this.contentService.usefulLinks$.pipe(
            tap(() => {
                this.loaded$.next(true);
            }),
        );
    }
}
