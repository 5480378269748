import { Component, OnInit } from '@angular/core';
import { TrackByItem } from '@components';
import { ContentService } from '@services';
import { BehaviorSubject, Observable, ReplaySubject, filter, switchMap, tap } from 'rxjs';
import { GknOnboardingWebinar } from '../../../@shared/services/content/interfaces/gkn-onboarding-webinar.interface';

@Component({
    selector: 'app-onboarding-webinars',
    templateUrl: './onboarding-webinars.component.html',
    styleUrls: ['./onboarding-webinars.component.css'],
})
export class OnboardingWebinarsComponent implements OnInit, TrackByItem<GknOnboardingWebinar> {
    loaded$: ReplaySubject<boolean> = new ReplaySubject(1);

    webinars$: Observable<GknOnboardingWebinar[]>;

    showLoadMoreButton$: BehaviorSubject<boolean> = new BehaviorSubject(true);
    maxItemsToLoad$: BehaviorSubject<number> = new BehaviorSubject(6);
    changeDetector: any;
    webinarsLength: number;

    constructor(private contentService: ContentService) {}

    ngOnInit(): void {
        this.webinars$ = this.contentService.onboardingWebinars$.pipe(
            tap((webinars) => {
                this.webinarsLength = webinars?.length;
                this.loaded$.next(true);
            }),
        );

        this.showLoadMoreButton$.pipe(
            filter((showLoadMoreButton) => showLoadMoreButton === false),
            switchMap(() => this.webinars$),
            tap((webinars) => {
                this.maxItemsToLoad$.next(webinars?.length);
            }),
        );
    }

    /**
     * Loads more tiles
     * @param event click event
     */
    loadMore(event: Event): void {
        event.stopPropagation();
        this.maxItemsToLoad$.next(30);
        this.showLoadMoreButton$.next(false);
    }

    trackByItem(_index: number, item: GknOnboardingWebinar): NonNullable<number | string> {
        return item.id;
    }
}
