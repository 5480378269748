import { AfterContentInit, ChangeDetectorRef, Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { KeplerPurecloudService, KeplerPurecloudUser, UserService } from '@services';
import { Subscription, map, switchMap, tap } from 'rxjs';
import 'video.js';
import 'videojs-markers';
import { app } from './app/app';

@Component({
    selector: 'app-welcome-video',
    templateUrl: './welcome-video.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./welcome-video.component.css'],
})
export class WelcomeVideoComponent implements AfterContentInit, OnDestroy {
    isLoading = true;
    private subs: Subscription[] = [];

    constructor(
        private cdr: ChangeDetectorRef,
        private keplerPurecloudService: KeplerPurecloudService,
        private userService: UserService,
    ) {}

    ngAfterContentInit(): void {
        this.subs.push(
            this.userService.claims$
                .pipe(
                    map((claims) => claims.email),
                    switchMap((email) => this.keplerPurecloudService.employeeSearch$(email)),
                    tap((user: KeplerPurecloudUser) => {
                        app(user).then(() => {
                            this.isLoading = false;
                            this.cdr.detectChanges();
                        });
                    }),
                )
                .subscribe(),
        );
    }

    ngOnDestroy(): void {
        if (this.subs) {
            this.subs.forEach((sub) => {
                sub.unsubscribe();
            });
        }
    }
}
