import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HoverMenuComponent } from './hover-menu.component';

@NgModule({
    declarations: [HoverMenuComponent],
    imports: [CommonModule, RouterModule],
    exports: [HoverMenuComponent],
})
export class HoverMenuModule {}
