import { Directive, ElementRef, EventEmitter, HostListener, OnInit, Output, Renderer2 } from '@angular/core';

/**
 * Reference Link: https://stackoverflow.com/questions/57309735/detect-click-into-iframe-using-angular
 */
@Directive({
    selector: '[appIframeTracker]',
})
export class IframeTrackerDirective implements OnInit {
    private iframeMouseOver: boolean;
    @Output() iframeClick = new EventEmitter<ElementRef>();

    constructor(private el: ElementRef, private renderer: Renderer2) {}

    /**
     * OnInit - add a window blur event handling
     */
    ngOnInit(): void {
        this.renderer.listen(window, 'blur', () => this.onWindowBlur());
    }

    /**
     * Add host listener to native mouse over event handling on iframe
     */
    @HostListener('mouseover')
    private onIframeMouseOver(): void {
        this.iframeMouseOver = true;
        this.resetFocusOnWindow();
    }

    /**
     * Add host listener to native mouse event handling on iframe
     */
    @HostListener('mouseout')
    private onIframeMouseOut(): void {
        this.iframeMouseOver = false;
        this.resetFocusOnWindow();
    }

    /**
     * On Window blur reset focus on window event handling on iframe
     */
    private onWindowBlur(): void {
        if (this.iframeMouseOver) {
            this.resetFocusOnWindow();
            this.iframeClick.emit(this.el);
        }
    }

    /**
     * Reset window focus on set timeout on iframe
     */
    private resetFocusOnWindow(): void {
        setTimeout(() => {
            window.focus();
        }, 100);
    }
}
