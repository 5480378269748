import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ComponentsModule } from '@components';
import { PipesModule } from '@pipes';
import { UseCasesComponent } from './use-cases.component';

@NgModule({
    declarations: [UseCasesComponent],
    imports: [CommonModule, ComponentsModule, PipesModule],
    exports: [UseCasesComponent],
})
export class ProductHelpUseCasesModule {}
