import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaintenanceAlertComponent } from './maintenance-alert.component';

@NgModule({
    declarations: [MaintenanceAlertComponent],
    imports: [CommonModule],
    exports: [MaintenanceAlertComponent],
})
export class MaintenanceAlertModule {}
