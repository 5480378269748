<ng-container *ngIf="(isMobile$ | async) === false; else mobileLayout">
    <app-tile-layout maxColumns="6">
        <ng-container *ngTemplateOutlet="additionalSites"></ng-container>
    </app-tile-layout>
</ng-container>
<ng-template #mobileLayout>
    <app-tile-layout maxColumns="2" [multiColumnMobile]="true">
        <ng-container *ngTemplateOutlet="additionalSites"></ng-container>
    </app-tile-layout>
</ng-template>
<ng-template #additionalSites>
    <li *ngFor="let item of additionalSites$ | async; trackBy: trackByItem">
        <a class="cursor neutral-link" [href]="item.url" target="_blank" rel="nofollow">
            {{ item.label }}
        </a>
    </li>
</ng-template>
