import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ComponentsModule } from '@components';
import { CustomerSuccessComponent } from './customer-success.component';

@NgModule({
    declarations: [CustomerSuccessComponent],
    imports: [CommonModule, ComponentsModule],
    exports: [CustomerSuccessComponent],
})
export class CustomerSuccessModule {}
