import { ActivatedRoute, Router } from '@angular/router';
import { Observable, map, skip, tap } from 'rxjs';

import { Injectable } from '@angular/core';
import { ProductUid } from '@enums';

@Injectable({
    providedIn: 'root',
})
export class ProductQueryParamService {
    constructor(private activatedRoute: ActivatedRoute, private router: Router) {}

    /**
     * Observes the activated routes query params for a `product` key
     * @returns observable {@link ProductUid} representing a new queried product, or undefined if not present
     */
    get productQueryParam$(): Observable<ProductUid | undefined> {
        return this.activatedRoute.queryParamMap.pipe(
            // queryParamMap is initialized as an empty BehaviorSubject,
            // so skip 1 to get the next value of the subject, the expected params.
            // https://github.com/angular/angular/issues/12157#issuecomment-350188651
            skip(1),
            map((queryParams) => queryParams.get('product')),
            tap((queryProductUid: ProductUid) => {
                console.debug('observed query product', queryProductUid);
            }),
        );
    }

    /**
     * Sets the `product` query in the browser address bar. This will NOT refresh
     * the browser, but it will push the changes into the browser's history so that
     * native back/forward navigation persists.
     *
     * @param uid to set
     * @throws on falsy uid
     */
    setProductQueryParam(uid: ProductUid): void {
        if (!uid) {
            throw new Error('Unable to update product query param due to a falsy UID');
        }

        const queryParams = { product: uid };
        console.debug('merging product query params', queryParams);

        this.router
            .navigate(
                [], // no url necessary
                {
                    relativeTo: this.activatedRoute, // without page reload
                    queryParams: queryParams,
                    queryParamsHandling: 'merge', // merge with existing query params, like search term.
                    preserveFragment: true, // perserve navigational url fragment
                },
            )
            .then((success) => {
                if (success) {
                    console.debug('successfully merged product query params', queryParams);
                }
            });
    }
}
