import { CmsGuid, CmsLinks } from './cms-core.interface';

export interface CmsFundamental {
    id?: number;
    date?: string;
    date_gmt?: string;
    guid?: CmsGuid;
    modified?: string;
    modified_gmt?: string;
    slug?: string;
    status?: string;
    type?: string;
    link?: string;
    title?: CmsGuid;
    author?: number;
    template?: string;
    acf?: CmsFundamentalAcf;
    _links?: CmsLinks;
}

export interface CmsFundamentalAcf {
    type?: CmsFundamentalAcfType;
    release_date?: string;
    label?: string;
    url?: string;
    label_2?: string;
    url_2?: string;
    quick_start?: string;
    statuses?: string;
    title?: string;
    body?: string;
    pdf_download?: string;
    video?: string;
    products?: string[];
    user_roles?: string[];
}

export enum CmsFundamentalAcfType {
    PRODUCT_IDEAS_LAB = 'product_ideas_lab',
    RELEASE_NOTE = 'release_note',
    ROADMAP = 'roadmap',
    ROADMAP_ARCHIVE = 'roadmap_archive',
}
