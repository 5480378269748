import { Component } from '@angular/core';
import { TrackByItem } from '@components';
import { DeveloperCenterData } from '../developer-center.interface';
import { toolsData } from './tools-data';

@Component({
    selector: 'app-tools',
    templateUrl: './tools.component.html',
    styleUrls: ['./tools.component.css'],
})
export class ToolsComponent implements TrackByItem<DeveloperCenterData> {
    developerCenterData: DeveloperCenterData;

    constructor() {
        this.developerCenterData = toolsData;
    }

    trackByItem(_index: number, item: DeveloperCenterData): NonNullable<number | string> {
        return item.baseUrl;
    }
}
