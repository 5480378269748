import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { KeplerVimeoResponse } from './kepler-vimeo.interface';

@Injectable({
    providedIn: 'root',
})
export class KeplerVimeoService {
    constructor(private http: HttpClient) {}

    getVideos(channelId = ''): Observable<KeplerVimeoResponse> {
        const body = {
            channelId: channelId || environment.api.kepler.vimeo.channelId,
        };
        return this.http.post<KeplerVimeoResponse>(`${environment.api.kepler.vimeo.url}/videos/`, body);
    }
}
