<!-- <button
    (click)="openModal($event)"
    class="share-button button-link cursor link-n"
    [ngClass]="customIconCss ? customIconCss : ''"
    [attr.data-analytics]="dataAnalyticsOpen">
    <img src="assets/icons/share/share-arrow.svg" width="30px" height="30px" alt="Open modal for share options" />
</button>

<div
    class="share-modal"
    [ngClass]="isRightSide ? 'share-modal-right' : ''"
    [class.carousel-modal]="isCarousel"
    *ngIf="modalOpen">
    <div class="share-modal-header">
        <span class="share-title">Share</span>
        <button (click)="closeModal()" class="close-share-modal" [attr.data-analytics]="dataAnalyticsClose">
            <img src="assets/icons/close-modal.svg" class="close-icon" width="9px" height="10px" alt="Close modal" />
        </button>
    </div>
    <div class="share-email">
        <a
            target="_blank"
            [href]="mailToLink"
            class="cursor share-email-button"
            rel="noopener noreferrer nofollow"
            [attr.data-analytics]="dataAnalyticsEmail">
            <img
                src="assets/icons/share/contact-email.svg"
                class="email-icon"
                width="19px"
                height="13px"
                alt="Share article via email" />
        </a>
    </div>
    <div class="copy-message">
        <span class="copy-link">Copy link </span><span *ngIf="linkCopied" class="link-copied">copied</span>
    </div>
    <div class="share-text">
        <input id="shareText" class="share-input" type="text" [value]="link" />
        <button
            id="copyButton"
            (click)="copyText()"
            class="button-link cursor link-n"
            [attr.data-analytics]="dataAnalyticsCopy">
            <img
                src="assets/icons/share/ui-icons-copy.svg"
                class="copy-icon"
                width="14px"
                height="14px"
                alt="Copy article link to clipboard" />
        </button>
    </div>
</div> -->

<img
    src="/assets/icons/copy-link.svg"
    alt="copy link icon"
    title="Copy link"
    class="cursor icon-external"
    [ngStyle]="{ 'margin-right': '10px' }"
    [attr.data-analytics]="dataAnalyticsCopy"
    (click)="copyResourceLinkForSharing()" />
