import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { scrollToTopScrolling } from '@utils';
//import ResizeObserver from 'resize-observer-polyfill';

@Component({
    selector: 'app-back-to-top',
    templateUrl: './back-to-top.component.html',
    styleUrls: ['./back-to-top.component.css'],
})
export class BackToTopComponent {
    readonly scrollToTopButtonClass = 'scrollToTopBtn';
    constructor(@Inject(DOCUMENT) private document: Document) {
        this.bodyHeightObserverSetup();
    }

    /**
     * Sets up observer that triggers an event whenever the body size changes
     * See the Reference links below:
     * {@link https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver/observe }
     * {@link https://stackoverflow.com/questions/41304968/how-to-get-on-scroll-events }
     * {@link https://css-tricks.com/how-to-make-an-unobtrusive-scroll-to-top-button/ }
     */

    bodyHeightObserverSetup(): void {
        const resizeObserver = new ResizeObserver(() => {
            const documentHeight = this.document.documentElement.scrollHeight;
            const windowHeight = window.innerHeight;
            const breakPoint = windowHeight * 3; // Sets breakpoint 3 times larger than the window

            // if the document height is larger than the breakpoint
            if (documentHeight > breakPoint) {
                // Show button
                if (this.document.getElementById(this.scrollToTopButtonClass)) {
                    this.document.getElementById(this.scrollToTopButtonClass).style.display = 'flex';
                }
            } else {
                // Hide button
                if (this.document.getElementById(this.scrollToTopButtonClass)) {
                    this.document.getElementById(this.scrollToTopButtonClass).style.display = 'none';
                }
            }
        });
        resizeObserver.observe(this.document.body);
    }

    /**
     * Scroll window position to top
     */
    scrollToTop(): void {
        scrollToTopScrolling();
    }
}
