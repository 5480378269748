import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ComponentsModule } from '@components';
import { DirectivesModule } from '@directives';
import { CxEvolutionCardComponent } from './cx-evolution-card.component';

@NgModule({
    declarations: [CxEvolutionCardComponent],
    imports: [CommonModule, DirectivesModule, ComponentsModule],
    exports: [CxEvolutionCardComponent],
})
export class CxEvolutionCardModule {}
