import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { TrackByItem } from '@components';
import { StarterKitStep } from '../starter-kit.interface';

@Component({
    selector: 'app-starter-kit-steps',
    templateUrl: './starter-kit-steps.component.html',
    styleUrls: ['./starter-kit-steps.component.css'],
})
export class StarterKitStepsComponent implements OnInit, OnChanges, TrackByItem<StarterKitStep> {
    @Input() steps: StarterKitStep[];
    @Input() isMobile = false;
    @Input() selectedIndex: number;
    @Input() access: boolean;
    @Input() showModal$: Subject<boolean>;
    @Input() updateToLatestStep$: Observable<boolean>;
    @Input() order: boolean;

    @Output() selectStep = new EventEmitter<number>();
    @Output() isDoneWithStarterKit = new EventEmitter<boolean>();

    largestAccessibleIndex = -1;

    constructor(private cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.goToProperIndex();
        this.updateToLatestStep$?.subscribe((updateAndGoTo) => {
            this.cdr.detectChanges();
            if (updateAndGoTo) {
                this.goToProperIndex(true);
            } else {
                this.determineLargestAccessibleIndex();
            }
        });
    }

    goToProperIndex(fromTabSwitch = false) {
        this.determineLargestAccessibleIndex();
        this.select(this.order || this.isMobile ? this.largestAccessibleIndex : 0, fromTabSwitch);
    }

    determineLargestAccessibleIndex() {
        if (!this.access) {
            return;
        }
        if (this.steps.find((step) => step.completed !== true) == undefined) {
            this.isDoneWithStarterKit.emit(true);
        } else {
            this.isDoneWithStarterKit.emit(false);
        }
        if (this.isMobile) {
            this.largestAccessibleIndex = this.steps.findIndex((step) => !step.mobileAllowed) - 1;
            return;
        }
        if (!this.order) {
            this.largestAccessibleIndex = this.steps.length - 1;
            return;
        }
        for (let i = 0; i < this.steps.length; i++) {
            if (!this.steps[i].completed) {
                this.largestAccessibleIndex = i;
                return;
            }
        }
        // implies completed starter kit
        this.largestAccessibleIndex = this.steps.length - 1;
    }

    trackByItem(_index: number, step: StarterKitStep): NonNullable<number | string> {
        return step.name;
    }

    ngOnChanges(): void {
        this.determineLargestAccessibleIndex();
    }

    select(index: number, fromTabSwitch = false) {
        if (
            index == -1 ||
            (index <= this.largestAccessibleIndex &&
                (!this.isMobile || (this.isMobile && this.steps[index].mobileAllowed)))
        ) {
            this.selectStep.emit(index);
            if (this.isMobile && !fromTabSwitch) {
                this.showModal$.next(true);
            }
        }
    }
}
