<app-fundamentals-card title="Product Ideas Lab" icon="/assets/icons/light-bulb.svg" [isLoading]="isLoading">
    <ul>
        <ng-container *ngFor="let link of links$ | async; let i = index">
            <li *ngIf="link.isButton">
                <a
                    mat-flat-button
                    class="button"
                    [color]="colors[i] ? colors[i] : (colors[i] = 'accent')"
                    (mouseover)="colors[i] = 'primary'"
                    (mouseout)="colors[i] = 'accent'"
                    [href]="link.url"
                    target="_blank"
                    [attr.data-analytics]="link.dataAnalytics?.join('-')">
                    {{ link.text }}
                </a>
            </li>

            <ng-container>
                <li *ngIf="!link.isButton">
                    <a
                        mat-button
                        class="mat-button-link"
                        [color]="colors[i] ? colors[i] : (colors[i] = 'accent')"
                        (mouseover)="colors[i] = 'primary'"
                        (mouseout)="colors[i] = 'accent'"
                        [href]="link.url"
                        [attr.data-analytics]="link.dataAnalytics?.join('-')">
                        {{ link.text }}
                        <mat-icon>download</mat-icon>
                    </a>
                </li>
            </ng-container>
        </ng-container>
    </ul>
</app-fundamentals-card>
