/**
 * Enumerates the different community discussion keys needed by the application.
 */
export enum CommunityDiscussionKey {
    GENNOVATOR = 'efec353e-c187-4b23-a2ce-07dba1299da1',
    GENESYS_CLOUD_CX = '510ed02d-5f53-4ff8-a945-f8f0b2847056',
    GENESYS_DX = '6a7acae9-5afb-4eac-8880-da125642b77e',
    GENESYS_ENGAGE_ON_PREM = '70f29c57-38a5-492d-9b65-bea137aff1b5',
    GENESYS_MULTICLOUD_CX = 'f1e7a021-ab80-4e80-bec7-e9d216109e9a',
    PURECONNECT = '0a3f2c80-7980-4efe-9f4c-a8bbd2b061f8',
    POINTILLIST = '',
    CERTIFICATION_AND_TRAINING = '0ec8b0f6-916f-4b51-bb34-6eeb3ef072f3',
    GENESYS_CLOUD_CX_BETA_HQ = '36158742-9f76-42d0-9ab6-fc6122134425',
    WORKFORCE_ENGAGEMENT_MANAGEMENT = 'd80a05d8-4c6c-4aee-93a6-3d22d97f85ca',
}

/**
 * Enumerates the different community keys needed by the application.
 */
export enum CommunityKey {
    GENNOVATOR = '16f9c9f3-dff1-498c-a32a-9313a8b560ad',
    GENESYS_CLOUD_CX = 'bab95e9c-6bbe-4a13-8ade-8ec0faf733d4',
    GENESYS_DX = '243620e0-b9c0-4f12-8d47-46b5131107d5',
    GENESYS_ENGAGE_ON_PREM = '04e76a4a-2a03-4059-ab66-82e058125372',
    GENESYS_MULTICLOUD_CX = '1d65ddfe-95e6-4f83-a3a4-961f097e0a84',
    PURECONNECT = 'cf214c8f-5206-4010-9b2c-2085cbd65a44',
    CERTIFICATION_AND_TRAINING = 'e2dd9cd4-4d04-40dd-9794-2d625061effb',
    GENESYS_CLOUD_CX_BETA_HQ = 'cbd2f140-168a-4c15-aacb-af2b23aa4329',
    WORKFORCE_ENGAGEMENT_MANAGEMENT = 'f6e20f73-2c94-4b44-be95-118216aafb4f',
}

/**
 * Enumerates the different community discussion URL fragments needed by the application.
 *
 * Note: Computed values are not permitted in an enum with string valued members.
 * Thus, we cannot reference the CommunityKey enum above.
 */
export enum CommunityDiscussionUrl {
    GENNOVATOR = 'gennovators?CommunityKey=16f9c9f3-dff1-498c-a32a-9313a8b560ad',
    GENESYS_CLOUD_CX = 'genesyscloudcx?CommunityKey=bab95e9c-6bbe-4a13-8ade-8ec0faf733d4',
    GENESYS_DX = 'dx?CommunityKey=243620e0-b9c0-4f12-8d47-46b5131107d5',
    GENESYS_ENGAGE_ON_PREM = 'genesysengage?CommunityKey=04e76a4a-2a03-4059-ab66-82e058125372',
    GENESYS_MULTICLOUD_CX = 'genesysmulticloudcx?CommunityKey=1d65ddfe-95e6-4f83-a3a4-961f097e0a84',
    PURECONNECT = 'pureconnect?CommunityKey=cf214c8f-5206-4010-9b2c-2085cbd65a44',
    POINTILLIST = '',
}

/**
 * Enumerates the different community names needed by the application.
 */
export enum CommunityName {
    GENNOVATOR = 'Gennovator',
    GENESYS_CLOUD_CX = 'Genesys Cloud',
    GENESYS_DX = 'Genesys DX',
    GENESYS_ENGAGE_ON_PREM = 'Genesys Engage on-premises',
    GENESYS_MULTICLOUD_CX = 'Genesys Multicloud CX',
    PURECONNECT = 'PureConnect',
    POINTILLIST = 'Pointillist',
    CERTIFICATION_AND_TRAINING = 'Certification and Training',
    GENESYS_CLOUD_CX_BETA_HQ = 'Genesys Cloud - Beta HQ',
    WORKFORCE_ENGAGEMENT_MANAGEMENT = 'Workforce Engagement Management (WEM)',
}
