import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ComponentsModule } from '@components';
import { DeveloperCenterMcCxComponent } from './developer-center-mc-cx.component';

@NgModule({
    declarations: [DeveloperCenterMcCxComponent],
    imports: [CommonModule, ComponentsModule],
    exports: [DeveloperCenterMcCxComponent],
})
export class DeveloperCenterMcCxModule {}
