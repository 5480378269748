import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { KeplerFacebookResponse } from './interfaces/kepler-facebook-response.interface';

@Injectable({
    providedIn: 'root',
})
export class KeplerFacebookService {
    constructor(private http: HttpClient) {}

    getPosts(): Observable<KeplerFacebookResponse> {
        return this.http.get<KeplerFacebookResponse>(`${environment.api.kepler.facebook.url}/posts`);
    }
}
