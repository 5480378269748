import { KeplerPurecloudUser } from '@services';
import videojs from 'video.js';
import { Overlay } from './components';
import { Marker } from './components/marker/marker.interface';
import {
    BookmarkKnowOverlay,
    BookmarkOneGenesysOverlay,
    CommunityOverlay,
    ElearningOverlay,
    RelaxOverlay,
    ResourcesOverlay,
    WelcomeOverlay,
} from './overlay';
import { EndSummaryOverlay } from './overlay/end-summary/end-summary.component';
import { aspectRatio } from './utils/aspect-ratio';

export function populateVideo(userData: KeplerPurecloudUser, videoPlayer: HTMLVideoElement) {
    const options = {
        controls: true,
        preload: 'auto',
        controlBar: { fullscreenToggle: false, pictureInPictureToggle: false },
        autoplay: true,
        muted: false,
        responsive: true,
    };

    videojs('videoPlayer', options as any).ready(function () {
        setupMarkersWithOverlays(this);
    });

    function setupMarkersWithOverlays(videoPlayer): void {
        const markers = [];
        overlays.forEach((overlay) => {
            markers.push(overlay.marker.start);
            markers.push(overlay.marker.end);
        });

        videoPlayer.markers({
            markers: markers,
            onMarkerReached: function (marker: Marker) {
                updateVideoOverLay(marker);
            },
        });
    }

    const root = document.getElementById('video-component');
    root.classList.add('tile-box-shadow');
    const endSummaryOverlay = new EndSummaryOverlay({ id: 'end-summary-overlay', user: userData });

    videoPlayer.ontimeupdate = function () {
        if (videoPlayer.currentTime > 62) {
            endSummaryOverlay.show(root);

            const replayButton = document.getElementById('replay-button');
            replayButton.addEventListener('click', replayButtonHandleClick);
        }
    };

    function replayButtonHandleClick(this: HTMLElement): void {
        endSummaryOverlay.hide();
        videoPlayer.currentTime = 0;
        videoPlayer.play();
    }

    let currentOverlay: Overlay;
    function updateVideoOverLay(marker): void {
        overlays.forEach((overlay) => {
            if (overlay.marker.start.text === marker.text) {
                currentOverlay = overlay.content;
                overlay.content.show(root);
            } else if (overlay.marker.end.text === marker.text) {
                overlay.content.hide();
            }
        });
        setTimeout(() => {
            removeAllNonCurrentOverlaysFromPage();
        }, 500); // wait 500ms for all hide() animations to complete
        aspectRatio.overlayOffset();
    }

    const overlays = [
        // Allow for at least `time: 0.2` between markers
        {
            content: new WelcomeOverlay({ id: 'welcome-overlay', user: userData, isThumbnail: false }),
            marker: WelcomeOverlay.marker,
        },
        {
            content: new RelaxOverlay({ id: 'relax-overlay' }),
            marker: RelaxOverlay.marker,
        },
        {
            content: new BookmarkKnowOverlay({ id: 'bookmark-know-overlay' }),
            marker: BookmarkKnowOverlay.marker,
        },
        {
            content: new CommunityOverlay({ id: 'communities-overlay' }),
            marker: CommunityOverlay.marker,
        },
        {
            content: new ResourcesOverlay({ id: 'resources-overlay' }),
            marker: ResourcesOverlay.marker,
        },
        {
            content: new BookmarkOneGenesysOverlay({ id: 'bookmark-one-genesys-overlay' }),
            marker: BookmarkOneGenesysOverlay.marker,
        },
        {
            content: new ElearningOverlay({ id: 'elearning-overlay' }),
            marker: ElearningOverlay.marker,
        },
    ];

    /**
     * Removes all overlays from the screen except the current overlay
     */
    function removeAllNonCurrentOverlaysFromPage() {
        overlays.forEach((overlay) => {
            if (currentOverlay != overlay.content) overlay.content.remove();
        });
    }
}
