import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from '@environments/environment';
import { AppModule } from './app/app.module';

addCookieConsentManager();

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => {
        if (!environment.production) console.error(err);
    });

function addCookieConsentManager() {
    const script = document.createElement('script');
    script.async = true;
    script.id = 'lvlzer0';
    script.setAttribute('data-gdprcm', 'true');
    script.setAttribute('data-gtm', 'true');
    script.setAttribute('data-gtm_id', 'GTM-TKNR763');
    script.src = 'https://web-fire.genesys.com/lvlzer0/lvlzer0.min.js';
    document.head.appendChild(script);
}
