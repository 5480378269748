import { DOCUMENT } from '@angular/common';
import {
    AfterViewChecked,
    Component,
    ContentChild,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    Output,
    ViewChild,
} from '@angular/core';

import { Theme } from '@enums';
import { environment } from '@environments/environment';
import { SigninWithCredentialsOptions } from '@okta/okta-auth-js';
import { isCookiesEnabled } from '@utils';

@Component({
    selector: 'app-login-modal',
    templateUrl: './login-modal.component.html',
    styleUrls: ['./login-modal.component.css'],
})
export class LoginModalComponent implements AfterViewChecked {
    @Input() containerID: string;
    @Input() modalMaxWidth: string;
    @Output() sendClick = new EventEmitter<boolean>();
    modalWrapper: HTMLElement;

    @Input() isAuthenticated: boolean;
    @Input() hideCloseButton: boolean;
    @Output() signInWithCredentialsEmitter = new EventEmitter<SigninWithCredentialsOptions>();
    @Output() signInWithOktaEmitter = new EventEmitter<void>();

    showSignInWithCredentials = environment.login.showSignInWithCredentials;

    // The content child allows to inject custom content into the modal container
    @ContentChild('modalContainer', { static: true }) contentElement: ElementRef;

    // NOTE: This element is the dom element for the modal dialog component
    @ViewChild('modalDialog') parentElement: ElementRef;

    theme = Theme.LIGHT;

    constructor(@Inject(DOCUMENT) private document: Document) {}
    /**
     * Set the view child element after view checked so we can use this element to hide and close
     * the modal dialog
     */
    ngAfterViewChecked(): void {
        this.modalWrapper = this.modalDialogElement;
    }

    /**
     * On click send to emit any optional events to be handled outside of this modal. (ex. analytics)
     */
    onClick(): void {
        this.sendClick.emit(true);
    }

    /**
     * Open Modal
     * Get the native element from Element Reference to display the block.
     */
    openModal(containerID: string): void {
        if (this.document.getElementById(containerID)) {
            this.document.getElementById(containerID).style.display = 'block';
        }
    }

    /**
     * Close Modal if the close icon or clicking outside the login modal
     * Get the native element from Element Reference to hide the block.
     */
    closeModal(event: Event): void {
        const clickElement = event.target as HTMLInputElement;
        if (clickElement.className === 'close' || clickElement.className === 'login-modal') {
            if (this.modalWrapper) {
                this.modalWrapper.style.display = 'none';
                this.sendClick.emit(true);
            }
        }
    }

    /**
     * Return parent element native element with custom getter
     */
    get modalDialogElement() {
        return this.parentElement.nativeElement;
    }

    /**
     * Emit sign in with credentials output on click
     * @param options - Okta Sign In with Credential Options interface with username and password
     */
    emitSignInWithCredentials(options: SigninWithCredentialsOptions): void {
        this.signInWithCredentialsEmitter.emit(options);
    }

    /**
     * Emit sign in with Okta output on click
     */
    emitSignInWithOkta(): void {
        if (isCookiesEnabled()) {
            this.signInWithOktaEmitter.emit();
        } else {
            alert('Please enable cookies in your browser to log into the Genesys Knowledge Network.');
        }
    }
}
