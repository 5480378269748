import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ComponentsModule } from '@components';
import { FundamentalsModule } from './fundamentals/fundamentals.module';
import { HomeComponent } from './home.component';
import { JustForMeModule } from './just-for-me/just-for-me.module';
import { MyFavoritesModule } from './my-favorites/my-favorites.module';
import { NewsModule } from './news/news.module';

@NgModule({
    imports: [CommonModule, ComponentsModule, FundamentalsModule, JustForMeModule, MyFavoritesModule, NewsModule],
    declarations: [HomeComponent],
})
export class HomeModule {}
