import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { EMPTY, Observable, catchError, concatMap, map, tap, toArray } from 'rxjs';
import { StatusFeed, StatusFeedIncident, StatusFeedMonth } from './fundamentals-status-feed.interface';

import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { IframeDialogComponent } from '@components';
import { environment } from '@environments/environment';
import { GknFundamentalCardLink } from '@services';
import { FundamentalsCardType } from '../fundamentals-card/fundamentals-card.enum';

@Component({
    selector: 'app-fundamentals-status',
    templateUrl: './fundamentals-status.component.html',
    styleUrls: ['./fundamentals-status.component.css'],
})
export class FundamentalsStatusComponent implements OnInit {
    incidents$: Observable<GknFundamentalCardLink[]>;
    isLoading = true;

    colors: string[] = [];

    constructor(private cdr: ChangeDetectorRef, private http: HttpClient, private dialog: MatDialog) {}
    ngOnInit(): void {
        this.incidents$ = this.http.get<StatusFeed>(`${environment.feed.gcStatus.url}/history.json`).pipe(
            concatMap((statusFeed: StatusFeed) => statusFeed.months),
            concatMap((statusFeedMonth: StatusFeedMonth) => statusFeedMonth.incidents),
            map((statusFeedIncident: StatusFeedIncident): GknFundamentalCardLink => {
                const formattedTitle = this.formatIncidentTitle(statusFeedIncident);
                const date = new Date(formattedTitle);

                return {
                    text: formattedTitle,
                    url: `${environment.feed.gcStatus.url}/incidents/${statusFeedIncident.code}`,
                    isButton: false,
                    dataAnalytics: [
                        'status',
                        'incidents',
                        date.getFullYear().toString(),
                        (date.getMonth() + 1).toString(),
                        date.getDate().toString(),
                    ],
                };
            }),
            toArray(),
            map((incidents: GknFundamentalCardLink[]) => {
                return [
                    {
                        text: 'ALL SYSTEMS OPERATIONAL',
                        url: environment.feed.gcStatus.url,
                        isButton: true,
                        dataAnalytics: ['status', 'incidents', 'all', 'systems', 'operational'],
                    },
                    ...incidents.slice(0, 3), // Incidents are already sorted by most recent, so we grab the top 3 but don't need to sort here
                    {
                        text: 'View All',
                        url: `${environment.feed.gcStatus.url}/#past-incidents`,
                        isButton: false,
                        dataAnalytics: ['status', 'incidents', 'view', 'all'],
                    },
                ];
            }),
            tap(() => {
                this.isLoading = false;
                this.cdr.detectChanges();
            }),
            catchError((err, caught) => {
                console.error(err, caught);
                return EMPTY;
            }),
        );
    }

    private formatIncidentTitle(statusFeedIncident: StatusFeedIncident): string {
        const date = statusFeedIncident.timestamp.slice(0, statusFeedIncident.timestamp.indexOf(','));
        return date.replace("<var data-var='date'>", '').replace('</var>', `, ${new Date().getFullYear()}`).trim();
    }

    handleOpenDialog(link: GknFundamentalCardLink) {
        this.dialog.open(IframeDialogComponent, {
            width: '80%',
            height: '80%',
            autoFocus: false,
            data: {
                title: FundamentalsCardType.STATUS,
                subtitle: link.text,
                src: link.url,
            },
        });
    }
}
