import { MarketingWpItem } from '../interfaces';

export const mockMarketingWpTipsAndTools: MarketingWpItem[] = [
    {
        title: { rendered: 'foo' },
        content: { rendered: '<h1>foo</h1>' },
        link: 'https://example.com',
        _embedded: {
            'wp:featuredmedia': [
                {
                    source_url: 'https://example.com/image.png',
                },
            ],
        },
    },
    {
        title: { rendered: 'bar' },
        content: { rendered: '<h1>bar</h1>' },
        link: 'https://example.com',
        _embedded: {
            'wp:featuredmedia': [
                {
                    source_url: 'https://example.com/image.png',
                },
            ],
        },
    },
    {
        title: { rendered: 'baz' },
        content: { rendered: '<h1>baz</h1>' },
        link: 'https://example.com',
        _embedded: {
            'wp:featuredmedia': [
                {
                    source_url: 'https://example.com/image.png',
                },
            ],
        },
    },
    {
        title: { rendered: 'qux' },
        content: { rendered: '<h1>qux</h1>' },
        link: 'https://example.com',
        _embedded: {
            'wp:featuredmedia': [
                {
                    source_url: 'https://example.com/image.png',
                },
            ],
        },
    },
];
