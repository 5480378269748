import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AuthedDirective } from './authed.directive';
import { DesktopDirective } from './desktop.directive';
import { FilterLocationDirective } from './filter-location.directive';
import { MobileDirective } from './mobile.directive';
import { ProductsDirective } from './products.directive';
import { UnauthedDirective } from './unauthed.directive';
import { UserRolesDirective } from './user-roles.directive';

const directives = [
    AuthedDirective,
    DesktopDirective,
    FilterLocationDirective,
    MobileDirective,
    ProductsDirective,
    UnauthedDirective,
    UserRolesDirective,
];

@NgModule({
    declarations: directives,
    imports: [CommonModule],
    exports: directives,
})
export class DirectivesModule {}
