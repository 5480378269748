<svg width="37" height="35" viewBox="0 0 37 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M30.7351 1.00036L27.4395 3.47656L28.3739 4.72019L31.6695 2.24398L30.7351 1.00036Z"
        [attr.fill]="fillColor" />
    <path d="M34.8178 3.1433L29.9004 6.82739L30.8331 8.07232L35.7505 4.38823L34.8178 3.1433Z" [attr.fill]="fillColor" />
    <path d="M35.636 7.63411L32.3369 10.1057L33.2696 11.3506L36.5686 8.87904L35.636 7.63411Z" [attr.fill]="fillColor" />
    <path
        d="M8.19749 32.1985C7.684 32.2003 7.17725 32.0816 6.71797 31.852C6.25868 31.6223 5.85969 31.2881 5.55306 30.8762L0.653074 24.2652C0.203706 23.6639 -0.025971 22.9267 0.00233579 22.1766C0.0306425 21.4264 0.315222 20.7087 0.808629 20.143L16.9864 1.16524C17.5562 0.498911 18.366 0.08432 19.2397 0.0115069C20.1135 -0.0613062 20.9807 0.213539 21.653 0.776351C21.8086 0.931906 22.0419 1.08746 22.1197 1.32079L33.1641 16.0207C33.4341 16.3701 33.6287 16.7717 33.7358 17.2C33.8429 17.6284 33.8601 18.0743 33.7863 18.5096C33.6284 19.3735 33.1577 20.1488 32.4641 20.6874C32.3085 20.7652 32.153 20.9207 31.9974 20.9985L19.0863 27.6096L18.3863 26.2096L31.2974 19.5985C31.3752 19.5207 31.453 19.5207 31.5307 19.443C31.9008 19.156 32.1472 18.7384 32.2195 18.2758C32.2918 17.8131 32.1845 17.3402 31.9196 16.9541L20.953 2.25412C20.8752 2.17635 20.7974 2.02079 20.7197 1.94301C20.3645 1.64361 19.9076 1.4927 19.444 1.52167C18.9804 1.55065 18.5459 1.75728 18.2308 2.09857L1.89751 21.0763C1.64149 21.3776 1.49455 21.7565 1.48043 22.1517C1.46632 22.5468 1.58586 22.9352 1.81974 23.2541L6.71972 29.8651C6.97762 30.1757 7.32783 30.396 7.71948 30.4939C8.11112 30.5918 8.52378 30.5623 8.89749 30.4096L11.0753 29.4763L11.6975 30.8762L9.59749 31.8874C9.15024 32.0645 8.67769 32.1696 8.19749 32.1985Z"
        [attr.fill]="fillColor" />
    <path
        d="M20.253 34.9985C19.9156 35.011 19.5797 34.9476 19.2701 34.813C18.9605 34.6784 18.685 34.476 18.4641 34.2207L18.2307 33.9874L14.5752 29.0874L15.8196 28.1541L19.553 33.1318C19.7002 33.3103 19.9054 33.4314 20.1329 33.4741C20.3603 33.5167 20.5955 33.4782 20.7974 33.3651C20.9084 33.3014 21.002 33.2113 21.0698 33.1028C21.1377 32.9942 21.1777 32.8706 21.1863 32.7429C21.2097 32.499 21.1553 32.2539 21.0307 32.0429L22.2752 31.1096C22.6632 31.6495 22.8301 32.3173 22.7418 32.9763C22.6989 33.3047 22.5865 33.6202 22.4122 33.9018C22.2378 34.1834 22.0055 34.4247 21.7307 34.6096C21.6529 34.6096 21.6529 34.6874 21.5752 34.6874C21.1522 34.8558 20.7066 34.9606 20.253 34.9985V34.9985Z"
        [attr.fill]="fillColor" />
</svg>
