import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-tile-subtitle',
    templateUrl: './tile-subtitle.component.html',
    styleUrls: ['./tile-subtitle.component.css'],
})
export class TileSubtitleComponent {
    @Input() isVertical = true; // Default tiles are vertical
}
