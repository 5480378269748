import { Injectable } from '@angular/core';
import {
    CommunitySectionLabel,
    EduTrainingSectionLabel,
    GetInvolvedSectionLabel,
    HomeSectionLabel,
    ProductUid,
    RecommendationSectionLabel,
    ResourcesSectionLabel,
    SupportSectionLabel,
} from '@enums';
import { ProductService, UserService } from '@services';
import { combineLatestWith, map, of } from 'rxjs';
import { GknRoutePath } from '../app-routing.enum';
import { SECTION_LINKS, SubmenuLink } from './submenu-data';

@Injectable({
    providedIn: 'root',
})
export class AppHeaderService {
    public submenuLink: SubmenuLink[];

    constructor(private productService: ProductService, private userService: UserService) {
        this.submenuLink = [
            {
                url: GknRoutePath.HOME,
                sections: [
                    {
                        label$: of(HomeSectionLabel.MY_FAVORITES),
                        location: SECTION_LINKS.MY_FAVORITES,
                        hidden$: this.userService.isAuthenticated$.pipe(
                            map((isAuthenticated) => isAuthenticated === true),
                        ),
                    },
                    {
                        label$: of(HomeSectionLabel.JUST_FOR_ME),
                        location: SECTION_LINKS.JUST_FOR_ME,
                        hidden$: this.userService.isAuthenticated$.pipe(
                            map((isAuthenticated) => isAuthenticated === false),
                        ),
                    },
                    {
                        label$: this.productService.selectedProduct$.pipe(
                            map((product) => product.displayName + ' ' + HomeSectionLabel.FUNDAMENTALS),
                        ),
                        location: SECTION_LINKS.PRODUCT_FUNDAMENTALS,
                        hidden$: this.productService.selectedProduct$.pipe(
                            map((product) => product.uid === ProductUid.POINTILLIST),
                        ),
                    },
                    {
                        label$: of(HomeSectionLabel.NEWS_FROM_GENESYS),
                        location: SECTION_LINKS.NEWS_FROM_GENESYS,
                        hidden$: of(false),
                    },
                ],
            },
            {
                url: GknRoutePath.COMMUNITY,
                sections: [
                    {
                        label$: this.productService.selectedProduct$.pipe(
                            map((product) => product.displayName + ' ' + CommunitySectionLabel.LATEST_DISCUSSIONS),
                        ),
                        location: SECTION_LINKS.LATEST_DISCUSSIONS,
                        hidden$: of(false),
                    },
                    {
                        label$: of(CommunitySectionLabel.QA_SHOW),
                        location: SECTION_LINKS.COMMUNITY_QA_SHOW,
                        hidden$: this.productService.selectedProduct$.pipe(
                            map((product) => product.uid !== ProductUid.GENESYS_CLOUD_CX),
                        ),
                    },
                    {
                        label$: of(CommunitySectionLabel.RELATED_COMMUNITIES),
                        location: SECTION_LINKS.RELATED_COMMUNITIES,
                        hidden$: of(false),
                    },
                ],
            },
            {
                url: GknRoutePath.GET_INVOLVED,
                sections: [
                    {
                        label$: of(GetInvolvedSectionLabel.CONNECT_WITH_GENESYS),
                        location: SECTION_LINKS.CONNECT_WITH_GENESYS,
                        hidden$: of(false),
                    },
                    {
                        label$: of(GetInvolvedSectionLabel.MARKETING_EVENTS),
                        location: SECTION_LINKS.VIRTUAL_AND_PHYSICAL_EVENTS,
                        hidden$: of(false),
                    },
                ],
            },
            {
                url: GknRoutePath.EDUCATION_AND_TRAINING,
                sections: [
                    {
                        label$: of(EduTrainingSectionLabel.BEYOND_TRAINING),
                        location: SECTION_LINKS.BEYOND_TRAINING,
                        hidden$: of(false),
                    },
                    {
                        label$: of(EduTrainingSectionLabel.CERTIFICATIONS_AND_BADGES),
                        location: SECTION_LINKS.CERTIFICATIONS_AND_BADGES,
                        hidden$: of(false),
                    },
                    // NOTE: Hide CS Academy Training since it's disabled
                    // {
                    //     label$: of(EduTrainingSectionLabel.CS_ACADEMY),
                    //     location: SECTION_LINKS.CS_ACADEMY_TRAINING,
                    //     hidden$: of(false),
                    // },
                    {
                        label$: of(EduTrainingSectionLabel.EDU_EVENTS_AND_WEBINARS),
                        location: SECTION_LINKS.EDU_EVENTS_AND_WEBINARS,
                        hidden$: this.productService.selectedProduct$.pipe(
                            map((product) => product.uid !== ProductUid.GENESYS_CLOUD_CX),
                        ),
                    },
                ],
            },
            {
                url: GknRoutePath.RESOURCES,
                sections: [
                    {
                        label$: of(ResourcesSectionLabel.GET_STARTED),
                        location: SECTION_LINKS.GET_STARTED,
                        hidden$: of(false),
                    },
                    {
                        label$: of(ResourcesSectionLabel.CX_EVOLUTION),
                        location: SECTION_LINKS.CX_EVOLUTION,
                        hidden$: of(false),
                    },
                    {
                        label$: this.productService.selectedProduct$.pipe(
                            map((product) => product.displayName + ' ' + ResourcesSectionLabel.PRODUCT_HELP),
                        ),
                        location: SECTION_LINKS.PRODUCT_HELP,
                        hidden$: this.productService.selectedProduct$.pipe(
                            map((product) => product.uid === ProductUid.POINTILLIST),
                        ),
                    },
                    {
                        label$: of(ResourcesSectionLabel.DEV_CENTER),
                        location: SECTION_LINKS.DEVELOPER_CENTER,
                        hidden$: this.productService.selectedProduct$.pipe(
                            map(
                                (product) =>
                                    product.uid === ProductUid.GENESYS_ENGAGE_ON_PREM ||
                                    product.uid === ProductUid.PURECONNECT ||
                                    product.uid === ProductUid.POINTILLIST,
                            ),
                        ),
                    },
                    {
                        label$: of(ResourcesSectionLabel.DEV_PORTAL_PURECONNECT),
                        location: SECTION_LINKS.DEVELOPER_PORTAL_PURECONNECT,
                        hidden$: this.productService.selectedProduct$.pipe(
                            map((product) => product.uid !== ProductUid.PURECONNECT),
                        ),
                    },
                    {
                        label$: of(ResourcesSectionLabel.EXPERT_APPS),
                        location: SECTION_LINKS.GENESYS_EXPERT_APPS,
                        hidden$: this.productService.selectedProduct$.pipe(
                            map((product) => product.uid !== ProductUid.GENESYS_CLOUD_CX),
                        ),
                    },
                    {
                        label$: of(ResourcesSectionLabel.APP_FOUNDRY),
                        location: SECTION_LINKS.APP_FOUNDRY,
                        hidden$: this.productService.selectedProduct$.pipe(
                            map((product) => product.uid !== ProductUid.GENESYS_CLOUD_CX),
                        ),
                    },
                    {
                        label$: of(ResourcesSectionLabel.CUSTOMER_SUCCESS),
                        location: SECTION_LINKS.CUSTOMER_SUCCESS,
                        hidden$: this.productService.selectedProduct$.pipe(
                            map((product) => product.uid === ProductUid.POINTILLIST),
                        ),
                    },
                ],
            },
            {
                url: GknRoutePath.SUPPORT,
                sections: [
                    {
                        label$: of(SupportSectionLabel.MY_CASES),
                        location: SECTION_LINKS.MY_CASES,
                        hidden$: this.userService.isAuthenticated$.pipe(
                            combineLatestWith(this.userService.isEmployee$),
                            map(([isAuthenticated, isEmployee]) => !isAuthenticated || isEmployee),
                        ),
                    },
                    {
                        label$: of(RecommendationSectionLabel.RECOMMENDATIONS),
                        location: SECTION_LINKS.RECOMMENDATIONS,
                        hidden$: of(false),
                    },
                    {
                        label$: of(SupportSectionLabel.PRODUCT_SUPPORT_CENTER),
                        location: SECTION_LINKS.PRODUCT_SUPPORT_CENTER,
                        hidden$: of(false),
                    },
                    {
                        label$: of(SupportSectionLabel.MY_CONTACTS),
                        location: SECTION_LINKS.MY_CONTACTS,
                        hidden$: this.userService.isAuthenticated$.pipe(
                            combineLatestWith(this.userService.isEmployee$),
                            map(([isAuthenticated, isEmployee]) => !isAuthenticated || isEmployee),
                        ),
                    },
                ],
            },
        ];
    }
}
