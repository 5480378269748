import { MarketingWpItem } from '../interfaces';

export const mockMarketingWpWebinars: MarketingWpItem[] = [
    {
        title: { rendered: 'foo' },
        link: 'https://example.com',
        acf: {
            meta_description: 'foo',
            w_sdate: '01/01/1970 08:00 am',
            w_edate: '01/01/1970 09:00 am',
        },
    },
];
