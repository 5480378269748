<!--FOOTER-->
<footer class="footer-container">
    <app-back-to-top></app-back-to-top>
    <!--PRIMARY FOOTER-->
    <div class="about-us-footer">
        <div class="footer-row">
            <!--LOGOS CONTAINER-->
            <div class="logos">
                <!--GENESYS LOGO-->
                <a target="_blank" rel="nofollow" title="Genesys" href="https://www.genesys.com" aria-label="Genesys">
                    <img src="/assets/images/genesys-logo.svg" width="199px" height="40px" alt="Genesys logo" />
                </a>
                <!--END GENESYS LOGO-->
                <ul class="social-media">
                    <li>
                        <a
                            target="_blank"
                            title="Genesys Twitter"
                            rel="nofollow"
                            href="https://twitter.com/Genesys"
                            aria-label="Genesys Twitter">
                            <span class="icon twitter-icon"></span>
                        </a>
                    </li>
                    <li>
                        <a
                            target="_blank"
                            title="Genesys LinkedIn"
                            rel="nofollow"
                            href="http://www.linkedin.com/company/601919?trk=tyah"
                            aria-label="Genesys LinkedIn">
                            <span class="icon linkedin-icon"></span>
                        </a>
                    </li>
                    <li>
                        <a
                            target="_blank"
                            rel="nofollow"
                            title="Genesys Facebook"
                            href="http://www.facebook.com/genesys"
                            aria-label="Genesys Facebook">
                            <span class="icon facebook-icon"></span>
                        </a>
                    </li>
                    <li>
                        <a
                            target="_blank"
                            rel="nofollow"
                            title="Genesys Instagram"
                            href="http://www.instagram.com/genesyscx"
                            aria-label="Genesys Instagram">
                            <span class="icon instagram-icon"></span>
                        </a>
                    </li>
                    <li>
                        <a
                            target="_blank"
                            title="Genesys YouTube"
                            rel="nofollow"
                            href="http://www.youtube.com/Genesys"
                            aria-label="Genesys YouTube">
                            <span class="icon youtube-icon"></span>
                        </a>
                    </li>
                    <li>
                        <a
                            target="_blank"
                            title="Genesys Blog"
                            rel="nofollow"
                            href="https://www.genesys.com/blog"
                            aria-label="Genesys Blog">
                            <span class="icon blog-icon"></span>
                        </a>
                    </li>
                </ul>
            </div>
            <!--END LOGOS CONTAINER-->
            <!--ABOUT US CONTAINER-->
            <div
                *ngIf="about$ | async as about"
                class="about-genesys hide-mobile-content margin-top-medium margin-bottom-medium">
                <span class="p-body-small-alt" id="about-us-title">
                    <b>{{ about.label }}</b>
                </span>
                <p id="about-us-description" class="about-us-description p-body-small-alt">{{ about.excerpt }}</p>
            </div>
            <!--END ABOUT US CONTAINER-->
        </div>
    </div>
    <!--END PRIMARY FOOTER-->
    <!--SECONDARY FOOTER-->
    <div class="copyright-footer">
        <div class="footer-row">
            <!--GENESYS CORPORATE LINKS-->
            <div class="copyright-links">
                <span class="p-body-alt no-wrap" data-testId="copyright">
                    Copyright &copy; {{ currentYear }}
                    <a
                        id="genesys-link"
                        class="neutral-link no-wrap"
                        rel="nofollow"
                        href="https://www.genesys.com"
                        target="_blank">
                        Genesys</a
                    >. All rights reserved.
                </span>
                <!--GENESYS TERMS OF USE LINK-->
                <ng-container *ngIf="termsOfUse$ | async as termsOfUse">
                    <a
                        id="terms-of-use-link"
                        class="hide-mobile-content neutral-link no-wrap margin-left-small"
                        rel="nofollow"
                        [href]="termsOfUse.url"
                        target="_blank">
                        {{ termsOfUse.label }}
                    </a>
                </ng-container>
                <!--END GENESYS TERMS OF USE LINK-->
                <app-vertical-divider class="hide-mobile-content" color="white"></app-vertical-divider>
                <!--GENESYS PRIVACY POLICY LINK-->
                <ng-container *ngIf="privacyPolicy$ | async as privacyPolicy">
                    <a
                        id="privacy-policy-link"
                        class="hide-mobile-content neutral-link no-wrap"
                        rel="nofollow"
                        [href]="privacyPolicy.url"
                        target="_blank">
                        {{ privacyPolicy.label }}
                    </a>
                </ng-container>
                <!--END GENESYS PRIVACY POLICY LINK-->
                <ng-container *ngIf="emailSubscription$ | async as emailSubscription">
                    <app-vertical-divider class="hide-mobile-content" color="white"></app-vertical-divider>
                    <!--GENESYS EMAIL SUBSCRIPTION LINK-->
                    <a
                        id="email-subscription-link"
                        class="hide-mobile-content neutral-link no-wrap"
                        rel="nofollow"
                        [href]="emailSubscription.url"
                        target="_blank">
                        {{ emailSubscription.label }}
                    </a>
                </ng-container>
                <!--END GENESYS EMAIL SUBSCRIPTION LINK-->
                <app-vertical-divider class="hide-mobile-content" color="white"></app-vertical-divider>
                <app-cookie-consent class="hide-mobile-content"></app-cookie-consent>
            </div>
            <!--END GENESYS CORPORATE LINKS-->
        </div>
    </div>
    <!--END SECONDARY FOOTER-->
</footer>
<!--END FOOTER-->
