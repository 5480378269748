import { OverlayProps } from '../components/overlay/overlay-html-element.component';

class AspectRatio {
    aspectRatio: number;
    windowRatio: number;
    videoPlayer: HTMLVideoElement;
    videoParent: HTMLElement;
    currentOverlay: OverlayProps;

    initEvents(player: HTMLVideoElement) {
        player.addEventListener('loadeddata', () => {
            this.videoPlayer = player;
            this.videoParent = this.videoPlayer.parentElement;
            this.videoPlayer.classList.add('vjs-16-9');
            this.videoParent.classList.remove('large-screen');
            this.videoParent.classList.add('small-screen');

            this.videoResize();
            window.addEventListener('resize', () => {
                this.videoResize();
            });
        });
    }
    updateCurrentOverlay(props: OverlayProps) {
        this.currentOverlay = props;
        this.overlayOffset();
    }

    overlayOffset() {
        const overlay = document.getElementById(this.currentOverlay.id);
        if (this.currentOverlay && overlay && this.currentOverlay.id != 'end-summary-overlay') {
            const width = this.currentOverlay.width || '100%';
            const right = this.currentOverlay.right || '0px';
            const left = this.currentOverlay.left || '0px';

            overlay.style.width = width;
            overlay.style.right = right;
            overlay.style.left = left;
        }
    }

    videoResize() {
        this.aspectRatio = this.videoPlayer.videoHeight / this.videoPlayer.videoWidth;
        this.windowRatio = window.innerHeight / window.innerWidth;

        this.overlayOffset();
    }
}

export const aspectRatio = new AspectRatio();
