import { map, Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { AppFoundryEntity } from './app-foundry-entity.interface';

@Injectable({
    providedIn: 'root',
})
export class AppFoundryService {
    /**
     * Maximum number of apps that pay to be listed higher in AppFoundry.
     * Note: This number is not arbitrary and was communicated from the Genesys Cloud API team.
     */
    private maxPaidApps = 7;

    constructor(private http: HttpClient) {}

    getEssentials(platform: string): Observable<AppFoundryEntity[]> {
        return this.http
            .get(`${environment.api.purecloud.marketplaceUrl}/listings`, {
                params: {
                    category: 'featured|essentials',
                    platform: platform,
                },
            })
            .pipe(
                map((data: { entities: AppFoundryEntity[] }) => {
                    return data.entities
                        .sort((a: AppFoundryEntity, b: AppFoundryEntity) => {
                            const aPriority = this.getPriorityOrFallback(a);
                            const bPriority = this.getPriorityOrFallback(b);
                            return aPriority - bPriority;
                        })
                        .slice(0, this.maxPaidApps);
                }),
            );
    }

    /**
     * @returns the priority of the given entity, or 999 if no priority is present. See {@link maxPaidApps}.
     */
    private getPriorityOrFallback(entity: AppFoundryEntity): number {
        // "0" is a valid priority, so falsy check is insuffient for this use case
        return entity?.metadata?.categoryPriority?.[0]?.priority == undefined ||
            entity?.metadata?.categoryPriority?.[0]?.priority == null
            ? 999 // we've observed that the priority can be upwards of 9, 10, or 11. Using 999 here ensures that apps without a category are placed at the end of the sorting operation
            : entity?.metadata?.categoryPriority[0]?.priority;
    }
}
