import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { FundamentalsCardModule } from '../fundamentals-card/fundamentals-card.module';
import { FundamentalsStatusComponent } from './fundamentals-status.component';

@NgModule({
    declarations: [FundamentalsStatusComponent],
    imports: [CommonModule, FundamentalsCardModule, MatButtonModule, MatDialogModule, MatListModule],
    exports: [FundamentalsStatusComponent],
})
export class FundamentalsStatusModule {}
