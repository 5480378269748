<ng-container *ngIf="featuredContent.type == 'modal' || featuredContent.type == 'newTab'">
    <app-featured-content-thumbnail [featuredContent]="featuredContent"></app-featured-content-thumbnail>
</ng-container>

<ng-container
    *ngIf="
        featuredContent.type == 'vimeoEmbed' ||
        featuredContent.type == 'wistiaEmbed' ||
        featuredContent.type == 'youtubeEmbed'
    ">
    <app-featured-content-video-embed [featuredContent]="featuredContent"> </app-featured-content-video-embed>
</ng-container>

<ng-container *ngIf="featuredContent.type == 'textOnly'">
    <app-featured-content-text-only [featuredContent]="featuredContent"></app-featured-content-text-only>
</ng-container>
