import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { UserService } from '@services';
import { Observable, filter, iif, map, switchMap } from 'rxjs';
import {
    KeplerCoveoRecommendation,
    KeplerCoveoRecommendationResponse,
    KeplerCoveoSearchPage,
    KeplerCoveoSearchToken,
    KeplerCoveoSearchTokenRequestBody,
    KeplerCoveoSuggestionsResponse,
} from './kepler-coveo.interface';

@Injectable({
    providedIn: 'root',
})
export class KeplerCoveoService {
    constructor(private http: HttpClient, private userService: UserService) {}

    /**
     * Gets a hosted search page from the kepler-coveo-api
     * @param pageId search page ID from Coveo dashboard
     * @returns search page or empty observable
     */
    searchPage$(pageId: string): Observable<KeplerCoveoSearchPage> {
        const baseUrl = environment.api.kepler.coveo.url;

        return this.http
            .get<KeplerCoveoSearchPage>(`${baseUrl}/search-page?pageId=${pageId}`)
            .pipe(filter((searchPage: KeplerCoveoSearchPage) => !!searchPage));
    }

    /**
     * Fetches a new search token from the kepler-coveo-api
     * @returns the search token string
     */
    searchToken$(searchHub: string): Observable<string> {
        return this.userService.isAuthenticated$.pipe(
            switchMap((isAuthed) => {
                const url = `${environment.api.kepler.coveo.url}/search-token`;

                return iif(
                    () => isAuthed,
                    this.userService.claims$.pipe(
                        map((claims) => claims.email),
                        switchMap((email) => {
                            return this.http.post<KeplerCoveoSearchToken>(url, {
                                email: email,
                                searchHub: searchHub,
                            } as KeplerCoveoSearchTokenRequestBody);
                        }),
                    ),
                    this.http.post<KeplerCoveoSearchToken>(url, {
                        // No email required for unauthed search token
                        searchHub: searchHub,
                    } as KeplerCoveoSearchTokenRequestBody),
                );
            }),
            map((searchTokenResponse) => searchTokenResponse?.token),
        );
    }

    suggestions$(searchTerm: string): Observable<KeplerCoveoSuggestionsResponse> {
        const params = new URLSearchParams();
        params.set('query', searchTerm);
        params.set('searchHub', environment.gknSearch.searchHub);

        const url = new URL(`${environment.api.kepler.coveo.url}/suggestions`);
        url.search = params.toString();

        return this.http
            .get<KeplerCoveoSuggestionsResponse>(url.toString())
            .pipe(filter((res) => res.data?.length > 0));
    }

    /**
     * Queries kepler-coveo-api for recommendations based on the given query string.
     * The currently selected product is not considered because filtering on mapped
     * fields in Coveo does not exist.
     * @param query to search
     * @returns up to 3 top recommendations, or empty array if none are found.
     *          the returned array could be any length, but will never exceed 3 items.
     */
    topThreeRecommendations$(query: string): Observable<KeplerCoveoRecommendation[]> {
        const baseUrl = environment.api.kepler.coveo.url;
        return this.http.get<KeplerCoveoRecommendationResponse>(`${baseUrl}/recommendations?query=${query}`).pipe(
            filter((res) => res?.results?.length > 0),
            map((res) => res?.results?.slice(0, 3)),
        );
    }
}
