import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DirectivesModule } from '@directives';
import { CxEvolutionExcerptComponent } from './cx-evolution-excerpt.component';

@NgModule({
    declarations: [CxEvolutionExcerptComponent],
    imports: [CommonModule, DirectivesModule],
    exports: [CxEvolutionExcerptComponent],
})
export class CxEvolutionExcerptModule {}
