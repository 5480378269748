import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ServicesModule } from '../../services/services.module';
import { FavoriteIconComponent } from './favorite-icon.component';

@NgModule({
    declarations: [FavoriteIconComponent],
    imports: [CommonModule, ServicesModule, RouterModule],
    exports: [FavoriteIconComponent],
})
export class FavoriteIconModule {}
