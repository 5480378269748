import { GknFundamentalCardLink } from '@services';

export const multiCloudCxReleaseNotes = {
    private: {
        url: 'https://all.docs.genesys.com/ReleaseNotes/Current/GenesysEngage-cloud/PrivateEdition?action=rnrss&tab=2',
        defaultUrl: 'https://all.docs.genesys.com/ReleaseNotes/Current/GenesysEngage-cloud',
        defaultButtonText: 'Release Notes',
        textTop: 'Private Edition',
        textLink: {
            text: 'View all',
            url: 'https://all.docs.genesys.com/ReleaseNotes/Current/GenesysEngage-cloud/PrivateEdition',
            isButton: false,
        } as GknFundamentalCardLink,
    },
    public: {
        url: 'https://all.docs.genesys.com/ReleaseNotes/Current/GenesysEngage-cloud/Hosted?action=rnrss&tab=2',
        defaultUrl: 'https://all.docs.genesys.com/ReleaseNotes/Current/GenesysEngage-cloud',
        defaultButtonText: 'Release Notes',
        textLink: {
            text: 'View all',
            url: 'https://all.docs.genesys.com/ReleaseNotes/Current/GenesysEngage-cloud/Hosted',
            isButton: false,
        } as GknFundamentalCardLink,
    },
};
