import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { AuthService } from '@services';
import { Observable } from 'rxjs';

export interface AuthorizerOpts {
    /** value to include in the X-Api-Key header for AWS API Gateway authorization */
    x_api_key?: string;
}

/**
 * Sets requests with the Authorization header necessary to verify the the current user's Okta JWT.
 *
 * Injectable extensions of this class should simply provide the request URL for intercepting at
 * construction. The {@link UserService} must be injected so that the token can be retrieved from
 * local storage. It **should not be private** or else you will get a compile error indicating that
 * this abstract parent class has already declared the `userService` property.
 *
 * @example
 * ```
 * export class MyCustomInterceptorNeedingJwtVerification extends KeplerApiAuthorizerInterceptor {
 *   constructor(userService: UserService) { // notice the non-private userService injection here
 *     super(userService, environment.api.*.url)
 *   }
 * }
 * ```
 *
 * Not all interceptors in the project extend this class. Business requirements drive the need
 * for a Kepler service to require authorization or be public.
 */
export abstract class KeplerApiAuthorizerInterceptor implements HttpInterceptor {
    /**
     * Always indicate to Kepler API Authorizer that requests are
     * coming from GKN so it uses the correct Okta app for verifying
     * the access token.
     */
    private readonly app = 'gkn';

    /**
     * Constructor
     *
     * @param userService to get the JWT accessToken string from local storage
     * @param urlToMatch the URL that this interceptor should apply the Authorization header to.
     * @param options authorizer options, as applicable
     */
    constructor(private authService: AuthService, private urlToMatch: string, private options?: AuthorizerOpts) {}

    /**
     * @inheritdoc
     *
     * Filters requested urls with String.prototype.startsWith to ensure
     * subpaths do not affect this interceptor.
     */
    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const headers = {
            Authorization: `Bearer ${this.authService.accessToken}`,
            'csdt-authorization-app': this.app,
            'Content-Type': 'application/json',
        };

        if (this.options?.x_api_key) {
            headers['X-Api-Key'] = this.options.x_api_key;
        }

        if (this.urlToMatch && request.url.startsWith(this.urlToMatch)) {
            const clone = request.clone({
                setHeaders: headers,
            });
            return next.handle(clone);
        } else {
            return next.handle(request);
        }
    }
}
