/**
 * Lock background body element when you have a modal pop-up.
 * Prevents the background from be scrolled and fixes body to be fixed
 */
export function lockBackgroundScrolling(): void {
    document.body.classList.add('article-open');
}

/**
 * Unlock background scrolling on body when a modal pop-up closes.
 */
export function unlockBackgroundScrolling(): void {
    document.body.classList.remove('article-open');
}

/**
 * Scrolling window position to top
 */
export function scrollToTopScrolling(): void {
    document.body.scrollIntoView({ behavior: 'smooth' });
}

/**
 * Scroll Position scrolls the element's ancestor containers.
 * @param element - HTML element of a document ID.
 * @param behavior - Defines the transition animation. One of auto or smooth. Defaults to auto.
 * @param block - Defines vertical alignment. One of start, center, end, or nearest. Defaults to start.
 * @param inline - Defines horizontal alignment. One of start, center, end, or nearest. Defaults to nearest.
 */
export function scrollPosition(element: any, behavior?: string, block?: string, inline?: string): void {
    if (element) {
        element.scrollIntoView({
            behavior: behavior,
            block: block,
            inline: inline,
        });
    }
}

/**
 * Smooth scrolls to the element with a specified vertical offset.
 * @param element - HTML element of a document ID.
 * @param offset - Defines a vertical offset.
 */
export function scrollToElementWithOffset(element: HTMLElement, offset: number): void {
    if (element) {
        window.scrollTo({
            top: element.offsetTop - offset,
            behavior: 'smooth',
        });
    }
}
