<app-section-header
    [title]="sectionLabel"
    [favoriteId]="favoriteId"
    [showTitle]="showTitle"
    [section]="sectionLocation"
    [showIcons]="showIcons"></app-section-header>

<app-tile-layout maxColumns="2">
    <ng-container *ngIf="contacts$ | async as contacts">
        <ng-container *ngFor="let contact of contacts">
            <ng-container *ngTemplateOutlet="handleContacts; context: contact"></ng-container>
        </ng-container>
    </ng-container>
</app-tile-layout>

<ng-template #handleContacts let-fullName="Name" let-title="Title" let-phone="Phone" let-email="Email">
    <li class="contact-card">
        <div class="profile" *ngIf="fullName">
            <p class="profile-img">
                <span translate="no">{{ fullName | getInitials }}</span>
            </p>
        </div>
        <div class="body">
            <div class="name" *ngIf="fullName">{{ fullName }}</div>
            <span class="job-title" *ngIf="title">
                <span translate="no">{{ title }}</span>
            </span>
            <p class="phone" *ngIf="phone">
                <img src="/assets/icons/phone.svg" width="26px" height="25px" alt="Phone icon" class="padding-text" />
                <a class="align-vertical" href="tel:{{ phone }}">{{ phone }}</a>
            </p>
            <p class="email" *ngIf="email">
                <img src="/assets/icons/email.svg" width="34px" height="24px" alt="Email icon" class="padding-text" />
                <a class="align-vertical" href="mailto:{{ email }}">{{ email }}</a>
            </p>
        </div>
    </li>
</ng-template>
