import { Component, Input } from '@angular/core';

import { GknFeaturedContent } from '../../services/content/interfaces/gkn-featured-content.interface';

@Component({
    selector: 'app-featured-content',
    templateUrl: './featured-content.component.html',
    styleUrls: ['./featured-content.component.css'],
})
export class FeaturedContentComponent {
    @Input() featuredContent: GknFeaturedContent;
}
