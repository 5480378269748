import { ProductDisplayName, ProductUid } from '@enums';
import { environment } from '@environments/environment';
import { DeveloperCenterData } from '../developer-center.interface';

export const toolsData: DeveloperCenterData = {
    baseUrl: environment.api.developerCenter.site.baseUrl,
    iconBase: '/assets/icons/developer-center/',
    cards: [
        {
            title: 'Get Started',
            text: `Start here! This is where you'll find developer-focused introductions, overviews, and guides for newcomers to the ${
                ProductDisplayName[ProductUid.GENESYS_CLOUD_CX]
            } platform.`,
            icon: 'devcenter-tools-get-started-icon.svg',
            url: 'guides/quickstarts/',
            width: '37px',
            height: '48px',
        },
        {
            title: 'API Explorer',
            text: `Looking for an interactive technical reference for the ${
                ProductDisplayName[ProductUid.GENESYS_CLOUD_CX]
            } Platform API where you can try API requests without writing any code? You've found it!`,
            icon: 'devcenter-tools-api-explorer-icon.svg',
            url: 'api/',
            width: '47px',
            height: '48px',
        },
        {
            title: 'Developer Tools',
            text: `Just like the dark knight, the Bright Yeti has a utility belt full of neat gadgets! Come check out the Notifications Monitor, Analytics Query Builder, Chat Tester, and more.`,
            icon: 'devcenter-tools-developer-tools-icon.svg',
            url: 'developer-tools/',
            width: '48px',
            height: '48px',
        },
        {
            title: 'Guides',
            text: `Browse through our code-first guides that offer deep dives into the ${
                ProductDisplayName[ProductUid.GENESYS_CLOUD_CX]
            } platform.`,
            icon: 'devcenter-tools-guides-icon.svg',
            url: 'guides/',
            width: '48px',
            height: '48px',
        },
        {
            title: 'Blueprints',
            text: `These projects provide turnkey examples of API usage, 3rd party integrations, and other concepts demonstrated via a working project.`,
            icon: 'devcenter-tools-blueprints-icon.svg',
            url: 'blueprints/',
            width: '44px',
            height: '48px',
        },
        {
            title: 'Videos',
            text: `If a picture is worth a thousand words, a 10 minute video at 30 fps is worth 18 million! Take a look through our DevDrop series for short briefs as well as the DevCast Webinar library for longer form broadcasts.`,
            icon: 'devcenter-tools-videos-icon.svg',
            url: 'video/',
            width: '38px',
            height: '48px',
        },
    ],
};
