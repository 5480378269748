import { ProductUid } from './product-uid.enum';

export enum SfdcSupportAccessEnum {
    GENESYS_ENGAGE_ON_PREM = 'Genesys Engage on-premises perpetual',
    GENESYS_ENGAGE = 'Genesys Engage',
    PURECONNECT_ON_PREM = 'PureConnect on-premises',
    GENESYS_MULTICLOUD_CX = 'Genesys Multicloud CX',
    PURECONNECT_CLOUD = 'PureConnect',
    GENESYS_CLOUD_CX = 'Genesys Cloud CX', // KNOW-2206 maintain for backwards compatibility
    GENESYS_CLOUD = 'Genesys Cloud',
    OUTBOUND_ENGAGEMENT_CLOUD = 'Outbound Engagement Cloud',
    PREMIER_EDITION_CLOUD = 'Premier Edition Cloud',
    PUREENGAGE_SUBSCRIPTION = 'PureEngage Subscription',
    PURECONNECT_SUBSCRIPTION = 'PureConnect Subscription',
    GENESYS_MULTICLOUD_CX_PRIVATE_ED = 'Genesys Multicloud CX private edition',
    GENESYS_DX = 'Genesys DX',
    GENESYS_MULTICLOUD = 'Genesys Multicloud',
    GENESYS_MULTICLOUD_PRIVATE_ED = 'Genesys Multicloud private edition',
}

type SfdcSupportAccessToProductType = Record<SfdcSupportAccessEnum, ProductUid>;

export const SfdcSupportAccessToProduct: SfdcSupportAccessToProductType = {
    [SfdcSupportAccessEnum.GENESYS_ENGAGE_ON_PREM]: ProductUid.GENESYS_ENGAGE_ON_PREM,
    [SfdcSupportAccessEnum.GENESYS_ENGAGE]: ProductUid.GENESYS_MULTICLOUD,
    [SfdcSupportAccessEnum.PURECONNECT_ON_PREM]: ProductUid.PURECONNECT,
    [SfdcSupportAccessEnum.GENESYS_MULTICLOUD_CX]: ProductUid.GENESYS_MULTICLOUD,
    [SfdcSupportAccessEnum.PURECONNECT_CLOUD]: ProductUid.PURECONNECT,
    [SfdcSupportAccessEnum.GENESYS_CLOUD_CX]: ProductUid.GENESYS_CLOUD_CX,
    [SfdcSupportAccessEnum.GENESYS_CLOUD]: ProductUid.GENESYS_CLOUD_CX,
    [SfdcSupportAccessEnum.OUTBOUND_ENGAGEMENT_CLOUD]: ProductUid.GENESYS_MULTICLOUD,
    [SfdcSupportAccessEnum.PREMIER_EDITION_CLOUD]: ProductUid.GENESYS_MULTICLOUD,
    [SfdcSupportAccessEnum.PUREENGAGE_SUBSCRIPTION]: ProductUid.GENESYS_MULTICLOUD,
    [SfdcSupportAccessEnum.PURECONNECT_SUBSCRIPTION]: ProductUid.PURECONNECT,
    [SfdcSupportAccessEnum.GENESYS_MULTICLOUD_CX_PRIVATE_ED]: ProductUid.GENESYS_MULTICLOUD,
    [SfdcSupportAccessEnum.GENESYS_DX]: ProductUid.GENESYS_DX,
    [SfdcSupportAccessEnum.GENESYS_MULTICLOUD]: ProductUid.GENESYS_MULTICLOUD,
    [SfdcSupportAccessEnum.GENESYS_MULTICLOUD_PRIVATE_ED]: ProductUid.GENESYS_MULTICLOUD,
};
