<svg width="34" height="35" viewBox="0 0 34 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M5.18393 16.2576L5.18743 12.1512L8.70338 11.7119L8.80761 11.346C9.25953 9.77764 10.1088 8.30998 11.2658 7.09416L11.5268 6.82133L10.1494 3.56142L13.7031 1.50963L15.8514 4.33862L16.2173 4.24768C17.0427 4.04411 17.8647 3.94128 18.6608 3.93638C19.4639 3.93918 20.2656 4.04131 21.1141 4.24768L21.4814 4.33862L23.6248 1.50684L27.1779 3.56072L25.7983 6.82693L26.0614 7.09976C27.2037 8.2883 28.053 9.75875 28.5196 11.3488L28.6245 11.7126L32.1391 12.1519V16.259L28.6217 16.6934L28.5168 17.0572C28.06 18.6403 27.2114 20.11 26.0635 21.3042L25.8004 21.577L27.1779 24.8467L23.6206 26.8985L21.6717 24.3248C21.4625 24.6907 21.2296 25.0447 20.9644 25.3714C20.9819 25.3665 20.9973 25.3665 21.0148 25.3616L23.3149 28.3997L28.6329 25.3343L27.1541 21.8225C28.2146 20.641 29.0212 19.2454 29.5116 17.7434L33.2919 17.2747L33.2905 11.1362L29.5116 10.6598C29.0149 9.15434 28.2069 7.75524 27.1513 6.58069L28.635 3.07103L23.3219 0L21.0169 3.04305C20.2138 2.87026 19.4415 2.78702 18.6594 2.78492C17.8892 2.78772 17.1036 2.87586 16.3138 3.04305L14.0074 0.00489687L8.69289 3.07313L10.1752 6.58069C9.10912 7.77412 8.30114 9.17253 7.81495 10.6619L4.03597 11.1383L4.03247 17.2768L7.66245 17.726C7.80306 17.3475 7.96955 16.9802 8.16682 16.6291L5.18393 16.2583V16.2576Z"
        [attr.fill]="fillColor" />
    <path
        d="M23.8934 14.2136C23.8934 15.6743 23.2862 16.9936 22.3131 17.9436L22.3306 17.994C22.4593 18.3885 22.5579 18.7922 22.623 19.2035C24.0948 18.0318 25.0441 16.2262 25.0441 14.1968C25.0441 10.6823 22.1823 7.82251 18.6649 7.82251C15.526 7.82251 12.9146 10.1058 12.3857 13.0978C12.7992 12.9544 13.2252 12.8495 13.6575 12.7767C14.2829 10.5892 16.2788 8.97327 18.6642 8.97327C21.5471 8.97327 23.8927 11.3161 23.8927 14.2136H23.8934Z"
        [attr.fill]="fillColor" />
    <path
        d="M2.50177 35C1.8288 35 1.19991 34.7307 0.751494 34.2816L0.706723 34.2368C-0.235574 33.2945 -0.235574 31.7233 0.706723 30.781L1.33492 30.1528L1.96312 30.781L1.33492 31.4092C0.751494 32.0374 0.751494 33.0252 1.33492 33.6086L1.37969 33.6534C1.69379 33.9675 2.05266 34.1025 2.457 34.1025C2.90612 34.1025 3.26499 33.9682 3.57908 33.6534L13.319 23.8688L13.6331 24.0485C14.6656 24.632 15.8324 24.767 16.9545 24.4522C17.7625 24.2276 18.5257 23.7792 19.1539 23.1503C20.5006 21.8037 20.9944 19.8288 20.5006 18.0338L18.2116 20.3675C17.2239 21.265 15.6981 21.265 14.7104 20.3675C14.2165 19.8736 13.9919 19.2902 13.9919 18.6172C13.9919 17.9443 14.2612 17.3154 14.7104 16.8669L16.8202 14.578C15.6534 14.2639 14.3963 14.3982 13.2742 14.9369C12.0626 15.5203 11.1196 16.5976 10.6711 17.8995C10.4914 18.4829 10.357 19.0663 10.4018 19.6945C10.4466 20.278 10.5816 20.8614 10.8957 21.4L11.0755 21.6694L10.1332 22.7019L3.84911 28.986L3.13067 28.313L9.90793 21.4903C9.63861 20.9069 9.50429 20.3234 9.45882 19.6952C9.45882 18.9768 9.54837 18.2591 9.77292 17.5854C10.3116 16.0597 11.3889 14.8473 12.8251 14.1296C14.2612 13.4112 15.922 13.3216 17.4484 13.8603C17.538 13.905 17.5827 13.9498 17.673 13.9946C17.8073 14.1289 17.8975 14.2639 17.8975 14.4437C17.8975 14.6235 17.8527 14.8026 17.7177 14.8928L15.2938 17.4958C14.9797 17.8099 14.7999 18.2143 14.7999 18.6179C14.7999 19.0216 14.9797 19.4259 15.249 19.74C15.8772 20.3234 16.865 20.3234 17.4932 19.74L20.0067 17.1817C20.0514 17.0922 20.141 17.0474 20.2312 17.002C20.5901 16.8676 20.9944 17.0467 21.1288 17.4056C21.892 19.6945 21.3533 22.1185 19.6926 23.824C18.9741 24.5424 18.0766 25.0811 17.0895 25.3497C15.8324 25.6638 14.5313 25.5743 13.4092 25.0356L12.8258 25.5743L4.25275 34.2816C3.75887 34.7755 3.13067 35 2.50247 35H2.50177Z"
        [attr.fill]="fillColor" />
</svg>
