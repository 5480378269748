import { Route } from '@angular/router';

import { OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';
import { ProductUid } from './@shared/enums/product-uid.enum';
import { ExternalRedirectGuard } from './@shared/guards/external-redirect.guard';
import { GknRouteConfigKey, GknRouteConfigLegacyKey, GknRouteLabel, GknRoutePath } from './app-routing.enum';
import { HomeComponent } from './home/home.component';
import { LegacyGetStartedComponent } from './legacy-get-started/legacy-get-started.component';
import { LoginComponent } from './login/login.component';

export type GknRouteConfig = Record<GknRouteConfigKey, { label?: GknRouteLabel; routerConfig: Route }>;
export type GknRouteConfigLegacy = Record<GknRouteConfigLegacyKey, Route>;

export const gknRouteConfig: GknRouteConfig = {
    [GknRouteConfigKey.HOME]: {
        label: GknRouteLabel.HOME,
        routerConfig: {
            // Warning: This path maps to a field in Okta's Admin dashboard.
            // Do not change it without updating the Okta app (done through IT).
            path: GknRoutePath.HOME,
            component: HomeComponent,
        },
    },
    [GknRouteConfigKey.LOGIN]: {
        routerConfig: {
            path: GknRoutePath.LOGIN,
            component: LoginComponent,
        },
    },
    [GknRouteConfigKey.LOGIN_CALLBACK]: {
        routerConfig: {
            // Warning: This path maps to a field in Okta's admin dashboard.
            // Do not change it without updating the Okta app (done through IT).
            path: GknRoutePath.LOGIN_CALLBACK,
            component: OktaCallbackComponent,
        },
    },
    [GknRouteConfigKey.PROFILE]: {
        routerConfig: {
            path: GknRoutePath.PROFILE,
            canActivate: [OktaAuthGuard],
            loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule),
        },
    },
    [GknRouteConfigKey.COMMUNITY]: {
        label: GknRouteLabel.COMMUNITY,
        routerConfig: {
            path: GknRoutePath.COMMUNITY,
            loadChildren: () => import('./community/community.module').then((m) => m.CommunityModule),
        },
    },
    [GknRouteConfigKey.GET_INVOLVED]: {
        label: GknRouteLabel.GET_INVOLVED,
        routerConfig: {
            path: GknRoutePath.GET_INVOLVED,
            loadChildren: () => import('./get-involved/get-involved.module').then((m) => m.GetInvolvedModule),
        },
    },
    [GknRouteConfigKey.EDU_TRAINING]: {
        label: GknRouteLabel.EDUCATION_AND_TRAINING,
        routerConfig: {
            path: GknRoutePath.EDUCATION_AND_TRAINING,
            loadChildren: () => import('./edu-training/edu-training.module').then((m) => m.EduTrainingModule),
        },
    },
    [GknRouteConfigKey.RESOURCES]: {
        label: GknRouteLabel.RESOURCES,
        routerConfig: {
            path: GknRoutePath.RESOURCES,
            loadChildren: () => import('./resources/resources.module').then((m) => m.ResourcesModule),
        },
    },
    [GknRouteConfigKey.SUPPORT]: {
        label: GknRouteLabel.SUPPORT,
        routerConfig: {
            path: GknRoutePath.SUPPORT,
            loadChildren: () => import('./support/support.module').then((m) => m.SupportModule),
        },
    },
    [GknRouteConfigKey.SEARCH]: {
        routerConfig: {
            path: GknRoutePath.SEARCH,
            loadChildren: () => import('./search-results/search-results.module').then((m) => m.SearchResultsModule),
        },
    },
    [GknRouteConfigKey.HOME_ARCHIVES]: {
        routerConfig: {
            path: GknRoutePath.HOME_ARCHIVES,
            canActivate: [OktaAuthGuard],
            loadChildren: () => import('./archives/archives.module').then((m) => m.ArchivesModule),
        },
    },
    [GknRouteConfigKey.GKN_STORIES]: {
        routerConfig: {
            path: GknRoutePath.GKN_STORIES,
            loadChildren: () => import('./gkn-stories/gkn-stories.module').then((m) => m.GknStoriesModule),
        },
    },
};

/** Legacy routes for /{path} from GKN 1.0 to new Know 2.0 path */
export const gknRouteConfigLegacy: GknRouteConfigLegacy = {
    [GknRouteConfigLegacyKey.AccountMerge]: {
        path: 'LegacyAccountMerge',
        canActivate: [ExternalRedirectGuard],
        component: HomeComponent,
        data: {
            externalUrl: 'https://genesys--c.na84.visual.force.com/apex/AccountMerge',
        },
    },
    [GknRouteConfigLegacyKey.BeyondRegistration]: {
        path: 'BeyondRegistration',
        canActivate: [ExternalRedirectGuard],
        component: HomeComponent,
        data: {
            externalUrl: 'https://register.beyond.genesys.com',
        },
    },
    [GknRouteConfigLegacyKey.KnowCommunity]: {
        path: 'KnowCommunity',
        component: LegacyGetStartedComponent,
    },
    [GknRouteConfigLegacyKey.KnowCommunityLogin]: {
        path: 'KnowCommunityLogin',
        component: LegacyGetStartedComponent,
    },
    [GknRouteConfigLegacyKey.KnowCommunityPureCloudH]: {
        path: 'KnowCommunityPureCloudH',
        component: LegacyGetStartedComponent,
    },
    [GknRouteConfigLegacyKey.KnowGenesysCloudHome]: {
        path: 'KnowGenesysCloudHome',
        redirectTo: `/?product=${ProductUid.GENESYS_CLOUD_CX}`,
    },
    [GknRouteConfigLegacyKey.KnowGenesysEngageHome]: {
        path: 'KnowGenesysEngageHome',
        redirectTo: `/?product=${ProductUid.GENESYS_ENGAGE_ON_PREM}`,
    },
    [GknRouteConfigLegacyKey.KnowGenesysEngageOnCloudHome]: {
        path: 'KnowGenesysEngageOnCloudHome',
        redirectTo: `/?product=${ProductUid.GENESYS_MULTICLOUD}`,
    },
    [GknRouteConfigLegacyKey.KnowHome]: {
        path: 'KnowHome',
        redirectTo: `/?product=${ProductUid.GENESYS_CLOUD_CX}`,
    },
    [GknRouteConfigLegacyKey.KnowPureCloudHome]: {
        path: 'KnowPureCloudHome',
        redirectTo: `/?product=${ProductUid.GENESYS_CLOUD_CX}`,
    },
    [GknRouteConfigLegacyKey.KnowPureConnectHome]: {
        path: 'KnowPureConnectHome',
        redirectTo: `/?product=${ProductUid.PURECONNECT}`,
    },
    [GknRouteConfigLegacyKey.KnowPureEngageCloudHome]: {
        path: 'KnowPureEngageCloudHome',
        redirectTo: `/?product=${ProductUid.GENESYS_MULTICLOUD}`,
    },
    [GknRouteConfigLegacyKey.KnowPureEngageHome]: {
        path: 'KnowPureEngageHome',
        redirectTo: `/?product=${ProductUid.GENESYS_ENGAGE_ON_PREM}`,
    },
    [GknRouteConfigLegacyKey.KnowUserProfile]: {
        path: 'KnowUserProfile',
        redirectTo: '/profile',
    },
    [GknRouteConfigLegacyKey.KnowUserPureCloudProfile]: {
        path: 'KnowUserPureCloudProfile',
        redirectTo: '/profile',
    },
    [GknRouteConfigLegacyKey.KnowUserPureConnectProfile]: {
        path: 'KnowUserPureConnectProfile',
        redirectTo: '/profile',
    },
};
