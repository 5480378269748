import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ComponentsModule } from '@components';
import { DirectivesModule } from '@directives';
import { CasesComponent } from './cases.component';

@NgModule({
    declarations: [CasesComponent],
    imports: [CommonModule, ComponentsModule, DirectivesModule],
    exports: [CasesComponent],
})
export class CasesModule {}
