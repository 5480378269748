import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'getInitials',
})
export class GetInitialsPipe implements PipeTransform {
    /**
     * Reference link: https://stackoverflow.com/a/63763497
     * @param fullName any fullName string
     * @returns the initials for the fullName provided
     */
    transform(fullName: string): string {
        if (fullName) {
            return fullName
                .match(/(\b\S)?/g)
                .join('')
                .match(/(^\S|\S$)?/g)
                .join('')
                .toUpperCase();
        } else {
            return null;
        }
    }
}
