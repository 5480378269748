import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from '@pipes';
import { RoadmapsWysiwygComponent } from './roadmaps-wysiwyg.component';

@NgModule({
    declarations: [RoadmapsWysiwygComponent],
    imports: [CommonModule, PipesModule],
    exports: [RoadmapsWysiwygComponent],
})
export class RoadmapsWysiwygModule {}
