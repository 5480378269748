<input
    *ngIf="isMobile"
    type="image"
    src="/assets/icons/orange-left-arrow.svg"
    alt="Go back"
    (click)="handleCloseMobileProfile($event)"
    width="10px"
    height="12px"
    class="margin-left-smaller" />
<app-section-header [title]="sectionLabel" [ngClass]="isMobile ? 'mobile-section-header' : ''"> </app-section-header>
<div class="container">
    <app-tile-layout>
        <li class="my-profile">
            <div class="main">
                <div class="icons">
                    <div class="outer-circle">
                        <a
                            class="secondary-link profile cursor"
                            routerLink="/profile"
                            [queryParams]="{ product: (selectedProduct | async).uid }">
                            <span class="notranslate">{{ initials$ | async }}</span>
                        </a>
                    </div>
                </div>
                <div class="profile-link cursor margin-left-large margin-top-large">
                    <a
                        class="brand-link"
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                        href="https://apps.genesys.com/pcodebroker/resetPcode.aspx">
                        Change Password
                    </a>
                </div>
            </div>
            <div class="margin-bottom-small profile-details">
                <div class="rows">
                    <div>
                        <label for="firstName" class="firstNameLabel">First Name</label>
                    </div>
                    <div>
                        <span id="firstName" class="firstName notranslate">{{ firstName$ | async }}</span>
                    </div>
                </div>
                <div class="rows">
                    <div>
                        <label for="lastName" class="lastNameLabel">Last Name</label>
                    </div>
                    <div>
                        <span id="lastName" class="lastName notranslate">{{ lastName$ | async }}</span>
                    </div>
                </div>
                <div class="rows email-row">
                    <div>
                        <label class="emailIdLabel">Email</label>
                    </div>
                    <div>
                        <label for="emailId">
                            <span id="emailId" class="notranslate emailId">{{ emailId$ | async }}</span>
                        </label>
                    </div>
                </div>
                <div>
                    <p class="p-body">
                        Contact
                        <a class="link-n" href="mailto:customercare@genesys.com?subject=Genesys%20Knowledge%20Network">
                            customercare@genesys.com
                        </a>
                        for name and email address update requests.
                    </p>
                </div>
            </div>
            <ng-container *ngIf="!isMobile; else isMobileViewTemplate">
                <div class="product-container">
                    <div class="product-header">
                        <h2 class="h4 h4-alt margin-left-small">My Products</h2>
                    </div>
                    <ul class="no-bullets">
                        <ng-container *ngIf="myProductList.length > 0; else noItemFound">
                            <li *ngFor="let item of myProductList; let i = index; trackBy: trackByItem">
                                <span class="margin-left-small margin-top-small product-item">
                                    <span class="notranslate">{{ item?.displayName }}</span>
                                </span>
                            </li>
                        </ng-container>
                    </ul>
                    <ng-template #noItemFound>
                        <li>
                            <span class="margin-left-small margin-top-small product-item">No products available.</span>
                        </li>
                    </ng-template>
                </div>
            </ng-container>
            <ng-template #isMobileViewTemplate>
                <div class="product-container-mobile" (click)="toggleMobileProductList()">
                    <div class="product-header-mobile">
                        <h2 class="h4 h4-alt-mobile margin-left-small">My Products</h2>
                        <a>
                            <input
                                type="image"
                                src="/assets/icons/arrow-down-navy.svg"
                                class="arrow-down caret-arrow"
                                alt="Caret arrow down icon"
                                width="30px"
                                height="30px"
                                [ngClass]="mobileProductListCaretActive === false ? 'caret-down-arrow' : 'caret-up'" />
                        </a>
                    </div>
                    <ul id="mobileProductsContainer" class="no-bullets">
                        <div class="list-container">
                            <ng-container *ngIf="myProductList.length > 0; else noItemFound">
                                <li *ngFor="let item of myProductList; let i = index; trackBy: trackByItem">
                                    <span class="margin-left-small margin-top-small product-item">
                                        <span class="notranslate">{{ item?.displayName }}</span>
                                    </span>
                                </li>
                            </ng-container>
                        </div>
                    </ul>
                    <ng-template #noItemFound>
                        <li>
                            <span class="margin-left-small margin-top-small product-item"> No products available.</span>
                        </li>
                    </ng-template>
                </div>
            </ng-template>
        </li>
    </app-tile-layout>
</div>
