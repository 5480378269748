import { gitInfo } from './git-info';
export const environment = {
    gitInfo: gitInfo,
    name: 'prod',
    production: true,
    appBaseHref: '/',
    oidc: {
        // Okta Prod "Know 2.0 OIDC SPA Prod"
        clientId: '0oa1nk6xy8fW4d3sw0h8',
        issuer: 'https://genesys.okta.com/oauth2/default',
        redirectUri: 'https://know.genesys.com/login/callback',
        scopes: ['openid', 'email', 'profile'],
    },
    mySupport: {
        url: 'https://genesys.my.site.com/customercare',
    },
    login: {
        showSignInWithCredentials: false,
    },
    widget: {
        useInteractionCodeFlow: true,
    },
    api: {
        resourceServer: {
            favoritesUrl: 'https://know-server.genesyscsdt.com/api/v1/favorites',
        },
        community: {
            url: 'https://api.connectedcommunity.org/api/v2.0',
            apiKey: '3e8b4d16-cb60-47c4-83e8-9dcd13b97cb3',
            returnUrl: 'https://know.genesys.com/community',
            baseUrl: 'https://community.genesys.com',
            utilizeTestCommunity: false,
        },
        purecloud: {
            marketplaceUrl: 'https://api.mypurecloud.com/api/v2/marketplace',
        },
        cms: {
            strapi: 'https://know-cms.genesyscsdteng.com/api',
            wp: 'https://gkncms.genesyscsdt.com/wp-json/wp/v2',
        },
        beyond: {
            training: {
                url: 'https://beyond.genesys.com/explore',
            },
            certification_external_url: 'https://beyond.genesys.com/explore/certification/home/',
            certification_base_url: 'https://beyond.genesys.com/explore/certification/',
            cms: {
                url: 'https://beyond-cms.genesys.com',
            },
            docebo: {
                beyondurl: 'https://learn.genesys.com',
                genedurl: 'https://gened.genesys.com',
            },
            knowvideos: {
                featuredVideos: 806,
                freeVideos: [551, 462],
            },
        },
        kepler: {
            apiAuthorizer: {
                url: 'https://kepler-authorizer-api.genesyscsdt.com/prod',
                invokeUrl: 'https://tjb9rvknri.execute-api.us-east-1.amazonaws.com/prod',
            },
            coveo: {
                orgId: 'genesys',
                url: 'https://kepler-coveo-api.genesyscsdt.com/prod',
                invokeUrl: 'https://ak9kgzmq94.execute-api.us-east-1.amazonaws.com/prod',
            },
            credly: {
                url: 'https://kepler-credly-api.genesyscsdt.com/prod',
                invokeUrl: 'https://yjw8xb2poi.execute-api.us-east-1.amazonaws.com/prod',
                sfdcIdOverride: false,
                overrideValue: '003U000000xWMldIAG',
            },
            db: {
                starterKit: {
                    url: 'https://kepler-db-api-prod.genesyscsdt.com/prod/welcome-kit',
                    invokeUrl: 'https://xjokkuh3il.execute-api.us-east-1.amazonaws.com/prod/welcome-kit',
                },
            },
            docebo: {
                url: 'https://kepler-docebo-api.genesyscsdt.com/prod/service',
                invokeUrl: 'https://s5rvom8teg.execute-api.us-east-1.amazonaws.com/prod',
            },
            facebook: {
                url: 'https://kepler-fb-api.genesyscsdt.com/prod',
                invokeUrl: 'https://73s0opcmkf.execute-api.us-east-1.amazonaws.com/prod',
            },
            marketing: {
                url: 'https://kepler-marketing-api.genesyscsdt.com/prod',
                invokeUrl: 'https://kis603ls9k.execute-api.us-east-1.amazonaws.com/prod',
            },
            purecloud: {
                url: 'https://kepler-purecloud-api.genesyscsdt.com/prod',
                invokeUrl: 'https://3n4hjolfid.execute-api.us-east-1.amazonaws.com/prod',
                apiKey: 'vxjc1cQJd21mz2ltejydZ69rTlfPXqn59fI699Ow',
            },
            sfdc: {
                url: 'https://kepler-sfdc-api.genesyscsdt.com/prod',
                invokeUrl: 'https://pcs0eqck9k.execute-api.us-east-1.amazonaws.com/prod',
            },
            vimeo: {
                url: 'https://kepler-vimeo-api.genesyscsdt.com/prod',
                invokeUrl: 'https://o2a2oztmaj.execute-api.us-east-1.amazonaws.com/prod',
                channelId: '1841991',
            },
        },
        developerCenter: {
            site: {
                baseUrl: 'https://developer.genesys.cloud/',
            },
            blog: {
                baseUrl: 'https://developer-content.genesys.cloud',
                blogFeed: '/data/blogs.json',
            },
        },
        developerCenterMcCx: {
            site: {
                baseUrl: 'https://developer.genesyscloud.com/',
                alternateBaseUrl: 'https://developer.genesys.com/',
            },
        },
    },
    gknSearch: {
        pageId: '21975495-ed53-4208-b160-e77ebce2d419',
        searchHub: 'KnowledgeNetwork',
    },
    feed: {
        announcements: { url: 'https://gkn-cms.genesys.com/wp-json/wp/v2/prod_announcements' },
        blog: { url: 'https://www.genesys.com/wp-json/wp/v2' },
        gcStatus: { url: 'https://status.mypurecloud.com' },
    },
    youtube: {
        url: 'https://youtube.googleapis.com/youtube/v3/',
        key: 'AIzaSyBDuNGfrtu5rromhEpbDF56ZW7rMRUhXeA',
        playlistId: 'PL4anLPM52HBCzZfvGhF-ZtKA_T4aBofMO',
    },
    videoEmbed: {
        youtube: 'https://www.youtube.com/embed',
        vimeo: 'https://player.vimeo.com/video',
        playlistid: 'PL4anLPM52HBCzZfvGhF-ZtKA_T4aBofMO',
    },
    welcomeVideo: {
        url: 'https://css-employee-welcome-prod.genesyscsdt.com',
    },
    tinyMce: {
        apiKey: '3zrpm6qeeknv7rrambbqwlfkgejl8wpx5zynxcr4kx07q2w2',
    },
    pendo: {
        userIdPrefix: '', // no prefix in prod
        cookieDomain: '.genesys.com', // dot prefix is required!
    },
    support: {
        botDeploymentId: 'a0f9795a-f10e-4892-a5f6-00d13c096004',
    },
    featureFlags: {
        // true to use a feature, false to disable it
        genEd: false,
        pendo: true,
    },
};
