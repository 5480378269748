import {} from '@enums';

import { Component } from '@angular/core';
import { DevCenterFilterLabel, FavoriteId, ResourcesSectionLabel } from '@enums';
import { SECTION_LINKS } from 'src/app/app-header/submenu-data';
import { environment } from '@environments/environment';
import { FavoritesService } from '@services';

@Component({
    selector: 'app-developer-center',
    templateUrl: './developer-center.component.html',
    styleUrls: ['./developer-center.component.css'],
})
export class DeveloperCenterComponent {
    externalUrl = environment.api.developerCenter.site.baseUrl;
    sectionLabel = ResourcesSectionLabel.DEV_CENTER;
    favoriteId = FavoriteId.DEVELOPER_CENTER;

    filterPrefix = SECTION_LINKS.DEVELOPER_CENTER;
    filterState = {
        tools: { label: DevCenterFilterLabel.TOOLS, location: 'tools' },
        popularTopics: { label: DevCenterFilterLabel.POPULAR_TOPICS, location: 'popular-topics' },
        blog: { label: DevCenterFilterLabel.BLOG, location: 'blog' },
    };

    anchors: string[];

    constructor(private favoritesService: FavoritesService) {
        this.anchors = [
            this.favoritesService.buildFragmentFromFavorite(FavoriteId.DEVELOPER_CENTER_BLOG),
            this.favoritesService.buildFragmentFromFavorite(FavoriteId.DEVELOPER_CENTER_POPULAR_TOPICS),
            this.favoritesService.buildFragmentFromFavorite(FavoriteId.DEVELOPER_CENTER_TOOLS),
            this.favoritesService.buildFragmentFromFavorite(FavoriteId.DEVELOPER_PORTAL_PURECONNECT),
        ];
    }

    filterUpdated(favoriteId: FavoriteId) {
        this.favoriteId = favoriteId;
    }
}
