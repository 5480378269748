import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CookieService as NgxCookieService } from 'ngx-cookie-service';
import { Observable, ReplaySubject, filter, tap } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CookieService {
    private _isConsented$: ReplaySubject<boolean> = new ReplaySubject(1);
    private readonly REJECT_ALL_BITMASK = '00000';

    constructor(private ngxCookieService: NgxCookieService, private router: Router) {
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                tap(() => {
                    const bitmask = this.getOnetrustCookieAsBitmask();
                    if (bitmask === this.REJECT_ALL_BITMASK) {
                        this._isConsented$.next(false);
                    } else {
                        const offBit = '0';
                        const isConsented = !bitmask.substring(0, 3).includes(offBit);
                        if (!isConsented) {
                            console.warn(`Some features may be restricted due to cookie rejection (${bitmask}).`);
                        }
                        this._isConsented$.next(isConsented);
                    }
                }),
            )
            .subscribe();
    }

    get(name: string): string {
        return this.ngxCookieService.get(name);
    }

    set(name: string, value: string, exp: Date): void {
        this.ngxCookieService.set(name, value, exp);
    }

    /**
     * @return Observable indication of whether the user has accepted or rejected cookies.
     */
    get isConsented$(): Observable<boolean> {
        return this._isConsented$.asObservable();
    }

    /**
     * Retrieves and converts the Onetrust cookie to a bitmask representing each {@link CookieConsentLevel}.
     *
     * Below is the Onetrust cookie string, where each underlying value is structure "key=value" and structures
     * are delimited by "&" ampersand. The `groups` key represents the consent levels that are inspected by this function.
     *
     * _isGpcEnabled=0&datestamp=Mon+May+01+2023+13:05:44+GMT-0400+(Eastern+Daylight+Time)&version=202303.2.0&browserGpcFlag=0&isIABGlobal=false&hosts=&landingPath=NotLandingPage&_```groups=C0003:1,C0005:1,C0004:1,C0001:1,C0002:0```_&geolocation=US;IN&AwaitingReconsent=false59_
     *
     * The {@link CookieConsentLevel#REQUIRED} level cannot be rejected by the user. So the first value of the returned
     * string is always 1. The remaining values represent the other consentlevels ordered by their permissive-ness.
     *
     * For example,
     *
     * - a user who has rejected only {@link CookieConsentLevel#SOCIAL_MEDIA} level cookies would have that bit set to off, e.g. "11110".
     * - a user who has rejected all but {@link CookieConsentLevel#PERFORMANCE} level cookies would expect this function to output "11000".
     *
     * @param cookie to convert
     * @returns bitmask, e.g. "11110"
     */
    private getOnetrustCookieAsBitmask(): string {
        const cookie: string = this.ngxCookieService.get('OptanonConsent');
        if (cookie == CookieConsentLevel.UNSET) {
            return this.REJECT_ALL_BITMASK;
        } else {
            return cookie
                ?.split('&')
                ?.find((data) => data.startsWith('groups'))
                ?.split('=')[1]
                ?.split(',')
                ?.sort((a, b) => {
                    // sort by last character. expected outcome is ["C0001","C0002","C0003","C0004","C0005"]
                    return b.substring(4, 5).localeCompare(a.substring(4, 5));
                })
                ?.map((group) => {
                    // convert each item to a keyless bitmask value
                    return group.split(':')[1];
                })
                ?.reduce((a, b) => (b += a));
        }
    }
}

/**
 * Consent levels represent the values used by the Trustarc cookie consent manager
 */
export enum CookieConsentLevel {
    UNSET = '',
    REQUIRED = 'C0001', // least permissive. the Onetrust UI doesn't let you turn this off
    PERFORMANCE = 'C0002',
    FUNCTIONAL = 'C0003', // threshold/benchmark for restricting features
    TARGETING = 'C0004',
    SOCIAL_MEDIA = 'C0005', // most permissive.
}
