import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ComponentsModule } from '@components';
import { ServicesModule } from '@services';
import { AppFooterComponent } from './app-footer.component';
import { VerticalDividerModule } from './vertical-divider/vertical-divider.module';

@NgModule({
    declarations: [AppFooterComponent],
    imports: [CommonModule, ComponentsModule, ServicesModule, VerticalDividerModule],
    exports: [AppFooterComponent],
})
/**
 * Common Footer Module
 */
export class AppFooterModule {}
