<span [class.mobile]="isMobile">
    <button (click)="this.showModal = !this.showModal; determineModalPosition()" #button> i </button>
    <span
        class="modal"
        *ngIf="showModal"
        #modal
        [style.--modalLeft]="modalLeft + 'px'"
        [style.--arrowLeft]="arrowLeft + 'px'">
        <img
            class="close"
            src="/assets/icons/x-modal-close.svg"
            alt="close modal icon"
            width="9px"
            height="9px"
            (click)="showModal = false" />
        <p class="p-body-small">
            <ng-content></ng-content>
        </p>
    </span>
</span>
