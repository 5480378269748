import { Marker, Overlay, OverlayHtmlElement, TypographySurroundingLink } from '../../components';

export class BookmarkKnowOverlay extends Overlay {
    static marker: Marker = {
        start: { time: 11.0, text: 'Bookmark Know' },
        end: { time: 17.0, text: 'Bookmark Know end' },
    };

    static KNOW_TEXT = 'Genesys Knowledge Network';
    static KNOW_LINK = 'https://know.genesys.com/';

    private width = document.getElementById('welcome-video-container').offsetWidth;

    private fontSize = this.width * 0.02 + 'px';
    private lineHeight = this.width * 0.029 + 'px';

    constructor(private props: { id: string }) {
        super(props.id, { animateInClass: 'fadeInLeftBig', animateOutClass: 'fadeOutLeftBig' });
    }

    html(): HTMLElement {
        const typography = new TypographySurroundingLink({
            leadingTypography: {
                text: "You're on the",
                fontSize: this.fontSize,
                lineHeight: this.lineHeight,
            },
            link: {
                text: BookmarkKnowOverlay.KNOW_TEXT,
                href: BookmarkKnowOverlay.KNOW_LINK,
                fontSize: this.fontSize,
                lineHeight: this.lineHeight,
            },
            trailingTypography: {
                text: "It's your all-in-one access point for Genesys content and resources.",
                fontSize: this.fontSize,
                lineHeight: this.lineHeight,
            },
        }).html();

        const overlay = new OverlayHtmlElement().buildHtml({
            id: this.props.id,
            childNodes: [typography],
            hidden: true,
            alignItems: 'flex-start',
            width: this.width * 0.55 + 'px', // This width keeps the link from line breaking
            padding: this.width * 0.03 + 'px',
        });

        return overlay;
    }
}
