import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import qs from 'qs';
import { Observable } from 'rxjs';
import { UseCase } from './use-case.interface';

@Injectable({
    providedIn: 'root',
})
export class UseCasesService {
    private baseUrl = 'https://all.docs.genesys.com/index.php';

    constructor(private http: HttpClient) {}

    /**
     * Observes uses cases from Genesys docs
     * @param productFilter the product to filter results by. This is not a Product UID! This string is specific to Genesys docs
     * @returns observable array of use cases
     */
    observeUseCases(productFilter: string): Observable<UseCase[]> {
        const query = qs.stringify({
            title: 'Special:CargoExport',
            tables: 'SMART_Meta, SMART_UseCase, SMART_Popular,SMART_Canonical,',
            'join on':
                'SMART_Canonical.ID=SMART_Meta.ID,SMART_Meta.ID=SMART_UseCase.ID,SMART_UseCase.ID=SMART_Popular.Title',
            fields: "CONCAT('https://all.docs.genesys.com/', SMART_UseCase._pageName)=url, CONCAT(SMART_Meta.Title, ' (',SMART_Meta.ID,')')=title,SMART_UseCase.UCSummary=summary, SMART_Canonical.DataSheetImageURL=image,",
            where: `SMART_Popular.Offering="${productFilter}"`,
            'group by': 'SMART_Meta.ID',
            'order by':
                "CONCAT ('{{server}}/',`cargo__SMART_UseCase`.`_pageName`,' ',`cargo__SMART_Meta`.`Title`, ' (',`cargo__SMART_Meta`.`ID`,')'),`cargo__SMART_Popular`.`Offering`",
            limit: '4',
            format: 'json',
        });

        return this.http.get<UseCase[]>(`${this.baseUrl}?${query}`);
    }
}
