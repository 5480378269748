import { KeplerPurecloudUser } from '@services';
import { initVideo } from './init-employee-video';
import { WelcomeOverlay } from './overlay';
import { HomePage } from './pages/home/home.page';

export async function app(user: KeplerPurecloudUser) {
    const container = document.createElement('div');
    container.setAttribute('class', 'container');
    const cssWelcomeVideoContainer = document.getElementById('welcome-video-container');
    cssWelcomeVideoContainer.replaceChildren();
    cssWelcomeVideoContainer.appendChild(container);

    if (user) {
        container.replaceChildren();
        const homePage = new HomePage().html();
        container.appendChild(homePage);

        await HomePage.renderHomePageThumbnail(); // must be called after homePage has been added to the DOM

        const welcomeOverlay = new WelcomeOverlay({
            id: 'home-page-welcome-overlay',
            user: user,
            isThumbnail: true,
        }).html();
        welcomeOverlay.hidden = false; // All overlays are hidden by default so they can be animated in. This is a special case where we don't want that to happen.
        homePage.appendChild(welcomeOverlay);

        const button = document.createElement('button');
        button.setAttribute('class', 'home-play-button');
        button.onclick = () => {
            container.replaceChildren();
            initVideo(container, user);
        };
        homePage.appendChild(button);
    }
}
