import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-tile-image',
    templateUrl: './tile-image.component.html',
    styleUrls: ['./tile-image.component.css'],
})
export class TileImageComponent {
    @Input() imageUrl: string; // Image url
    @Input() altText: string; // Image alt text
}
