import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ComponentsModule } from '@components';
import { LoginModalComponent } from './login-modal.component';

@NgModule({
    declarations: [LoginModalComponent],
    imports: [CommonModule, ComponentsModule, FormsModule, ReactiveFormsModule],
    exports: [LoginModalComponent],
})
export class LoginModalModule {}
