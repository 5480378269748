import { Injectable } from '@angular/core';
import { ProductDisplayName, ProductUid, UserRoleUid } from '@enums';
import { environment } from '@environments/environment';
import { BeyondRiseVideo, DoceboEnrollment, DoceboSubscription, UserService } from '@services';
import { BeyondTrainingTile } from './beyond-training-tile.interface';
import { BeyondCallToAction, DoceboEnrollmentStatus, DoceboLearningType } from './enums';

@Injectable({
    providedIn: 'root',
})
export class BeyondTrainingTileAdapter {
    constructor(private userService: UserService) {}

    /**
     * Converts the given subscription from Docebo to a Beyond Training Tile
     * @param subscription to convert
     * @returns the beyond training tile, or null
     */
    fromSubscription(subscription: DoceboSubscription): BeyondTrainingTile | null {
        if (!subscription?.id || !subscription?.name) {
            console.error('Invalid Docebo subscription', subscription);
            return null;
        }

        let subscriptionTitle: string;
        let subtitleIcon: string;
        let customImageClassName: string;

        if (subscription.name.includes('Genesys Cloud')) {
            subscriptionTitle = ProductDisplayName[ProductUid.GENESYS_CLOUD_CX];
            subtitleIcon = 'content-tags-genesys-cloud-cx.svg';
            customImageClassName = 'beyond-subscription-genesys-cloud-cx';
        } else if (subscription.name.includes('Genesys Engage')) {
            subscriptionTitle = ProductDisplayName[ProductUid.GENESYS_MULTICLOUD];
            subtitleIcon = 'content-tags-genesys-multicloud-cx.svg';
            customImageClassName = 'beyond-subscription-genesys-multicloud-cx';
        } else if (subscription.name.includes('PureConnect')) {
            subscriptionTitle = ProductDisplayName[ProductUid.PURECONNECT];
            subtitleIcon = 'content-tags-pureconnect.svg';
            customImageClassName = 'beyond-subscription-pureconnect';
        } else if (subscription.name.includes('Pointillist')) {
            subscriptionTitle = ProductDisplayName[ProductUid.POINTILLIST];
            subtitleIcon = 'content-tags-pureconnect.svg';
            customImageClassName = 'beyond-subscription-pointillist';
        }

        return {
            id: subscription.id,
            openInModal: false,
            title: `${subscriptionTitle} Learning Plan`,
            subtitle: ['subscription', subscriptionTitle],
            subtitleIcon: `/assets/icons/beyond/${subtitleIcon}`,
            description: subscription.description,
            url: `https://beyond.genesys.com/explore/dashboard`,
            cta: 'Learn more',
            customImageClassName: `beyond-subscription ${customImageClassName}`,
        } as BeyondTrainingTile;
    }

    /**
     * Converts the given enrollment from Docebo to a Beyond Training Tile
     * @param enrollment to convert
     * @returns the beyond training tile, or null
     */
    fromEnrollment(enrollment: DoceboEnrollment, userRole: UserRoleUid): BeyondTrainingTile | null {
        if (!enrollment || !enrollment?.id) {
            console.error('Invalid Docebo enrollment', enrollment);
            return null;
        }

        const featureFlagGenEd = environment.featureFlags.genEd;

        return {
            id: enrollment.id,
            openInModal: false,
            title: enrollment.name,
            subtitle: [
                (enrollment.type?.toLowerCase() === 'learning_plan' ? 'course' : enrollment.type.toLowerCase()) || '',
            ],
            subtitleIcon: `/assets/icons/beyond/content-tags-${enrollment?.type?.toLowerCase()}.svg`,
            description: enrollment.description,
            url:
                featureFlagGenEd && this.userService.isEmployee(userRole)
                    ? environment.api.beyond.docebo.genedurl + enrollment.url
                    : environment.api.beyond.docebo.beyondurl + enrollment.url,
            cta: this.convertToEnrollmentMessage(
                enrollment.status as DoceboEnrollmentStatus,
                enrollment.type as DoceboLearningType,
            ),
            customImageClassName:
                (enrollment.type?.toLowerCase() === 'learning_plan' ? 'course' : enrollment.type.toLowerCase()) || '',
        } as BeyondTrainingTile;
    }

    /**
     * Return enrolled message based on enrollment status and learningType
     * Learning type is optional. If there is no learning type, then the enrollment
     * message can still be returned if there is a valid enrollment status.
     * @param {DoceboEnrollmentStatus} status to convert
     * @param {DoceboLearningType} learningType statuses may map to a different message based on learning type
     * @throws when the given status is not being handled
     */
    private convertToEnrollmentMessage(status: DoceboEnrollmentStatus, learningType: DoceboLearningType): string {
        switch (learningType) {
            case DoceboLearningType.E_LEARNING:
            // fall-through
            case DoceboLearningType.SELF_STUDY:
                if (status === DoceboEnrollmentStatus.COMPLETED) {
                    return BeyondCallToAction.REVIEW;
                } else if (status === DoceboEnrollmentStatus.IN_PROGRESS) {
                    return BeyondCallToAction.CONTINUE;
                } else if (status === DoceboEnrollmentStatus.ENROLLED) {
                    return BeyondCallToAction.START;
                } else if (status === DoceboEnrollmentStatus.WAITING) {
                    return BeyondCallToAction.WAITLIST;
                } else {
                    throw this.throwUnhandledStatusError(status, learningType);
                }
            // Virtual and In-Person is used to show historical, legacy
            // completed, or enrolled courses on the dashboard progress only.
            // It should not appear in current catalog search or enrollments.
            case DoceboLearningType.INSTRUCTOR_LED_VIRTUAL:
            // fall-through
            case DoceboLearningType.INSTRUCTOR_LED_IN_PERSON:
            // fall-through
            case DoceboLearningType.INSTRUCTOR_LED:
            // fall-through
            case DoceboLearningType.VIRTUAL:
            // fall-through
            case DoceboLearningType.IN_PERSON:
                if (status === DoceboEnrollmentStatus.COMPLETED) {
                    return BeyondCallToAction.VIEW;
                } else if (status === DoceboEnrollmentStatus.IN_PROGRESS) {
                    return BeyondCallToAction.CONTINUE;
                } else if (status === DoceboEnrollmentStatus.ENROLLED) {
                    return BeyondCallToAction.START;
                } else if (status === DoceboEnrollmentStatus.WAITING) {
                    return BeyondCallToAction.WAITLIST;
                } else {
                    throw this.throwUnhandledStatusError(status, learningType);
                }
            case DoceboLearningType.CERTIFICATION_EXAM:
                if (status === DoceboEnrollmentStatus.COMPLETED) {
                    return BeyondCallToAction.VIEW_DETAILS;
                } else if (status === DoceboEnrollmentStatus.IN_PROGRESS) {
                    return BeyondCallToAction.VIEW_DETAILS;
                } else if (status === DoceboEnrollmentStatus.ENROLLED) {
                    return BeyondCallToAction.VIEW_DETAILS;
                } else if (status === DoceboEnrollmentStatus.WAITING) {
                    return BeyondCallToAction.VIEW_DETAILS;
                } else {
                    throw this.throwUnhandledStatusError(status, learningType);
                }
            default:
                // It is possible for enrollments have a falsy learning type.
                // This is done by changing the the enrolled status rules in
                // the Docebo admin console.
                if (status === DoceboEnrollmentStatus.COMPLETED) {
                    return BeyondCallToAction.VIEW;
                } else if (status === DoceboEnrollmentStatus.IN_PROGRESS) {
                    return BeyondCallToAction.CONTINUE;
                } else if (status === DoceboEnrollmentStatus.ENROLLED) {
                    return BeyondCallToAction.START;
                } else if (status === DoceboEnrollmentStatus.WAITING) {
                    return BeyondCallToAction.WAITLIST;
                } else {
                    throw this.throwUnhandledStatusError(status, learningType);
                }
        }
    }

    private throwUnhandledStatusError(status: DoceboEnrollmentStatus, learningType: DoceboLearningType) {
        return new Error(`Unhandled enrollment status '${status}' for learning type '${learningType}'.`);
    }

    /**
     * Converts the given free video from Rise to a Beyond Training Tile
     * @param freeVideo to convert
     * @returns the beyond training tile, or null
     */
    fromFreeVideo(freeVideo: BeyondRiseVideo): BeyondTrainingTile | null {
        if (!freeVideo || !freeVideo?.id) {
            console.error('Invalid Rise video', freeVideo);
            return null;
        }

        return {
            id: freeVideo.id,
            openInModal: true,
            title: freeVideo.title?.rendered || '',
            description: freeVideo.content?.rendered || '',
            subtitle: ['video'],
            subtitleIcon: '/assets/icons/content-tags-video.svg',
            url: freeVideo.acf?.embed || '',
            cta: BeyondCallToAction.WATCH,
        };
    }
}
