import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

/**
 * Responsive tile layout with optional maxColumns property (default 3).
 *
 * The minimum value for maxColumns is 1, anything lower will default to 1.
 * The maximum value for maxColumns is 4, anything higher will default to 4.
 */
@Component({
    selector: 'app-tile-layout',
    templateUrl: './tile-layout.component.html',
    styleUrls: ['./tile-layout.component.css'],
})
export class TileLayoutComponent implements OnChanges {
    /**
     * Sets maximum number of columns.
     */
    @Input() maxColumns = 3;
    /**
     * Sets thumbnails to be mobile friendly.
     */
    @Input() mobileThumbnails = false;
    /**
     * Sets columns to remain fixed and not squash down to 1 single column on mobile.
     */
    @Input() multiColumnMobile = false;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.maxColumns.currentValue < 1) this.maxColumns = 1;
        if (changes.maxColumns.currentValue > 6) this.maxColumns = 6;
    }
}
