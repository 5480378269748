import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GetStartedSectionLabel } from '@enums';
import { environment } from '@environments/environment';
import { stripShortCodes, trimString } from '@utils';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Observable, filter, map } from 'rxjs';
import { GknTipsAndTools, MarketingWpItem } from './interfaces';

dayjs.extend(customParseFormat);

@Injectable({
    providedIn: 'root',
})
export class MarketingWpService {
    constructor(private http: HttpClient) {}

    get blogs$(): Observable<MarketingWpItem[]> {
        return this.sendWordpressRequest$(`/blog?${this.getQueryString('10', 'true')}`).pipe(
            filter((data) => data?.length > 0),
        );
    }

    get tipsAndTools$(): Observable<GknTipsAndTools[]> {
        return this.sendWordpressRequest$(`/resources?${this.getQueryString('6', 'true')}`).pipe(
            filter((data) => data?.length > 0),
            map((data) =>
                data.map((resource) => ({
                    title: resource.title.rendered,
                    description: stripShortCodes(trimString(resource.content?.rendered)) || '',
                    imageUrl: resource._embedded?.['wp:featuredmedia']?.[0]?.source_url || '',
                    icon: '/assets/icons/tips-tools-icon.svg',
                    subtitle: GetStartedSectionLabel.TIPS_AND_TOOLS,
                    url: resource.link,
                    urlLabel: resource.link ? 'Read more' : '',
                })),
            ),
        );
    }

    /**
     * Send WordPress Request
     * @param endpoint - url endpoint parameters
     * @returns Observable array of {@link MarketingWpItem}
     */
    private sendWordpressRequest$(endpoint: string): Observable<MarketingWpItem[]> {
        return this.http
            .get<MarketingWpItem[]>(environment.feed.blog.url + endpoint)
            .pipe(filter((data) => data?.length > 0));
    }

    /**
     * Builds the query string for the Marketing WordPress query.
     * We set _embed to false by default, as some events that we see on https://genesys.com/events are excluded by the WP API when _embed is set to true.
     * @returns a stringified query string
     */
    private getQueryString(per_page = '5', fetchEmbeddedFields = 'false'): string {
        const params = new URLSearchParams();
        params.set('per_page', per_page);
        params.set('orderBy', 'date');
        params.set('order', 'desc');
        params.set('_embed', fetchEmbeddedFields);
        return params.toString();
    }
}
