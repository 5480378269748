import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { StarterKitStep, StarterKitTab } from '../starter-kit.interface';

import { ToastColor, ToastNotificationService, UserService } from '@services';
import { KeplerDbService } from 'src/app/@shared/services/kepler-db/kepler-db.service';

@Component({
    selector: 'app-starter-kit-step-content',
    templateUrl: './starter-kit-step-content.component.html',
    styleUrls: ['./starter-kit-step-content.component.css'],
})
export class StarterKitStepContentComponent implements OnInit, OnChanges, OnDestroy {
    @Input() step: StarterKitStep;
    @Input() tab: StarterKitTab;
    @Input() progress: Observable<StarterKitTab[]>;
    @Input() selectedIndex: number;
    @Input() isMobile: boolean;

    @Input() showModal$: Observable<boolean>;

    @Output() navigate: EventEmitter<number> = new EventEmitter<number>();

    private shouldShowCongrats = false;

    private subs: Subscription[] = [];

    showModal = false;
    continueMessage = '';
    firstStep = false;
    lastStep = false;

    email = '';

    constructor(
        private dbService: KeplerDbService,
        private toastService: ToastNotificationService,
        private userService: UserService,
    ) {}

    showCongrats() {
        this.shouldShowCongrats = false;
        this.toastService.notify({
            // TODO Add support to toasts to show a component
            color: ToastColor.INFO,
            innerHtml: `
            <h3 class="color-brand-navy">You're all set!</h3>
            <div style="display: flex; margin-top: 0.5rem">
                <img src="/assets/images/congrats-animation-starter-kit.gif" style="width: 150px; height: 150px; margin-right: 1rem" alt="Congratulations" />
                <div>
                    <p class="p-body">
                        Congratulations on completing your ${this.tab.name} Starter Kit!
                    </p>
                    <p class="p-body" style="margin-top: 0.5rem">
                        Be sure to bookmark
                        <a class="end-link" target="_blank" href="https://know.genesys.com/resources?product=genesys-cloud-cx">this page</a>
                        as well as these other great resources:
                        <a class="end-link" target="_blank" href="https://community.genesys.com/home">Genesys Community</a>,
                        <a class="end-link" target="_blank" href="https://help.mypurecloud.com/">Resource Center</a>, and
                        <a class="end-link" target="_blank" href="https://developer.genesys.cloud/">Developer Center</a>.
                    </p>
                </div>
            </div>
            `,
        });
    }

    ngOnInit(): void {
        this.subs.push(
            this.userService.claims$.subscribe((claims) => {
                this.email = claims?.email;
            }),
            this.showModal$.subscribe((show) => {
                if (show) {
                    this.openModal();
                } else {
                    this.showModal = false;
                }
            }),
        );
    }

    ngOnChanges(): void {
        this.continueMessage = this.step.completed ? this.step.messages.replay : this.step.messages.continue;
        if (!this.tab.order && !this.step.completed) {
            this.continueMessage = this.continueMessage.split(' ')[0];
        }
        this.firstStep = this.selectedIndex == 0;
        this.lastStep = this.selectedIndex == this.tab.steps.length - 1;
    }

    openModal() {
        if (this.step.type == 3) {
            this.openLink();
            return;
        }
        this.showModal = true;
        if (!this.step.completed) {
            this.complete();
        }
    }

    openLink() {
        window.open(this.step.link, '_blank');
        this.complete();
    }

    complete() {
        if (!this.email) return;
        const remaining = this.tab.steps.filter((step) => !step.completed);
        if (remaining.length == 1 && remaining[0] == this.step) {
            this.shouldShowCongrats = true;
            if (!this.showModal) this.showCongrats();
        }
        this.subs.push(
            this.dbService
                .addStarterKitProgress(this.email, {
                    productId: this.tab.id,
                    stepName: this.step.name,
                    stepId: this.step.id,
                    completed: true,
                })
                .subscribe(() => {
                    this.subs.push(this.progress.subscribe());
                }),
        );
    }

    /**
     * Closes modal. If congrats is queued up, in the case where the
     * previous modal would be obstructing it, show the congrats message
     */
    closeModal(): void {
        this.showModal = false;
        if (this.shouldShowCongrats) this.showCongrats();
    }

    ngOnDestroy(): void {
        this.subs.forEach((sub) => sub.unsubscribe());
    }
}
