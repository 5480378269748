import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, Input, OnChanges, OnDestroy } from '@angular/core';
import { TrackByItem } from '@components';
import { Subscription, map } from 'rxjs';
import { UseCase } from './use-case.interface';
import { UseCasesService } from './use-cases.service';

@Component({
    selector: 'app-use-cases',
    templateUrl: './use-cases.component.html',
    styleUrls: ['./use-cases.component.css'],
})
export class UseCasesComponent implements OnChanges, OnDestroy, TrackByItem<UseCase> {
    @Input() productFilter: string;
    useCases: UseCase[];
    private subs: Subscription[] = [];
    isLoading = true;

    constructor(
        private changeDetector: ChangeDetectorRef,
        @Inject(DOCUMENT) private document: Document,
        private useCasesService: UseCasesService,
    ) {}

    ngOnChanges() {
        this.subs.push(
            this.useCasesService
                .observeUseCases(this.productFilter)
                .pipe(
                    map((useCases: UseCase[]) =>
                        useCases.map((useCase) => ({
                            ...useCase,
                            summary: this.decodeEntities(useCase.summary),
                        })),
                    ),
                )
                .subscribe((useCases) => {
                    this.useCases = useCases;
                    this.isLoading = false;
                    this.changeDetector.markForCheck();
                }),
        );
    }

    private decodeEntities(str: string): string {
        const textarea = this.document.createElement('textarea');
        textarea.innerText = str;
        return textarea.value;
    }

    trackByItem(_index: number, item: UseCase): NonNullable<number | string> {
        return item.title;
    }

    ngOnDestroy(): void {
        this.subs.forEach((s) => s.unsubscribe());
    }
}
