/**
 * Enumerates products that categorize knowledge base content.
 *
 * These map 1:1 to GKN CMS > ACF > Field Groups > Products to
 * allow content filtering against products with some compile
 * time type safety. Do not change the string values without
 * also updating GKN CMS and Brain Tab!
 *
 * Entries are purposefully alphabetical to allow for deterministic
 * usage of functions like Object.values()
 */
export enum ProductUid {
    GENESYS_CLOUD_CX = 'genesys-cloud-cx',
    GENESYS_DX = 'genesys-dx',
    GENESYS_ENGAGE_ON_PREM = 'genesys-engage-on-prem',
    GENESYS_MULTICLOUD = 'genesys-multicloud-cx',
    POINTILLIST = 'pointillist',
    PURECONNECT = 'pureconnect',
}

/**
 * Object representing mappings of a {@link ProductUid} to a human-readable display name
 */
export const ProductDisplayName: Record<ProductUid, string> = {
    [ProductUid.GENESYS_CLOUD_CX]: 'Genesys Cloud',
    [ProductUid.GENESYS_DX]: 'Genesys DX',
    [ProductUid.GENESYS_ENGAGE_ON_PREM]: 'Genesys Engage on-premises',
    [ProductUid.GENESYS_MULTICLOUD]: 'Genesys Multicloud',
    [ProductUid.PURECONNECT]: 'PureConnect',
    [ProductUid.POINTILLIST]: 'Pointillist',
};
