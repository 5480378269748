import { gitInfo } from './git-info';
/**
 * Dev environment
 */
export const environment = {
    gitInfo: gitInfo,
    name: 'dev',
    production: false,
    appBaseHref: '/',
    oidc: {
        // Okta Preview "Know 2.0 OIDC SPA Dev"
        clientId: '0oa14qrugnpmeLGRL0h8',
        issuer: 'https://genesys.oktapreview.com/oauth2/default',
        redirectUri: 'https://know-dev.genesyscsdteng.com/login/callback',
        scopes: ['openid', 'email', 'profile'],
    },
    mySupport: {
        url: 'https://genesys--uat.sandbox.my.site.com/customercare',
    },
    login: {
        showSignInWithCredentials: false,
    },
    widget: {
        useInteractionCodeFlow: true,
    },
    api: {
        resourceServer: {
            favoritesUrl: 'https://know-server-dev.genesyscsdteng.com/api/v1/favorites',
        },
        community: {
            url: 'https://api.connectedcommunity.org/api/v2.0',
            apiKey: '3e8b4d16-cb60-47c4-83e8-9dcd13b97cb3',
            returnUrl: 'http://know-dev.genesyscsdteng.com/community',
            baseUrl: 'https://community.genesys.com',
            utilizeTestCommunity: true,
        },
        purecloud: {
            marketplaceUrl: 'https://api.mypurecloud.com/api/v2/marketplace',
        },
        cms: {
            strapi: 'https://know-cms.genesyscsdteng.com/api',
            wp: 'https://dev.gkncms.genesyscsdteng.com/wp-json/wp/v2',
        },
        beyond: {
            training: {
                // TODO: Update this URL - 'https://inindca.atlassian.net/browse/KNOW-895'
                url: 'https://beyond-dev.genesyscsdteng.com/explore',
            },
            certification_external_url: 'https://beyond-dev.genesyscsdteng.com/explore/certification/home',
            certification_base_url: 'https://beyond-dev.genesyscsdteng.com/explore/certification/',
            cms: {
                url: 'https://beyondcmsdev.wpengine.com',
            },
            docebo: {
                beyondurl: 'https://learnsandbox.genesys.com',
                genedurl: 'https://gened.genesys.com',
            },
            knowvideos: {
                featuredVideos: 806,
                freeVideos: [551, 462],
            },
        },
        kepler: {
            apiAuthorizer: {
                url: 'https://kepler-authorizer-api.genesyscsdteng.com/dev',
                invokeUrl: 'https://1c48uhvp8c.execute-api.us-east-1.amazonaws.com/dev',
            },
            coveo: {
                orgId: 'genesyssandbox',
                url: 'https://kepler-coveo-api.genesyscsdteng.com/dev',
                invokeUrl: 'https://6lb6kosh27.execute-api.us-east-1.amazonaws.com/dev',
            },
            credly: {
                url: 'https://kepler-credly-api.genesyscsdteng.com/dev',
                invokeUrl: 'https://6lb6kosh27.execute-api.us-east-1.amazonaws.com/dev',
                sfdcIdOverride: false,
                overrideValue: '003U000000xWMldIAG',
            },
            db: {
                starterKit: {
                    url: 'https://kepler-db-api-dev.genesyscsdteng.com/dev/welcome-kit',
                    invokeUrl: 'https://rm9c6wsh77.execute-api.us-east-1.amazonaws.com/dev/welcome-kit',
                },
            },
            docebo: {
                url: 'https://kepler-docebo-api.genesyscsdteng.com/dev/service',
                invokeUrl: 'https://hglvdqi3k6.execute-api.us-east-1.amazonaws.com/dev',
            },
            facebook: {
                url: 'https://kepler-fb-api.genesyscsdteng.com/dev',
                invokeUrl: 'https://e8l6tuzw1j.execute-api.us-east-1.amazonaws.com/dev',
            },
            marketing: {
                url: 'https://kepler-marketing-api.genesyscsdteng.com/dev',
                invokeUrl: 'https://uwi5l065fj.execute-api.us-east-1.amazonaws.com/dev',
            },
            purecloud: {
                url: 'https://kepler-purecloud-api.genesyscsdteng.com/dev',
                invokeUrl: 'https://3n4hjolfid.execute-api.us-east-1.amazonaws.com/dev',
                apiKey: 'XLRgxcOduFvL41hpxck55S7o1pMtsyj3ZQFk5AM2',
            },
            sfdc: {
                url: 'https://kepler-sfdc-api.genesyscsdteng.com/dev',
                invokeUrl: 'https://1z69u0vgrh.execute-api.us-east-1.amazonaws.com/dev',
            },
            vimeo: {
                url: 'https://kepler-vimeo-api.genesyscsdteng.com/dev',
                invokeUrl: 'https://sv4l37z0yk.execute-api.us-east-1.amazonaws.com/dev',
                channelId: '1841991',
            },
        },
        developerCenter: {
            site: {
                baseUrl: 'https://developer.genesys.cloud/',
            },
            blog: {
                baseUrl: 'https://developer-content.genesys.cloud',
                blogFeed: '/data/blogs.json',
            },
        },
        developerCenterMcCx: {
            site: {
                baseUrl: 'https://developer.genesyscloud.com/',
                alternateBaseUrl: 'https://developer.genesys.com/',
            },
        },
    },
    gknSearch: {
        pageId: 'dd1c8171-9b1c-4c74-b2ec-e9491f1e36ec',
        searchHub: 'KnowledgeNetwork',
    },
    feed: {
        announcements: { url: 'https://gkn-cms.genesys.com/wp-json/wp/v2/prod_announcements' },
        blog: { url: 'https://www.genesys.com/wp-json/wp/v2' },
        gcStatus: { url: 'https://status.mypurecloud.com' },
    },
    youtube: {
        url: 'https://youtube.googleapis.com/youtube/v3/',
        key: 'AIzaSyAL_5L0zvOGQzPMeU89ksYER1tVasSP73k',
        playlistId: 'PL4anLPM52HBCzZfvGhF-ZtKA_T4aBofMO',
    },
    videoEmbed: {
        youtube: 'https://www.youtube.com/embed',
        vimeo: 'https://player.vimeo.com/video',
        playlistid: 'PL4anLPM52HBCzZfvGhF-ZtKA_T4aBofMO',
    },
    welcomeVideo: {
        url: 'https://css-employee-welcome-dev.genesyscsdt.com',
    },
    tinyMce: {
        apiKey: '3zrpm6qeeknv7rrambbqwlfkgejl8wpx5zynxcr4kx07q2w2',
    },
    pendo: {
        userIdPrefix: 'DEV', // use a prefix in dev so Pendo can filter out these analytics
        cookieDomain: '.genesyscsdteng.com', // dot prefix is required!
    },
    support: {
        botDeploymentId: '27aaede2-d5a9-4027-bd9e-a12a69feaf3d',
    },
    featureFlags: {
        // true to use a feature, false to disable it
        genEd: false,
        pendo: true,
    },
};
