import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';
import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { TrackByItem } from '@components';
import { UserRoleUid } from '@enums';
import { AuthService, ToastColor, ToastNotificationService, UserService } from '@services';
import { isPrideMonth, scrollToTopScrolling } from '@utils';
import { BehaviorSubject, Observable, Subscription, filter, firstValueFrom, map, tap } from 'rxjs';
import { Product, ProductService } from '../@shared/services/product';
import { GknRouteConfig, gknRouteConfig } from '../app-routing.config';
import { GknRouteConfigKey, GknRoutePath } from '../app-routing.enum';
import { AppHeaderService } from './app-header.service';
import { LoginModalComponent } from './login-modal/login-modal.component';
import { SubmenuLink } from './submenu-data';

@Component({
    selector: 'app-header',
    templateUrl: './app-header.component.html',
    styleUrls: ['./app-header.component.css'],
})
export class AppHeaderComponent implements OnInit, OnDestroy, TrackByItem<GknRouteConfig> {
    private subs: Subscription[] = [];

    @ViewChild('mobileMenuContainer') mobileMenuContainer: ElementRef;
    isMobileViewport = false;
    isShowingMenu = false;
    isAuthenticated = false;
    initials$: Observable<string>;

    navbarRouteKeys: GknRouteConfig[keyof GknRouteConfig][];
    homeRoutePath = '/' + GknRoutePath.HOME;
    profileRoutePath = '/' + GknRoutePath.PROFILE;

    selectedProduct: Observable<Product>;
    @Output() openMobileProfile = new EventEmitter<void>();

    userRole: Observable<UserRoleUid>;
    submenuLink: SubmenuLink[];

    // TODO: if you add a type of LoginModalComponent here, it reveals that we are calling a close method on line 114 that does not exist.
    // https://inindca.atlassian.net/browse/KNOW-1386
    signInModal;

    showMockGknUpdates$ = new BehaviorSubject(false);
    showSearchBar$ = new BehaviorSubject(true);

    constructor(
        private appHeaderService: AppHeaderService,
        private authService: AuthService,
        private breakpointObserver: BreakpointObserver,
        private changeDetector: ChangeDetectorRef,
        @Inject(DOCUMENT) private document: Document,
        private element: ElementRef,
        private productService: ProductService,
        private router: Router,
        private toastService: ToastNotificationService,
        private userService: UserService,
    ) {}

    ngOnInit(): void {
        this.selectedProduct = this.productService.selectedProduct$;
        this.userRole = this.userService.userRole$;
        this.navbarRouteKeys = this._navbarRouteKeys;
        this.submenuLink = this.appHeaderService.submenuLink;

        // Note: Setting flag to header search bar & search result page show up.
        this.subs.push(
            this.router.events
                .pipe(
                    filter((event) => event instanceof NavigationEnd),
                    map(() => {
                        if (this.router.url.includes('/search')) {
                            // The search bar is made available via search-results.component.ts, so we hide it here.
                            this.showSearchBar$.next(false);
                        } else {
                            this.showSearchBar$.next(true);
                        }
                    }),
                )
                .subscribe(),
        );

        this.subs.push(
            this.breakpointObserver
                .observe('(max-width: 1020px)')
                .pipe(
                    tap((state: BreakpointState) => {
                        const prevValue = this.isMobileViewport;
                        this.isMobileViewport = state.matches;
                        if (prevValue != this.isMobileViewport) {
                            this.isShowingMenu = false; // hide the menu when the viewport changes from desktop to mobile and/or back.
                        }

                        /*
                         * KNOW-1204 The breakpoint observer must reset the Google Translate widget, as this subscription doesn't
                         * re-trigger ng lifecycle hooks, which can result in the Google Translate Widget not being rendered properly.
                         */
                        this.cleanAndInitGoogleTranslateWidget();
                        this.changeDetector.markForCheck();
                    }),
                )
                .subscribe(),
        );

        this.subs.push(
            this.userService.isAuthenticated$.subscribe((isAuthenticated) => {
                this.isAuthenticated = isAuthenticated;
                this.initials$ = this.userService.initials$;
                this.changeDetector.markForCheck();
            }),
        );

        this.subs.push(
            this.authService.openLoginModal.subscribe(() => {
                this.openLoginModal();
            }),
        );
    }

    /**
     * Adds Google Translate Initialization scripts to the DOM,
     * only after cleaning any scripts with the same attribute ID.
     */
    cleanAndInitGoogleTranslateWidget(): void {
        const prototypeId = 'gt-prototype';
        const invokeId = 'gt-invoke';

        // clean up any existing scripts
        [prototypeId, invokeId].forEach((id) => this.document.getElementById(id)?.remove());

        const v = this.document.createElement('script');
        v.id = prototypeId;
        v.type = 'text/javascript';

        // This list should match https://genesys-confluence.atlassian.net/wiki/spaces/GCK/pages/162661526/Languages+Supported+in+Knowledge+Services
        const languages = [
            'en',
            'es',
            'fr',
            'de',
            'it',
            'pt-PT',
            'nl',
            'ca',
            'tr',
            'sv',
            'no',
            'da',
            'fi',
            'ja',
            'ar',
            'ko',
            'zh-CN',
            'zh-TW',
            'pl',
            'th',
            'hi',
            'hu',
            'vi',
            'uk',
            'eu',
            'iw',
            'ru',
            'cs',
        ].join(',');

        v.innerHTML = `function googleTranslateElementInit() { new google.translate.TranslateElement({ pageLanguage: 'en', includedLanguages: '${languages}', layout: google.translate.TranslateElement.InlineLayout.SIMPLE }, 'google_translate_element'); }`;
        this.element.nativeElement.appendChild(v);

        const s = this.document.createElement('script');
        s.id = invokeId;
        s.type = 'text/javascript';
        s.src = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
        this.element.nativeElement.appendChild(s);
    }

    /**
     * Checks if current month is June for Pride Month
     * * @returns boolean
     */
    isPrideMonth(): boolean {
        return isPrideMonth();
    }

    /**
     * Open Login Modal to Sign In
     */
    openLoginModal(): void {
        if (!this.signInModal) {
            this.signInModal = new LoginModalComponent(this.document);
        }
        this.signInModal.openModal('login-modal');
    }

    /**
     * Close Login Modal
     */
    closeLoginModal(): void {
        // TODO: How does this work? There is not a close() method defined in the LoginModalComponent class.
        // https://inindca.atlassian.net/browse/KNOW-1386
        this.signInModal.close();
    }

    /**
     * Sign in with AuthService via redirect to the Okta Login page
     */
    async signInWithOkta(): Promise<void> {
        await this.authService.redirectToOktaHostedLogin();
    }

    /**
     * Sign out with AuthService
     */
    signOut(): void {
        this.authService
            .signOut()
            .then(() => {
                return firstValueFrom(this.selectedProduct);
            })
            .then((selectedProduct) => {
                this.isAuthenticated = false;
                this.changeDetector.markForCheck();
                this.router
                    .navigate(['/home'], {
                        queryParams: {
                            product: selectedProduct,
                        },
                        queryParamsHandling: 'merge',
                    })
                    .then(() => {
                        this.toastService.notify({
                            innerHtml: 'You have been signed out of Okta.',
                            color: ToastColor.INFO,
                        });
                    });
            });
    }

    /**
     * Open mobile Menu
     * @param event click event
     */
    openMenu(event?: Event): void {
        event?.stopPropagation();
        this.isShowingMenu = true;
        this.mobileMenuContainer?.nativeElement.classList.add('active');
        this.changeDetector.markForCheck();
    }

    /**
     * Close mobile menu toggle
     * @param event click event
     */
    closeMenu(event?: Event): void {
        event?.stopPropagation();
        this.isShowingMenu = false;
        this.mobileMenuContainer?.nativeElement.classList.remove('active');
        this.changeDetector.markForCheck();
    }

    /** Filter navbar routes from app router configs. */
    private get _navbarRouteKeys(): GknRouteConfig[keyof GknRouteConfig][] {
        const keys = [
            GknRouteConfigKey.HOME,
            GknRouteConfigKey.COMMUNITY,
            GknRouteConfigKey.GET_INVOLVED,
            GknRouteConfigKey.EDU_TRAINING,
            GknRouteConfigKey.RESOURCES,
            GknRouteConfigKey.SUPPORT,
        ];

        return keys.map((routeName) => {
            return gknRouteConfig[routeName];
        });
    }

    /**
     * Handle search event
     * Redirects to the deafult search page with search route and any additional
     * fragment and queryParams
     * @param searchTerm to search string
     */
    handleSearch(searchTerm: string): void {
        firstValueFrom(this.selectedProduct).then((selectedProduct: Product) => {
            this.showSearchBar$.next(false);
            this.router.navigate(['/search'], {
                fragment: `q=${searchTerm}`,
                queryParams: {
                    product: selectedProduct,
                },
                queryParamsHandling: 'preserve',
            });
        });
    }

    handleOpenMobileProfile() {
        this.openMobileProfile.emit();
    }

    scrollToTop(): void {
        scrollToTopScrolling();
    }

    trackByItem(index: number, _item: GknRouteConfig): NonNullable<number | string> {
        return index;
    }

    ngOnDestroy(): void {
        if (this.subs) {
            this.subs.forEach((sub) => {
                sub.unsubscribe();
            });
        }
    }
}
