import { AfterViewChecked, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

import { NgTemplateOutlet } from '@angular/common';
import { TrackByItem } from '@components';
import { NguCarouselConfig } from '@ngu/carousel';

@Component({
    selector: 'app-mobile-carousel',
    templateUrl: './mobile-carousel.component.html',
    styleUrls: ['./mobile-carousel.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class MobileCarouselComponent implements OnInit, AfterViewChecked, TrackByItem<any> {
    /** configuration */
    carouselMobile: NguCarouselConfig;
    /**  background color for carousel dots */
    @Input() carouselDotActiveCSS = 'default-active';
    /** data items for the carousel */
    @Input() carouselItems: any[] = [];
    /** carousel left arrows based optional css */
    @Input() carouselLeftArrowCSS: string;
    /** carousel right arrows based on optional css */
    @Input() carouselRightArrowCSS: string;
    /** template outlet for rendering {@link carouselItems} */
    @Input() carouselItemTemplate: NgTemplateOutlet;

    constructor(private cdr: ChangeDetectorRef) {}

    /**
     * OnInit - Configure the banner carousel data and images to be loaded in carousel
     */
    ngOnInit(): void {
        this.carouselMobile = {
            grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
            slide: 1,
            speed: 400,
            interval: {
                timing: 10000, // 10 seconds
                initialDelay: 1000,
            },
            point: {
                visible: true,
            },
            load: 2,
            custom: 'banner',
            loop: false,
            touch: true, // touch is not currently in active for vertical carousel, will enable it in future build
            vertical: {
                enabled: false,
                height: 400,
            },
        };
    }

    ngAfterViewChecked(): void {
        this.cdr.detectChanges();
    }

    trackByItem(index: number, _data: any): NonNullable<number | string> {
        return index;
    }
}
