import { Html } from '../../components';

export class HomePage implements Html {
    static CANVAS_ID = 'home-canvas';

    html(): HTMLElement {
        const root = document.createElement('div');
        root.setAttribute('class', 'home-root');

        const canvas = document.createElement('canvas');
        canvas.setAttribute('class', 'home-canvas mat-elevation-z6');
        canvas.id = HomePage.CANVAS_ID;
        root.appendChild(canvas);

        return root;
    }

    static async renderHomePageThumbnail(): Promise<void> {
        return new Promise((resolve, reject) => {
            const videoPlayer: HTMLVideoElement = <HTMLVideoElement>document.createElement('video');
            videoPlayer.src =
                'https://csd-static-assets-572708336319-us-east-1.s3.us-east-1.amazonaws.com/assets/onboarding.mp4';
            videoPlayer.preload = 'metadata';
            videoPlayer.muted = true;
            videoPlayer.playsInline = true;

            // load metadata of the video to get video duration and dimensions
            videoPlayer.addEventListener('loadeddata', () => {
                // seek to user defined timestamp (in seconds) if possible
                const seekTo = 3.0;
                if (videoPlayer.duration < seekTo) {
                    reject('video is too short.');
                    return;
                }

                // delay seeking or else 'seeked' event won't fire
                setTimeout(() => {
                    videoPlayer.currentTime = seekTo;
                }, 100);

                // extract video thumbnail once seeking is complete
                videoPlayer.addEventListener('seeked', () => {
                    // pause the video
                    videoPlayer.pause();

                    const canvas: HTMLCanvasElement = <HTMLCanvasElement>document.getElementById(HomePage.CANVAS_ID);
                    const ctx = canvas.getContext('2d');
                    canvas.width = videoPlayer.videoWidth;
                    canvas.height = videoPlayer.videoHeight;
                    ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
                    resolve();
                });
            });

            videoPlayer.play();
        });
    }
}
