import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription, tap } from 'rxjs';
import { UserRoleUid } from '../enums/user-role-uid.enum';
import { UserService } from '../services/user/user.service';

/**
 * Structural directive indicating the attached element should only be shown for the user roles in the given list.
 *
 * @example
 * // my.component.ts
 * import { UserRoleUid } from '@enums'
 * export class MyComponent {
 *   userRoleEnum = UserRoleUid;
 * }
 *
 * // my.component.html
 * <p *userRoles="[userRoleEnum.CUSTOMER, userRoleEnum.PARTNER]">
 *   hello, client-facing world!
 * </p>
 * <p *userRoles="[userRoleEnum.EMPLOYEE, userRoleEnum.CSM_TAM, userRoleEnum.CONSULTANT]">
 *   hello, internal world!
 * </p>
 */
@Directive({
    selector: '[userRoles]',
})
export class UserRolesDirective implements OnInit, OnDestroy {
    /** rxjs subscription to the active user role */
    private sub: Subscription;

    /**
     * list of user roles for which the component should render.
     * must have the same name as the directive selector!
     */
    @Input() private userRoles: UserRoleUid[];

    constructor(
        private userService: UserService,
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
    ) {}

    ngOnInit() {
        this.sub = this.userService.userRole$
            .pipe(
                tap((userRole: UserRoleUid) => {
                    this.viewContainer.clear();
                    if (this.userRoles?.includes(userRole)) {
                        this.viewContainer.createEmbeddedView(this.templateRef);
                    }
                }),
            )
            .subscribe();
    }

    ngOnDestroy(): void {
        this.sub?.unsubscribe();
    }
}
