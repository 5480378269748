import { BreakpointObserver } from '@angular/cdk/layout';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NewsOrigin, UserRoleHierarchy, UserRoleHierarchyNode, UserRoleUid } from '@enums';
import { environment } from '@environments/environment';
import { AnalyticsService, ProductService, RouteService, UserService } from '@services';
import { MapMonthsToNumber } from '@utils';
import dayjs from 'dayjs';
import {
    EMPTY,
    Observable,
    catchError,
    combineLatestWith,
    filter,
    map,
    shareReplay,
    take,
    tap,
    throwError,
} from 'rxjs';
import { ArchivesByYear } from 'src/app/archives/archives.component';
import { RoadmapsArchive } from 'src/app/archives/roadmaps-archive.interface';
import { mapDatesToGknDurations } from '../../rxjs';
import { GknDuration } from '../../types/gkn-duration.interface';
import {
    CmsAlerts,
    CmsCsAcademyData,
    CmsCsAcademyResponse,
    CmsCxEvolution,
    CmsEducation,
    CmsEducationAcfType,
    CmsFeaturedContent,
    CmsFooter,
    CmsOnboardingWebinar,
    CmsProductAnnouncement,
    CmsRelatedCommunity,
    CmsTestimonial,
    CmsUsefulLink,
    GknAlert,
    GknCsAcademyCard,
    GknCxEvolution,
    GknCxEvolutionCard,
    GknCxEvolutionCardTab,
    GknCxEvolutionCommunity,
    GknCxEvolutionExcerpt,
    GknEduEventAndWebinarCard,
    GknEduFreeTraining,
    GknFeaturedContent,
    GknFooter,
    GknGetCertifiedCard,
    GknNewsFeed,
    GknOnboardingWebinar,
    GknRelatedCommunity,
    GknTestimonial,
    GknUsefulLink,
} from './interfaces';
import { CmsFundamental, CmsFundamentalAcfType } from './interfaces/cms-fundamental.interface';
import { GknFundamentalCardLink, GknFundamentalRoadmapCard } from './interfaces/gkn-fundamental.interface';

/**
 * Structure for organizing a Strapi response with multiple
 * items based on the user role relationship
 */
interface ContentByRole {
    [UserRoleUid.PUBLIC]?: any[];
    [UserRoleUid.AUTHENTICATED]?: any[];
    [UserRoleUid.PARTNER]?: any[];
    [UserRoleUid.CUSTOMER]?: any[];
    [UserRoleUid.EMPLOYEE]?: any[];
    [UserRoleUid.CSM_TAM]?: any[];
}

/**
 * Gets a single item of content for the given user role. This item is as specific
 * as is available by intelligently considering the hierarchy of {@link UserRoleUid},
 * e.g. a CSM_TAM user is also an Employee, and Authenticated, and Public.
 *
 * This function is typically only necessary when a single item shall be rendered on
 * the screen. For things like lists, users should see all available content within
 * their user role hierarchy (this mapping is not necessary).
 *
 * @param contentByRole response from Strapi, organized by role
 * @param userRole the current user's role
 * @returns a single object of type <T>, or null (be sure to use a pipe operator to `filter` null responses)
 */
function mapToMostSpecificContentForUserRole<T>(contentByRole: ContentByRole, userRole: UserRoleUid): T {
    let node: UserRoleHierarchyNode = UserRoleHierarchy[userRole];

    while (!contentByRole[node.val]) {
        const next = node.next;
        if (next) {
            node = next;
        } else {
            return null;
        }
    }

    return contentByRole[node.val].length > 0 ? contentByRole[node.val][0] : null;
}

interface ContentCache {
    eduCerts$: Observable<GknGetCertifiedCard[]>;
    eduEventsAndWebinars$: Observable<GknEduEventAndWebinarCard[]>;
    eduFreeTrainings$: Observable<GknEduFreeTraining[]>;
    productIdeasLab$?: Observable<GknFundamentalCardLink[]>;
    releaseNotes$?: Observable<GknFundamentalCardLink[]>;
    roadmaps$?: Observable<string>;
    roadmapArchives$?: Observable<ArchivesByYear[]>;
}

/**
 * Provides access to Know CMS
 */
@Injectable({
    providedIn: 'root',
})
export class ContentService {
    // TODO KNOW-2593 Add server-side REST API filtering to Wordpress.
    // This is only fetched ones at start-up because CMS API data is not restricted to any Authorization tokens.
    private _fundamentalsCache$ = this.http.get<CmsFundamental[]>(`${environment.api.cms.wp}/fundamental`).pipe(
        shareReplay({ bufferSize: 1, refCount: true }),
        take(1),
        tap((fundamentals) => console.debug('fundamentals', fundamentals)),
    );

    // TODO KNOW-2593 Add server-side REST API filtering to Wordpress.
    // This is only fetched ones at start-up because CMS API data is not restricted to any Authorization tokens.
    private _eduCache$ = this.http.get<CmsEducation[]>(`${environment.api.cms.wp}/education`).pipe(
        shareReplay({ bufferSize: 1, refCount: true }),
        take(1),
        tap((educations) => console.debug('educations', educations)),
    );

    /**
     * Caches the given observable
     * @param obs to cache
     * @returns monotype operator function to share the observable as a replay subject
     */
    private _cacheObservable = <T>(obs: Observable<T>) =>
        obs.pipe(shareReplay({ bufferSize: 1, refCount: true }), take(1));

    /**
     * Internal cache for this content. See {@link _fundamentalCache$}.
     */
    private _cache: ContentCache = {
        eduCerts$: this._cacheObservable<GknGetCertifiedCard[]>(this._eduCerts$),
        eduEventsAndWebinars$: this._cacheObservable<GknEduEventAndWebinarCard[]>(this._eduEventsAndWebinars$),
        eduFreeTrainings$: this._cacheObservable<GknEduFreeTraining[]>(this._eduFreeTrainings$),
        productIdeasLab$: this._cacheObservable<GknFundamentalCardLink[]>(this._productIdeasLab$),
        releaseNotes$: this._cacheObservable<GknFundamentalCardLink[]>(this._releaseNotes$),
        roadmaps$: this._cacheObservable<string>(this._roadmaps$),
        roadmapArchives$: this._cacheObservable<ArchivesByYear[]>(this._roadmapArchives$),
    };

    constructor(
        private breakpointObserver: BreakpointObserver,
        private http: HttpClient,
        private productService: ProductService,
        private routeService: RouteService,
        private userService: UserService,
    ) {}

    /**
     * Creates a comination of user role query filters for using with Strapi's $or operator.
     * https://docs.strapi.io/developer-docs/latest/developer-resources/database-apis-reference/rest/filtering-locale-publication.html#filtering
     *
     * @deprecated This will be deleted with the removal of Strapi
     * @param userRole from business logic
     * @returns the query filter as a JSON object
     */
    private createQueryFile$OrUserRole(userRole: UserRoleUid) {
        const userRolesFilter: UserRoleUid[] = [userRole];

        if (userRole != UserRoleUid.PUBLIC) {
            userRolesFilter.push(UserRoleUid.PUBLIC);
        }

        if (
            userRole != UserRoleUid.AUTHENTICATED &&
            // the following roles are inherently "authenticated"
            (userRole == UserRoleUid.CSM_TAM ||
                userRole == UserRoleUid.CUSTOMER ||
                userRole == UserRoleUid.EMPLOYEE ||
                userRole == UserRoleUid.PARTNER ||
                userRole == UserRoleUid.CONSULTANT)
        ) {
            userRolesFilter.push(UserRoleUid.AUTHENTICATED);
        }

        if (userRole == UserRoleUid.CSM_TAM || userRole == UserRoleUid.CONSULTANT) {
            userRolesFilter.push(UserRoleUid.EMPLOYEE);
        }

        return userRolesFilter.map((role) => ({
            user_roles: { uid: role },
        }));
    }

    get featuredContent$(): Observable<GknFeaturedContent> {
        return this.http.get<CmsFeaturedContent[]>(`${environment.api.cms.wp}/featured-content`).pipe(
            filter((featuredContents) => featuredContents?.length > 0),
            combineLatestWith(
                this.productService.selectedProduct$,
                this.userService.userRole$,
                this.routeService.currentRoute$,
            ),
            map(([data, product, userRole, route]) => {
                const contentByRole: ContentByRole = {};

                data.filter((item) => {
                    return item?.acf?.products?.includes(product.uid) && item?.acf?.pages?.includes(route);
                }).forEach((item: CmsFeaturedContent) => {
                    const mapped: GknFeaturedContent = {
                        title: item?.acf?.title,
                        description: item?.acf?.excerpt,
                        urlLabel: item?.acf?.label,
                        url: item?.acf?.url,
                        type: item?.acf?.type,
                        thumbnail: item?.acf?.thumbnail,
                        userRoles: item?.acf?.user_roles as UserRoleUid[],
                    };

                    mapped.userRoles?.forEach((userRoleUid: UserRoleUid) => {
                        if (!contentByRole[userRoleUid]) {
                            contentByRole[userRoleUid] = [];
                        }

                        contentByRole[userRoleUid].push(mapped);
                    });
                });

                return [contentByRole, userRole];
            }),
            map(([contentByRole, userRole]: [ContentByRole, UserRoleUid]) =>
                mapToMostSpecificContentForUserRole<GknFeaturedContent>(contentByRole, userRole),
            ),
            filter((featuredContent) => !!featuredContent), // filter null
            tap((featuredContent) => console.debug('featured content', featuredContent)),
        );
    }

    get testimonials$(): Observable<GknTestimonial[]> {
        return this.http.get<CmsTestimonial[]>(`${environment.api.cms.wp}/testimonial`).pipe(
            filter((testimonials) => testimonials?.length > 0),
            map((testimonials) =>
                testimonials.map((t) => {
                    return {
                        quote: t?.acf?.quote,
                        source: t?.acf?.source,
                    };
                }),
            ),
            tap((testimonials) => {
                console.debug('testimonials', testimonials);
            }),
        );
    }

    public get productIdeasLab$(): Observable<GknFundamentalCardLink[]> {
        return this._cache.productIdeasLab$;
    }

    private get _productIdeasLab$(): Observable<GknFundamentalCardLink[]> {
        return this.productService.selectedProduct$.pipe(
            combineLatestWith(this._fundamentalsCache$, this.userService.userRole$),
            map(([product, fundamentals, userRole]) => {
                return fundamentals?.find((fundamental) => {
                    return (
                        fundamental?.acf?.type == CmsFundamentalAcfType.PRODUCT_IDEAS_LAB &&
                        fundamental?.acf?.products?.includes(product.uid) &&
                        fundamental?.acf?.user_roles?.includes(userRole)
                    );
                });
            }),
            map((productIdeasLab) => {
                return [
                    {
                        url: productIdeasLab?.acf?.url,
                        text: productIdeasLab?.acf?.label,
                        isButton: true,
                        dataAnalytics: [
                            'product',
                            'ideas',
                            'lab',
                            AnalyticsService.format(productIdeasLab?.acf?.label),
                        ],
                    },
                    {
                        url: productIdeasLab?.acf?.quick_start,
                        text: 'Quick Guide',
                        isButton: false,
                        dataAnalytics: ['product', 'ideas', 'lab', 'quick', 'guide'],
                    },
                    {
                        url: productIdeasLab?.acf?.statuses,
                        text: 'Statuses',
                        isButton: false,
                        dataAnalytics: ['product', 'ideas', 'lab', 'statuses'],
                    },
                ];
            }),
            tap((productIdeasLab) => console.debug('product ideas lab', productIdeasLab)),
        );
    }

    public get releaseNotes$(): Observable<GknFundamentalCardLink[]> {
        return this._cache.releaseNotes$;
    }

    private get _releaseNotes$(): Observable<GknFundamentalCardLink[]> {
        return this._fundamentalsCache$.pipe(
            map((fundamentals) => {
                return fundamentals?.filter((fundamental) => {
                    return fundamental?.acf?.type == CmsFundamentalAcfType.RELEASE_NOTE;
                });
            }),
            filter((releaseNotes) => releaseNotes?.length > 0),
            combineLatestWith(this.productService.selectedProduct$, this.userService.userRole$),
            map(([cmsReleaseNotes, product, userRole]): GknFundamentalCardLink[] => {
                const acf = cmsReleaseNotes?.find((rn) => {
                    return rn?.acf?.products?.includes(product.uid) && rn?.acf?.user_roles?.includes(userRole);
                })?.acf;

                const releaseNotes: GknFundamentalCardLink[] = [];

                if (acf?.label && acf?.url) {
                    releaseNotes.push({
                        text: acf?.label,
                        url: acf?.url,
                        isButton: true,
                        dataAnalytics: ['release', 'notes', AnalyticsService.format(acf?.label)],
                    });
                }

                if (acf?.label_2 && acf?.url_2) {
                    releaseNotes.push({
                        text: acf?.label_2,
                        url: acf?.url_2,
                        isButton: true,
                        dataAnalytics: ['release', 'notes', AnalyticsService.format(acf?.label_2)],
                    });
                }

                return releaseNotes;
            }),
            tap((releaseNotes) => console.debug('release notes', releaseNotes)),
        ) as Observable<GknFundamentalCardLink[]>;
    }

    public get roadmaps$(): Observable<string> {
        return this._cache.roadmaps$;
    }

    private get _roadmaps$(): Observable<string> {
        return this._fundamentalsCache$.pipe(
            map((fundamentals): CmsFundamental[] => {
                return fundamentals?.filter((fundamental) => {
                    return fundamental?.acf?.type == CmsFundamentalAcfType.ROADMAP;
                });
            }),
            filter((roadmaps: CmsFundamental[]) => roadmaps?.length > 0),
            combineLatestWith(this.productService.selectedProduct$, this.userService.userRole$),
            map(([roadmaps, product, userRole]) => {
                const roadmap = roadmaps?.filter(
                    (r) => r?.acf?.products?.includes(product.uid) && r?.acf?.user_roles?.includes(userRole),
                )?.[0];

                if (roadmap) {
                    return {
                        rendered: roadmap?.acf?.body.replace(/<a(.*)/, '<a target="_blank" rel="nofollow"$1'), // make sure all links open in a new tab
                        user_roles: roadmap?.acf?.user_roles as UserRoleUid[],
                    } as GknFundamentalRoadmapCard;
                } else {
                    throwError(() => new Error('No roadmaps found.'));
                }
            }),
            map((roadmap: GknFundamentalRoadmapCard) => {
                const contentByRole: ContentByRole = {};

                roadmap?.user_roles?.forEach((userRoleUid: UserRoleUid) => {
                    if (!contentByRole[userRoleUid]) {
                        contentByRole[userRoleUid] = [];
                    }

                    contentByRole[userRoleUid].push(roadmap);
                });
                console.log('roadmaps by role', contentByRole);
                return contentByRole;
            }),
            combineLatestWith(this.userService.userRole$),
            map(([contentByRole, userRole]) =>
                mapToMostSpecificContentForUserRole<GknFundamentalRoadmapCard>(contentByRole, userRole),
            ),
            filter((roadmap: GknFundamentalRoadmapCard) => !!roadmap),
            map((roadmap) => roadmap.rendered),
            catchError((err): Observable<string> => {
                console.error(err);
                return EMPTY;
            }),
            tap((roadmaps) => console.debug('roadmaps', roadmaps)),
        ) as Observable<string>;
    }

    public get roadmapArchives$(): Observable<ArchivesByYear[]> {
        return this._cache.roadmapArchives$;
    }

    private get _roadmapArchives$(): Observable<ArchivesByYear[]> {
        return this._fundamentalsCache$.pipe(
            map((fundamentals): CmsFundamental[] => {
                return fundamentals?.filter((fundamental) => {
                    return fundamental?.acf?.type == CmsFundamentalAcfType.ROADMAP_ARCHIVE;
                });
            }),
            filter((roadmapArchives: CmsFundamental[]) => roadmapArchives?.length > 0),
            map((roadmapArchives) => {
                const archives = roadmapArchives.map(({ acf, id }): RoadmapsArchive => {
                    const monthYear = acf?.label.split('-')[1].trim();
                    const month = monthYear.split(' ')[0];
                    const year = monthYear.split(' ')[1];
                    return {
                        id: id,
                        title: acf?.label,
                        type: acf?.type,
                        pdfUrl: acf?.pdf_download,
                        videoId: acf?.url?.split('https://vimeo.com/')[1],
                        month: MapMonthsToNumber[month.toLocaleLowerCase()],
                        year: parseInt(year),
                    };
                });

                const groupByYear = {};

                archives.forEach((archive) => {
                    if (!groupByYear[archive.year]) {
                        groupByYear[archive.year] = [];
                    }
                    groupByYear[archive.year].push(archive);
                });

                const archivesByYear = [];

                for (const [key, value] of Object.entries(groupByYear)) {
                    archivesByYear.push({
                        year: parseInt(key),
                        archives: value,
                    });
                }

                return archivesByYear.sort((a, b) => b.year - a.year);
            }),
            tap((roadmapArchives) => console.debug('roadmap archives', roadmapArchives)),
        );
    }

    get usefulLinks$(): Observable<GknUsefulLink[]> {
        return this.http.get<CmsUsefulLink[]>(`${environment.api.cms.wp}/useful-link`).pipe(
            filter((usefulLinks) => usefulLinks?.length > 0),
            combineLatestWith(this.productService.selectedProduct$),
            map(([usefulLinks, product]) =>
                usefulLinks
                    .filter((link) => link?.acf?.products?.includes(product.uid))
                    .map((link) => {
                        return {
                            label: link?.acf?.label,
                            url: link?.acf?.url,
                            iconUrl: link.acf.icon,
                            LoadsInFrame:
                                link?.acf?.label === 'Best Practices' ||
                                link?.acf?.label === 'Billing FAQs' ||
                                link?.acf?.label === 'Download your usage reports' ||
                                link?.acf?.label === 'Troubleshooting' ||
                                link?.acf?.label === 'Your Genesys Cloud CX invoice explained' || // KNOW-2206 maintained for backwards compatibility
                                link?.acf?.label === 'Your Genesys Cloud invoice explained',
                        };
                    }),
            ),
        );
    }

    get footers$(): Observable<GknFooter[]> {
        return this.http.get<CmsFooter[]>(`${environment.api.cms.wp}/footer`).pipe(
            filter((footers) => footers?.length > 0),
            map((footers) => {
                return footers?.map((footer) => ({
                    type: footer?.acf?.type,
                    label: footer?.acf?.label,
                    excerpt: footer?.acf?.excerpt,
                    url: footer?.acf?.url,
                }));
            }),
        );
    }

    get productAnnouncements$(): Observable<GknNewsFeed[]> {
        return this.http.get<CmsProductAnnouncement[]>(`${environment.api.cms.wp}/product-announcement`).pipe(
            filter((announcements) => announcements?.length > 0),
            combineLatestWith(
                this.productService.selectedProduct$,
                this.breakpointObserver.observe('(max-width: 1020px)').pipe(map((state) => state.matches)),
            ),
            map(([data, product, isMobile]) => {
                return (
                    data
                        ?.filter((announcement) => {
                            return announcement?.acf?.products?.includes(product.uid);
                        })
                        ?.map((announcement: CmsProductAnnouncement): GknNewsFeed => {
                            return {
                                AltText: announcement?.acf?.title,
                                Id: announcement?.id?.toString(),
                                Url: announcement?.acf?.url,
                                Origin: NewsOrigin.ANNOUNCEMENT,
                                Content: announcement?.acf?.excerpt,
                                Published: announcement?.acf?.start_date_time
                                    ? new Date(announcement?.acf?.start_date_time)
                                    : null,
                                Image:
                                    (isMobile
                                        ? announcement?.acf?.thumbnails?.mobile
                                        : announcement?.acf?.thumbnails?.desktop) || '/assets/icons/speaker-phone.svg',
                                Title: announcement?.acf?.title,
                                LoadsInFrame: false,
                                dataAnalytics: [
                                    'news',
                                    'product',
                                    'announcement',
                                    AnalyticsService.format(announcement?.acf?.title),
                                ],
                            };
                        }) || []
                );
            }),
            tap((announcements) => console.debug('product announcements', announcements)),
        );
    }

    get csAcademyCards$(): Observable<GknCsAcademyCard[]> {
        return this.http.get<CmsCsAcademyResponse>(`${environment.api.cms.strapi}/cs-academy-tiles`).pipe(
            filter((res) => res?.data?.length > 0),
            map((res) =>
                res.data.map((card: CmsCsAcademyData) => ({
                    title: card.attributes.title,
                    urlLabel: card.attributes.urlLabel,
                    url: card.attributes.url,
                    description: card.attributes.description,
                    icon: 'assets/icons/marketing-events/content-tags-cs-academy.svg',
                    dataAnalytics: ['training', 'cs', 'academy', AnalyticsService.format(card.attributes.title)],
                })),
            ),
        );
    }

    get relatedCommunities$(): Observable<GknRelatedCommunity[]> {
        return this.http.get<CmsRelatedCommunity[]>(`${environment.api.cms.wp}/related-community`).pipe(
            filter((relatedCommunities) => relatedCommunities?.length > 0),
            combineLatestWith(this.productService.selectedProduct$),
            map(([relatedCommunities, product]) => {
                return relatedCommunities
                    .filter((relatedCommunity) => relatedCommunity?.acf?.products?.includes(product.uid))
                    .map((relatedCommunity) => {
                        return {
                            label: relatedCommunity?.acf?.label,
                            url: relatedCommunity?.acf?.url,
                        };
                    })
                    .sort((a, b) => a.label.localeCompare(b.label));
            }),
        );
    }

    get onboardingWebinars$(): Observable<GknOnboardingWebinar[]> {
        return this.http.get<CmsOnboardingWebinar[]>(`${environment.api.cms.wp}/onboarding-webinar`).pipe(
            filter((webinars) => webinars?.length > 0),
            map((webinars) => {
                return webinars.map(
                    (webinar) =>
                        ({
                            id: webinar.id,
                            title: webinar?.acf?.title,
                            subtitle: 'Webinar',
                            subtitleIcon: 'assets/icons/marketing-events/content-tags-webinar.svg',
                            duration: {
                                startDate: webinar?.acf?.start_date_time ? dayjs(webinar?.acf?.start_date_time) : null,
                                endDate: webinar?.acf?.end_date_time ? dayjs(webinar?.acf?.end_date_time) : null,
                                timezone: webinar?.acf?.timezone,
                            },
                            description: webinar?.acf?.excerpt,
                            thumbnails: webinar?.acf?.thumbnails,
                            url: webinar?.acf?.url,
                            cta: 'Learn more',
                            dataAnalytics: [
                                'new',
                                'customer',
                                'onboarding',
                                AnalyticsService.format(webinar?.acf?.title),
                            ],
                        } as GknOnboardingWebinar),
                );
            }),
            mapDatesToGknDurations('datetime'),
            map((webinars) => webinars.sort((a, b) => (a.duration.startDate?.isAfter(b.duration.startDate) ? 1 : -1))),
            tap((webinars) => console.debug('onboarding webinars', webinars[0].duration.startDate)),
            shareReplay(),
        );
    }

    get alerts$(): Observable<GknAlert[]> {
        const url = new URL(`${environment.api.cms.strapi}/alerts`);
        url.searchParams.set('populate', '*');
        return this.http.get<CmsAlerts>(url.toString()).pipe(
            filter((res) => res?.data?.length > 0),
            map((res) =>
                res.data.map((alert) => ({
                    id: alert?.id,
                    color: alert?.attributes?.color,
                    rendered: alert?.attributes?.rendered,
                })),
            ),
        );
    }

    get eduCerts$(): Observable<GknGetCertifiedCard[]> {
        return this._cache.eduCerts$;
    }

    private get _eduCerts$(): Observable<GknGetCertifiedCard[]> {
        return this._eduCache$.pipe(
            combineLatestWith(this.userService.userRole$),
            map(([educations, userRole]) => {
                const temp = educations?.filter((edu) => {
                    return (
                        edu?.acf?.type == CmsEducationAcfType.CERT &&
                        (edu?.acf?.user_roles?.includes(userRole) || edu?.acf?.user_roles?.includes(UserRoleUid.PUBLIC))
                    );
                });
                return temp;
            }),
            filter((certs) => certs?.length > 0),
            map((certs) => {
                return certs.map(
                    (certifiedTile: CmsEducation) =>
                        ({
                            title: certifiedTile?.acf?.title,
                            urlLabel: certifiedTile?.acf?.cta,
                            url: certifiedTile?.acf?.url,
                            beyondCertificationUrl:
                                environment.api.beyond.certification_base_url + certifiedTile?.acf?.links?.shortcut_url,
                            description: certifiedTile?.acf?.excerpt,
                            products: certifiedTile?.acf?.products,
                        } as GknGetCertifiedCard),
                );
            }),
            tap((certs) => console.debug('edu certs', certs)),
            shareReplay(),
        );
    }

    get eduEventsAndWebinars$(): Observable<GknEduEventAndWebinarCard[]> {
        return this._cache.eduEventsAndWebinars$;
    }

    private get _eduEventsAndWebinars$(): Observable<GknEduEventAndWebinarCard[]> {
        return this.productService.selectedProduct$.pipe(
            combineLatestWith(this._eduCache$, this.userService.userRole$),
            map(([product, educations, userRole]) => {
                return educations?.filter((edu) => {
                    return (
                        (edu?.acf?.type == CmsEducationAcfType.EVENT ||
                            edu?.acf?.type == CmsEducationAcfType.WEBINAR) &&
                        edu?.acf?.products?.includes(product.uid) &&
                        (edu?.acf?.user_roles?.includes(userRole) || edu?.acf?.user_roles?.includes(UserRoleUid.PUBLIC))
                    );
                });
            }),
            filter((educations) => educations?.length > 0),
            map((educations: CmsEducation[]) => {
                return educations.map((edu: CmsEducation) => {
                    let icon: string;
                    const baseUrl = 'assets/icons/marketing-events/';
                    switch (edu?.acf?.type) {
                        case CmsEducationAcfType.EVENT:
                            icon = baseUrl + 'miscellaneous-calendar.svg';
                            break;
                        default:
                            icon = baseUrl + 'content-tags-webinar.svg';
                    }

                    return (
                        {
                            title: edu?.acf?.title,
                            type: edu?.acf?.type,
                            duration: {
                                startDate: edu?.acf?.duration?.start_date_time
                                    ? dayjs(edu?.acf?.duration?.start_date_time)
                                    : null,
                                endDate: edu?.acf?.duration?.end_date_time
                                    ? dayjs(edu?.acf?.duration?.end_date_time)
                                    : null,
                                timezone: edu?.acf?.duration?.timezone,
                            } as GknDuration,
                            description: edu?.acf?.excerpt,
                            thumbnails: {
                                desktop: edu?.acf?.thumbnails?.desktop,
                                mobile: edu?.acf?.thumbnails?.mobile,
                            },
                            icon: icon,
                            url: edu?.acf?.url,
                            urlLabel: edu?.acf?.cta,
                        } || []
                    );
                });
            }),
            mapDatesToGknDurations('datetime'),
            map((eventsAndWebinars: GknEduEventAndWebinarCard[]) =>
                eventsAndWebinars.sort((a, b) => (a.duration.startDate?.isAfter(b.duration.startDate) ? 1 : -1)),
            ),
            tap((eventsAndWebinars) => console.debug('edu events and webinars', eventsAndWebinars)),
            shareReplay(),
        );
    }

    get eduFreeTrainings$(): Observable<GknEduFreeTraining[]> {
        return this._cache.eduFreeTrainings$;
    }

    private get _eduFreeTrainings$(): Observable<GknEduFreeTraining[]> {
        // TODO KNOW-1628 Get free Beyond Training video identifiers from Wordpress
        return throwError(() => new Error('Not implemented'));
    }

    get cxEvolution$(): Observable<GknCxEvolution> {
        return this.http.get<CmsCxEvolution[]>(`${environment.api.cms.wp}/cx-evolution`).pipe(
            combineLatestWith(this.productService.selectedProduct$),
            map(([cxEvolution, selectedProduct]) => {
                const contentForSelectedProduct = cxEvolution.filter((datum) =>
                    datum?.acf?.products?.includes(selectedProduct.uid),
                );

                const cmsExcerpt: CmsCxEvolution = contentForSelectedProduct.find(
                    (datum) => datum?.acf?.type == 'excerpt',
                );
                const gknExcerpt: GknCxEvolutionExcerpt = {
                    primaryExcerpt: cmsExcerpt?.acf?.primary_excerpt,
                    subtitle: cmsExcerpt?.acf?.subtitle,
                    communitiesTitle: cmsExcerpt?.acf?.communities_title,
                    communitiesExcerpt: cmsExcerpt?.acf?.communities_excerpt,
                };

                const communities: GknCxEvolutionCommunity[] =
                    contentForSelectedProduct
                        .filter((datum) => datum?.acf?.type == 'community')
                        ?.map((community) => {
                            return {
                                id: community?.id,
                                name: community?.acf?.title,
                                url: community?.acf?.url,
                                dataAnalytics: [
                                    'cx',
                                    'evolution',
                                    'community',
                                    AnalyticsService.format(community?.acf?.title),
                                ],
                            };
                        }) || [];

                const cards: GknCxEvolutionCard[] =
                    contentForSelectedProduct
                        .filter((datum) => datum?.acf?.type == 'card')
                        ?.map((card: CmsCxEvolution) => {
                            const tab = card.acf.tab;
                            const isGetStarted = tab == GknCxEvolutionCardTab.GET_STARTED;

                            return {
                                id: card?.id,
                                title: card?.acf?.title,
                                description: card?.acf?.description,
                                cta: card?.acf?.cta,
                                url: card?.acf?.url,
                                thumbnails: {
                                    desktop: card.acf?.thumbnails?.desktop,
                                    mobile: card?.acf?.thumbnails?.mobile,
                                    height: isGetStarted ? '246px' : '286px',
                                    width: isGetStarted ? '146px' : '178px',
                                },
                                vertical: isGetStarted,
                                tab: tab,
                                dataAnalytics: ['cx', 'evolution', AnalyticsService.format(card?.acf?.title)],
                            };
                        }) || [];

                return {
                    excerpt: gknExcerpt,
                    communities: communities,
                    cards: cards,
                };
            }),
            tap((cxEvolution) => console.debug('cx evolution', cxEvolution)),
            shareReplay(),
        );
    }
}
