import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { ComponentsModule } from '@components';
import { DirectivesModule } from '@directives';
import { CxEvolutionExcerptModule } from './component/cx-evolution-excerpt/cx-evolution-excerpt.module';
import { CxEvolutionComponent } from './cx-evolution.component';
import { CxEvolutionConnectModule } from './tab/cx-evolution-connect/cx-evolution-connect.module';
import { CxEvolutionGetStartedModule } from './tab/cx-evolution-get-started/cx-evolution-get-started.module';
import { CxEvolutionLearnFromOthersModule } from './tab/cx-evolution-learn-from-others/cx-evolution-learn-from-others.module';

@NgModule({
    declarations: [CxEvolutionComponent],
    imports: [
        CommonModule,
        ComponentsModule,
        DirectivesModule,
        CxEvolutionExcerptModule,
        CxEvolutionConnectModule,
        CxEvolutionGetStartedModule,
        CxEvolutionLearnFromOthersModule,
        DirectivesModule,
        MatTabsModule,
    ],
    exports: [CxEvolutionComponent],
})
export class CxEvolutionModule {}
