import { ModuleWithProviders, NgModule } from '@angular/core';
import { KeplerDoceboInterceptor, KeplerDoceboService } from './kepler-docebo';

import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { OktaAuthModule } from '@okta/okta-angular';
import { KeplerMarketingService, KeplerPurecloudService } from '@services';
import { CookieService as NgxCookieService } from 'ngx-cookie-service';
import { ProductHelpService } from 'src/app/resources/product-help/product-help.service';
import { AnalyticsService } from './analytics/analytics.service';
import { AuthService } from './auth/auth.service';
import { BeyondWordpressService } from './beyond-wordpress/beyond-wordpress.service';
import { ContentInterceptor } from './content/content.interceptor';
import { ContentService } from './content/content.service';
import { CookieService } from './cookie/cookie.service';
import { FavoritesService } from './favorites/favorites.service';
import { HealthCheckService } from './health-check/health-check.service';
import { KeplerCoveoService } from './kepler-coveo/kepler-coveo.service';
import { KeplerCredlyInterceptor } from './kepler-credly/kepler-credly.interceptor';
import { KeplerDbInterceptor } from './kepler-db/kepler-db.interceptor';
import { KeplerFacebookService } from './kepler-facebook/kepler-facebook.service';
import { KeplerMarketingAdapter } from './kepler-marketing/kepler-marketing.adapter';
import { KeplerPurecloudInterceptor } from './kepler-purecloud/kepler-purecloud.interceptor';
import { KeplerSfdcInterceptor } from './kepler-sfdc/kepler-sfdc.interceptor';
import { KeplerSfdcService } from './kepler-sfdc/kepler-sfdc.service';
import { KeplerVimeoService } from './kepler-vimeo/kepler-vimeo.service';
import { MarketingWpService } from './marketing-wp/marketing-wp.service';
import { ProductQueryParamService } from './product-query-param/product-query-param.service';
import { ProductService } from './product/product.service';
import { RouteService } from './route/route.service';
import { SectionScrollService } from './section-scroll/section-scroll.service';
import { SupportRecommendationsService } from './support-recommendations';
import { ToastNotificationService } from './toast-notification/toast-notification.service';
import { UserService } from './user/user.service';

@NgModule({
    imports: [CommonModule, OktaAuthModule],
})
export class ServicesModule {
    static forRoot(): ModuleWithProviders<ServicesModule> {
        return {
            ngModule: ServicesModule,
            providers: [
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: ContentInterceptor,
                    multi: true,
                },
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: KeplerSfdcInterceptor,
                    multi: true,
                },
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: KeplerCredlyInterceptor,
                    multi: true,
                },
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: KeplerDoceboInterceptor,
                    multi: true,
                },
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: KeplerDbInterceptor,
                    multi: true,
                },
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: KeplerPurecloudInterceptor,
                    multi: true,
                },
                AnalyticsService,
                AuthService,
                BeyondWordpressService,
                CookieService,
                NgxCookieService,
                ContentService,
                FavoritesService,
                HealthCheckService,
                KeplerCoveoService,
                KeplerFacebookService,
                KeplerMarketingService,
                KeplerMarketingAdapter,
                KeplerPurecloudService,
                KeplerVimeoService,
                KeplerSfdcService,
                KeplerDoceboService,
                MarketingWpService,
                ProductHelpService,
                ProductService,
                ProductQueryParamService,
                RouteService,
                SupportRecommendationsService,
                SectionScrollService,
                ToastNotificationService,
                UserService,
            ],
        };
    }
}
