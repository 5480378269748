<ng-container *ngIf="(progress$ | async) || (unauthenticatedView$ | async)">
    <div class="tabs">
        <button
            *ngFor="let tab of tabs; let i = index; trackBy: trackByItem"
            (click)="selectTab(i)"
            class="tab"
            [class.selected]="selectedTabIndex == i"
            [attr.data-analytics]="tab.dataAnalytics.join('-')">
            {{ tab.name }}
        </button>
    </div>
    <app-starter-kit-tab
        *ngIf="selectedTabIndex !== undefined && tabs[selectedTabIndex] != undefined"
        [tab]="tabs[selectedTabIndex]"
        [progress]="progress$"
        [updateToLatestStep$]="updateToLatestStep$"></app-starter-kit-tab>
    <section *ngIf="isDevMode$ | async">
        <div class="deleteContainer">
            <button class="delete" (click)="deleteStepProgress()">Clear Step Progress</button>
            <button class="delete" (click)="deleteProductProgress()">Clear Product Progress</button>
            <button class="delete" (click)="deleteAllProgress()">Clear All Progress</button>
        </div>
    </section>
</ng-container>
