<ng-container *desktop>
    <ng-container
        *ngTemplateOutlet="
            cardTemplate;
            context: {
                isMobile: false,
                card: card,
                thumbnail: card?.thumbnails?.desktop,
                thumbnailWidth: card?.thumbnails?.width,
                thumbnailHeight: card?.thumbnails?.height
            }
        "></ng-container>
</ng-container>
<ng-container *mobile>
    <ng-container
        *ngTemplateOutlet="
            cardTemplate;
            context: {
                isMobile: true,
                card: card,
                thumbnail: card?.thumbnails?.mobile,
                thumbnailWidth: card?.thumbnails?.width,
                thumbnailHeight: card?.thumbnails?.height
            }
        "></ng-container>
</ng-container>

<ng-template
    #cardTemplate
    let-isMobile="isMobile"
    let-card="card"
    let-thumbnail="thumbnail"
    let-thumbnailWidth="thumbnailWidth"
    let-thumbnailHeight="thumbnailHeight">
    <app-tile-vertical
        [title]="card?.title"
        [titleLink]="true"
        [description]="card?.description"
        [isCustomFooterLink]="true"
        [vertical]="isMobile === true || card?.vertical"
        [url]="card?.url"
        [urlLabel]="card?.cta"
        [border]="false"
        [hasBoxShadow]="true"
        [imageUrl]="thumbnail"
        [bigImage]="true"
        [isCustomImage]="false"
        [customImageCss]="thumbnail && thumbnail?.endsWith('svg') ? 'tile-background-color' : ''"
        [customSVGIcon]="thumbnail && thumbnail?.endsWith('svg') ? thumbnail : null"
        [hasImageAutoHeight]="false"
        [imageWidth]="thumbnailWidth"
        [imageHeight]="thumbnailHeight"
        [hasMarginTopAuto]="false"
        [dataAnalytics]="card?.dataAnalytics">
        <div class="external-link-div margin-top-smaller" customFooterLink>
            <div>
                <a
                    class="cursor link-n"
                    [href]="card?.url"
                    target="_blank"
                    [attr.data-analytics]="card?.dataAnalytics?.join('-')">
                    {{ card?.cta }}
                </a>
            </div>
            <div class="external-icons">
                <app-share [link]="card?.url" [dataAnalyticsCopy]="card?.dataAnalytics"></app-share>
                <app-external-url [url]="card?.url"></app-external-url>
            </div>
        </div>
    </app-tile-vertical>
</ng-template>
