<li class="li-border">
    <div class="card cursor">
        <a class="primary-link" target="_blank" rel="nofollow" [href]="developerCenterData.baseUrl + card.url">
            <img
                [src]="developerCenterData.iconBase + card.icon"
                [attr.height]="card?.height"
                [attr.width]="card?.width"
                [alt]="card.title" />
            <h2 class="h3 sub-title-1 text-brand-navy margin-top-small margin-bottom-medium">{{ card.title }}</h2>
        </a>
        <p class="p-body">{{ card.text }}</p>
    </div>
</li>
