import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ToolsCardComponent } from './tools-card.component';

@NgModule({
    declarations: [ToolsCardComponent],
    imports: [CommonModule],
    exports: [ToolsCardComponent],
})
export class ToolsCardModule {}
