<app-header (openMobileProfile)="openMobileProfile()"></app-header>

<ng-container *mobile>
    <ng-container *ngIf="!(isSearchPage$ | async)">
        <app-product-select></app-product-select>
        <hr />
    </ng-container>
</ng-container>

<app-hero-banner *ngIf="showHeroBanner$ | async"></app-hero-banner>

<main class="default">
    <router-outlet (activate)="scrollToTop()"></router-outlet>
</main>

<app-footer></app-footer>

<div *mobile #mobileProfileContainer class="mobile-profile-container">
    <app-profile (closeMobileProfile)="closeMobileProfile()"></app-profile>
</div>
