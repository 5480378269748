import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-anchor-point',
    templateUrl: './anchor-point.component.html',
    styleUrls: ['./anchor-point.component.css'],
})
export class AnchorPointComponent {
    @Input() id: string;
}
