import { Component, Input, OnInit } from '@angular/core';

import { ProductService } from '@services';
import { take } from 'rxjs';
import { TileSmall } from './tile-small.interface';

@Component({
    selector: 'app-tile-small',
    templateUrl: './tile-small.component.html',
    styleUrls: ['./tile-small.component.css'],
})
export class TileSmallComponent implements OnInit {
    @Input() altText: string;
    @Input() tileSmallData: TileSmall;
    @Input() dark: true;
    selected: TileSmall;

    constructor(private productService: ProductService) {}

    ngOnInit(): void {
        this.productService.selectedProduct$.pipe(take(1)).subscribe((product) => {
            if (product.uid === 'pureconnect' && this.tileSmallData?.label === 'Best Practices') {
                this.tileSmallData.LoadsInFrame = false;
            }
        });
    }

    /**
     * Open Article modal
     * @param tileSmallData - tileSmall to be open in modal
     */
    openArticle(tileSmallData: TileSmall): void {
        if (tileSmallData && tileSmallData.LoadsInFrame && tileSmallData.url) {
            this.selected = tileSmallData;
        }
    }

    /**
     * Close Article modal
     */
    closeArticle(): void {
        this.selected = undefined;
    }
}
