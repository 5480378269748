<app-section-header
    [title]="sectionLabel"
    [favoriteId]="favoriteId"
    [externalUrl]="externalUrl"
    [section]="sectionLocation"
    [showTitle]="showTitle"
    [showIcons]="showIcons"></app-section-header>

<p *desktop class="p-body-large">{{ excerpt }}</p>
<p *mobile class="p-body">{{ excerpt }}</p>

<div class="case-pages">
    <a class="btn btn-secondary" [href]="externalUrl" target="_blank" rel="noopener" data-testId="visit-my-support">
        Visit My Support
    </a>
</div>

<ng-container *ngIf="cases$ | async as cases">
    <ng-container *ngIf="cases.length > 0; else showNoCases">
        <div class="my-cases">
            <div class="container">
                <table>
                    <tr>
                        <th>Case #</th>
                        <th>Subject</th>
                        <th>Status</th>
                        <th>Updated</th>
                    </tr>
                    <ng-container *ngFor="let case of cases | slice: 0:pagingLimit">
                        <tr>
                            <td>{{ case.case }}</td>
                            <td>{{ case.subject }}</td>
                            <td>{{ case.status }}</td>
                            <td>
                                {{ case.updatedDate | date: 'M/d/Y' }}<br />{{ case.updatedDate | date: 'shortTime' }}
                            </td>
                        </tr>
                    </ng-container>
                </table>
            </div>
        </div>
    </ng-container>
    <ng-template #showNoCases>
        <h2 class="h3 sub-title-3 text-brand-navy">Congratulations, you don't have any cases!</h2>
        <div class="text-align-center margin-top-large"> </div>
    </ng-template>
</ng-container>
