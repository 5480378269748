/**
 * Remove whitespace from string
 * @param str - string that needs whitespace to be removed
 * @returns remove whitespace string, or no-op for falsy input
 */
export function removeWhiteSpace(str: string): string | null | undefined {
    if (str) {
        return str.replace(/\s/g, '');
    } else {
        return str;
    }
}
