<mat-card class="mat-elevation-z6" [ngClass]="flexDirection">
    <header class="bg-brand-navy">
        <img [src]="icon" [alt]="title" height="40px" />
        <h4 class="text-white">{{ title }}</h4>
    </header>
    <mat-card-content [ngClass]="{ isLoading: isLoading }">
        <mat-progress-spinner
            *ngIf="isLoading"
            mode="indeterminate"
            color="primary"
            diameter="50"></mat-progress-spinner>
        <div *ngIf="!isLoading">
            <ng-content></ng-content>
        </div>
    </mat-card-content>
</mat-card>
