import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import qs from 'qs';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { YouTubeInterface } from './youtube.interface';

@Injectable({
    providedIn: 'root',
})
export class YoutubeService {
    private youtubeApiUrl = `${environment.youtube.url}`;
    private youtubeApiKey = `${environment.youtube.key}`;

    constructor(private http: HttpClient) {}

    /**
     * Return Observable array of videos by playlist Id
     * @returns Observable array of youtube videos of playlist
     */
    getYouTubePlayList(youTubePlaylistId: string): Observable<YouTubeInterface[]> {
        const query = {
            part: 'snippet,contentDetails',
            maxResults: 15,
            playlistId: youTubePlaylistId,
            key: this.youtubeApiKey,
        };

        return this.http.get<YouTubeInterface[]>(`${this.youtubeApiUrl + 'playlistItems?' + qs.stringify(query)}`);
    }

    /**
     * Return Observable array of videos from YouTube, based on ids passed to function.
     * @returns Observable array of YouTube videos
     *
     * @param videoIds - array of ids
     */
    getVideoByIds(videoIds: string[]): Observable<YouTubeInterface[]> {
        const query = {
            part: 'snippet,contentDetails',
            id: videoIds.join(','),
            key: this.youtubeApiKey,
        };

        return this.http.get<YouTubeInterface[]>(`${this.youtubeApiUrl + 'videos?' + qs.stringify(query)}`);
    }
}
