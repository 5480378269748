import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { KeplerMeta } from '@types';
import { map, Observable } from 'rxjs';

interface KeplerPurecloudEmployeeSearchResponse {
    meta: KeplerMeta;
    data: {
        user: KeplerPurecloudUser;
    };
}
export interface KeplerPurecloudUser {
    userId: string;
    name: string;
    email: string;
    avatarUri: string;
    licenses: string[];
    roles: KeplerPurecloudDomainRole[];
}

export interface KeplerPurecloudDomainRole {
    id: string;
    name: string;
}

@Injectable({
    providedIn: 'root',
})
export class KeplerPurecloudService {
    constructor(private http: HttpClient) {}

    employeeSearch$(email: string): Observable<KeplerPurecloudUser> {
        return this.http
            .post<KeplerPurecloudEmployeeSearchResponse>(environment.api.kepler.purecloud.url + '/employee-search', {
                search: email,
            })
            .pipe(map((res) => res?.data?.user));
    }
}
