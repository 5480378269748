import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ComponentsModule } from '@components';
import { PipesModule } from '@pipes';
import { ProductHelpArticleComponent } from './product-help-article.component';

@NgModule({
    declarations: [ProductHelpArticleComponent],
    imports: [CommonModule, RouterModule, ComponentsModule, PipesModule],
    exports: [ProductHelpArticleComponent],
})
export class ProductHelpArticleModule {}
