import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ComponentsModule } from '@components';
import { DirectivesModule } from '@directives';
import { OnboardingWebinarsComponent } from './onboarding-webinars.component';

@NgModule({
    declarations: [OnboardingWebinarsComponent],
    imports: [CommonModule, ComponentsModule, DirectivesModule],
    exports: [OnboardingWebinarsComponent],
})
export class OnboardingWebinarsModule {}
