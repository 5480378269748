import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { Theme } from '@enums';
import { environment } from '@environments/environment';
import { SigninWithCredentialsOptions } from '@okta/okta-auth-js';
import { isCookiesEnabled } from '@utils';

@Component({
    selector: 'app-login-form',
    templateUrl: './login-form.component.html',
    styleUrls: ['./login-form.component.css'],
})
export class LoginFormComponent implements OnInit {
    @Input() containerID: string;
    @Input() modalMaxWidth: string;
    @Output() sendClick = new EventEmitter<boolean>();
    modalWrapper: HTMLElement;

    @Input() theme: Theme;
    @Input() isAuthenticated: boolean;
    @Input() hideCloseButton: boolean;
    @Output() signInWithCredentialsEmitter = new EventEmitter<SigninWithCredentialsOptions>();
    @Output() signInWithOktaEmitter = new EventEmitter<void>();

    loginForm: FormGroup;
    showLoginError = false;
    invalid = false;
    showSignInWithCredentials = environment.login.showSignInWithCredentials;

    /**
     * Initialize login form on init
     */
    ngOnInit(): void {
        this.initializeLoginForm();
    }

    /**
     * Emit sign in with credentials output on click
     * @param options - Okta Sign In with Credential Options interface with username and password
     */
    emitSignInWithCredentials(options: SigninWithCredentialsOptions): void {
        this.signInWithCredentialsEmitter.emit(options);
    }

    /**
     * Emit sign in with Okta output on click
     */
    emitSignInWithOkta(): void {
        if (isCookiesEnabled()) {
            this.signInWithOktaEmitter.emit();
        } else {
            alert('Please enable cookies in your browser to log into the Genesys Knowledge Network.');
        }
    }

    /**
     * Initialize new login form
     */
    initializeLoginForm(): void {
        this.loginForm = new FormGroup({
            username: new FormControl('', Validators.required),
            password: new FormControl('', Validators.required),
        });
    }

    /**
     * Use to return the Form controls. Used in template
     */
    get formControl(): any {
        return this.loginForm.controls;
    }

    /**
     * Process user name and password on login event to be
     * authenticated with OKTA
     */
    onLogin(): void {
        this.showLoginError = false;
        const options: SigninWithCredentialsOptions = {
            username: this.loginForm.value.username,
            password: this.loginForm.value.password,
        };
        if (this.loginForm.value.username && this.loginForm.value.password) {
            this.emitSignInWithCredentials(options);
        } else {
            this.invalid = true;
            this.showLoginError = true;
        }
    }
}
