import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-down-arrow',
    templateUrl: './down-arrow.component.html',
    styleUrls: ['./down-arrow.component.css'],
})
export class DownArrowComponent {
    @Input() fillColor = 'var(--genesys-csdt-brand-navy)';
}
