<app-section-header
    [title]="sectionLabel"
    [externalUrl]="externalLink"
    [showTitle]="showTitle"
    [section]="sectionLocation"
    [favoriteId]="favoriteId"
    [showIcons]="showIcons"></app-section-header>

<!-- MAIN VIEW -->
<ng-container *ngIf="beyondTrainingTiles$ | async as beyondTrainingTiles; else showLoadingSpinner">
    <div class="min-height-container">
        <app-tile-layout class="beyond-tile-layout" *ngIf="beyondTrainingTiles.length > 0" maxColumns="2">
            <!-- LEFT COLUMN (FEATURED TRAINING TILE) -->
            <li id="featuredTile">
                <ng-container *ngIf="beyondTrainingTiles[0].openInModal; else externalContentTile">
                    <app-beyond-video-content
                        [beyondTrainingTile]="beyondTrainingTiles[0]"
                        [bigImage]="true"
                        [vertical]="true">
                    </app-beyond-video-content>
                </ng-container>
                <ng-template #externalContentTile>
                    <app-beyond-external-content
                        [beyondTrainingTile]="beyondTrainingTiles[0]"
                        [bigImage]="true"
                        [vertical]="true">
                    </app-beyond-external-content>
                </ng-template>
            </li>
            <!-- END LEFT COLUMN (FEATURED TRAINING TILE) -->
            <!-- RIGHT COLUMN (SECONDARY TRAINING TILES) -->
            <ng-template #secondaryTiles>
                <li id="secondaryTiles">
                    <div
                        class="beyond-icon-container"
                        *ngFor="let tile of beyondTrainingTiles | slice: 1:3; trackBy: trackByItem">
                        <ng-container *ngIf="tile.openInModal; else externalContentTile">
                            <app-beyond-video-content
                                [beyondTrainingTile]="tile"
                                [bigImage]="beyondTrainingTiles.length == 2"
                                [vertical]="beyondTrainingTiles.length == 2 || isVideoMobile">
                            </app-beyond-video-content>
                        </ng-container>
                        <ng-template #externalContentTile>
                            <app-beyond-external-content
                                [beyondTrainingTile]="tile"
                                [bigImage]="beyondTrainingTiles.length == 2"
                                [vertical]="beyondTrainingTiles.length == 2 || isVideoMobile">
                            </app-beyond-external-content>
                        </ng-template>
                    </div>
                </li>
            </ng-template>
            <!-- END RIGHT COLUMN (SECONDARY TRAINING TILES) -->
            <!-- SPECIAL CALL-TO-ACTION TILE. ONLY PRESENT IN AUTH CASE OF SUBSCRIPTION BUT NO ENROLLMENTS -->
            <li class="cta-tile-secondary" *ngIf="showSectionCtaAsTile; else secondaryTiles">
                <hr *ngIf="isVideoMobile" />
                <div class="padded-sides">
                    <h3 class="h3 sub-title-3 text-brand-navy margin-top-small margin-bottom-small">
                        You don't have any courses in progress.
                    </h3>
                    <p class="p-body">
                        No matter the career path, find training to guide you on your continuous learning journey.
                    </p>
                    <br />
                    <p class="p-body">Browse all courses included in the {{ beyondTrainingTiles[0].title }}.</p>
                    <div class="text-align-left margin-top-large">
                        <a
                            class="btn btn-secondary"
                            [ngClass]="isVideoMobile ? 'explore-mobile' : ''"
                            [href]="dashboardLink"
                            target="_blank"
                            rel="noopener noreferrer nofollow">
                            Explore the catalog
                        </a>
                    </div>
                </div>
            </li>
            <!-- END SPECIAL CALL-TO-ACTION TILE -->
        </app-tile-layout>
        <!-- STANDARD CALL-TO-ACTION. ONLY SHOWN WHEN SPECIAL CALL-TO-ACTION IS NOT SHOWN -->
        <div *ngIf="!showSectionCtaAsTile" class="text-align-center margin-top-large">
            <a class="btn btn-secondary" [href]="sectionCtaUrl" target="_blank" rel="noopener noreferrer nofollow">
                {{ sectionCta }}
            </a>
        </div>
    </div>
    <!-- END STANDARD CALL-TO-ACTION -->
</ng-container>
<!-- END MAIN VIEW -->

<ng-template #showLoadingSpinner>
    <app-loading-spinner marginTop="150px" marginBottom="200px"></app-loading-spinner>
</ng-template>
