import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ComponentsModule } from '@components';
import { CxEvolutionCardModule } from '../../component/cx-evolution-card/cx-evolution-card.module';
import { CxEvolutionLearnFromOthersComponent } from './cx-evolution-learn-from-others.component';

@NgModule({
    declarations: [CxEvolutionLearnFromOthersComponent],
    imports: [CommonModule, ComponentsModule, CxEvolutionCardModule],
    exports: [CxEvolutionLearnFromOthersComponent],
})
export class CxEvolutionLearnFromOthersModule {}
