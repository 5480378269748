import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WordpressPaging } from '@enums';
import { environment } from '@environments/environment';
import { BeyondRiseVideo } from '@services';
import { getCacheBustedParams } from '@utils';

@Injectable({
    providedIn: 'root',
})
export class BeyondWordpressService {
    constructor(private http: HttpClient) {}

    /**
     * Gets free content from Beyond's wordpress,
     * @returns observabl list of {@link BeyondRiseVideo}
     */
    getBeyondRiseVideos(): Observable<BeyondRiseVideo[]> {
        const videoURLParams = getCacheBustedParams()
            .append(WordpressPaging.ORDER_BY_KEY, WordpressPaging.ORDER_BY_VALUE)
            .append(WordpressPaging.ORDER_KEY, WordpressPaging.ORDER_VALUE)
            .append(WordpressPaging.PER_PAGE_KEY, WordpressPaging.PER_PAGE_VALUE);

        return this.http.get<BeyondRiseVideo[]>(`${environment.api.beyond.cms.url}/wp-json/wp/v2/videos`, {
            params: videoURLParams,
        });
    }
}
