import { Component, Input, OnInit } from '@angular/core';
import { FavoriteId, SupportSectionLabel } from '@enums';
import { ProductService, UserService } from '@services';

import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { SECTION_LINKS } from 'src/app/app-header/submenu-data';
import { MyCase } from './my-case.interface';

@Component({
    selector: 'app-cases',
    templateUrl: './cases.component.html',
    styleUrls: ['./cases.component.css'],
})
export class CasesComponent implements OnInit {
    sectionLabel = 'Product Support: ' + SupportSectionLabel.MY_CASES;
    favoriteId = FavoriteId.SUPPORT_CASES;
    sectionLocation = SECTION_LINKS.MY_CASES;
    externalUrl = environment.mySupport.url;

    excerpt =
        "Clickable case hyperlinks are not available at this time. They'll be back! In the interim, you can still keep tabs on your case statuses.";
    cases$: Observable<MyCase[]>;

    /** custom paging limit. Defaults to 7 */
    @Input() pagingLimit = 7;
    /** indicates whether to show the section header. Defaults to true */
    @Input() showTitle = true;
    /** indicates whether to show favorite and external link icons */
    @Input() showIcons = true;

    constructor(private userService: UserService, private productService: ProductService) {}

    ngOnInit(): void {
        this.cases$ = this.userService.cases$;
    }
}
