<app-section-header
    [title]="sectionLabel"
    [favoriteId]="favoriteId"
    [section]="section"
    [externalUrl]="externalUrl"></app-section-header>
<ng-container *ngIf="isMobile$ | async; else desktopLayout">
    <ng-container *ngTemplateOutlet="mobileLayout"></ng-container>
</ng-container>
<ng-template #desktopLayout>
    <app-tile-layout maxColumns="3" *ngIf="!useDesktopCarousel">
        <li *ngFor="let tile of tiles | slice: 0:maxDesktopTiles; trackBy: trackByItem">
            <ng-container *ngTemplateOutlet="tileTemplate; context: { $context: tile }"></ng-container>
        </li>
    </app-tile-layout>
    <app-carousel
        *ngIf="useDesktopCarousel"
        [carouselDotActiveCSS]="'blue-active'"
        [carouselItems]="desktopTiles"
        [carouselLeftArrowCSS]="['leftRs', 'arrow-left']"
        [carouselRightArrowCSS]="['rightRs', 'arrow-right']"
        [carouselItemTemplate]="tileTemplate">
    </app-carousel>
</ng-template>

<ng-template #mobileLayout>
    <app-mobile-carousel
        [carouselDotActiveCSS]="'blue-active'"
        [carouselItems]="tiles | slice: 0:maxMobileTiles"
        [carouselLeftArrowCSS]="['leftRs', 'arrow-left']"
        [carouselRightArrowCSS]="['rightRs', 'arrow-right']"
        [carouselItemTemplate]="tileTemplate">
    </app-mobile-carousel>
</ng-template>
