<!--
    This #toast ID is used for CSS selecting by the ToastNotificationService.
    There should only be one toast in the entire application.
    It is toggled with visibility css
-->

<div class="toast" [ngClass]="color">
    <div [innerHTML]="innerHtml | safe: 'html'"></div>
    <input
        type="image"
        src="/assets/icons/close.svg"
        (click)="onClose()"
        width="15px"
        height="15px"
        alt="Dismiss notification"
        aria-label="Dismiss notification"
        [attr.data-analytics]="'dismiss-notification'" />
</div>

<!--
    To show multiple toasts, we would need a 100vh container
    that could dynamically add new <div> elements for new toasts
    at the top of the container, like a feed. This would require
    updating the ToastNotificationService to be aware of this
    dynamic list of DOM IDs.
-->
