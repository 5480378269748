import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Directive, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription, tap } from 'rxjs';

/**
 * Structural directive indicating the attached element should only be shown in desktop viewports.
 * Works well alongside MobileDirective.
 *
 * @example
 * <p *desktop>
 *   hello, desktop world!
 * </p>
 * <p *mobile>
 *   hello, mobile world!
 * </p>
 */
@Directive({
    selector: '[desktop]',
})
export class DesktopDirective implements OnInit, OnDestroy {
    private sub: Subscription;

    constructor(
        private breakpointObserver: BreakpointObserver,
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
    ) {}

    ngOnInit() {
        this.sub = this.breakpointObserver
            .observe('(max-width: 1020px)')
            .pipe(
                tap((state: BreakpointState) => {
                    this.viewContainer.clear();
                    if (!state.matches) {
                        this.viewContainer.createEmbeddedView(this.templateRef);
                    }
                }),
            )
            .subscribe();
    }

    ngOnDestroy(): void {
        this.sub?.unsubscribe();
    }
}
