import { Component, Input } from '@angular/core';
import { GknFeaturedContent } from '@services';

@Component({
    selector: 'app-featured-content-text-only',
    templateUrl: './featured-content-text-only.component.html',
    styleUrls: ['./featured-content-text-only.component.css'],
})
export class FeaturedContentTextOnlyComponent {
    @Input() featuredContent: GknFeaturedContent;
}
