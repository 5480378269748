import { Card } from '../../components/card/card.component';
import { Marker } from '../../components/marker/marker.interface';
import { OverlayHtmlElement } from '../../components/overlay/overlay-html-element.component';
import { Overlay } from '../../components/overlay/overlay.component';

export class ResourcesOverlay extends Overlay {
    static marker: Marker = {
        start: { time: 30, text: 'Resources' },
        end: { time: 38.5, text: 'Resources end' },
    };

    static CSS_ONBOARDING_LINK =
        'https://onegenesys--simpplr.vf.force.com/apex/simpplr__app?u=/site/a145e000000Em51AAC/page/a128W000005xutCQAQ';
    static PSGO_LINK = 'https://extranet.genesys.com/pages/viewpage.action?spaceKey=PSGO&title=OFFERS';

    private width = document.getElementById('welcome-video-container').offsetWidth;
    padding = this.width * 0.02 + 'px';

    private itemWidth = '25%';
    private itemMargin = this.width * 0.015 + 'px';
    private itemPadding = `0 ${this.padding} ${this.padding} ${this.padding}`;

    constructor(private props: { id: string }) {
        super(props.id, { animateInClass: 'fadeInLeftBig', animateOutClass: 'fadeOutLeftBig' });
    }

    html(): HTMLElement {
        const csAcademyCard: HTMLElement = new Card({
            id: 'resources-card-css-onboarding',
            width: this.itemWidth,
            margin: this.itemMargin,
            padding: this.itemPadding,
            title: {
                text: 'Further your Genesys education with CSS onboarding.',
                color: 'white',
                fontSize: this.width * 0.02 + 'px',
                lineHeight: this.width * 0.0275 + 'px',
                className: 'ellipsis-title',
            },
            subtitle: {
                text: 'Courses and programs',
                color: 'white',
                fontSize: this.width * 0.0125 + 'px',
            },
            link: {
                text: 'Visit',
                href: ResourcesOverlay.CSS_ONBOARDING_LINK,
                fontSize: this.width * 0.0125 + 'px',
            },
        }).html();

        // Extranet is temporarily hidden behind VPN, so hide this for now
        // const psGlobalOpsCard: HTMLElement = new Card({
        //     id: 'resources-card-ps-global-ops',
        //     width: this.itemWidth,
        //     margin: this.itemMargin,
        //     padding: this.itemPadding,
        //     title: {
        //         text: 'Check out Professional Services offers for customers and partners.',
        //         color: 'white',
        //         fontSize: this.width * 0.02 + 'px',
        //         lineHeight: this.width * 0.0275 + 'px',
        //         className: 'ellipsis-title',
        //     },
        //     subtitle: {
        //         text: '300+ product resources',
        //         color: 'white',
        //         fontSize: this.width * 0.0125 + 'px',
        //     },
        //     link: {
        //         text: 'Visit',
        //         href: ResourcesOverlay.PSGO_LINK,
        //         fontSize: this.width * 0.0125 + 'px',
        //     },
        // }).html();

        const overlay: HTMLElement = new OverlayHtmlElement().buildHtml({
            id: this.props.id,
            hidden: true,
            childNodes: [csAcademyCard /*psGlobalOpsCard*/],
            flexDirection: 'row',
            top: '25%',
        });

        return overlay;
    }
}
