<ng-container *ngIf="isSearchPage$ | async; else defaultLayout">
    <div class="search-results-page">
        <ng-container *ngTemplateOutlet="inputs"></ng-container>
    </div>
</ng-container>
<ng-template #defaultLayout>
    <div class="default">
        <ng-container *ngTemplateOutlet="inputs"></ng-container>
    </div>
</ng-template>

<ng-template #inputs>
    <div class="inputs">
        <input
            class="search-icon margin-left-small margin-right-smaller"
            type="image"
            src="/assets/icons/search.svg"
            (click)="handleSearch($event)"
            aria-label="Search icon"
            [attr.data-analytics]="'search-icon'" />
        <input
            #searchTermRef
            type="text"
            [formControl]="searchTerm"
            [placeholder]="(isMobile$ | async) ? 'Search' : defaultSearchPlaceHolder"
            (keydown.enter)="handleSearch($event)"
            (keyup)="keyup($event)"
            (keydown.arrowup)="arrowUp($event)"
            (keydown.arrowdown)="arrowDown($event)"
            aria-label="Search bar"
            [attr.data-analytics]="'search-bar'" />
        <input
            *ngIf="searchTerm.value"
            type="image"
            src="/assets/icons/close.svg"
            class="clear-icon"
            (click)="handleClear()"
            width="15px"
            height="15px"
            alt="Clear search"
            aria-label="Clear search"
            [attr.data-analytics]="'search-clear'" />
    </div>
    <ng-container *ngIf="suggestions$ | async as suggestions">
        <div class="suggestions" *ngIf="suggestions.length > 0">
            <span
                *ngFor="let s of suggestions | slice: 0:5; let i = index; trackBy: trackByItem"
                (click)="searchSuggestion(s)"
                class="p-body"
                [ngClass]="{ 'active-suggestion': (suggestionIndex$ | async) == i }"
                [innerHtml]="s | suggestionMask">
            </span>
        </div>
    </ng-container>
</ng-template>
