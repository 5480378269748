import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { TrackByItem } from '@components';
import { MyProfileLabel, UserRoleUid } from '@enums';
import { UserClaims } from '@okta/okta-auth-js';
import { Product, ProductService, UserService } from '@services';
import { Observable, Subscription, map, tap } from 'rxjs';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.css'],
})
export class ProfileComponent implements OnInit, OnDestroy, TrackByItem<Product> {
    private subs: Subscription[] = [];
    sectionLabel = MyProfileLabel.MY_PROFILE;
    firstName$: Observable<string>;
    lastName$: Observable<string>;
    emailId$: Observable<string>;
    initials$: Observable<string>;
    claims$: Observable<UserClaims>;
    userRole$: Observable<UserRoleUid>;
    isAuthenticated = false;
    isMobile = false;
    myProductList: Product[] = [];
    selectedProduct: Observable<Product>;
    mobileProductListCaretActive = false;
    @Output() closeMobileProfile = new EventEmitter<void>();

    constructor(
        private breakpointObserver: BreakpointObserver,
        private changeDetector: ChangeDetectorRef,
        private productService: ProductService,
        private userService: UserService,
    ) {}

    /************************************************
     * Hidden UI for dev mode query parameter
     ************************************************/

    ngOnInit() {
        this.subs.push(
            this.breakpointObserver
                .observe('(max-width: 1020px)')
                .pipe(
                    tap((state: BreakpointState) => {
                        this.isMobile = state.matches;
                        this.changeDetector.markForCheck();
                    }),
                )
                .subscribe(),
        );

        this.selectedProduct = this.productService.selectedProduct$;

        this.initials$ = this.userService.initials$;
        this.firstName$ = this.userService.claims$.pipe(map((claims) => claims?.given_name));
        this.lastName$ = this.userService.claims$.pipe(map((claims) => claims?.family_name));
        this.emailId$ = this.userService.claims$.pipe(map((claims) => claims?.email));

        this.subs.push(
            this.userService.subscribedProducts$.subscribe((products: Product[]) => {
                this.myProductList = products;
                this.changeDetector.markForCheck();
            }),
        );

        this.userRole$ = this.userService.userRole$;
    }

    /**
     * Toggles the My Products list open/close for mobile devices
     */
    toggleMobileProductList(): void {
        const container = document.getElementById('mobileProductsContainer');
        if (this.mobileProductListCaretActive) {
            this.mobileProductListCaretActive = false;
            container.style.display = 'block';
        } else {
            this.mobileProductListCaretActive = true;
            container.style.display = 'none';
        }
    }

    /**
     * Closes the profile fly-out element for mobile devices
     */
    handleCloseMobileProfile(event?: Event): void {
        event?.stopPropagation();
        this.closeMobileProfile.emit();
    }

    ngOnDestroy(): void {
        if (this.subs) {
            this.subs.forEach((sub) => {
                sub.unsubscribe();
            });
        }
    }

    trackByItem(_index: number, item: Product): NonNullable<number | string> {
        return item.uid;
    }
}
