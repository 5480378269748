import { Tile } from '@components';
import { ProductDisplayName, ProductUid } from '@enums';

export const expertAppsData: Tile[] = [
    {
        title: 'A3S Analytics as a Service',
        description:
            'Genesys A3S is a cloud-hosted analytics solution that provides automated API and Event Bridge data integration, an enterprise-grade Snowflake data warehouse (Infomart in the cloud), and ready to use reporting and analytics dashboards to host on your own Tableau instance.',
        url: 'https://appfoundry.genesys.com/filter/genesyscloud/listing/30febd59-960b-447b-8c7b-328c429c2564',
        imageUrl: 'assets/images/expert-apps/a3s-logo.png',
    },
    {
        title: 'R2S Recording as a Service',
        description:
            'With Genesys Recording as a Service (R2S) all your interactions, across Genesys and legacy platforms, are managed in a single, easily accessible storage solution.',
        url: 'https://appfoundry.genesys.com/filter/genesyscloud/listing/c1d4b129-571c-4441-88dc-06e5c9322fdd',
        imageUrl: 'assets/images/expert-apps/r2s-logo.png',
    },
    {
        title: 'Email Ninja',
        description: `${
            ProductDisplayName[ProductUid.GENESYS_CLOUD_CX]
        } Email Ninja allows supervisors and agents to find and manage emails while still in queue. Search, filter, view, pickup, transfer to user or queue, delete individual or in bulk. Supervisors may also transfer emails from an agent queue back to a workgroup queue.`,
        url: 'https://appfoundry.genesys.com/filter/genesyscloud/listing/8e458a3b-bac0-4280-8964-661a3b00b118',
        imageUrl: 'assets/images/expert-apps/email-ninja-logo.png',
    },
    {
        title: 'Innovation Pass',
        description:
            'Expand your capabilities and reach your goals with a collection of premium expert applications developed by the Genesys Professional Services team. It’s all available for one monthly price with an Innovation Pass subscription.',
        url: 'https://appfoundry.genesys.com/filter/genesyscloud/listing/155799a5-c634-4b4c-97eb-13a18da25e36',
        imageUrl: 'assets/images/expert-apps/innovation-pass-logo.png',
    },
    {
        title: 'Universal Messaging',
        description: `The ${
            ProductDisplayName[ProductUid.GENESYS_CLOUD_CX]
        } Universal Messaging solution enables businesses to manage interactions, including rich media, from popular messaging applications.`,
        url: 'https://appfoundry.genesys.com/filter/genesyscloud/listing/15fff5e7-2f97-451c-b444-b42193306d40',
        imageUrl: 'assets/images/expert-apps/universal-messaging-logo.png',
    },
];
