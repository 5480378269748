<app-tile-layout maxColumns="4">
    <li class="li-border" *ngFor="let card of popularTopics.cards; trackBy: trackByItem">
        <div class="card cursor">
            <a
                class="primary-link"
                target="_blank"
                rel="noopener noreferrer nofollow"
                [href]="popularTopics.baseUrl + card.url">
                <img [src]="popularTopics.iconBase + card.icon" [alt]="card.title + ' icon'" />
                <h2 class="h3 sub-title-1 text-brand-navy margin-top-small margin-bottom-medium">{{ card.title }}</h2>
            </a>
        </div>
    </li>
</app-tile-layout>
