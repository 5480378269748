import { Component } from '@angular/core';

@Component({
    selector: 'button[animated]', // eslint-disable-line
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {}

@Component({
    selector: 'a[animated]', // eslint-disable-line
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
})
export class AnchorComponent {}
