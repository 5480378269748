import { Component, Input } from '@angular/core';
import { FundamentalsCardType } from './fundamentals-card.enum';

@Component({
    selector: 'app-fundamentals-card',
    templateUrl: './fundamentals-card.component.html',
    styleUrls: ['./fundamentals-card.component.css'],
})
export class FundamentalsCardComponent {
    /** type of fundamentals card */
    @Input() type: FundamentalsCardType;
    /** /assets/../*.svg path for the header icon */
    @Input() icon: string;
    /** title text for the header */
    @Input() title: string;
    /** indicates whether this card is still loading */
    @Input() isLoading = true;
    /** Flexbox direction for this card. 'row' is only used for the Roadmaps card in practice */
    @Input() flexDirection: 'row' | 'column' = 'column';
}
