import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
    selector: 'app-loading-spinner',
    templateUrl: './loading-spinner.component.html',
    styleUrls: ['./loading-spinner.component.css'],
})
export class LoadingSpinnerComponent implements OnInit, OnChanges {
    /**
     * top margin
     */
    @Input() marginTop: string;

    /**
     * bottom margin
     */
    @Input() marginBottom: string;

    /**
     * Size of the spinner. Applied to CSS width and height properties
     */
    @Input() size: string;

    /**
     * Border width property. Spinner is made up of 4 borders basically
     */
    @Input() borderWidth: string;

    /**
     *  Spinner color theme
     */
    @Input() theme: 'light' | 'dark' | 'navy';

    constructor(private cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.setTheme();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.theme) {
            this.setTheme(changes.theme.currentValue);
        }
    }

    private setTheme(theme?: 'light' | 'dark' | 'navy'): void {
        this.theme = 'light';

        switch (theme) {
            case 'dark':
                this.theme = 'dark';
                break;
            case 'navy':
                this.theme = 'navy';
                break;
            case 'light':
            // fall-through
            default:
                this.theme = 'light';
        }

        this.cdr.markForCheck();
    }
}
