import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-section-header-hyperlink',
    templateUrl: './section-header-hyperlink.component.html',
    styleUrls: ['./section-header-hyperlink.component.css'],
})
export class SectionHeaderHyperlinkComponent {
    @Input() url: string;
    @Input() text: string;
}
