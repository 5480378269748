import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import dayjs from 'dayjs';
import { Observable, filter, map, shareReplay, tap } from 'rxjs';
import { mapDatesToGknDurations } from '../../rxjs';
import { GknVirtualAndPhysicalEvent, KeplerMarketingVpEventResponse } from './interfaces';
import { KeplerMarketingAdapter } from './kepler-marketing.adapter';

@Injectable({
    providedIn: 'root',
})
export class KeplerMarketingService {
    constructor(private adapter: KeplerMarketingAdapter, private http: HttpClient) {}

    get virtualAndPhysicalEvents$(): Observable<GknVirtualAndPhysicalEvent[]> {
        return this.http
            .get<KeplerMarketingVpEventResponse>(`${environment.api.kepler.marketing.url}/v2/vp-events?future=true`)
            .pipe(
                filter((res) => res?.data?.length > 0),
                map((res) => {
                    return res.data.map((vpEvent) => {
                        return this.adapter.adaptVpEvent(vpEvent);
                    });
                }),
                mapDatesToGknDurations(),
                map((vpEvents) => {
                    // sort future events ascending and past events descending, with today in the middle

                    const currentMonth = dayjs().month();
                    const currentYear = dayjs().year();

                    const currentVpEvents: GknVirtualAndPhysicalEvent[] = [];
                    const pastVpEvents: GknVirtualAndPhysicalEvent[] = []; // events without a duration are also considered "past"

                    vpEvents.forEach((vpEvent) => {
                        if (
                            vpEvent.duration?.startDate?.month() == currentMonth &&
                            vpEvent?.duration?.startDate?.year() == currentYear
                        ) {
                            currentVpEvents.push(vpEvent);
                        } else {
                            pastVpEvents.push(vpEvent);
                        }
                    });

                    return [...currentVpEvents, ...pastVpEvents];
                }),
                tap((vpEvents) => console.debug('virtual and physical events', vpEvents)),
                shareReplay(),
            );
    }
}
