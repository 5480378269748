import { ExtraOptions, NoPreloading, RouterModule, Routes } from '@angular/router';

import { NgModule } from '@angular/core';
import { gknRouteConfig, gknRouteConfigLegacy } from './app-routing.config';

export const routerRoutes: Routes = Object.keys(gknRouteConfig).map((key) => {
    const route = gknRouteConfig[key];
    return route.routerConfig;
});

export const legacyRouterRoutes: Routes = Object.values(gknRouteConfigLegacy);

const routerOptions: ExtraOptions = {
    preloadingStrategy: NoPreloading, // lazy load all modules
    // anchorScrolling: 'enabled',
    // scrollPositionRestoration: 'enabled',
    // onSameUrlNavigation: 'reload',
};
@NgModule({
    imports: [
        RouterModule.forRoot(
            [
                ...legacyRouterRoutes,
                ...routerRoutes,
                {
                    // This route should always be the very last route.
                    // It will catch any non-matching endpoints and redirect to /home.
                    path: '**',
                    pathMatch: 'full',
                    // Do not redirect using HomeComponent or else the
                    // active state won't be set on the navbar (orange top border)
                    redirectTo: '/home',
                    // // component: HomeComponent,
                },
            ],
            routerOptions,
        ),
    ], //
    exports: [RouterModule],
})
export class AppRoutingModule {}
