import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WelcomeVideoComponent } from './welcome-video.component';
import { ComponentsModule } from '@components';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
    declarations: [WelcomeVideoComponent],
    imports: [CommonModule, ComponentsModule, MatProgressSpinnerModule],
    exports: [WelcomeVideoComponent],
})
export class WelcomeVideoModule {}
