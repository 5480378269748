<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
<ng-container *ngIf="!isLoading">
    <app-tile-layout maxColumns="2">
        <li *ngFor="let useCase of useCases; trackBy: trackByItem">
            <app-tile-vertical
                [imageUrl]="useCase?.image"
                [title]="useCase?.title"
                [description]="useCase?.summary | decodeEntities | stripHtml"
                [hasBoxShadow]="true"
                [titleLink]="true"
                [url]="useCase?.url"
                icon="/assets/icons/content-tags-article-small.svg"
                details="USE CASE"
                urlLabel="Read more"
                [border]="false"
                [vertical]="false"></app-tile-vertical>
        </li>
    </app-tile-layout>
</ng-container>
