import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, ReplaySubject, filter, tap } from 'rxjs';
import { GknRoutePath } from 'src/app/app-routing.enum';

/**
 * Service for determining the current active page via Angular Router.
 */
@Injectable({
    providedIn: 'root',
})
export class RouteService {
    private _currentPage$: ReplaySubject<GknRoutePath | undefined> = new ReplaySubject(1);

    constructor(private router: Router) {
        this.router.events
            ?.pipe(
                filter((event) => event instanceof NavigationEnd),
                tap(() => {
                    let route: GknRoutePath = Object.values(GknRoutePath).find((path) =>
                        this.router.url.includes(`/${path}`),
                    ) as GknRoutePath;

                    if (!route && this.router.url === '/') {
                        // Handle niche case for when a user first visits the site with exactly "https://know.genesys.com"
                        route = GknRoutePath.HOME;
                    }

                    if (route) {
                        console.debug('current route', route);
                        this._currentPage$.next(route);
                    } else {
                        console.warn(
                            `Unable to determine current route. This may affect available content. url="${this.router.url}"`,
                        );
                    }
                }),
            )
            .subscribe();
    }

    get currentRoute$(): Observable<GknRoutePath> {
        return this._currentPage$.asObservable();
    }
}
