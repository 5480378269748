import {
    CommunitySectionLabel,
    CxEvolutionFilterLabel,
    DeveloperCenterFilterLabel,
    EduTrainingBadgesFilterLabel,
    EduTrainingSectionLabel,
    FavoriteId,
    GetInvolvedSectionLabel,
    GetStartedFilterLabel,
    HomeSectionLabel,
    JustForMeSectionLabel,
    NewsFromGenesysFilterLabel,
    ProductHelpFilterLabel,
    ResourcesSectionLabel,
    SupportSectionLabel,
} from '@enums';

import { Observable, ReplaySubject, catchError, combineLatest, combineLatestWith, concatMap, map, tap } from 'rxjs';
import { Product, ProductService } from '../product';
import {
    FavoriteAttributes,
    FavoriteRouteMapping,
    FavoritesDbResponse,
    FavoritesServiceResponse,
    UserCredentials,
} from './types';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { environment } from '@environments/environment';
import { AuthService, UserService } from '@services';
import { SECTION_LINKS } from 'src/app/app-header/submenu-data';
import { GknRoutePath } from 'src/app/app-routing.enum';
import { FavoriteStoreMethod } from './enums';

@Injectable({ providedIn: 'root' })
export class FavoritesService {
    private readonly favoritesApiUrl = environment.api.resourceServer.favoritesUrl;

    private _favorites$ = new ReplaySubject<FavoriteAttributes[]>(1);
    private _favoriteFragment = new ReplaySubject<string>(1);

    constructor(
        private productService: ProductService,
        private http: HttpClient,
        private authService: AuthService,
        private userService: UserService,
        private router: Router,
    ) {
        this.productService.selectedProduct$
            .pipe(
                combineLatestWith(this.getFavorites()),
                tap(([selectedProduct, favorites]) => {
                    const newFavs = this.filterFavoritesByProduct(favorites, selectedProduct);
                    this._favorites$.next(newFavs);
                }),
            )
            .subscribe();
    }

    private filterFavoritesByProduct(favorites: FavoriteAttributes[], selectedProduct: Product): FavoriteAttributes[] {
        return favorites.filter((favorite) => favorite.productId == selectedProduct.uid) || [];
    }

    get favorites$(): Observable<FavoriteAttributes[]> {
        return this._favorites$.asObservable();
    }

    /** Get user salesforce Id */
    get userSalesForceId$() {
        // Alternative approach is to lett the server add its own unique ID
        return this.userService.sfdcUserDetail$.pipe(map((userSfdcRes) => userSfdcRes?.User?.Id));
    }

    /** Get user credentials required to make API requests */
    private get userCredential$(): Observable<UserCredentials> {
        return combineLatest([this.userSalesForceId$, this.userService.claims$]).pipe(
            map(([userId, claims]) => {
                return {
                    headers: {
                        Authorization: `Bearer ${this.authService.accessToken}`,
                    },
                    userId: userId,
                    email: claims?.email,
                };
            }),
        );
    }

    /** Observable that emits(once) the updated list of favorites. */
    private getFavorites(): Observable<FavoriteAttributes[]> {
        return this.userCredential$.pipe(
            concatMap(({ headers, email, userId }) => {
                const params = { email, userId };

                return this.http.get<FavoritesDbResponse>(this.favoritesApiUrl, { headers, params });
            }),
            map(({ favorites }) => favorites),
        );
    }

    toggleFavorite(favorite: FavoriteAttributes, isFavorited: boolean): Observable<FavoritesServiceResponse> {
        const method: FavoriteStoreMethod = isFavorited ? FavoriteStoreMethod.Remove : FavoriteStoreMethod.Add;

        if (isFavorited) {
            return this.removeFavorite(favorite).pipe(
                combineLatestWith(this.productService.selectedProduct$),
                map(([favorites, selectedProduct]) => {
                    const newFavs = this.filterFavoritesByProduct(favorites, selectedProduct);
                    this._favorites$.next(newFavs);
                    return { favorites: favorites, method: method, status: 'success' };
                }),
                catchError((err) => {
                    console.error(`Failed to remove favorite ${favorite.favoriteId}`, { cause: err });
                    return this.favorites$.pipe(
                        map((favorites) => ({ favorites: favorites, method: method, status: 'fail' })),
                    );
                }),
            );
        } else {
            return this.addFavorite(favorite).pipe(
                combineLatestWith(this.productService.selectedProduct$),
                map(([favorites, selectedProduct]) => {
                    const newFavs = this.filterFavoritesByProduct(favorites, selectedProduct);
                    this._favorites$.next(newFavs);
                    return { favorites, method, status: 'success' };
                }),
                catchError((err) => {
                    console.error(`Failed to add favorite ${favorite.favoriteId}`, { cause: err });
                    return this.favorites$.pipe(
                        map((favorites) => ({ favorites: favorites, method: method, status: 'fail' })),
                    );
                }),
            );
        }
    }

    /**
     * Adds favorite to store.
     * @param favorite List of favorites to be added.
     * @returns Observable that emits(once) the updated list of favorites after update is successful.
     */
    private addFavorite(favorite: FavoriteAttributes): Observable<FavoriteAttributes[]> {
        return this.userCredential$.pipe(
            concatMap(({ headers, email, userId }) => {
                const body = { email, userId, favorites: [favorite] };

                return this.http.patch<FavoritesDbResponse>(this.favoritesApiUrl, body, { headers: headers });
            }),
            map(({ favorites }) => favorites),
        );
    }

    /**
     * Removes favorite from store.
     * @param favorites Favorite to remove.
     * @returns Observable that emits(once) the updated list of favorites after update is successful.
     */
    private removeFavorite(favorite: FavoriteAttributes): Observable<FavoriteAttributes[]> {
        const { favoriteId, productId } = favorite;
        return this.userCredential$.pipe(
            concatMap(({ headers, email, userId }) => {
                const body = { email, userId, favoriteId, productId };

                return this.http.delete<FavoritesDbResponse>(this.favoritesApiUrl, { headers: headers, body: body });
            }),
            map(({ favorites }) => favorites),
        );
    }

    // Maps a Favorite ID to the correct Router Mapping
    favoritesRouteMapping: FavoriteRouteMapping = {
        [FavoriteId.ADDITIONAL_SITES]: {
            path: GknRoutePath.HOME,
            section: SECTION_LINKS.JUST_FOR_ME,
            filter: FavoriteId.ADDITIONAL_SITES,
        },
        [FavoriteId.APP_FOUNDRY]: {
            path: GknRoutePath.RESOURCES,
            section: SECTION_LINKS.APP_FOUNDRY,
        },
        [FavoriteId.ARCHIVES]: {
            path: GknRoutePath.HOME,
            section: SECTION_LINKS.ARCHIVES,
        },
        [FavoriteId.BEYOND]: {
            path: GknRoutePath.EDUCATION_AND_TRAINING,
            section: SECTION_LINKS.BEYOND_TRAINING,
        },
        [FavoriteId.CERTIFICATIONS_GET_CERTIFIED]: {
            path: GknRoutePath.EDUCATION_AND_TRAINING,
            section: SECTION_LINKS.CERTIFICATIONS_AND_BADGES,
            filter: FavoriteId.CERTIFICATIONS_GET_CERTIFIED,
        },
        [FavoriteId.CERTIFICATIONS_MY_AWARDS]: {
            path: GknRoutePath.EDUCATION_AND_TRAINING,
            section: SECTION_LINKS.CERTIFICATIONS_AND_BADGES,
            filter: FavoriteId.CERTIFICATIONS_MY_AWARDS,
        },
        [FavoriteId.COMMUNITY_QA_SHOW]: {
            path: GknRoutePath.COMMUNITY,
            section: SECTION_LINKS.COMMUNITY_QA_SHOW,
        },
        [FavoriteId.CS_ACADEMY]: {
            path: GknRoutePath.EDUCATION_AND_TRAINING,
            section: SECTION_LINKS.CS_ACADEMY_TRAINING,
        },
        [FavoriteId.CUSTOMER_SUCCESS]: {
            path: GknRoutePath.RESOURCES,
            section: SECTION_LINKS.CUSTOMER_SUCCESS,
        },
        [FavoriteId.CX_EVOLUTION]: {
            path: GknRoutePath.RESOURCES,
            section: SECTION_LINKS.CX_EVOLUTION,
        },
        [FavoriteId.CX_EVOLUTION_CONNECT]: {
            path: GknRoutePath.RESOURCES,
            section: SECTION_LINKS.CX_EVOLUTION,
            filter: FavoriteId.CX_EVOLUTION_CONNECT,
        },
        [FavoriteId.CX_EVOLUTION_GET_STARTED]: {
            path: GknRoutePath.RESOURCES,
            section: SECTION_LINKS.CX_EVOLUTION,
            filter: FavoriteId.CX_EVOLUTION_GET_STARTED,
        },
        [FavoriteId.CX_EVOLUTION_LEARN_FROM_OTHERS]: {
            path: GknRoutePath.RESOURCES,
            section: SECTION_LINKS.CX_EVOLUTION,
            filter: FavoriteId.CX_EVOLUTION_LEARN_FROM_OTHERS,
        },
        [FavoriteId.DEVELOPER_CENTER]: {
            path: GknRoutePath.RESOURCES,
            section: SECTION_LINKS.DEVELOPER_CENTER,
        },
        [FavoriteId.DEVELOPER_CENTER_BLOG]: {
            path: GknRoutePath.RESOURCES,
            section: SECTION_LINKS.DEVELOPER_CENTER,
            filter: FavoriteId.DEVELOPER_CENTER_BLOG,
        },
        [FavoriteId.DEVELOPER_CENTER_POPULAR]: {
            path: GknRoutePath.RESOURCES,
            section: SECTION_LINKS.DEVELOPER_CENTER,
            filter: FavoriteId.DEVELOPER_CENTER_POPULAR_TOPICS,
        },
        [FavoriteId.DEVELOPER_CENTER_POPULAR_TOPICS]: {
            path: GknRoutePath.RESOURCES,
            section: SECTION_LINKS.DEVELOPER_CENTER,
            filter: FavoriteId.DEVELOPER_CENTER_POPULAR_TOPICS,
        },
        [FavoriteId.DEVELOPER_CENTER_TOOLS]: {
            path: GknRoutePath.RESOURCES,
            section: SECTION_LINKS.DEVELOPER_CENTER,
            filter: FavoriteId.DEVELOPER_CENTER_TOOLS,
        },
        [FavoriteId.DEVELOPER_PORTAL_PURECONNECT]: {
            path: GknRoutePath.RESOURCES,
            section: SECTION_LINKS.DEVELOPER_PORTAL_PURECONNECT,
        },
        [FavoriteId.EDU_EVENTS_AND_WEBINARS]: {
            path: GknRoutePath.EDUCATION_AND_TRAINING,
            section: SECTION_LINKS.EDU_EVENTS_AND_WEBINARS,
        },
        [FavoriteId.EXPERT_APPS]: {
            path: GknRoutePath.RESOURCES,
            section: SECTION_LINKS.GENESYS_EXPERT_APPS,
        },
        [FavoriteId.GET_STARTED]: {
            path: GknRoutePath.RESOURCES,
            section: SECTION_LINKS.GET_STARTED,
        },
        [FavoriteId.GET_STARTED_MY_PERSONALIZED_VIDEO]: {
            path: GknRoutePath.RESOURCES,
            section: SECTION_LINKS.GET_STARTED,
            filter: FavoriteId.GET_STARTED_MY_PERSONALIZED_VIDEO,
        },
        [FavoriteId.GET_STARTED_ONBOARDING]: {
            path: GknRoutePath.RESOURCES,
            section: SECTION_LINKS.GET_STARTED,
            filter: FavoriteId.GET_STARTED_ONBOARDING,
        },
        [FavoriteId.GET_STARTED_SHORT_TRACKS]: {
            path: GknRoutePath.RESOURCES,
            section: SECTION_LINKS.GET_STARTED,
            filter: FavoriteId.GET_STARTED_SHORT_TRACKS,
        },
        [FavoriteId.GET_STARTED_STARTER_KIT]: {
            path: GknRoutePath.RESOURCES,
            section: SECTION_LINKS.GET_STARTED,
            filter: FavoriteId.GET_STARTED_STARTER_KIT,
        },
        [FavoriteId.GET_STARTED_TIPS_AND_TOOLS]: {
            path: GknRoutePath.RESOURCES,
            section: SECTION_LINKS.GET_STARTED,
            filter: FavoriteId.GET_STARTED_TIPS_AND_TOOLS,
        },
        [FavoriteId.GET_STARTED_USEFUL_LINKS]: {
            path: GknRoutePath.RESOURCES,
            section: SECTION_LINKS.GET_STARTED,
            filter: FavoriteId.GET_STARTED_USEFUL_LINKS,
        },
        [FavoriteId.LATEST_DISCUSSIONS]: {
            path: GknRoutePath.COMMUNITY,
            section: SECTION_LINKS.LATEST_DISCUSSIONS,
        },
        [FavoriteId.MARKETING_EVENTS]: {
            path: GknRoutePath.GET_INVOLVED,
            section: SECTION_LINKS.VIRTUAL_AND_PHYSICAL_EVENTS,
        },
        [FavoriteId.MY_CONTACTS]: {
            path: GknRoutePath.SUPPORT,
            section: SECTION_LINKS.MY_CONTACTS,
        },
        [FavoriteId.MY_FAVORITES]: {
            path: GknRoutePath.HOME,
            section: SECTION_LINKS.JUST_FOR_ME,
            filter: FavoriteId.MY_FAVORITES,
        },
        [FavoriteId.NEWS_FROM_GENESYS]: {
            path: GknRoutePath.HOME,
            section: SECTION_LINKS.NEWS_FROM_GENESYS,
        },
        [FavoriteId.NEWS_FROM_GENESYS_ALL]: {
            path: GknRoutePath.HOME,
            section: SECTION_LINKS.NEWS_FROM_GENESYS,
            filter: FavoriteId.NEWS_FROM_GENESYS_ALL,
        },
        [FavoriteId.NEWS_FROM_GENESYS_ANNOUNCEMENTS]: {
            path: GknRoutePath.HOME,
            section: SECTION_LINKS.NEWS_FROM_GENESYS,
            filter: FavoriteId.NEWS_FROM_GENESYS_ANNOUNCEMENTS,
        },
        [FavoriteId.NEWS_FROM_GENESYS_BLOG]: {
            path: GknRoutePath.HOME,
            section: SECTION_LINKS.NEWS_FROM_GENESYS,
            filter: FavoriteId.NEWS_FROM_GENESYS_BLOG,
        },
        [FavoriteId.NEWS_FROM_GENESYS_SOCIAL]: {
            path: GknRoutePath.HOME,
            section: SECTION_LINKS.NEWS_FROM_GENESYS,
            filter: FavoriteId.NEWS_FROM_GENESYS_SOCIAL,
        },
        [FavoriteId.PRODUCT_FUNDAMENTALS]: {
            path: GknRoutePath.HOME,
            section: SECTION_LINKS.PRODUCT_FUNDAMENTALS,
        },
        [FavoriteId.PRODUCT_HELP]: {
            path: GknRoutePath.RESOURCES,
            section: SECTION_LINKS.PRODUCT_HELP,
        },
        [FavoriteId.PRODUCT_HELP_ARTICLES]: {
            path: GknRoutePath.RESOURCES,
            section: SECTION_LINKS.PRODUCT_HELP,
            filter: FavoriteId.PRODUCT_HELP_ARTICLES,
        },
        [FavoriteId.PRODUCT_HELP_DOCUMENTATION]: {
            path: GknRoutePath.RESOURCES,
            section: SECTION_LINKS.PRODUCT_HELP,
            filter: FavoriteId.PRODUCT_HELP_DOCUMENTATION,
        },
        [FavoriteId.PRODUCT_HELP_USE_CASES]: {
            path: GknRoutePath.RESOURCES,
            section: SECTION_LINKS.PRODUCT_HELP,
            filter: FavoriteId.PRODUCT_HELP_USE_CASES,
        },
        [FavoriteId.PRODUCT_HELP_VIDEOS]: {
            path: GknRoutePath.RESOURCES,
            section: SECTION_LINKS.PRODUCT_HELP,
            filter: FavoriteId.PRODUCT_HELP_VIDEOS,
        },
        [FavoriteId.PRODUCT_SUPPORT_CENTER]: {
            path: GknRoutePath.SUPPORT,
            section: SECTION_LINKS.PRODUCT_SUPPORT_CENTER,
        },
        [FavoriteId.RECOMMENDATIONS]: {
            path: GknRoutePath.SUPPORT,
            section: SECTION_LINKS.RECOMMENDATIONS,
        },
        [FavoriteId.RELATED_COMMUNITIES]: {
            path: GknRoutePath.COMMUNITY,
            section: SECTION_LINKS.RELATED_COMMUNITIES,
        },
        [FavoriteId.SUPPORT_CASES]: {
            path: GknRoutePath.SUPPORT,
            section: SECTION_LINKS.PRODUCT_SUPPORT_CENTER,
        },
        [FavoriteId.OWTGI_WRITE_A_REVIEW]: {
            path: GknRoutePath.GET_INVOLVED,
            section: SECTION_LINKS.CONNECT_WITH_GENESYS,
        },
        [FavoriteId.OWTGI_CUSTOMER_MONTHLY_NEWSLETTER]: {
            path: GknRoutePath.GET_INVOLVED,
            section: SECTION_LINKS.CONNECT_WITH_GENESYS,
        },
        [FavoriteId.OWTGI_SUPER_HUMAN_SERVICE]: {
            path: GknRoutePath.GET_INVOLVED,
            section: SECTION_LINKS.CONNECT_WITH_GENESYS,
        },
        [FavoriteId.OWTGI_PRODUCT_IDEAS_LAB]: {
            path: GknRoutePath.GET_INVOLVED,
            section: SECTION_LINKS.CONNECT_WITH_GENESYS,
        },
        [FavoriteId.OWTGI_GENESYS_USER_GROUPS]: {
            path: GknRoutePath.GET_INVOLVED,
            section: SECTION_LINKS.CONNECT_WITH_GENESYS,
        },
        [FavoriteId.OWTGI_COLLABORATE_WITH_OUR_UX_TEAM]: {
            path: GknRoutePath.GET_INVOLVED,
            section: SECTION_LINKS.CONNECT_WITH_GENESYS,
        },
        [FavoriteId.OWTGI_DEVELOPER_NEWSLETTER]: {
            path: GknRoutePath.GET_INVOLVED,
            section: SECTION_LINKS.CONNECT_WITH_GENESYS,
        },
        [FavoriteId.OWTGI_ADVOCACY_PAYS]: {
            path: GknRoutePath.GET_INVOLVED,
            section: SECTION_LINKS.CONNECT_WITH_GENESYS,
        },
    };

    buildFragment(section: SECTION_LINKS, favoriteId?: FavoriteId): string {
        let fragment;
        if (favoriteId) {
            fragment = section + '_' + favoriteId;
        } else {
            fragment = section;
        }
        return fragment;
    }

    buildFragmentFromFavorite(favoriteId: FavoriteId) {
        const routeMap = this.favoritesRouteMapping[favoriteId];
        if (routeMap) {
            return this.buildFragment(routeMap.section, routeMap.filter);
        }
        return '';
    }

    navigateToFavorite(favorite: FavoriteId): string {
        const fragment = this.buildFragmentFromFavorite(favorite);
        if (fragment) {
            const navigateExtras: NavigationExtras = {
                fragment,
                replaceUrl: true,
                preserveFragment: false,
                queryParamsHandling: 'preserve',
            };
            this.router.navigate([], navigateExtras);
            return fragment;
        }
    }

    /**
     * Maps the given favorite ID to a human-readable label/display name. This
     * function should only be used for favorite IDs that represent static content.
     * Dynamic content from Strapi should use the label from the API response.
     *
     * @param favoriteId to map
     * @param selectedProduct the currently selected product. Some favorite IDs map to a label that is prefixed with the product label.
     * @returns a label (display name) for the given favorite
     * @throws when the given favorite is not mapped to any label, or the given favorite represents an Other Way To Get Involved tile (Know CMS)ß
     */
    mapToLabel(favoriteId: FavoriteId, selectedProduct?: Product): string {
        switch (favoriteId) {
            case FavoriteId.APP_FOUNDRY:
                return ResourcesSectionLabel.APP_FOUNDRY;
            case FavoriteId.ARCHIVES:
                return HomeSectionLabel.ARCHIVES;
            case FavoriteId.BEYOND:
                return EduTrainingSectionLabel.BEYOND_TRAINING;
            case FavoriteId.CS_ACADEMY:
                return EduTrainingSectionLabel.CS_ACADEMY;
            case FavoriteId.COMMUNITY_QA_SHOW:
                return CommunitySectionLabel.QA_SHOW;
            case FavoriteId.CUSTOMER_SUCCESS:
                return ResourcesSectionLabel.CUSTOMER_SUCCESS;
            case FavoriteId.CX_EVOLUTION:
                return ResourcesSectionLabel.CX_EVOLUTION;
            case FavoriteId.DEVELOPER_CENTER:
                return ResourcesSectionLabel.DEV_CENTER;
            case FavoriteId.DEVELOPER_CENTER_POPULAR:
                return ResourcesSectionLabel.DEV_CENTER_POPULAR;
            case FavoriteId.DEVELOPER_PORTAL_PURECONNECT:
                return ResourcesSectionLabel.DEV_PORTAL_PURECONNECT;
            case FavoriteId.EXPERT_APPS:
                return ResourcesSectionLabel.EXPERT_APPS;
            case FavoriteId.GET_STARTED:
                return ResourcesSectionLabel.GET_STARTED;
            case FavoriteId.MARKETING_EVENTS:
                return GetInvolvedSectionLabel.MARKETING_EVENTS;
            case FavoriteId.LATEST_DISCUSSIONS:
                return CommunitySectionLabel.LATEST_DISCUSSIONS;
            case FavoriteId.NEWS_FROM_GENESYS:
                return HomeSectionLabel.NEWS_FROM_GENESYS;
            case FavoriteId.PRODUCT_FUNDAMENTALS:
                return `${selectedProduct?.displayName} ${HomeSectionLabel.FUNDAMENTALS}`.trim();
            case FavoriteId.PRODUCT_HELP:
                return `${selectedProduct?.displayName} ${ResourcesSectionLabel.PRODUCT_HELP}`.trim();
            case FavoriteId.PRODUCT_SUPPORT_CENTER:
                return SupportSectionLabel.PRODUCT_SUPPORT_CENTER;
            case FavoriteId.RELATED_COMMUNITIES:
                return CommunitySectionLabel.RELATED_COMMUNITIES;
            case FavoriteId.SUPPORT_CASES:
                return SupportSectionLabel.MY_CASES;
            case FavoriteId.MY_CONTACTS:
                return SupportSectionLabel.MY_CONTACTS;
            case FavoriteId.RECOMMENDATIONS:
                return SupportSectionLabel.RECOMMENDATIONS;
            case FavoriteId.EDU_EVENTS_AND_WEBINARS:
                return EduTrainingSectionLabel.EDU_EVENTS_AND_WEBINARS;
            case FavoriteId.NEWS_FROM_GENESYS_ALL:
                return `${HomeSectionLabel.NEWS_FROM_GENESYS} — ${NewsFromGenesysFilterLabel.ALL}`;
            case FavoriteId.NEWS_FROM_GENESYS_ANNOUNCEMENTS:
                return `${HomeSectionLabel.NEWS_FROM_GENESYS} — ${NewsFromGenesysFilterLabel.ANNOUNCEMENTS}`;
            case FavoriteId.NEWS_FROM_GENESYS_BLOG:
                return `${HomeSectionLabel.NEWS_FROM_GENESYS} — ${NewsFromGenesysFilterLabel.BLOG}`;
            case FavoriteId.NEWS_FROM_GENESYS_SOCIAL:
                return `${HomeSectionLabel.NEWS_FROM_GENESYS} — ${NewsFromGenesysFilterLabel.SOCIAL}`;
            case FavoriteId.GET_STARTED_MY_PERSONALIZED_VIDEO:
                return `${ResourcesSectionLabel.GET_STARTED} — ${GetStartedFilterLabel.MY_PERSONALIZED_VIDEO}`;
            case FavoriteId.GET_STARTED_STARTER_KIT:
                return `${ResourcesSectionLabel.GET_STARTED} — ${GetStartedFilterLabel.STARTER_KIT}`;
            case FavoriteId.GET_STARTED_ONBOARDING:
                return `${ResourcesSectionLabel.GET_STARTED} — ${GetStartedFilterLabel.NEW_CUSTOMER_ONBOARDING}`;
            case FavoriteId.GET_STARTED_SHORT_TRACKS:
                return `${ResourcesSectionLabel.GET_STARTED} — ${GetStartedFilterLabel.SHORT_TRACKS}`;
            case FavoriteId.GET_STARTED_USEFUL_LINKS:
                return `${ResourcesSectionLabel.GET_STARTED} — ${GetStartedFilterLabel.USEFUL_LINKS}`;
            case FavoriteId.GET_STARTED_TIPS_AND_TOOLS:
                return `${ResourcesSectionLabel.GET_STARTED} — ${GetStartedFilterLabel.TIPS_AND_TOOLS}`;
            case FavoriteId.CX_EVOLUTION_GET_STARTED:
                return `${ResourcesSectionLabel.CX_EVOLUTION} — ${CxEvolutionFilterLabel.GET_STARTED}`;
            case FavoriteId.CX_EVOLUTION_CONNECT:
                return `${ResourcesSectionLabel.CX_EVOLUTION} — ${CxEvolutionFilterLabel.CONNECT}`;
            case FavoriteId.CX_EVOLUTION_LEARN_FROM_OTHERS:
                return `${ResourcesSectionLabel.CX_EVOLUTION} — ${CxEvolutionFilterLabel.LEARN_FROM_OTHERS}`;
            case FavoriteId.PRODUCT_HELP_ARTICLES:
                return `${selectedProduct?.displayName} ${ResourcesSectionLabel.PRODUCT_HELP} — ${ProductHelpFilterLabel.ARTICLES}`;
            case FavoriteId.PRODUCT_HELP_VIDEOS:
                return `${selectedProduct?.displayName} ${ResourcesSectionLabel.PRODUCT_HELP} — ${ProductHelpFilterLabel.VIDEOS}`;
            case FavoriteId.PRODUCT_HELP_USE_CASES:
                return `${selectedProduct?.displayName} ${ResourcesSectionLabel.PRODUCT_HELP} — ${ProductHelpFilterLabel.USE_CASES}`;
            case FavoriteId.PRODUCT_HELP_DOCUMENTATION:
                return `${selectedProduct?.displayName} ${ResourcesSectionLabel.PRODUCT_HELP} — ${ProductHelpFilterLabel.DOCUMENTATION}`;
            case FavoriteId.DEVELOPER_CENTER_TOOLS:
                return `${ResourcesSectionLabel.DEV_CENTER} — ${DeveloperCenterFilterLabel.TOOLS}`;
            case FavoriteId.DEVELOPER_CENTER_POPULAR_TOPICS:
                return `${ResourcesSectionLabel.DEV_CENTER} — ${DeveloperCenterFilterLabel.POPULAR_TOPICS}`;
            case FavoriteId.DEVELOPER_CENTER_BLOG:
                return `${ResourcesSectionLabel.DEV_CENTER} — ${DeveloperCenterFilterLabel.BLOG}`;
            case FavoriteId.CERTIFICATIONS_MY_AWARDS:
                return `${EduTrainingSectionLabel.CERTIFICATIONS_AND_BADGES} — ${EduTrainingBadgesFilterLabel.MY_AWARDS}`;
            case FavoriteId.CERTIFICATIONS_GET_CERTIFIED:
                return `${EduTrainingSectionLabel.CERTIFICATIONS_AND_BADGES} — ${EduTrainingBadgesFilterLabel.GET_CERTIFIED}`;
            case FavoriteId.ADDITIONAL_SITES:
                return JustForMeSectionLabel.ADDITIONAL_SITES;
            case FavoriteId.MY_FAVORITES:
                return JustForMeSectionLabel.MY_FAVORITES;
            //fall-through
            default:
                console.error(`Label not defined for static favorite section: ${favoriteId}`);
                return favoriteId;
        }
    }
}
