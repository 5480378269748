import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { ComponentsModule } from '@components';
import { DirectivesModule } from '@directives';
import { PipesModule, StripHtmlPipe } from '@pipes';
import { ProductHelpArticleModule } from './product-help-article/product-help-article.module';
import { ProductHelpVideosModule } from './product-help-videos/product-help-videos.module';
import { ProductHelpComponent } from './product-help.component';
import { ProductHelpService } from './product-help.service';
import { ProductHelpUseCasesModule } from './use-cases/use-cases.module';

@NgModule({
    declarations: [ProductHelpComponent],
    imports: [
        CommonModule,
        ComponentsModule,
        DirectivesModule,
        ProductHelpArticleModule,
        ProductHelpVideosModule,
        ProductHelpUseCasesModule,
        MatTabsModule,
        PipesModule,
    ],
    providers: [ProductHelpService, StripHtmlPipe],
    exports: [ProductHelpComponent],
})
export class ProductHelpModule {}
