import { DOCUMENT } from '@angular/common';
import {
    Component,
    ContentChild,
    ElementRef,
    EventEmitter,
    HostListener,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
} from '@angular/core';

import { hideElement, lockBackgroundScrolling, showElement, unlockBackgroundScrolling } from '@utils';
import { ProductHelpArticle } from 'src/app/resources/product-help/product-help-article/product-help-article.interface';

@Component({
    selector: 'app-article',
    templateUrl: './article.component.html',
    styleUrls: ['./article.component.css'],
})
export class ArticleComponent implements OnInit, OnDestroy {
    @Input() article: ProductHelpArticle;

    /**
     * Optional modal tile
     */
    @Input() title: string;

    /**
     * Custom article content to be rendered. Default is false for most articles. True for custom content.
     */
    @Input() isCustomContent: false;

    /**
     * Override modal container styling (ex. full width)
     */
    @Input() customContainerCss: string;

    /**
     * Override default modal styling
     */
    @Input() customModalCss: string;

    /**
     * Override default close icon styling
     */
    @Input() customIconCss: string;

    /**
     * Override default title modal styling
     */
    @Input() customFontTitleModalCss: string;

    /**
     * Override default header modal styling
     */
    @Input() customSecondaryHeaderCSS: string;

    /**
     * Override whether to show the share icon in the modal header. Defaults to true. False to hide.
     */
    @Input() showShare = true;

    /**
     * Override the link used by {@link showShare}
     */
    @Input() customShareLink: string;

    /**
     * Close events
     */
    @Output() closeArticle = new EventEmitter<void>();

    /**
     * Allow custom content to be projected into the article modal like videos, etc...without having to pass an {@link article}
     */
    @ContentChild('customContent', { static: true }) customContent: ElementRef;

    heightOffset: number;

    private header = this.document.querySelector('header');
    private unlistenHeaderClick: () => void;

    /**
     * String identifiers for analytics services to track this tile, joined with hyphen in HTML
     */
    @Input() dataAnalytics: string[];
    dataAnalyticsShare: string;
    dataAnalyticsClose: string;

    constructor(@Inject(DOCUMENT) private document: Document, private renderer: Renderer2) {}

    ngOnInit(): void {
        this.dataAnalyticsShare = [...(this.dataAnalytics || []), 'modal', 'share'].join('-');
        this.dataAnalyticsClose = [...(this.dataAnalytics || []), 'modal', 'close'].join('-');

        // Hide Alert when we open article modal
        hideElement('alert');
        // Lock background scrolling
        lockBackgroundScrolling();

        if (this.header) {
            this.unlistenHeaderClick = this.renderer.listen(this.header, 'click', () => {
                this.closeModal();
            });
        }
    }

    @HostListener('window:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
        if (event.key === 'Escape') {
            this.handleClose(event);
        }
    }

    @HostListener('window:popstate', ['$event']) onPopState(event) {
        if (event.type === 'popstate') {
            this.handleClose(event);
        }
    }

    handleClose(event: Event): void {
        const clickElement = event.target as HTMLInputElement;
        if (clickElement?.className?.includes('close') || clickElement?.className?.includes('article-modal')) {
            this.closeModal();
        }
    }

    closeModal() {
        showElement('alert');
        unlockBackgroundScrolling();
        console.debug(`closing modal "${this.title}".`, { article: this.article });
        this.closeArticle.emit();
    }

    ngOnDestroy() {
        this.closeModal();
        if (this.unlistenHeaderClick) {
            this.unlistenHeaderClick();
        }
    }
}
