import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ComponentsModule } from '@components';
import { DirectivesModule } from 'src/app/@shared/directives/directives.module';
import { CxEvolutionCommunitiesComponent } from './cx-evolution-communities.component';

@NgModule({
    declarations: [CxEvolutionCommunitiesComponent],
    imports: [CommonModule, ComponentsModule, DirectivesModule],
    exports: [CxEvolutionCommunitiesComponent],
})
export class CxEvolutionCommunitiesModule {}
