import { Component, ContentChild, ElementRef, Input } from '@angular/core';

@Component({
    selector: 'app-tile-vertical',
    templateUrl: './tile-vertical.component.html',
    styleUrls: ['./tile-vertical.component.css'],
})
export class TileVerticalComponent {
    @Input() imageUrl = '';
    @Input() imageHeight: string;
    @Input() imageWidth: string;
    @Input() videoUrl = false; // TODO: Need to be hasVideoUrl. Clean this up globally in the future
    @Input() isStrapiVideo = false;
    @Input() icon = ''; // This is the heading small icon
    @Input() customSVGIcon: string; // Custom SVG with css background, defaults to  Genesys Logo

    /**
     * Represents the text content for the underlying <h2> element of the tile.
     * Use {@link customTitleLink} to make this clickable.
     * In the case where an image is used, this is also used for the alt tag.
     */
    @Input() title = '';

    /**
     * Add a minimum height to this tile's title. This is typically useful when vertically aligning
     * multi-line titles and descriptions for tile-layout of tile-vertical components.
     */
    @Input() titleMinHeight = true;

    @Input() description = '';
    @Input() details = '';
    @Input() url = '';
    @Input() urlLabel = 'Learn more';
    @Input() border = true;
    @Input() vertical = true;
    @Input() showImage = true;
    @Input() bigImage = false;
    @Input() titleLink = false;
    @Input() isCustomTitleLink = false;
    @Input() isCustomFooterLink = false;
    @Input() isCustomTileLink = false;
    @Input() isCustomImage = false;
    @Input() isImageShort = false;
    @Input() hasImageAutoHeight = false; // Override default height of 171px for specific tiles
    @Input() hasMarginTopAuto = true;
    @Input() hasBoxShadow = false; // Allow tiles to have box shadows if image background is transparent or tile has white background */
    @Input() customImage = '';
    @Input() customImageCss: string;
    @Input() videoId = '';
    // @Input() htmlVideoUrl = '';

    // TODO KNOW-1411 Remove facebook considerations from generic tile vertical component
    @Input() isFacebookNews = false;
    @Input() isCustomGenesysBlogImage = false;

    // The content child allows to inject custom content into the tile container
    // Can be video, image, etc...
    @ContentChild('customTileLink', { static: true }) customTileLink: ElementRef;
    @ContentChild('customTitleLink', { static: true }) customTitleLink: ElementRef;
    @ContentChild('customFooterLink', { static: true }) customFooterLink: ElementRef;

    @Input() dataAnalytics?: string[];
}
