<app-get-started
    *ngIf="isCssEmployee != null && selectedProduct$ | async; else loading"
    [isCssEmployee]="isCssEmployee"
    [selectedProduct]="selectedProduct$ | async"></app-get-started>
<ng-template #loading>
    <app-section-header [title]="sectionLabels.GET_STARTED"></app-section-header>
    <app-loading-spinner></app-loading-spinner>
</ng-template>
<hr />
<app-cx-evolution></app-cx-evolution>
<ng-container *ngIf="featuredContent$ | async as featuredContent">
    <hr />
    <app-featured-content [featuredContent]="featuredContent"></app-featured-content>
</ng-container>
<ng-container *ngIf="selectedProduct$ | async as selectedProduct">
    <ng-container *ngIf="selectedProduct !== productUidEnum.POINTILLIST">
        <hr />
        <app-product-help></app-product-help>
    </ng-container>
    <ng-container *ngIf="selectedProduct === productUidEnum.GENESYS_CLOUD_CX">
        <hr />
        <app-developer-center></app-developer-center>
    </ng-container>
    <ng-container *ngIf="selectedProduct === productUidEnum.GENESYS_CLOUD_CX">
        <hr />
        <app-expert-apps></app-expert-apps>
    </ng-container>
    <ng-container *ngIf="selectedProduct === productUidEnum.GENESYS_MULTICLOUD">
        <hr />
        <app-developer-center-mc-cx></app-developer-center-mc-cx>
    </ng-container>
    <ng-container *ngIf="selectedProduct === productUidEnum.PURECONNECT">
        <hr />
        <app-developer-portal-pureconnect></app-developer-portal-pureconnect>
    </ng-container>
    <ng-container *ngIf="selectedProduct === productUidEnum.GENESYS_CLOUD_CX">
        <hr />
        <app-app-foundry></app-app-foundry>
    </ng-container>
    <ng-container *ngIf="selectedProduct !== productUidEnum.POINTILLIST">
        <hr />
        <app-customer-success></app-customer-success>
    </ng-container>
</ng-container>
