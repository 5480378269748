import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProductUid } from '@enums';
import { AnalyticsService, ContentService, GknFundamentalCardLink, ProductService } from '@services';
import qs from 'qs';
import { EMPTY, Observable, catchError, filter, forkJoin, map, switchMap } from 'rxjs';
import { genesysCloudReleaseNotes, multiCloudCxReleaseNotes } from './config';
import { ResourceCenterReleaseNote } from './resource-center-release-note.interface';
import { ReleaseNoteLink } from './types/release-note.type';

@Injectable({
    providedIn: 'root',
})
export class ReleaseNotesService {
    constructor(
        private contentService: ContentService,
        private httpClient: HttpClient,
        private productService: ProductService,
    ) {}

    /**
     * Provides Release Notes data to be displayed in UI.
     * @params productUid - id of given product.
     */
    get releaseNotes$(): Observable<GknFundamentalCardLink[]> {
        return this.productService.selectedProduct$.pipe(
            switchMap((product) => {
                switch (product.uid) {
                    case ProductUid.GENESYS_CLOUD_CX:
                        return this.getLatestGenesysCloudReleaseNote();
                    case ProductUid.GENESYS_MULTICLOUD:
                        return this.getLatestMultiCloudCXReleaseNote();
                    default:
                        return this.contentService.releaseNotes$;
                }
            }),
            filter((data) => data?.length > 0),
        );
    }

    /**
     * Provides Genesys Cloud release notes data from dedicate resource.
     */
    private getLatestGenesysCloudReleaseNote(): Observable<GknFundamentalCardLink[]> {
        const releaseNotesParams = qs.stringify({ filter: { releasenote_category: 'genesys-cloud' } });

        return this.httpClient
            .get<ResourceCenterReleaseNote[]>(`${genesysCloudReleaseNotes.url}?${releaseNotesParams}`)
            .pipe(
                filter((res: ResourceCenterReleaseNote[]) => res?.length > 0 && !!res[0]),
                map((res: ResourceCenterReleaseNote[]) => res[0]),
                map((releaseNoteData: ResourceCenterReleaseNote) => {
                    const releaseNotes: GknFundamentalCardLink[] = [];

                    // Matches the title.rendered to:
                    //   /              begin JavaScript regex
                    //   .*             any number of characters 0-n
                    //   (              capturing group
                    //   \b             word boundry
                    //   (?:            non-capturing group
                    //   Jan...Dec      exact match text to full month
                    //   )              close non-capturing group
                    //                  literal space
                    //   \d{1,2}        any digit 0-9, 1 to 2 occurrances
                    //   ,              literal comma
                    //                  literal space
                    //   \d{4}          any digit 0-9, exactly 4 occurrances
                    //   .*             any number of characters 0-n
                    //   /g             end JavaScript regex, apply "globally" to the input
                    const regex =
                        /.*((?:January|February|March|April|May|June|July|August|September|October|November|December) \d{1,2}, \d{4}).*/;

                    const match = releaseNoteData?.title?.rendered?.match(regex);

                    let callToAction = 'View Most Recent';
                    let dataAnalytics = ['release', 'notes'];
                    if (match) {
                        callToAction = match[1];
                        const date = new Date(callToAction);
                        dataAnalytics = [
                            ...dataAnalytics,
                            date.getFullYear().toString(),
                            (date.getMonth() + 1).toString(),
                            date.getDate().toString(),
                        ];
                    } else {
                        dataAnalytics = [...dataAnalytics, 'view', 'most', 'recent'];
                    }

                    releaseNotes.push({
                        url: releaseNoteData?.link,
                        text: callToAction,
                        isButton: true,
                        dataAnalytics: dataAnalytics,
                    });

                    // Always include text links, and always place them last in the array
                    genesysCloudReleaseNotes.textLinks.forEach((link) => {
                        releaseNotes.push({
                            url: link.url,
                            text: link.label,
                            isButton: false,
                            dataAnalytics: ['release', 'notes', AnalyticsService.format(link.label)],
                        });
                    });

                    return releaseNotes;
                }),
            );
    }

    /**
     * Provides Genesys Multi Cloud CX release notes data from dedicate resource.
     */
    private getLatestMultiCloudCXReleaseNote(): Observable<GknFundamentalCardLink[] | undefined> {
        const privateResource = multiCloudCxReleaseNotes.private;
        const publicResource = multiCloudCxReleaseNotes.public;
        const fetchers = [publicResource, privateResource].map((resource) => {
            return this.httpClient.get(resource.url, { responseType: 'text' }).pipe(
                map((xmlText: string) => {
                    const xmlParser = new DOMParser();
                    return xmlParser.parseFromString(xmlText, 'text/xml');
                }),
                // extract button link data from xml
                map((xmlDoc: Document): ReleaseNoteLink | undefined => {
                    const publishedDateRawText = xmlDoc.querySelector('pubDate')?.textContent;
                    const date = new Date(publishedDateRawText);
                    // eg. January 4, 2021
                    const label = `${date.toLocaleString('default', {
                        month: 'long',
                    })} ${date.getUTCDate()}, ${date.getUTCFullYear()}`;
                    const url = xmlDoc.querySelector('guid')?.textContent;
                    const foundBtnLinkData = !!label && !!url;
                    if (foundBtnLinkData) return { label, url };
                }),
                catchError(() => EMPTY),
                // add text links data
                map((buttonLink: ReleaseNoteLink) => {
                    // NOTE: Button link and url maybe undefined hence the check on the label and url.
                    const textTop = (resource as any)?.textTop;
                    const releaseNote = {
                        buttonLink: {
                            text:
                                buttonLink && buttonLink?.label ? buttonLink.label : privateResource.defaultButtonText,
                            url: buttonLink && buttonLink.url ? buttonLink.url : privateResource.defaultUrl,
                            isButton: true,
                            prefixLabel: textTop ? textTop : null,
                            dataAnalytics: [
                                'release',
                                'notes',
                                buttonLink && buttonLink.label
                                    ? AnalyticsService.format(buttonLink?.label)
                                    : AnalyticsService.format('private-edition'),
                            ],
                        } as GknFundamentalCardLink,
                        textLink: resource.textLink,
                    };

                    return releaseNote;
                }),
            );
        });

        // emit data for all or none
        const releaseNotes: Observable<GknFundamentalCardLink[] | undefined> = forkJoin(fetchers).pipe(
            map(([publicData, privateData]) => {
                const links = [];
                links.push(publicData.buttonLink);
                links.push(publicData.textLink);
                links.push(privateData.buttonLink);
                links.push(privateData.textLink);
                return links;
            }),
        );
        return releaseNotes;
    }
}
