import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AnnouncementsComponent } from './announcements.component';

@NgModule({
    declarations: [AnnouncementsComponent],
    imports: [CommonModule],
    exports: [AnnouncementsComponent],
})
export class AnnouncementsModule {}
