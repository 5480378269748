import { DoceboSubscription } from '../interfaces';

export const mockDoceboSubscriptions: DoceboSubscription[] = [
    {
        priority: 0,
        ecommerce: { on_sale: 0, price: null, purchase_method: 1 },
        id: 84,
        code: '3GP110766ABAA', // real code
        name: 'Genesys Cloud Learning Subscription',
        description:
            '<p>Gain access to all available training for Genesys Cloud and any new content released during the year of an active subscription. In addition, subscribers receive one certification voucher for each available exam within the subscription plan.</p>\\n<p>A learning subscription is an individual, one-year subscription package that includes access to all, new and existing content and certification exams. It is ideal for any customer or partner with users that are responsible for the business and/or technical aspects of their Genesys technology, such as contact center management, system administration, or integration.</p>',
        create_date: '2022-06-28 20:47:05',
        total_duration: 85200,
        status: 'not-subscribed',
        complete_percent: 0,
        shopify_product_meaningful_id: null,
        type: 'learning_plan',
        settings: { show_in_catalog: true, enable_catchup: false, days_of_validity: 0 },
        deeplink: false,
        enrollment: [],
        subscription: [],
        enter_status: { status: 'enroll', price: 0 },
        slug_name: 'genesys-cloud-learning-subscription',
        curricula_redirect_to_learning_plan: false,
        is_assigned: true,
    },
    {
        priority: 1,
        ecommerce: { on_sale: 0, price: null, purchase_method: 1 },
        id: 190,
        code: '456', // fake code
        name: 'Genesys Engage Learning Subscription',
        description:
            '<p>Gain access to all available training for Genesys Engage and any new content released during the year of an active subscription. In addition, subscribers receive one certification voucher for each available exam within the subscription plan.</p>\\n<p>A learning subscription is an individual, one-year subscription package that includes access to all, new and existing content and certification exams. It is ideal for any customer or partner with users that are responsible for the business and/or technical aspects of their Genesys technology, such as contact center management, system administration, or integration.</p>',
        create_date: '2022-06-28 20:47:05',
        total_duration: 85200,
        status: 'not-subscribed',
        complete_percent: 0,
        shopify_product_meaningful_id: null,
        type: 'learning_plan',
        settings: { show_in_catalog: true, enable_catchup: false, days_of_validity: 0 },
        deeplink: false,
        enrollment: [],
        subscription: [],
        enter_status: { status: 'enroll', price: 0 },
        slug_name: 'genesys-engage-learning-subscription',
        curricula_redirect_to_learning_plan: false,
        is_assigned: true,
    },
    {
        priority: 2,
        ecommerce: { on_sale: 0, price: null, purchase_method: 1 },
        id: 189,
        code: '789', // fake code
        name: 'PureConnect Learning Subscription',
        description:
            '<p>Gain access to all available training for PureConnect and any new content released during the year of an active subscription. In addition, subscribers receive one certification voucher for each available exam within the subscription plan.</p>\\n<p>A learning subscription is an individual, one-year subscription package that includes access to all, new and existing content and certification exams. It is ideal for any customer or partner with users that are responsible for the business and/or technical aspects of their Genesys technology, such as contact center management, system administration, or integration.</p>',
        create_date: '2022-06-28 20:47:05',
        total_duration: 85200,
        status: 'not-subscribed',
        complete_percent: 0,
        shopify_product_meaningful_id: null,
        type: 'learning_plan',
        settings: { show_in_catalog: true, enable_catchup: false, days_of_validity: 0 },
        deeplink: false,
        enrollment: [],
        subscription: [],
        enter_status: { status: 'enroll', price: 0 },
        slug_name: 'pureconnect-learning-subscription',
        curricula_redirect_to_learning_plan: false,
        is_assigned: true,
    },
];
