<div class="container">
    <label for="product">Product</label>
    <ng-select
        tabindex="0"
        labelForId="product"
        [selectOnTab]="true"
        [tabindex]="0"
        [searchable]="false"
        [clearable]="false"
        [trackByFn]="trackByProductUid"
        [items]="availableProducts"
        bindLabel="displayName"
        bindValue="uid"
        [(ngModel)]="selectedProductUId"
        (change)="handleSelectedProduct($event)"
        [attr.data-analytics]="'product-select'">
    </ng-select>
</div>
