import { MarketingWpItem } from '../interfaces';

export const mockMarketingWpBlogs: MarketingWpItem[] = [
    {
        id: 1,
        date: new Date().toISOString(),
        link: 'https://example.com',
        title: { rendered: 'foo' },
        content: { rendered: '<h1>foo</h1>' },
    },
    {
        id: 2,
        date: new Date().toISOString(),
        link: 'https://example.com',
        title: { rendered: 'bar' },
        content: { rendered: '<h1>bar</h1>' },
    },
    {
        id: 3,
        date: new Date().toISOString(),
        link: 'https://example.com',
        title: { rendered: 'baz' },
        content: { rendered: '<h1>baz</h1>' },
    },
    {
        id: 4,
        date: new Date().toISOString(),
        link: 'https://example.com',
        title: { rendered: 'qux' },
        content: { rendered: '<h1>qux</h1>' },
    },
];
