import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-video-container',
    templateUrl: './video-container.component.html',
    styleUrls: ['./video-container.component.css'],
})
export class VideoContainerComponent implements OnInit {
    @Input() title: string; // Video title from iframe if available
    @Input() isStrapi: boolean;
    @Input() embedUrl: string; // Embedded iframe url
    @Input() thumbnail: string; // Video thumbnail image
    @Input() iframeAllow: string; // iframe configurations to tell how video will be played. Ex. autoplay, etc...
    @Input() iframeClass: string;
    @Input() iframeName: string;
    @Input() containerCss: string; // Optional container css - ex. border radius to embeded container
    @Output() iframeOnLoad = new EventEmitter<Event>();
    @Output() sendClick = new EventEmitter<boolean>();

    @Input() dataAnalytics: string[];
    dataAnalyticsInlineVideo: string;

    ngOnInit(): void {
        this.dataAnalyticsInlineVideo = [...(this.dataAnalytics || []), 'inline', 'video'].join('-');
    }

    /**
     * Add native host listener to handle the onClick from the custom iframe-directive for the
     * video container's iframe.
     */
    onIframeClick(_isClicked: boolean): void {
        this.sendClick.emit(true);
    }

    /** Captures iframe `onLoad event` and passes down `event` object */
    onIframeLoad(event: Event) {
        this.iframeOnLoad.emit(event);
    }
}
