/**
 * Hide Element
 * @param className - className of an element
 */
export function hideElement(className: string): void {
    const element = Array.from(document.getElementsByClassName(className) as HTMLCollectionOf<HTMLElement>);
    if (element && element.length > 0) {
        element.forEach((val) => (val.style.display = 'none'));
    }
}

/**
 * Show Element
 * @param className - className of an element
 */
export function showElement(className: string): void {
    const element = Array.from(document.getElementsByClassName(className) as HTMLCollectionOf<HTMLElement>);
    if (element && element.length > 0) {
        element.forEach((val) => (val.style.display = 'flex'));
    }
}
