import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ProductUid, ResourcesSectionLabel } from '@enums';
import {
    AuthService,
    ContentService,
    GknFeaturedContent,
    KeplerSfdcService,
    ProductService,
    UserService,
} from '@services';
import { Observable, Subscription, map } from 'rxjs';

@Component({
    selector: 'app-resources',
    templateUrl: './resources.component.html',
    styleUrls: ['./resources.component.css'],
})
export class ResourcesComponent implements OnInit, OnDestroy {
    featuredContent$: Observable<GknFeaturedContent>;
    selectedProduct$: Observable<ProductUid>;

    isCssEmployee = null;
    productUidEnum = ProductUid;
    sectionLabels = ResourcesSectionLabel;

    private authSubscription: Subscription;

    constructor(
        private changeDetector: ChangeDetectorRef,
        private authService: AuthService,
        private keplerSfdcService: KeplerSfdcService,
        private contentService: ContentService,
        private productService: ProductService,
        private userService: UserService,
    ) {}

    ngOnInit(): void {
        this.authSubscription = this.userService.isCssEmployee$.subscribe((isCssEmployee) => {
            this.isCssEmployee = isCssEmployee;
            this.changeDetector.markForCheck();
        });

        this.featuredContent$ = this.contentService.featuredContent$;
        this.selectedProduct$ = this.productService.selectedProduct$.pipe(map((product) => product.uid));
    }

    ngOnDestroy() {
        this.authSubscription.unsubscribe();
    }
}
