import { ProductDisplayName, ProductUid } from '@enums';

/**
 * Service to retrieve courses that can be
 * recommended to users based on their role.
 */
export class ElearningCoursesService {
    static STATIC_COURSE = {
        title: `${ProductDisplayName[ProductUid.GENESYS_CLOUD_CX]}: An Overview`,
        minutes: '30',
        href: 'https://beyond.genesys.com/explore/course/genesys-cloud-cx-an-overview',
    };

    private courses = [
        {
            role: 'default',
            course: {
                title: `Introduction to ${ProductDisplayName[ProductUid.GENESYS_CLOUD_CX]} for Agents: Interactions`,
                minutes: 15,
                href: 'https://beyond.genesys.com/explore/genesys-cloud-cx-elearning#intro_agents_interactions',
            },
        },
        {
            role: 'administrator',
            course: {
                title: `${ProductDisplayName[ProductUid.GENESYS_CLOUD_CX]} Recording and Quality Management Basics`,
                minutes: 25,
                href: 'https://beyond.genesys.com/explore/genesys-cloud-cx-elearning#genesys_cloud_recording_and_quality_management_basics',
            },
        },
        {
            role: 'agent',
            course: {
                title: `Introduction to ${ProductDisplayName[ProductUid.GENESYS_CLOUD_CX]} for Agents: The Basics`,
                minutes: 15,
                href: 'https://beyond.genesys.com/explore/genesys-cloud-cx-elearning#intro_agents_basics',
            },
        },
        {
            role: 'supervisor',
            course: {
                title: `Introduction to ${
                    ProductDisplayName[ProductUid.GENESYS_CLOUD_CX]
                } for Supervisors: Managing Agents`,
                minutes: 20,
                href: 'https://beyond.genesys.com/explore/genesys-cloud-cx-elearning#intro_supervisors_managing_agents',
            },
        },
        {
            role: 'manager',
            course: {
                title: `Introduction to ${
                    ProductDisplayName[ProductUid.GENESYS_CLOUD_CX]
                } for Supervisors: Managing Agents`,
                minutes: 20,
                href: 'https://beyond.genesys.com/explore/genesys-cloud-cx-elearning#intro_supervisors_managing_agents',
            },
        },
    ];

    findAll() {
        return this.courses;
    }

    findByRole(role: string): any | null {
        const filteredCourses = this.findAll().filter((data) => data.role === role);
        if (filteredCourses != undefined && filteredCourses != null && filteredCourses.length > 0) {
            return filteredCourses[0].course;
        } else {
            return this.findAll().filter((data) => data.role === 'default')[0].course;
        }
    }
}
