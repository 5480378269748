import { MarketingWpItem } from '../interfaces';

export const mockMarketingWpPodcasts: MarketingWpItem[] = [
    {
        title: { rendered: 'foo' },
        link: 'https://example.com',
        date: new Date().toISOString(),
        acf: {
            meta_description: 'foo',
        },
    },
    {
        title: { rendered: 'bar' },
        link: 'https://example.com',
        date: new Date().toISOString(),
        acf: {
            meta_description: 'bar',
        },
    },
    {
        title: { rendered: 'baz' },
        link: 'https://example.com',
        date: new Date().toISOString(),
        acf: {
            meta_description: 'baz',
        },
    },
    {
        title: { rendered: 'qux' },
        link: 'https://example.com',
        date: new Date().toISOString(),
        acf: {
            meta_description: 'qux',
        },
    },
];
