import { Component } from '@angular/core';

/**
 * Wrapper component for Onetrust cookie consent manager
 */
@Component({
    selector: 'app-cookie-consent',
    templateUrl: './cookie-consent.component.html',
    styleUrls: ['./cookie-consent.component.css'],
})
export class CookieConsentComponent {}
