import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FundamentalsCardModule } from '../fundamentals-card/fundamentals-card.module';
import { ProductIdeasLabComponent } from './product-ideas-lab.component';

@NgModule({
    declarations: [ProductIdeasLabComponent],
    imports: [CommonModule, FundamentalsCardModule, MatButtonModule, MatIconModule],
    exports: [ProductIdeasLabComponent],
})
export class ProductIdeasLabModule {}
