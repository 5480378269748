<a
    id="scrollToTopBtn"
    (click)="scrollToTop()"
    [ngStyle]="{ display: 'none' }"
    title="Scroll to top"
    class="btn btn-primary back-to-top cursor">
    <img
        class="back-to-top-img"
        src="/assets/images/arrow.svg"
        width="48px"
        height="24px"
        alt="Scroll to top icon" />TOP
</a>
