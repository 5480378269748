export interface CmsAbout {
    href?: string;
}

export interface CmsAuthor {
    embeddable?: boolean;
    href?: string;
}

export interface CmsLinks {
    self?: CmsAbout[];
    collection?: CmsAbout[];
    about?: CmsAbout[];
    author?: CmsAuthor[];
    'wp:attachment'?: CmsAbout[];
    curies?: CmsCury[];
}

export interface CmsCury {
    name?: string;
    href?: string;
    templated?: boolean;
}

export interface CmsGuid {
    rendered?: string;
}

export interface CmsDuration {
    start_date_time?: string;
    end_date_time?: string;
    timezone?: CmsTimezone;
}

export enum CmsTimezone {
    EST = 'est',
    CET = 'cet',
    NZST = 'nzst',
}

export interface CmsThumbnails {
    desktop: string;
    mobile: string;
}
