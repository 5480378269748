import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-release-alert',
    templateUrl: './release-alert.component.html',
    styleUrls: ['./release-alert.component.css'],
})
export class ReleaseAlertComponent {
    @Input() fillColor = '#ffffff';
}
