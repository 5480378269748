import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-vertical-divider',
    templateUrl: './vertical-divider.component.html',
    styleUrls: ['./vertical-divider.component.css'],
})
export class VerticalDividerComponent {
    @Input() color: 'black' | 'white' | 'lightgrey' | 'darkgrey' = 'black';
}
