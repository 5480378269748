import { Clipboard } from '@angular/cdk/clipboard';
import { Component, ElementRef, Inject, OnDestroy, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription, timer } from 'rxjs';

export interface IframeDialogData {
    title: string;
    subtitle?: string;
    src: string;
}

@Component({
    selector: 'app-iframe-dialog-component',
    templateUrl: './iframe-dialog.component.html',
    styleUrls: ['./iframe-dialog.component.css'],
})
export class IframeDialogComponent implements OnDestroy {
    private subs: Subscription[] = [];
    @ViewChild('dialogContent') dialogContent: ElementRef;

    isCopied = false;

    colors = {
        copy: 'primary',
        close: 'primary',
        open: 'primary',
    };

    constructor(
        private clipboard: Clipboard,
        public readonly dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data: IframeDialogData,
    ) {}

    handleCopy(e?: Event) {
        e?.preventDefault();

        if (this.data.src.startsWith('https://help.mypurecloud.com') && this.data.src.includes('theme=simplified')) {
            const resourceCenterUrl = new URL(this.data.src);
            resourceCenterUrl.searchParams.delete('theme');
            this.clipboard.copy(resourceCenterUrl.toString());
        } else {
            this.clipboard.copy(this.data.src);
        }

        this.isCopied = true;

        this.subs.push(
            timer(2000).subscribe(() => {
                this.isCopied = false;
            }),
        );
    }

    ngOnDestroy(): void {
        this.subs?.forEach((sub) => sub?.unsubscribe());
    }
}
