import { Injectable } from '@angular/core';
import { AnalyticsService, KeplerCoveoCommonSourceName, KeplerCoveoRecommendation, KeplerCoveoSource } from '@services';
import { SupportRecommendation } from './support-recommendations.interface';

@Injectable({
    providedIn: 'root',
})
export class SupportRecommendationsAdapter {
    adapt(recommendation: KeplerCoveoRecommendation): SupportRecommendation {
        const subtitle = this.getSubtitle(recommendation);
        return {
            title: recommendation.title,
            subtitle: subtitle,
            description: recommendation.excerpt
                ?.replace('× Close Genesys Cloud Resource Center Submit Search ...', '')
                .trim(),
            url: recommendation.clickUri,
            thumbnail: this.getThumbnail(recommendation),
            subtitleIcon: this.getSubtitleIcon(recommendation),
            isLoadingInFrame: subtitle === KeplerCoveoCommonSourceName.DOCUMENTATION,
            dataAnalytics: ['recommendations', AnalyticsService.format(recommendation.title)],
        };
    }

    private getSubtitle(recommendation: KeplerCoveoRecommendation): string {
        if (recommendation.raw?.commonsourcename) {
            return recommendation.raw.commonsourcename;
        } else if (recommendation.raw?.source) {
            switch (recommendation.raw.source) {
                case KeplerCoveoSource.GENESYS_ONLINE_COMMUNITY_HL:
                    return KeplerCoveoCommonSourceName.COMMUNITY;
                case KeplerCoveoSource.COMMUNITY_YOUTUBE:
                // fall-through
                case KeplerCoveoSource.COMMUNITY_YOUTUBE_1:
                // fall-through
                case KeplerCoveoSource.COMMUNITY_YOUTUBE_2:
                    return KeplerCoveoCommonSourceName.VIDEO;
                case KeplerCoveoSource.GENESYS_BLOG:
                // fall-through
                case KeplerCoveoSource.GENESYS_BLOG_2:
                    return KeplerCoveoCommonSourceName.BLOGS;
                case KeplerCoveoSource.DEV_CENTER:
                // fall-through
                case KeplerCoveoSource.DEV_CENTER_2:
                // fall-through
                case KeplerCoveoSource.DEV_CENTER_3:
                    return KeplerCoveoCommonSourceName.DEV_CENTER;
            }
        }

        // default
        return KeplerCoveoCommonSourceName.DOCUMENTATION;
    }

    private getThumbnail(recommendation: KeplerCoveoRecommendation): string {
        if (recommendation.raw?.commonsourcename) {
            switch (recommendation.raw.commonsourcename) {
                case KeplerCoveoCommonSourceName.KNOWLEDGE_BASE:
                    return '/assets/icons/support/recommendations/knowledge-base.svg';
                case KeplerCoveoCommonSourceName.TRAINING:
                    return '/assets/icons/support/recommendations/training.svg';
                case KeplerCoveoCommonSourceName.VIDEO:
                    return '/assets/icons/support/recommendations/video.svg';
                case KeplerCoveoCommonSourceName.DOCUMENTATION:
                // fall-through
                default:
                    return '/assets/icons/support/recommendations/documentation.svg';
            }
        } else if (recommendation.raw?.source) {
            switch (recommendation.raw.source) {
                case KeplerCoveoSource.GENESYS_ONLINE_COMMUNITY_HL:
                    return '/assets/icons/support/recommendations/community.svg';
                case KeplerCoveoSource.COMMUNITY_YOUTUBE:
                // fall-through
                case KeplerCoveoSource.COMMUNITY_YOUTUBE_1:
                // fall-through
                case KeplerCoveoSource.COMMUNITY_YOUTUBE_2:
                    return '/assets/icons/support/recommendations/video.svg';
                case KeplerCoveoSource.GENESYS_BLOG:
                // fall-through
                case KeplerCoveoSource.GENESYS_BLOG_2:
                    return '/assets/icons/support/recommendations/blog.svg';
                case KeplerCoveoSource.DEV_CENTER:
                // fall-through
                case KeplerCoveoSource.DEV_CENTER_2:
                // fall-through
                case KeplerCoveoSource.DEV_CENTER_3:
                    return '/assets/icons/support/recommendations/dev-center.svg';
            }
        }

        // default
        return '/assets/icons/support/recommendations/documentation.svg';
    }

    private getSubtitleIcon(recommendation: KeplerCoveoRecommendation): string {
        if (recommendation.raw?.commonsourcename) {
            switch (recommendation.raw.commonsourcename) {
                case KeplerCoveoCommonSourceName.KNOWLEDGE_BASE:
                    return '/assets/icons/support/recommendations/knowledge-base-white.svg';
                case KeplerCoveoCommonSourceName.TRAINING:
                    return '/assets/icons/support/recommendations/training-white.svg';
                case KeplerCoveoCommonSourceName.VIDEO:
                    return '/assets/icons/support/recommendations/video-white.svg';
            }
        } else if (recommendation.raw?.source) {
            switch (recommendation.raw.source) {
                case KeplerCoveoSource.GENESYS_ONLINE_COMMUNITY_HL:
                    return '/assets/icons/support/recommendations/community-white.svg';
                case KeplerCoveoSource.COMMUNITY_YOUTUBE:
                // fall-through
                case KeplerCoveoSource.COMMUNITY_YOUTUBE_1:
                // fall-through
                case KeplerCoveoSource.COMMUNITY_YOUTUBE_2:
                    return '/assets/icons/support/recommendations/video-white.svg';
                case KeplerCoveoSource.GENESYS_BLOG:
                // fall-through
                case KeplerCoveoSource.GENESYS_BLOG_2:
                    return '/assets/icons/support/recommendations/blog-white.svg';
                case KeplerCoveoSource.DEV_CENTER:
                // fall-through
                case KeplerCoveoSource.DEV_CENTER_2:
                // fall-through
                case KeplerCoveoSource.DEV_CENTER_3:
                    return '/assets/icons/support/recommendations/dev-center-white.svg';
            }
        }

        // default
        return '/assets/icons/support/recommendations/documentation-white.svg';
    }
}
