import { AfterViewInit, Directive, EventEmitter, Host, Input, OnDestroy, Optional, Output, Self } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import {
    CxEvolutionFilterLabel,
    EduTrainingBadgesFilterLabel,
    EduTrainingSectionLabel,
    FavoriteId,
    GetStartedFilterLabel,
    HomeSectionLabel,
    JustForMeSectionLabel,
    NewsFromGenesysFilterLabel,
    ProductHelpFilterLabel,
    ResourcesSectionLabel,
    SupportSectionLabel,
} from '@enums';

import { Subscription } from 'rxjs';

/**
 * Attribute directive that adds functionality to the MatTabGroup component
 *
 * This directive is specifically for use with the MatTabGroup component.
 * When the component hosting this directive loads, this directive will read the location
 * parameter from the url and change to the tab with the matching id. On tab changes,
 * this directive will emit an event with the favorite id of the newly selected tab.
 *
 * {@example}
 * // component.ts
 *
 *   filterUpdated(favoriteId: string) {
 *      // do something with new favorite id
 *   }
 *
 * // component.html
 *
 *     <mat-tab-group appFilterLocation (currentlySelectedEvent)="filterUpdated($event)" [prefix]="filterPrefix" dynamicHeight>
 *       <mat-tab [label]="'Tab 1'">
 *           <app-tab-1></app-tab-1>
 *       </mat-tab>
 *       <mat-tab [label]="'Tab 2'">
 *           <app-tab-2></app-tab-2>
 *       </mat-tab>
 *   </mat-tab-group>
 *
 */

@Directive({
    selector: '[appFilterLocation]',
})
export class FilterLocationDirective implements AfterViewInit, OnDestroy {
    @Input() prefix: string;
    @Output() currentlySelectedEvent = new EventEmitter<FavoriteId>();
    private subs: Subscription[] = [];

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        @Host() @Self() @Optional() private host: MatTabGroup,
    ) {}

    ngAfterViewInit() {
        if (this.host) {
            this.subs.push(
                this.host.selectedIndexChange.subscribe((index) => {
                    const favoriteId = this.favoriteIdUrlPathMap[this.host._tabs.toArray()[index].textLabel];
                    if (favoriteId) {
                        this.updateURL(favoriteId);
                        this.currentlySelectedEvent.emit(favoriteId);
                    }
                }),
            );
            this.subs.push(
                this.activatedRoute.fragment.subscribe((fragment) => {
                    if (fragment) {
                        const filterId = fragment.replace(this.prefix + '_', '');
                        if (filterId) {
                            const tabsArr = this.host._tabs.toArray();
                            for (let i = 0; i < tabsArr.length; i++) {
                                const tab = tabsArr[i];
                                if (filterId == this.favoriteIdUrlPathMap[tab.textLabel]) {
                                    this.host.selectedIndex = i;
                                    break;
                                }
                            }
                        }
                    }
                }),
            );
        }
        // this.updateURL(this.favoriteIdUrlPathMap[this.host._tabs.toArray()[this.host.selectedIndex].textLabel]);
    }

    ngOnDestroy() {
        this.subs?.forEach((sub) => sub?.unsubscribe());
    }

    /**
     * Update the URL based on the filter button clicked
     * @param filterLabel - the button's value
     */
    private updateURL(favoriteId: FavoriteId): string {
        const fragment = this.prefix + '_' + favoriteId;
        const navigateExtras: NavigationExtras = {
            fragment,
            replaceUrl: true,
            preserveFragment: false,
            queryParamsHandling: 'preserve',
        };
        this.router.navigate([], navigateExtras);
        return fragment;
    }

    favoriteIdUrlPathMap: Record<string, FavoriteId> = {
        [EduTrainingBadgesFilterLabel.GET_CERTIFIED]: FavoriteId.CERTIFICATIONS_GET_CERTIFIED,
        [EduTrainingBadgesFilterLabel.MY_AWARDS]: FavoriteId.CERTIFICATIONS_MY_AWARDS,
        [GetStartedFilterLabel.MY_PERSONALIZED_VIDEO]: FavoriteId.GET_STARTED_MY_PERSONALIZED_VIDEO,
        [GetStartedFilterLabel.NEW_CUSTOMER_ONBOARDING]: FavoriteId.GET_STARTED_ONBOARDING,
        [GetStartedFilterLabel.SHORT_TRACKS]: FavoriteId.GET_STARTED_SHORT_TRACKS,
        [GetStartedFilterLabel.STARTER_KIT]: FavoriteId.GET_STARTED_STARTER_KIT,
        [GetStartedFilterLabel.TIPS_AND_TOOLS]: FavoriteId.GET_STARTED_TIPS_AND_TOOLS,
        [GetStartedFilterLabel.USEFUL_LINKS]: FavoriteId.GET_STARTED_USEFUL_LINKS,
        [NewsFromGenesysFilterLabel.ALL]: FavoriteId.NEWS_FROM_GENESYS_ALL,
        [NewsFromGenesysFilterLabel.ANNOUNCEMENTS]: FavoriteId.NEWS_FROM_GENESYS_ANNOUNCEMENTS,
        [NewsFromGenesysFilterLabel.BLOG]: FavoriteId.NEWS_FROM_GENESYS_BLOG,
        [NewsFromGenesysFilterLabel.SOCIAL]: FavoriteId.NEWS_FROM_GENESYS_SOCIAL,
        [CxEvolutionFilterLabel.GET_STARTED]: FavoriteId.CX_EVOLUTION_GET_STARTED,
        [CxEvolutionFilterLabel.CONNECT]: FavoriteId.CX_EVOLUTION_CONNECT,
        [CxEvolutionFilterLabel.LEARN_FROM_OTHERS]: FavoriteId.CX_EVOLUTION_LEARN_FROM_OTHERS,
        [ProductHelpFilterLabel.ARTICLES]: FavoriteId.PRODUCT_HELP_ARTICLES,
        [ProductHelpFilterLabel.USE_CASES]: FavoriteId.PRODUCT_HELP_USE_CASES,
        [ProductHelpFilterLabel.VIDEOS]: FavoriteId.PRODUCT_HELP_VIDEOS,
        [EduTrainingSectionLabel.BEYOND_TRAINING]: FavoriteId.BEYOND,
        [ResourcesSectionLabel.CUSTOMER_SUCCESS]: FavoriteId.CUSTOMER_SUCCESS,
        [SupportSectionLabel.MY_CASES]: FavoriteId.SUPPORT_CASES,
        [SupportSectionLabel.MY_CONTACTS]: FavoriteId.MY_CONTACTS,
        [HomeSectionLabel.MY_FAVORITES]: FavoriteId.MY_FAVORITES,
        [JustForMeSectionLabel.ADDITIONAL_SITES]: FavoriteId.ADDITIONAL_SITES,
    };
}
