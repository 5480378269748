import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { sortByDate } from '@utils';
import { catchError, map, Observable, of } from 'rxjs';
import {
    DoceboEnrollment,
    DoceboEnrollmentsByCode,
    DoceboEnrollmentsResponse,
    DoceboSubscription,
    DoceboSubscriptionsResponse,
} from './interfaces';

@Injectable({
    providedIn: 'root',
})
export class KeplerDoceboService {
    constructor(private http: HttpClient) {}

    /**
     * Observes Docebo subscriptions for the given user
     * @param userId Docebo user id
     * @returns observable list of subscriptions, sorted by priority.
     */
    subscriptions$(userId: number): Observable<DoceboSubscription[]> {
        if (!userId || userId <= 0) {
            console.error(`Failed to fetch Docebo subscriptions. Invalid Docebo user ID "${userId}".`);
            return of([]);
        }

        return this.http
            .get<DoceboSubscriptionsResponse>(`${environment.api.kepler.docebo.url}/subscriptions`, {
                params: {
                    userId: userId,
                },
            })
            .pipe(
                map((response: DoceboSubscriptionsResponse) => response?.data),
                catchError((err) => {
                    console.error('Failed to fetch Docebo subscriptions.', { cause: err });
                    return of([]);
                }),
            );
    }

    /**
     * Observes Docebo enrollments for the given user
     * @param userId Docebo user id
     * @returns observable list of enrollments split by Beyond and GenEd
     */
    enrollments$(userId: number): Observable<DoceboEnrollmentsByCode> {
        if (!userId || userId <= 0) {
            console.error(`Failed to fetch Docebo enrollments. Invalid Docebo user ID "${userId}".`);
            return of({
                beyond: [],
                genEd: [],
            });
        }

        return this.http
            .get<DoceboEnrollmentsResponse>(`${environment.api.kepler.docebo.url}/enrollments`, {
                params: {
                    userId: userId,
                    // get a large response to allow us to filter out the possibility
                    // of an overacheiver with a ton of 'complete' courses that need filtering
                    pageSize: 200,
                },
            })
            .pipe(
                map((response: DoceboEnrollmentsResponse) => response?.data),
                map((enrollments: DoceboEnrollment[]) => {
                    const split = {
                        beyond: [],
                        genEd: [],
                    } as DoceboEnrollmentsByCode;

                    const beyondCodes = ['3G', 'BYD', 'GU'];

                    enrollments
                        ?.filter((enrollment) => {
                            return enrollment.status != 'complete';
                        })
                        .forEach((enrollment) => {
                            if (beyondCodes.some((code) => enrollment?.code?.startsWith(code))) {
                                split.beyond.push(enrollment);
                            } else {
                                split.genEd.push(enrollment);
                            }
                        });

                    return split;
                }),
                map((enrollments: DoceboEnrollmentsByCode) => {
                    const fieldName = 'date_last_updated';
                    const desc = true;

                    return {
                        beyond: sortByDate(enrollments.beyond, fieldName, desc),
                        genEd: sortByDate(enrollments.genEd, fieldName, desc),
                    };
                }),
                catchError((err) => {
                    console.error('Failed to fetch Docebo enrollments.', { cause: err });
                    return of({
                        beyond: [],
                        genEd: [],
                    });
                }),
            );
    }
}
