<app-section-header
    [title]="sectionLabel"
    [subTitle]="subTitle"
    [favoriteId]="favoriteId"
    [section]="location"
    [externalUrl]="externalUrl"></app-section-header>

<app-tile-layout maxColumns="3">
    <li *ngFor="let card of cards; trackBy: trackByItem">
        <app-tile-vertical
            [isCustomTitleLink]="true"
            [isCustomFooterLink]="true"
            [titleLink]="true"
            [showImage]="false"
            [border]="false">
            <div class="title-min-height" customTitleLink>
                <a class="cursor primary-link" target="_blank" rel="noopener noreferrer nofollow" [href]="card?.url">
                    <h2 class="sub-title-3 text-brand-navy ellipsis-2 margin-bottom-small">{{ card?.title }}</h2>
                </a>
            </div>
            <div customFooterLink>
                <a
                    class="cursor link-n margin-top-auto'"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                    [href]="card?.url">
                    Read more
                </a>
            </div>
        </app-tile-vertical>
    </li>
</app-tile-layout>
