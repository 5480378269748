import { Component, Input, OnInit } from '@angular/core';

import { FavoriteId } from '@enums';
import { Observable } from 'rxjs';
import { SECTION_LINKS } from 'src/app/app-header/submenu-data';
import { UserService } from '../../services/user/user.service';
import { FavoritesService, Product, ProductService } from '@services';

@Component({
    selector: 'app-section-header',
    templateUrl: './section-header.component.html',
    styleUrls: ['./section-header.component.css'],
})
export class SectionHeaderComponent implements OnInit {
    /**
     * indicates whether to show the title text for this section.
     * typically set to false when a component is shown in the Just for Me section
     */
    @Input() showTitle = true;
    /** title text for the header */
    @Input() title: string;
    /** subtitle text for the header */
    @Input() subTitle?: string;
    /** indicates whether to show copy/favorite/external icon group */
    @Input() showIcons = true;
    /** scroll location for this section */
    @Input() section?: SECTION_LINKS;
    /** id to enable favoriting this section. Requires a {@link location}. */
    @Input() favoriteId?: FavoriteId;
    /** external navigation link for the section to open in a new tab */
    @Input() externalUrl?: string;
    /** optional ids of extra anchors to establish in the section header that can be set */
    @Input() anchors?: string[];
    /**
     * indicates whether the current user is authenticated. favorite icon is not
     * available to unauth'ed users, regardless of the presence of {@link favoriteId}
     */
    isAuthenticated$: Observable<boolean>;
    selectedProduct$: Observable<Product>;

    constructor(
        private userService: UserService,
        private productService: ProductService,
        private favoritesService: FavoritesService,
    ) {}

    ngOnInit() {
        if (!this.anchors) {
            this.anchors = [];
        }
        this.isAuthenticated$ = this.userService.isAuthenticated$;
        this.selectedProduct$ = this.productService.selectedProduct$;
        this.anchors.unshift(this.section);
        this.anchors.unshift(this.favoritesService.buildFragment(this.section, this.favoriteId));
    }
}
