import { KeplerPurecloudUser } from '@services';
import { populateVideo } from './populate-employee-video';
import { aspectRatio } from './utils/aspect-ratio';

let lastPlayingState = false;

export function initVideo(parentElement: HTMLElement, user: KeplerPurecloudUser) {
    const root = document.createElement('div');
    root.setAttribute('id', 'video-component');
    root.setAttribute('class', 'video-component');
    parentElement.appendChild(root);

    const videoPlayer = document.createElement('video');
    videoPlayer.setAttribute('id', 'videoPlayer');
    videoPlayer.setAttribute('class', 'video-js');
    videoPlayer.setAttribute('playsinline', '');
    document.addEventListener('visibilitychange', () => {
        if (isVideoPlaying(videoPlayer)) {
            lastPlayingState = true;
            videoPlayer.pause();
        } else {
            if (document.visibilityState === 'visible' && lastPlayingState) {
                videoPlayer.play();
            }
            lastPlayingState = false;
        }
    });
    root.appendChild(videoPlayer);

    aspectRatio.initEvents(videoPlayer);

    const videoRemoteFile = document.createElement('source');
    videoRemoteFile.setAttribute('type', 'video/mp4');
    videoRemoteFile.setAttribute(
        'src',
        'https://csd-static-assets-572708336319-us-east-1.s3.us-east-1.amazonaws.com/assets/onboarding.mp4',
    );
    videoPlayer.appendChild(videoRemoteFile);

    const enableJavaScriptMessage = document.createElement('p');
    enableJavaScriptMessage.setAttribute('class', 'vjs-no-js');
    enableJavaScriptMessage.innerHTML =
        'To view this video please enable JavaScript, and consider upgrading to a web browser that' +
        '<a href="https://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a>';
    videoPlayer.appendChild(enableJavaScriptMessage);
    populateVideo(user, videoPlayer);
    return root;
}

function isVideoPlaying(video) {
    return !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2);
}
