import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CxEvolutionFilterLabel, FavoriteId, ResourcesSectionLabel } from '@enums';
import { ContentService, FavoritesService, GknCxEvolution, GknCxEvolutionExcerpt } from '@services';
import { Observable, map } from 'rxjs';
import { SECTION_LINKS } from 'src/app/app-header/submenu-data';

@Component({
    selector: 'app-cx-evolution',
    templateUrl: './cx-evolution.component.html',
    styleUrls: ['./cx-evolution.component.css'],
})
export class CxEvolutionComponent implements OnInit {
    sectionLabel = ResourcesSectionLabel.CX_EVOLUTION;
    favoriteId = FavoriteId.CX_EVOLUTION_GET_STARTED;
    cxEvolutionFilterLabel = CxEvolutionFilterLabel;

    externalUrl = '';

    filterPrefix = SECTION_LINKS.CX_EVOLUTION;

    excerpt$: Observable<GknCxEvolutionExcerpt>;

    anchors: string[];

    constructor(
        private contentService: ContentService,
        private cdr: ChangeDetectorRef,
        private favoritesService: FavoritesService,
    ) {
        this.anchors = [
            this.favoritesService.buildFragmentFromFavorite(FavoriteId.CX_EVOLUTION_GET_STARTED),
            this.favoritesService.buildFragmentFromFavorite(FavoriteId.CX_EVOLUTION_CONNECT),
            this.favoritesService.buildFragmentFromFavorite(FavoriteId.CX_EVOLUTION_LEARN_FROM_OTHERS),
        ];
    }

    ngOnInit(): void {
        this.excerpt$ = this.contentService.cxEvolution$.pipe(
            map((cxEvolution: GknCxEvolution) => cxEvolution?.excerpt),
        );

        this.filterUpdated(FavoriteId.CX_EVOLUTION_GET_STARTED);
    }

    filterUpdated(favoriteId: FavoriteId) {
        this.favoriteId = favoriteId;

        switch (favoriteId) {
            case FavoriteId.CX_EVOLUTION_GET_STARTED:
                this.externalUrl = 'https://vimeo.com/channels/1841991';
                break;
            case FavoriteId.CX_EVOLUTION_CONNECT:
            // fall-through
            case FavoriteId.CX_EVOLUTION_LEARN_FROM_OTHERS:
                this.externalUrl = 'https://www.genesys.com/resources';
                break;
            default:
                this.externalUrl = '';
        }

        this.cdr.markForCheck();
    }
}
