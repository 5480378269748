import { KeplerDBStarterKitResponse, KeplerDBStarterKitStepProgressAddition } from './kepler-db.interface';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class KeplerDbService {
    constructor(private http: HttpClient) {}

    getStarterKitProgress(userId: string): Observable<KeplerDBStarterKitResponse> {
        return this.http.get<KeplerDBStarterKitResponse>(`${environment.api.kepler.db.starterKit.url}/` + userId);
    }

    deleteStepStarterKitProgress(
        userId: string,
        productId: string,
        stepId: string,
    ): Observable<KeplerDBStarterKitResponse> {
        return this.http.delete<KeplerDBStarterKitResponse>(
            `${environment.api.kepler.db.starterKit.url}/${userId}?productId=${productId}&stepId=${stepId}`,
        );
    }

    deleteStarterKitProgress(userId: string, productId: string): Observable<KeplerDBStarterKitResponse> {
        return this.http.delete<KeplerDBStarterKitResponse>(
            `${environment.api.kepler.db.starterKit.url}/${userId}?productId=${productId}`,
        );
    }

    deleteAllStarterKitProgress(userId: string): Observable<KeplerDBStarterKitResponse> {
        return this.http.delete<KeplerDBStarterKitResponse>(`${environment.api.kepler.db.starterKit.url}/` + userId);
    }

    addStarterKitProgress(
        userId: string,
        progressAddition: KeplerDBStarterKitStepProgressAddition,
    ): Observable<KeplerDBStarterKitResponse> {
        return this.http.put<KeplerDBStarterKitResponse>(
            `${environment.api.kepler.db.starterKit.url}/` + userId,
            progressAddition,
        );
    }
}
