import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { GknCxEvolutionExcerpt } from '@services';

@Component({
    selector: 'app-cx-evolution-excerpt',
    templateUrl: './cx-evolution-excerpt.component.html',
    styleUrls: ['./cx-evolution-excerpt.component.css'],
})
export class CxEvolutionExcerptComponent implements OnChanges {
    @Input() excerpt: GknCxEvolutionExcerpt;

    ngOnChanges(changes: SimpleChanges): void {
        this.excerpt = changes.excerpt.currentValue;
    }
}
