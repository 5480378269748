import { Component, Input, OnInit } from '@angular/core';

import { Product, ProductService } from '@services';
import { BehaviorSubject, Observable } from 'rxjs';
import { SubmenuLink } from 'src/app/app-header/submenu-data';

@Component({
    selector: 'app-hover-menu',
    templateUrl: './hover-menu.component.html',
    styleUrls: ['./hover-menu.component.css'],
})
export class HoverMenuComponent implements OnInit {
    @Input() submenuLink: SubmenuLink;
    selectedProduct$: Observable<Product>;
    isOpen$ = new BehaviorSubject(false);

    constructor(private productService: ProductService) {}

    ngOnInit(): void {
        this.selectedProduct$ = this.productService.selectedProduct$;
    }

    open() {
        this.isOpen$.next(true);
    }

    close() {
        this.isOpen$.next(false);
    }
}
