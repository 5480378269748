import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Input } from '@angular/core';
import { ToastColor, ToastNotificationService } from '@services';

@Component({
    selector: 'app-share',
    templateUrl: './share.component.html',
    styleUrls: ['./share.component.css'],
})
export class ShareComponent {
    /** ---- REPLACING FUNCTIONALITY TO COPY THE LINK TO CLIPBOARD UNTIL WE SETTLE ON AND IMPLEMENT THE FULL SOCIAL SHARING EXPERIENCE FOR THE GKN. ----  */
    @Input() dataAnalyticsCopy: string; // share via copy (presume paste)
    linkCopied = false;
    @Input() link = '';

    constructor(private clipboard: Clipboard, private toast: ToastNotificationService) {}

    copyResourceLinkForSharing() {
        if (this.link.toLowerCase().includes('youtube.com')) {
            this.clipboard.copy(this.link.replace('embed/', 'watch?v='));
        } else {
            this.clipboard.copy(this.link);
        }
        this.toast.notify({
            innerHtml: `<strong>Link copied.</strong>`,
            color: ToastColor.INFO,
        });
        this.linkCopied = true;
    }

    /** ---- OLD FUNCTIONALITY TO SHARE VIA EMAIL AND COPY LINK TO CLIPBOARD. SAVING FOR NOW IN CASE WE NEED TO REVERT. ----  */
    // @Input() isCarousel = false;
    // documentBody = document.body;
    // modalOpen = false;
    // linkCopied = false;
    // isRightSide = false;
    // mailToLink = '';
    // body = 'Check out this helpful resource that I found on the Genesys Knowledge Network:';
    // subject = 'Shared from the Genesys Knowledge Network';

    // @Input() link = '';

    // /**
    //  * Custom css class(es) for the share icon. Applied to the <button> wrapping the <img>
    //  */
    // @Input() customIconCss: string;

    // /**
    //  * String identifiers for analytics services to track this tile, joined with hyphen in HTML
    //  */
    // @Input() dataAnalytics: string[];
    // dataAnalyticsOpen: string; // open share modal
    // dataAnalyticsEmail: string; // share via email
    // dataAnalyticsCopy: string; // share via copy (presume paste)
    // dataAnalyticsClose: string; // close share modal

    // ngOnInit(): void {
    //     this.dataAnalyticsOpen = [...(this.dataAnalytics || []), 'share'].join('-');
    //     this.dataAnalyticsEmail = [...(this.dataAnalytics || []), 'share', 'email'].join('-');
    //     this.dataAnalyticsCopy = [...(this.dataAnalytics || []), 'share', 'copy'].join('-');
    //     this.dataAnalyticsClose = [...(this.dataAnalytics || []), 'share', 'close'].join('-');
    // }

    // ngOnChanges(): void {
    //     this.mailToLink = `mailto:?subject=${encodeURIComponent(this.subject)}&body=${encodeURIComponent(
    //         this.body + ' ' + this.link,
    //     )}`;
    // }

    // // Close modal when escape key is pressed
    // @HostListener('window:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    //     if (event.key === 'Escape') {
    //         this.closeModal();
    //     }
    // }

    // //Close modal when user clicks outside of modal
    // @HostListener('window:mousedown', ['$event']) onClickHandler(event: MouseEvent) {
    //     if (this.modalOpen) {
    //         const clickElement = event.target as HTMLInputElement;
    //         if (!clickElement.closest('.share-modal')) {
    //             this.closeModal();
    //         }
    //     }
    // }

    // openModal(event) {
    //     if (this.isCarousel) {
    //         this.isRightSide = event?.clientX > window.innerWidth / 1.7;
    //     } else {
    //         this.isRightSide = event?.clientX > window.innerWidth / 2;
    //     }
    //     this.modalOpen = true;
    //     this.linkCopied = false;
    // }

    // closeModal() {
    //     this.modalOpen = false;
    // }

    // copyText() {
    //     const copyText = document.getElementById('shareText') as HTMLInputElement;

    //     // Select the text field
    //     copyText.select();
    //     copyText.setSelectionRange(0, 99999); // For mobile devices

    //     // Copy the text inside the text field
    //     navigator.clipboard.writeText(copyText.value);
    //     this.linkCopied = true;
    // }
}
