<app-section-header
    [title]="sectionLabel"
    [subTitle]="sectionSubTitle"
    [favoriteId]="favoriteId"
    [section]="sectionLocation"
    [externalUrl]="externalUrl"></app-section-header>

<app-tile-layout [maxColumns]="isMobileViewport ? 2 : 4" [multiColumnMobile]="true">
    <li *ngFor="let tile of tiles">
        <mat-card
            class="mat-elevation-z0"
            [ngClass]="{
                desktop: !isMobileViewport,
                mobile: isMobileViewport
            }">
            <img mat-card-image [src]="tile.imageUrl" know />
            <mat-card-title class="ellipsis-2 sub-title-3 text-brand-navy title-min-height">
                {{ tile.title }}
            </mat-card-title>
            <mat-card-content>
                <p *desktop class="ellipsis-3 p-body">{{ tile.description }}</p>
                <div *mobile>
                    <ng-container *ngTemplateOutlet="actions"></ng-container>
                </div>
            </mat-card-content>
            <mat-card-actions *desktop>
                <ng-container *ngTemplateOutlet="actions"></ng-container>
            </mat-card-actions>

            <ng-template #actions>
                <div class="external-link-div margin-top-smaller footer-min-height">
                    <a class="cursor link-n read-more" [href]="tile?.url" target="_blank" rel="nofollow">
                        Learn more
                    </a>
                    <app-external-url [url]="tile?.url"></app-external-url>
                </div>
            </ng-template>
        </mat-card>
        <!-- <app-tile-vertical
            [imageUrl]="tile.imageUrl"
            [titleLink]="true"
            [title]="tile.title"
            [description]="isMobileViewport ? null : tile.description"
            [url]="tile.url"
            [isCustomFooterLink]="true">
            <div class="external-link-div margin-top-smaller footer-min-height" customFooterLink>
                <a class="cursor link-n read-more" [href]="tile?.url" target="_blank" rel="nofollow"> Learn more </a>
                <app-external-url [url]="tile?.url"></app-external-url>
            </div>
        </app-tile-vertical> -->
    </li>
</app-tile-layout>
