<div
    *ngIf="currentAlert"
    class="alert"
    [ngClass]="{
        outage: currentAlert.color == alertTypes.OUTAGE,
        release: currentAlert.color == alertTypes.RELEASE,
        maintenance: currentAlert.color == alertTypes.MAINTENANCE
    }">
    <app-announcement-alert
        [fillColor]="fillColor"
        *ngIf="currentAlert.color == alertTypes.ANNOUNCEMENT"></app-announcement-alert>
    <app-maintenance-alert *ngIf="currentAlert.color == alertTypes.MAINTENANCE"></app-maintenance-alert>
    <app-outage-alert [fillColor]="fillColor" *ngIf="currentAlert.color == alertTypes.OUTAGE"></app-outage-alert>
    <app-release-alert [fillColor]="fillColor" *ngIf="currentAlert.color == alertTypes.RELEASE"></app-release-alert>

    <span class="alert-text" [innerHTML]="currentAlert.rendered | safe: 'html'"></span>
    <button aria-label="Close button icon" class="close-button" (click)="dismissAlert(currentAlert.id)">
        <app-close [fillColor]="fillColor"></app-close>
    </button>
</div>
