import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ProductUid } from '@enums';
import { ProductQueryParamService, ProductService } from '@services';
import { Observable, Subscription } from 'rxjs';
import { Product } from 'src/app/@shared/services/product';

export type HtmlSelectOnChangeEvent = Event & { target?: HTMLSelectElement & { value?: ProductUid | unknown } };

@Component({
    selector: 'app-product-select',
    templateUrl: './product-select.component.html',
    styleUrls: ['./product-select.component.css'],
})
export class ProductSelectComponent implements OnInit, OnDestroy {
    @Output() closeMenu = new EventEmitter<void>();
    availableProducts: Product[];
    selectedProduct$: Observable<Product>;
    isArchivesPage = false;
    selectedProductUId: ProductUid;
    private _subs: Subscription[] = [];

    constructor(private productService: ProductService, private productQueryParamService: ProductQueryParamService) {
        this.availableProducts = this.productService.availableProducts;
    }

    ngOnInit(): void {
        this.selectedProduct$ = this.productService.selectedProduct$;
        this._subs.push(
            this.selectedProduct$.subscribe((data) => {
                // NOTE: We have to do this to make the ng-select binding to work
                // cannot do the | async binding to the ngModel
                this.selectedProductUId = data.uid;
            }),
        );
    }

    /**
     * Handles UI changes to the selected product.
     *
     * @param event product chosen via ng-select custom dropdown
     */
    handleSelectedProduct(event: Product): void {
        const selectedUid = event.uid;
        if (selectedUid) {
            this.productQueryParamService.setProductQueryParam(selectedUid);
        }
    }

    /**
     * Handle menu close
     */
    handleClosingMenu(): void {
        this.closeMenu.emit();
    }

    /**
     * Add Track By Product to not redraw or
     * re-render list items they have not changed. This improves performance.
     * NOTE: This is using the [trackByFn] function binding to ng-select not the Angular trackBy,
     * so it cannot use our proprietary {@link TrackByItem} API.
     * @param item - Product
     * @returns - Product Uid
     */
    trackByProductUid(item: Product): string {
        return item.uid;
    }

    /**
     * On Destroy, unsubscribe subscriptions to prevent memory leaks
     */
    ngOnDestroy(): void {
        if (this._subs) {
            this._subs.forEach((sub) => {
                sub.unsubscribe();
            });
        }
    }
}
