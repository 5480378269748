import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-roadmaps-wysiwyg',
    templateUrl: './roadmaps-wysiwyg.component.html',
    styleUrls: ['./roadmaps-wysiwyg.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class RoadmapsWysiwygComponent {
    @Input() roadmaps: string;
}
