import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { ComponentsModule } from '@components';
import { DirectivesModule } from '@directives';
import { GetStartedComponent } from './get-started.component';
import { OnboardingWebinarsModule } from './onboarding-webinars/onboarding-webinars.module';
import { ShortTracksModule } from './short-tracks/short-tracks.module';
import { StarterKitModule } from './starter-kit/starter-kit.module';
import { TipsAndToolsModule } from './tips-and-tools/tips-and-tools.module';
import { UsefulLinksModule } from './useful-links/useful-links.module';
import { WelcomeVideoModule } from './welcome-video/welcome-video.module';

@NgModule({
    imports: [
        CommonModule,
        ComponentsModule,
        DirectivesModule,
        OnboardingWebinarsModule,
        ShortTracksModule,
        TipsAndToolsModule,
        UsefulLinksModule,
        StarterKitModule,
        WelcomeVideoModule,
        MatTabsModule,
    ],
    declarations: [GetStartedComponent],
    exports: [GetStartedComponent],
})
export class GetStartedModule {}
