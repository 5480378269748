import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-maintenance-alert',
    templateUrl: './maintenance-alert.component.html',
    styleUrls: ['./maintenance-alert.component.css'],
})
export class MaintenanceAlertComponent {
    @Input() fillColor = 'var(--genesys-csdt-brand-navy)';
}
