import { Component, OnInit } from '@angular/core';
import { TrackByItem } from '@components';
import {
    ContentService,
    GknCxEvolution,
    GknCxEvolutionCard,
    GknCxEvolutionCardTab,
    GknCxEvolutionCommunity,
    GknCxEvolutionExcerpt,
} from '@services';
import { Observable, map } from 'rxjs';

@Component({
    selector: 'app-cx-evolution-connect',
    templateUrl: './cx-evolution-connect.component.html',
    styleUrls: ['./cx-evolution-connect.component.css'],
})
export class CxEvolutionConnectComponent implements OnInit, TrackByItem<GknCxEvolutionCard> {
    excerpt$: Observable<GknCxEvolutionExcerpt>;
    cards$: Observable<GknCxEvolutionCard[]>;
    communities$: Observable<GknCxEvolutionCommunity[]>;

    constructor(private contentService: ContentService) {}

    ngOnInit(): void {
        this.cards$ = this.contentService.cxEvolution$.pipe(
            map((cxEvolution: GknCxEvolution) => {
                return (
                    cxEvolution?.cards?.filter((card) => {
                        return card.tab == GknCxEvolutionCardTab.CONNECT;
                    }) || []
                );
            }),
        );
    }

    trackByItem(_index: number, item: GknCxEvolutionCard): NonNullable<string | number> {
        return item.id;
    }
}
