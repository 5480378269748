import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FeaturedContentTextOnlyComponent } from './featured-content-text-only.component';

@NgModule({
    declarations: [FeaturedContentTextOnlyComponent],
    imports: [CommonModule],
    exports: [FeaturedContentTextOnlyComponent],
})
export class FeaturedContentTextOnlyModule {}
