import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FavoriteId, HomeSectionLabel, NewsFromGenesysFilterLabel, NewsLabel, NewsOrigin } from '@enums';
import {
    ContentService,
    FacebookPost,
    FavoritesService,
    KeplerFacebookResponse,
    KeplerFacebookService,
    MarketingWpItem,
    MarketingWpService,
    ProductService,
} from '@services';
import { sortByDate, stripShortCodes, trimString } from '@utils';
import { Observable, Subscription, map, of } from 'rxjs';

import { TrackByItem } from '@components';
import { GknNewsFeed } from '@services';
import { SECTION_LINKS } from 'src/app/app-header/submenu-data';

@Component({
    selector: 'app-news',
    templateUrl: './news.component.html',
    styleUrls: ['./news.component.css'],
})
export class NewsComponent implements OnInit, OnDestroy, TrackByItem<GknNewsFeed> {
    private subs: Subscription[] = [];
    sectionLabel = HomeSectionLabel.NEWS_FROM_GENESYS;
    favoriteId = FavoriteId.NEWS_FROM_GENESYS_ALL;
    isMobile$: Observable<boolean> = of(false);
    feeds: GknNewsFeed[] = [];
    filteredFeed: GknNewsFeed[] = [];
    filterLabels = NewsFromGenesysFilterLabel;
    selectedUrl: string;
    title: string;
    LoadsInFrame: boolean;
    iFrameableUrls = ['https://www.genesys.com', 'https://help.mypurecloud.com', 'https://docs.genesys.com'];
    maxItemsToLoad = 8;
    showReadMoreButton = true;
    filterPrefix = SECTION_LINKS.NEWS_FROM_GENESYS;
    facebookOrigin = NewsOrigin.FACEBOOK;
    anchors: string[];

    filters: FavoriteId[] = [
        FavoriteId.NEWS_FROM_GENESYS_ALL,
        FavoriteId.NEWS_FROM_GENESYS_ANNOUNCEMENTS,
        FavoriteId.NEWS_FROM_GENESYS_BLOG,
        FavoriteId.NEWS_FROM_GENESYS_SOCIAL,
    ];

    constructor(
        private breakpointObserver: BreakpointObserver,
        private changeDetector: ChangeDetectorRef,
        private contentService: ContentService,
        private marketingWpService: MarketingWpService,
        private keplerFacebookService: KeplerFacebookService,
        private productService: ProductService,
        private favoritesService: FavoritesService,
    ) {
        this.anchors = [
            this.favoritesService.buildFragmentFromFavorite(FavoriteId.NEWS_FROM_GENESYS_ALL),
            this.favoritesService.buildFragmentFromFavorite(FavoriteId.NEWS_FROM_GENESYS_ANNOUNCEMENTS),
            this.favoritesService.buildFragmentFromFavorite(FavoriteId.NEWS_FROM_GENESYS_BLOG),
            this.favoritesService.buildFragmentFromFavorite(FavoriteId.NEWS_FROM_GENESYS_SOCIAL),
        ];
    }

    ngOnInit(): void {
        this.isMobile$ = this.breakpointObserver
            .observe('(max-width: 1020px)')
            .pipe(map((state: BreakpointState) => state.matches));

        if (this.breakpointObserver.isMatched('(max-width: 1020px)')) {
            this.maxItemsToLoad = 4;
        } else {
            this.maxItemsToLoad = 8;
        }

        this.changeDetector.markForCheck();
        this.subscribeProductAnnouncements();
        this.subscribeBlogs();
        this.subscribeFacebook();
    }

    /**
     * Subscribe Product Announcements
     * Note that Product Announcements change on product change.
     */
    private subscribeProductAnnouncements(): void {
        this.subs.push(
            this.contentService.productAnnouncements$.subscribe((feeds: GknNewsFeed[]) => {
                // Keep the non-announcement items (blogs and social posts) and the announcement items belonging to the current product in the list.
                this.feeds = this.feeds?.filter((item) => {
                    return item?.Origin !== NewsOrigin.ANNOUNCEMENT;
                });

                feeds.forEach((feed) => {
                    feed.LoadsInFrame = this.sourceCanLoadInIframe(feed.Url);

                    // Only add this feed item to the list if this.feeds does not already contain it.
                    if (!this.feeds.some((currentFeedItem) => currentFeedItem.Id === feed.Id)) {
                        this.feeds.push(feed);
                    }
                });

                sortByDate(this.feeds, NewsLabel.PUBLISHED);
                this.filterFeed();
                this.changeDetector.markForCheck();
            }),
        );
    }

    /**
     * Subscribe to Genesys Blogs.
     * Note that Blogs do not change on product change.
     */
    private subscribeBlogs(): void {
        this.subs.push(
            this.marketingWpService.blogs$.subscribe((blogs: MarketingWpItem[]) => {
                blogs?.forEach((blog) => {
                    const feed: GknNewsFeed = {
                        Id: blog.id.toString(),
                        Url: blog.link,
                        Origin: NewsOrigin.BLOG,
                        OriginUrl: 'https://www.genesys.com/blog',
                        Content: stripShortCodes(trimString(blog.content?.rendered)) || '',
                        Published: new Date(blog.date),
                        Title: blog.title?.rendered || '',
                        LoadsInFrame: this.sourceCanLoadInIframe(blog.link),
                        Image:
                            blog._embedded['wp:featuredmedia'][0]?.source_url ||
                            '/assets/icons/blog/content-tags-blog-medium.svg',
                        hasSVGIcon: true,
                        dataAnalytics: ['news', 'blog'],
                    };
                    // Only add this feed item to the list if this.feeds does not already contain it.
                    if (!this.feeds.some((currentFeedItem) => currentFeedItem.Id === feed.Id)) {
                        this.feeds.push(feed);
                    }
                });

                sortByDate(this.feeds, NewsLabel.PUBLISHED);
                this.filterFeed();
                this.changeDetector.markForCheck();
            }),
        );
    }

    /**
     * Check if URL source can be loaded within Iframe
     * @param url - Source URL
     * @return - boolean can load in iframe
     */
    private sourceCanLoadInIframe(url: string): boolean {
        if (!url) return false;
        return this.iFrameableUrls.some((iFrameUrl) => {
            return url.startsWith(iFrameUrl);
        });
    }

    /**
     * Subscribe Facebook feed
     * Note that Facebook posts do not change on product change.
     */
    private subscribeFacebook(): void {
        this.subs.push(
            this.keplerFacebookService
                .getPosts()
                .pipe(map((res: KeplerFacebookResponse) => res.data))
                .subscribe((posts: FacebookPost[]) => {
                    posts?.forEach((post: FacebookPost) => {
                        if (post?.message) {
                            const feed: GknNewsFeed = {
                                Id: post?.id,
                                Url: post?.permalink_url,
                                Origin: NewsOrigin.FACEBOOK,
                                OriginUrl: 'https://www.facebook.com/Genesys/',
                                Content: post?.message,
                                Published: post?.created_time,
                                Image: post?.full_picture,
                                hasSVGIcon: post?.full_picture ? false : true,
                            };

                            // Only add this feed item to the list if this.feeds does not already contain it.
                            if (!this.feeds.some((currentFeedItem) => currentFeedItem.Id === feed.Id)) {
                                this.feeds.push(feed);
                            }
                        }
                    });

                    sortByDate(this.feeds, NewsLabel.PUBLISHED);
                    this.filterFeed();
                    this.changeDetector.markForCheck();
                }),
        );
    }

    /**
     * Toggles the Read more button
     */
    toggleReadMoreButton(): void {
        if (this.filteredFeed.length < this.maxItemsToLoad) {
            this.showReadMoreButton = false;
        } else {
            if (this.maxItemsToLoad > 8) {
                if (this.favoriteId == FavoriteId.NEWS_FROM_GENESYS_ANNOUNCEMENTS) {
                    this.showReadMoreButton = true;
                } else {
                    this.showReadMoreButton = false;
                }
            } else {
                this.showReadMoreButton = true;
            }
        }
    }

    /**
     * Loads more tiles
     */
    loadMore(): void {
        this.maxItemsToLoad += 4;
        this.filterFeed();
    }

    filterUpdated(favoriteId: FavoriteId) {
        this.favoriteId = favoriteId;
        this.filterFeed();
    }

    /**
     * Takes items from the feeds array and adds them to the filteredFeeds array,
     * it adds them based on their origin
     */
    filterFeed(): void {
        this.filteredFeed = [];
        this.feeds.forEach((feed: GknNewsFeed) => {
            if (this.filteredFeed.length < this.maxItemsToLoad) {
                this.appendToFilteredFeed(this.favoriteId, feed);
            } else if (this.favoriteId == FavoriteId.NEWS_FROM_GENESYS_ALL) {
                // If the user is on the All filter and the filtered feed has 8 or more items, continue to add, sort, and remove
                // the last item from the array.This ensures one label does not take up all 8 spots automatically and we get the
                // most recent content across all resources.
                this.appendToFilteredFeed(this.favoriteId, feed, true);
            }
        });
        this.toggleReadMoreButton();
    }

    appendToFilteredFeed(favoriteId: FavoriteId, feed: GknNewsFeed, sortFilteredFed = false): void {
        switch (favoriteId) {
            case FavoriteId.NEWS_FROM_GENESYS_ALL:
                this.filteredFeed.push(feed);
                if (sortFilteredFed) {
                    sortByDate(this.filteredFeed, NewsLabel.PUBLISHED);
                    this.filteredFeed = this.filteredFeed.slice(0, -1); // Remove the last items.
                    this.changeDetector.markForCheck();
                }
                break;
            case FavoriteId.NEWS_FROM_GENESYS_ANNOUNCEMENTS:
                if (feed.Origin === NewsOrigin.ANNOUNCEMENT) {
                    this.filteredFeed.push(feed);
                }
                break;
            case FavoriteId.NEWS_FROM_GENESYS_BLOG:
                if (feed.Origin === NewsOrigin.BLOG) {
                    this.filteredFeed.push(feed);
                }
                break;
            case FavoriteId.NEWS_FROM_GENESYS_SOCIAL:
                if (feed.Origin === NewsOrigin.FACEBOOK) {
                    this.filteredFeed.push(feed);
                }
                break;
            default:
                break;
        }
    }

    /**
     * Open Article modal
     * @param feed - Feed to be open in modal
     */
    openArticle(feed: GknNewsFeed): void {
        if (feed.LoadsInFrame) {
            this.selectedUrl = feed.Url;
            this.title = feed.Title;
        } else {
            this.selectedUrl = undefined;
            this.title = undefined;
        }
    }

    /**
     * Close Article modal
     */
    closeArticle(): void {
        this.selectedUrl = undefined;
    }

    /**
     * On Destroy, unsubscribe subscriptions to prevent memory leaks
     */
    ngOnDestroy(): void {
        if (this.subs) {
            this.subs.forEach((sub) => {
                sub.unsubscribe();
            });
        }
    }

    trackByItem(_index: number, item: GknNewsFeed): string {
        return item.Id;
    }

    /**
     * Formats a string to use for setting a News data-analytics attribute.
     * @param dataAnalytics specific tags for a News
     * @returns formatted data-analytics attribute value
     */
    getTileDataAnalytics(dataAnalytics: string[]): string {
        return [...(dataAnalytics || []), 'tile', 'link'].join('-');
    }

    /**
     * Formats a string to use for setting a News data-analytics attribute.
     * @param dataAnalytics specific tags for a News
     * @returns formatted data-analytics attribute value
     */
    getTitleDataAnalytics(dataAnalytics: string[]): string {
        return [...(dataAnalytics || []), 'title', 'link'].join('-');
    }

    /**
     * Formats a string to use for setting a News data-analytics attribute.
     * @param dataAnalytics specific tags for a News
     * @returns formatted data-analytics attribute value
     */
    getReadMoreDataAnalytics(dataAnalytics: string[]): string {
        return [...(dataAnalytics || []), 'read-more', 'link'].join('-');
    }

    isCustomGenesysBlogImageUrl(feed: GknNewsFeed): boolean {
        return !feed.Image?.includes('/assets/icons/blog/content-tags-blog-medium.svg');
    }
}
