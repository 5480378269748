import { Html } from '../html/html.interface';

/**
 * Properties for a {@link Typography}
 */
export interface TypographyProps {
    text?: string;
    html?: string;
    id?: string;
    color?: string;
    fontFamily?: string;
    fontSize?: string;
    fontStyle?: string;
    fontWeight?: string;
    width?: string;
    margin?: string;
    padding?: string;
    textAlign?: string;
    lineHeight?: string;
    className?: string;
}

/**
 * Properties for a {@link TypographyLink}
 */
export interface TypographyLinkProps extends TypographyProps {
    href: string;
}

/**
 * Typography component for text
 *
 * @param props properties for this text
 * @returns an {@link HTMLElement}
 */
export class Typography implements Html {
    constructor(private props: TypographyProps) {}

    html(): HTMLElement {
        const text: HTMLElement = document.createElement('p');
        apply(text, this.props);
        text.style.color = this.props.color || 'black';
        return text;
    }
}

/**
 * Typography component for button
 *
 * @param props properties for this text
 * @returns an {@link HTMLElement}
 */
export class TypographyButton implements Html {
    constructor(private props: TypographyProps) {}

    html(): HTMLElement {
        const button: HTMLElement = document.createElement('button');
        apply(button, this.props);
        button.style.color = this.props.color || 'white';
        button.style.alignItems = 'center';
        return button;
    }
}

/**
 * Typography component for an anchor link with an href. Opens the link in a new tab
 *
 * @param props properties for this link
 * @returns an {@link HTMLAnchorElement}
 */
export class TypographyLink {
    constructor(private props: TypographyLinkProps) {}

    html(): HTMLAnchorElement {
        const link: HTMLAnchorElement = document.createElement('a');
        apply(link, this.props);
        link.href = this.props.href;
        link.target = '_blank';
        link.rel = 'noopener noreferrer';
        return link;
    }
}

/**
 * Typography component for text containing an anchor link with an href. Opens the link in a new tab.
 *
 * @param props properties for this link
 * @returns an {@link HTMLAnchorElement}
 */
export class TypographyWithLink implements Html {
    constructor(
        private paragraphProps: TypographyProps,
        private anchorProps: TypographyLinkProps,
        private appendPeriod: boolean = true,
    ) {}

    html(): HTMLElement {
        const link: HTMLAnchorElement = new TypographyLink(this.anchorProps).html();
        const paragraph = new Typography(this.paragraphProps).html();
        paragraph.appendChild(link);
        this.appendPeriod ? (paragraph.innerHTML += '.') : '';
        return paragraph;
    }
}

export class TypographySurroundingLink implements Html {
    constructor(
        private props: {
            leadingTypography: TypographyProps;
            link: TypographyLinkProps;
            trailingTypography: TypographyProps;
        },
    ) {}

    html(): HTMLElement {
        const leading = new Typography(this.props.leadingTypography).html();
        const link: HTMLAnchorElement = new TypographyLink(this.props.link).html();
        const trailing = new Typography(this.props.trailingTypography).html();

        leading.innerHTML += ' '; // Space before the link
        leading.appendChild(link);
        leading.innerHTML += '. '; // Period and space after the link
        leading.innerHTML += trailing.innerHTML; // Don't append a child <p> tag, just use the inner HTML
        return leading;
    }
}

/**
 * Applies the given props to the element
 *
 * @param props to apply
 * @param elem the {@link HTMLElement} to style and update
 */
function apply(elem: HTMLElement, props: TypographyProps): void {
    if (props.html) {
        elem.innerHTML = props.html;
    } else if (props.text) {
        elem.innerText = props.text;
    }

    elem.id = props.id || '';
    elem.className = props.className || '';
    elem.style.fontFamily = props.fontFamily || 'Roboto';
    elem.style.fontSize = props.fontSize || '';
    elem.style.fontStyle = props.fontStyle || '';
    elem.style.fontWeight = props.fontWeight || '';
    elem.style.width = props.width || '';
    elem.style.margin = props.margin || '';
    elem.style.padding = props.padding || '';
    elem.style.textAlign = props.textAlign || '';
    elem.style.lineHeight = props.lineHeight || '';
}
