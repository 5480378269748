<header mat-dialog-title>
    <div class="title">
        <h2 class="h3">{{ data.title }}</h2>
        <h3 class="sub-title-4 text-secondary-gray-1" *ngIf="data.subtitle">{{ data.subtitle }}</h3>
    </div>
    <div class="actions">
        <button
            mat-icon-button
            mat-dialog-close
            [color]="colors.close"
            (mouseover)="colors.close = 'accent'"
            (mouseout)="colors.close = 'primary'"
            aria-label="Close"
            [attr.data-analytics]="data.dataAnalytics.join('-')">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</header>
<mat-dialog-content #dialogContent>
    <mat-divider></mat-divider>
    <app-vimeo-player [videoId]="data.videoId" [dialog]="true" [width]="800"></app-vimeo-player>
</mat-dialog-content>
