<div class="developer-center-container">
    <app-section-header
        [title]="sectionLabel"
        [externalUrl]="externalUrl"
        [section]="filterPrefix"
        [favoriteId]="favoriteId"
        [anchors]="anchors"></app-section-header>

    <mat-tab-group (currentlySelectedEvent)="filterUpdated($event)" [prefix]="filterPrefix" appFilterLocation>
        <mat-tab [label]="filterState.tools.label">
            <app-tools></app-tools>
        </mat-tab>
        <mat-tab [label]="filterState.popularTopics.label">
            <app-popular-topics></app-popular-topics>
        </mat-tab>
        <mat-tab [label]="filterState.blog.label">
            <app-blog></app-blog>
        </mat-tab>
    </mat-tab-group>
</div>
