/**
 * Enumerates routes that categorize GKN pages.
 *
 * These map 1:1 to Know CMS (where editing is disabled) to
 * allow content filtering against routes with some compile
 * time type safety. Do not change the string values without
 * also updating Know CMS.
 */
export enum GknRouteLabel {
    HOME = 'Home',
    COMMUNITY = 'Community',
    GET_INVOLVED = 'Get Involved',
    EDUCATION_AND_TRAINING = 'Education & Training',
    RESOURCES = 'Resources',
    SUPPORT = 'Support',
    GKN_STORIES = 'GKN Stories',
}

/**
 * Enumerates website routes (pages).
 *
 * These map 1:1 to GKN CMS > ACF > Field Groups > Pages to
 * allow content filtering against pages with some compile
 * time type safety. Do not change the string values without
 * also updating GKN CMS and Brain Tab!
 *
 * Entries are purposefully alphabetical to allow for deterministic
 * usage of functions like Object.values()
 */
export enum GknRoutePath {
    COMMUNITY = 'community',
    EDUCATION_AND_TRAINING = 'education-and-training',
    GET_INVOLVED = 'get-involved',
    GKN_STORIES = 'gkn-stories',
    HOME = 'home',
    HOME_ARCHIVES = 'home/archives',
    LOGIN = 'login',
    LOGIN_CALLBACK = 'login/callback',
    PROFILE = 'profile',
    RESOURCES = 'resources',
    SUPPORT = 'support',
    SEARCH = 'search',
}

/**
 * Keys for Know 2.0 router config object
 */
export enum GknRouteConfigKey {
    COMMUNITY = 'community',
    EDU_TRAINING = 'educationAndTraining',
    GET_INVOLVED = 'getInvolved',
    GKN_STORIES = 'gkn-stories',
    HOME = 'home',
    HOME_ARCHIVES = 'archives',
    LOGIN = 'login',
    LOGIN_CALLBACK = 'loginCallback',
    PROFILE = 'profile',
    RESOURCES = 'resources',
    SUPPORT = 'support',
    SEARCH = 'search',
}

/**
 * Keys for GKN 1.0 legacy router config object.
 * We need to retain from legacy GKN 1.0 for legacy bookmarking support to Know 2.0 routes
 */
export enum GknRouteConfigLegacyKey {
    AccountMerge = 'AccountMerge',
    BeyondRegistration = 'BeyondRegistration',
    KnowCommunity = 'KnowCommunity',
    KnowCommunityLogin = 'knowCommunityLogin',
    KnowCommunityPureCloudH = 'KnowCommunityPureCloudH',
    KnowGenesysCloudHome = 'KnowGenesysCloudHome',
    KnowGenesysEngageHome = 'KnowGenesysEngageHome',
    KnowGenesysEngageOnCloudHome = 'KnowGenesysEngageOnCloudHome',
    KnowHome = 'KnowHome',
    KnowPureCloudHome = 'KnowPureCloudHome',
    KnowPureConnectHome = 'KnowPureConnectHome',
    KnowPureEngageCloudHome = 'KnowPureEngageCloudHome',
    KnowPureEngageHome = 'KnowPureEngageHome',
    KnowUserProfile = 'KnowUserProfile',
    KnowUserPureCloudProfile = 'KnowUserPureCloudProfile',
    KnowUserPureConnectProfile = 'KnowUserPureConnectProfile',
}
