import { Component, Input } from '@angular/core';
import { GknCxEvolutionCard } from '@services';

@Component({
    selector: 'app-cx-evolution-card',
    templateUrl: './cx-evolution-card.component.html',
    styleUrls: ['./cx-evolution-card.component.css'],
})
export class CxEvolutionCardComponent {
    @Input() card: GknCxEvolutionCard;
}
