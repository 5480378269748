import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ComponentsModule } from '@components';
import { PipesModule } from '@pipes';
import { BeyondExternalContentComponent } from './beyond-external-content.component';

@NgModule({
    declarations: [BeyondExternalContentComponent],
    imports: [CommonModule, ComponentsModule, PipesModule],
    exports: [BeyondExternalContentComponent],
})
export class BeyondExternalContentModule {}
