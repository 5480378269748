import { KeplerPurecloudUser } from '@services';
import { BookmarkKnowOverlay, BookmarkOneGenesysOverlay, CommunityOverlay, ResourcesOverlay } from '..';
import {
    Overlay,
    OverlayHtmlElement,
    Typography,
    TypographyButton,
    TypographyLink,
    TypographyWithLink,
} from '../../components';
import { ElearningCoursesService } from '../../services/elearning-courses.service';

interface EndSummaryOverlayProps {
    id: string;
    user: KeplerPurecloudUser;
}

export class EndSummaryOverlay extends Overlay {
    constructor(private props: EndSummaryOverlayProps) {
        super(props.id, { animateInClass: 'fadeInLeftBig', animateOutClass: 'fadeOutRightBig' });
    }

    private width = document.getElementById('welcome-video-container').offsetWidth;

    html(): HTMLElement {
        const content = document.createElement('div');
        content.className = 'overlay-content';

        const firstName = this.props.user.name.split(' ')[0];
        const heading = new Typography({
            id: 'summary-title',
            text: `We hope you loved your personalized video, ${firstName}!`,
            fontFamily: 'springwood_brush',
            fontSize: this.width * 0.04 + 'px',
            lineHeight: '0',
        }).html();
        content.appendChild(heading);

        const listContainer = document.createElement('div');
        listContainer.className = 'list-container';

        const listHeader = new Typography({
            text: 'All links can be accessed here:',
            fontSize: this.width * 0.02 + 'px',
            lineHeight: '0',
            textAlign: 'center',
        }).html();
        listContainer.appendChild(listHeader);

        const list = document.createElement('ul');
        const links = this.getSummaryTypographyLinks();
        for (const link of links) {
            const item = document.createElement('li');
            item.style.fontSize = this.width * 0.017 + 'px';
            item.appendChild(link);
            list.appendChild(item);
        }
        listContainer.appendChild(list);
        content.appendChild(listContainer);

        const email = 'knowledgenetwork@genesys.com';
        content.appendChild(
            new TypographyWithLink(
                {
                    text: 'Stay in touch with us at ',
                    fontSize: this.width * 0.02 + 'px',
                    textAlign: 'center',
                },
                {
                    text: email,
                    href: `mailto:${email}`,
                    fontSize: this.width * 0.02 + 'px',
                },
                false,
            ).html(),
        );

        const actionContainer = document.createElement('div');
        actionContainer.className = 'action-container';
        content.appendChild(actionContainer);

        const icon = document.createElement('span');

        const replayButton = new TypographyButton({
            text: 'Replay',
            id: 'replay-button',
            className: 'btn-animated',
        }).html();
        replayButton.prepend(icon);
        actionContainer.appendChild(replayButton);

        const overlay = new OverlayHtmlElement().buildHtml({
            id: this.props.id,
            childNodes: [content],
            hidden: true,
            width: '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '0',
            left: '0',
            opacity: '1',
        });
        return overlay;
    }

    private getSummaryTypographyLinks(): HTMLElement[] {
        const links: HTMLElement[] = [];
        links.push(
            new TypographyLink({
                href: BookmarkKnowOverlay.KNOW_LINK,
                text: BookmarkKnowOverlay.KNOW_TEXT,
            }).html(),
        );
        links.push(
            new TypographyLink({
                href: CommunityOverlay.COMMUNITY_LINK,
                text: CommunityOverlay.COMMUNITY_TEXT,
            }).html(),
        );
        links.push(
            new TypographyLink({
                href: ResourcesOverlay.CSS_ONBOARDING_LINK,
                text: 'CSS onboarding',
            }).html(),
        );
        links.push(
            new TypographyLink({
                href: BookmarkOneGenesysOverlay.ONE_GENESYS_LINK,
                text: BookmarkOneGenesysOverlay.ONE_GENESYS_TEXT,
            }).html(),
        );
        links.push(
            new TypographyLink({
                href: ElearningCoursesService.STATIC_COURSE.href,
                text: ElearningCoursesService.STATIC_COURSE.title,
            }).html(),
        );
        return links;
    }
}
