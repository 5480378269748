/**
 * Returns trimmed string - remove any wordpress tags, html tags, etc...
 * @param str - string to be trimmed
 * @returns - trimmed string replaced by regex
 */
export function trimString(str: string): string {
    return str
        .replace(/<\/?p>/g, ' ')
        .replace(/&nbsp;/g, '')
        .replace(/\s{2,}/g, ' ')
        .replace(/<[^>]*>/g, '')
        .trim();
}
