import { Pipe, PipeTransform } from '@angular/core';
import { KeplerCoveoSuggestion } from '@services';

@Pipe({
    name: 'suggestionMask',
})
export class SearchSuggestionsPipe implements PipeTransform {
    /**
     * Transforms the given string into HTML with
     * bold text that pertains to the search term.
     *
     * For example, searching "ca" yields a result like "[how] [to] {ca}[ll] [other] [people] (clea)[ly]"
     *
     * Text in {} and () is made bold. Text in [] is left normal.
     *
     * @param suggestion search suggestion value
     * @returns
     */
    transform(suggestion: KeplerCoveoSuggestion): string {
        return (
            suggestion.highlighted
                ?.replace(/\[/g, '')
                .replace(/]/g, '')
                .replace(/\(/g, '<b>')
                .replace(/\)/g, '</b>')
                .replace(/{/g, '<b>')
                .replace(/}/g, '</b>') || suggestion.expression
        );
    }
}
