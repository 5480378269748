/*
 * Returns boolean if the current month is June for Pride Month
 * @returns boolean
 */
export function isPrideMonth(): boolean {
    const june = 5; // Months are from 0-11
    const date = new Date();
    const month = date.getMonth();
    return month == june;
}
