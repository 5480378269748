import { OperatorFunction, map } from 'rxjs';
import { GknDuration } from '../../types/gkn-duration.interface';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export interface GknDurationMutable {
    [key: string]: any;
    duration?: GknDuration;
}

/**
 * Maps a list of objects containing durations to include a rendered version of each underlying duration
 *
 * - only one of two dates.....Jun 11, 2024
 * - duration in same month....Jun 11 - 25, 2024
 * - duration in same year.....Jun 11 - Aug 2, 2024
 * - duration multiyear........Jun 11, 2024 - Feb 8, 2025
 *
 * @example
 * a = [{ foo: 'bar', duration: { startDate: daysjs('1970-01-01') } }]
 * b = of(a).pipe(mapDatesToGknDurations())
 * // b = [{ foo: 'bar', duration: { startDate: <Dayjs>, rendered: 'Jan 1, 1970' }}]
 *
 * @returns the given array containing additional underlying properties
 */

const formatDate = (dayjsDate: dayjs.Dayjs) => dayjsDate.format('MMM D, YYYY');
const formatTime = (dayjsDate: dayjs.Dayjs) => dayjsDate.format('h:mm A');

export function mapDatesToGknDurations<T extends GknDurationMutable>(
    format: 'date' | 'time' | 'datetime' = 'date',
): OperatorFunction<T[], T[]> {
    return map((mutables: T[]): T[] => {
        return mutables.map((m) => {
            const timeZone = m?.duration?.timezone || 'EST';
            const startDayjs = m?.duration?.startDate ?? null;
            const endDayjs = m?.duration?.endDate ?? null;

            let rendered: string;

            if (startDayjs) {
                if (!endDayjs || startDayjs.isSame(endDayjs, 'day')) {
                    // Single-day event
                    rendered = formatSingleDayEvent(startDayjs, format, timeZone);
                } else if (startDayjs.year() === endDayjs?.year()) {
                    rendered = formatMultiDayEventSameYear(startDayjs, endDayjs, format, timeZone);
                } else {
                    rendered = formatMultiDayEventDifferentYear(startDayjs, endDayjs, format, timeZone);
                }
            } else if (endDayjs) {
                if (format === 'datetime') {
                    rendered = `${formatDate(endDayjs)} | ${formatTime(endDayjs)} ${timeZone}`;
                } else if (format === 'time') {
                    rendered = `${formatTime(endDayjs)} ${timeZone}`;
                } else {
                    rendered = formatDate(endDayjs);
                }
            } else {
                rendered = '';
            }

            return {
                ...m,
                duration: {
                    ...m.duration,
                    rendered: rendered,
                },
            };
        });
    });
}

function formatSingleDayEvent(startDayjs: dayjs.Dayjs, format: string, timeZone: string): string {
    if (format === 'datetime') {
        return `${formatDate(startDayjs)} | ${formatTime(startDayjs)} ${timeZone}`;
    } else if (format === 'time') {
        return `${formatTime(startDayjs)} ${timeZone}`;
    } else {
        return formatDate(startDayjs);
    }
}

function formatMultiDayEventSameYear(
    startDayjs: dayjs.Dayjs,
    endDayjs: dayjs.Dayjs,
    format: string,
    timeZone: string,
): string {
    if (startDayjs.year() === endDayjs?.year()) {
        if (startDayjs.month() === endDayjs.month()) {
            // Multi-day event within the same year
            // Same month, same year
            if (format === 'datetime') {
                return `${startDayjs.format('MMM D')} - ${endDayjs.format('D, YYYY')} | ${formatTime(
                    startDayjs,
                )} ${timeZone}`;
            }
            if (format === 'time') {
                return `${formatTime(startDayjs)} ${timeZone}`;
            } else {
                return `${startDayjs.format('MMM D')} - ${endDayjs.format('D, YYYY')}`;
            }
        } else {
            // Different months, same year
            if (format === 'datetime') {
                return `${startDayjs.format('MMM D')} - ${endDayjs.format('MMM D, YYYY')} | ${formatTime(
                    startDayjs,
                )} ${timeZone}`;
            } else if (format === 'time') {
                return `${formatTime(startDayjs)} ${timeZone}`;
            } else {
                return `${startDayjs.format('MMM D')} - ${endDayjs.format('MMM D, YYYY')}`;
            }
        }
    }
}

function formatMultiDayEventDifferentYear(
    startDayjs: dayjs.Dayjs,
    endDayjs: dayjs.Dayjs,
    format: string,
    timeZone: string,
): string {
    // Different years
    if (format === 'datetime') {
        return `${startDayjs.format('MMM D, YYYY')} - ${endDayjs.format('MMM D, YYYY')} | ${formatTime(
            startDayjs,
        )} ${timeZone}`;
    } else if (format === 'time') {
        return `${formatTime(startDayjs)} ${timeZone}`;
    } else {
        return `${startDayjs.format('MMM D, YYYY')} - ${endDayjs.format('MMM D, YYYY')}`;
    }
}
