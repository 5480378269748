import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ComponentsModule } from '@components';
import { StarterKitStepContentComponent } from './starter-kit-step-content/starter-kit-step-content.component';
import { StarterKitStepComponent } from './starter-kit-step/starter-kit-step.component';
import { StarterKitStepsComponent } from './starter-kit-steps/starter-kit-steps.component';
import { StarterKitTabComponent } from './starter-kit-tab/starter-kit-tab.component';
import { StarterKitComponent } from './starter-kit.component';

@NgModule({
    declarations: [
        StarterKitComponent,
        StarterKitTabComponent,
        StarterKitStepsComponent,
        StarterKitStepComponent,
        StarterKitStepContentComponent,
    ],
    imports: [CommonModule, ComponentsModule],
    exports: [StarterKitComponent],
})
export class StarterKitModule {}
