import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DirectivesModule } from '@directives';
import { PipesModule } from '@pipes';
import { ArticleModule } from '../../article/article.module';
import { TileLayoutModule } from '../../tile-layout/tile-layout.module';
import { FeaturedContentThumbnailComponent } from './featured-content-thumbnail.component';

@NgModule({
    declarations: [FeaturedContentThumbnailComponent],
    imports: [CommonModule, ArticleModule, DirectivesModule, PipesModule, TileLayoutModule],
    exports: [FeaturedContentThumbnailComponent],
})
export class FeaturedContentThumbnailModule {}
