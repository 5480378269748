<ng-container *ngIf="excerpt$ | async as excerpt">
    <h4 class="sub-title-3 text-brand-navy">
        {{ excerpt?.communitiesTitle }}
    </h4>
    <p class="p-body">
        {{ excerpt?.communitiesExcerpt }}
    </p>
</ng-container>

<app-tile-layout *desktop maxColumns="6">
    <ng-container *ngTemplateOutlet="relatedCommunities"></ng-container>
</app-tile-layout>

<app-tile-layout *mobile maxColumns="2" [multiColumnMobile]="true">
    <ng-container *ngTemplateOutlet="relatedCommunities"></ng-container>
</app-tile-layout>

<ng-template #relatedCommunities>
    <li *ngFor="let community of communities$ | async; trackBy: trackByItem">
        <a
            class="circle cursor neutral-link community-text"
            [href]="community?.url"
            target="_blank"
            [attr.data-analytics]="community?.dataAnalytics?.join('-')">
            {{ community?.name }}
        </a>
    </li>
</ng-template>
