import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DatesAgoPipe } from './dates-ago/dates-ago.pipe';
import { DecodeEntitiesPipe } from './decode-entities/decode-entities.pipe';
import { GetInitialsPipe } from './get-initials/get-initials.pipe';
import { PluralSingularPipe } from './plural-singular/plural-singular.pipe';
import { SafePipe } from './safe/safe.pipe';
import { StripHtmlPipe } from './strip-html/strip-html.pipe';

const declarations = [DatesAgoPipe, DecodeEntitiesPipe, GetInitialsPipe, PluralSingularPipe, StripHtmlPipe, SafePipe];

@NgModule({
    imports: [CommonModule],
    declarations: declarations,
    exports: declarations,
})
export class PipesModule {}
