import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AnnouncementAlertComponent } from './announcement-alert.component';

@NgModule({
    declarations: [AnnouncementAlertComponent],
    imports: [CommonModule],
    exports: [AnnouncementAlertComponent],
})
export class AnnouncementAlertModule {}
