import { CmsDuration, CmsGuid, CmsLinks, CmsThumbnails } from './cms-core.interface';

export interface CmsEducation {
    id?: number;
    date?: string;
    date_gmt?: string;
    guid?: CmsGuid;
    modified?: string;
    modified_gmt?: string;
    slug?: string;
    status?: string;
    type?: string;
    link?: string;
    title?: CmsGuid;
    author?: number;
    template?: string;
    acf?: CmsEducationAcf;
    _links?: CmsLinks;
}

export interface CmsEducationAcf {
    type?: CmsEducationAcfType;
    title?: string;
    excerpt?: string;
    duration?: CmsDuration;
    links?: {
        shortcut_url?: string;
        study_guide?: string;
    };
    cta?: string;
    url?: string;
    thumbnails?: CmsThumbnails;
    featured?: boolean;
    video_id?: string;
    products?: string[];
    user_roles?: string[];
}

export enum CmsEducationAcfType {
    CERT = 'certification',
    WEBINAR = 'webinar',
    EVENT = 'event',
    FREE_TRAINING = 'free_training',
}
