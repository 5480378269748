import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ServicesModule } from '../../services/services.module';
import { CopyLinkModule } from '../copy-link/copy-link.module';
import { FavoriteIconModule } from '../favorite-icon/favorite-icon.module';
import { SectionHeaderHyperlinkComponent } from './section-header-hyperlink.component';

@NgModule({
    declarations: [SectionHeaderHyperlinkComponent],
    imports: [CommonModule, FavoriteIconModule, ServicesModule, CopyLinkModule],
    exports: [SectionHeaderHyperlinkComponent],
})
export class SectionHeaderHyperlinkModule {}
