import { Marker, Overlay, OverlayHtmlElement, TypographyWithLink } from '../../components';

export class BookmarkOneGenesysOverlay extends Overlay {
    static marker: Marker = {
        start: { time: 42.3, text: 'Bookmark One Genesys' },
        end: { time: 47, text: 'Bookmark One Genesys end' },
    };

    static ONE_GENESYS_TEXT = 'One Genesys';
    static ONE_GENESYS_LINK =
        'https://onegenesys--simpplr.visualforce.com/apex/simpplr__app?u=/site/a145e000000Em51AAC/dashboard';

    private width = document.getElementById('welcome-video-container').offsetWidth;

    constructor(private props: { id: string }) {
        super(props.id, { animateInClass: 'fadeInLeftBig', animateOutClass: 'fadeOutLeftBig' });
    }

    html(): HTMLElement {
        const message = new TypographyWithLink(
            {
                id: 'bookmark-one-genesys-action',
                text: 'For CSS news and info, visit ',
                fontSize: this.width * 0.025 + 'px',
            },
            {
                text: BookmarkOneGenesysOverlay.ONE_GENESYS_TEXT,
                href: BookmarkOneGenesysOverlay.ONE_GENESYS_LINK,
                fontSize: this.width * 0.025 + 'px',
                width: '100%',
            },
            true,
        ).html();

        const overlay = new OverlayHtmlElement().buildHtml({
            id: this.props.id,
            childNodes: [message],
            hidden: true,
            alignItems: 'flex-start',
            width: '30%',
            padding: this.width * 0.03 + 'px',
        });

        return overlay;
    }
}
