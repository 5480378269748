import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-tile',
    templateUrl: './tile.component.html',
    styleUrls: ['./tile.component.css'],
})
export class TileComponent {
    @Input() isVertical = true;
    @Input() hasBoxShadow = false;
    @Input() hasBorder = true;
}
