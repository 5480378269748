import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ComponentsModule } from '@components';
import { UsefulLinksComponent } from './useful-links.component';

@NgModule({
    imports: [CommonModule, ComponentsModule],
    declarations: [UsefulLinksComponent],
    exports: [UsefulLinksComponent],
})
export class UsefulLinksModule {}
