import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ComponentsModule } from '@components';
import { MobileViewportMenuComponent } from './mobile-viewport-menu.component';

@NgModule({
    declarations: [MobileViewportMenuComponent],
    imports: [CommonModule, ComponentsModule, RouterModule],
    exports: [MobileViewportMenuComponent],
})
export class MobileViewportMenuModule {}
