<ng-container *ngIf="cards$ | async as cards; else isLoading">
    <ng-container *ngIf="cards.length > 0">
        <app-section-header
            [title]="sectionLabel"
            [section]="sectionLocation"
            [favoriteId]="favoriteId"></app-section-header>
        <app-tile-layout maxColumns="3">
            <li *ngFor="let card of cards | slice: 0:(maxItemsToLoad$ | async); trackBy: trackByItem">
                <!--NOTE: If the start and end dates are the same, just show the start date only by using the
                date pipe 'mediumDate', which compares the date but not the hours. If this ever changes you need to
                update the pipe format to include hours, if you need to show the same date-->
                <app-tile-vertical
                    [title]="card.title"
                    [titleLink]="true"
                    [bigImage]="false"
                    [showImage]="false"
                    [url]="card.url"
                    [urlLabel]="card.urlLabel"
                    [description]="card.description"
                    [isCustomTileLink]="true"
                    [icon]="card.icon"
                    [details]="card.type + ' | ' + card.duration?.rendered"
                    [border]="false">
                    <div customTileLink>
                        <a class="cursor primary-link" target="_blank" [href]="card.url" rel="nofollow">
                            <img
                                *desktop
                                class="card-image tile-border-radius mat-elevation-z6"
                                [alt]="card.title"
                                [src]="card.thumbnails?.desktop" />
                            <img
                                *mobile
                                class="card-image tile-border-radius mat-elevation-z6"
                                [alt]="card.title"
                                [src]="card.thumbnails?.mobile" />
                        </a>
                        <ng-template #defaultImageLink>
                            <div class="tile-large default-image">
                                <img src="/assets/icons/genesys-g.svg" width="28px" height="45px" alt="Genesys logo" />
                            </div>
                        </ng-template>
                    </div>
                </app-tile-vertical>
            </li>
        </app-tile-layout>
    </ng-container>
</ng-container>
<ng-template #isLoading>
    <app-loading-spinner></app-loading-spinner>
</ng-template>

<ng-container *ngIf="cardsLength > 3">
    <div *ngIf="showLoadMoreButton$ | async" class="text-align-center margin-top-large">
        <button class="btn btn-secondary padding-left-small padding-right-small" (click)="loadMore($event)">
            Load More
            <app-down-arrow *desktop class="margin-left-smaller" fillColor="#ffffff"></app-down-arrow>
            <app-down-arrow *mobile class="margin-left-smaller" fillColor="#3b90aa"></app-down-arrow>
        </button>
    </div>
</ng-container>
