import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ProductUid } from '@enums';

import { ContentService, Product, ProductService } from '@services';
import { Observable, tap } from 'rxjs';

@Component({
    selector: 'app-roadmaps',
    templateUrl: './roadmaps.component.html',
    styleUrls: ['./roadmaps.component.css'],
})
export class RoadmapsComponent implements OnInit {
    @Input() flexDirection: 'row' | 'column';

    selectedProduct$: Observable<Product>;
    productEnum = ProductUid;

    roadmaps$: Observable<string>;
    isLoading = true;

    viewAllColor = 'accent';

    constructor(
        private contentService: ContentService,
        private cdr: ChangeDetectorRef,
        private productService: ProductService,
    ) {}

    ngOnInit() {
        this.selectedProduct$ = this.productService.selectedProduct$;

        this.roadmaps$ = this.contentService.roadmaps$.pipe(
            tap(() => {
                this.isLoading = false;
                this.cdr.detectChanges();
            }),
        );
    }
}
