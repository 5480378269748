import { Component, OnInit } from '@angular/core';
import { ContentService, GknCxEvolution, GknCxEvolutionCommunity, GknCxEvolutionExcerpt } from '@services';
import { Observable, map } from 'rxjs';

@Component({
    selector: 'app-cx-evolution-communities',
    templateUrl: './cx-evolution-communities.component.html',
    styleUrls: ['./cx-evolution-communities.component.css'],
})
export class CxEvolutionCommunitiesComponent implements OnInit {
    excerpt$: Observable<GknCxEvolutionExcerpt>;
    communities$: Observable<GknCxEvolutionCommunity[]>;

    constructor(private contentService: ContentService) {}

    ngOnInit(): void {
        this.excerpt$ = this.contentService.cxEvolution$.pipe(
            map((cxEvolution: GknCxEvolution) => cxEvolution?.excerpt),
        );

        this.communities$ = this.contentService.cxEvolution$.pipe(
            map((cxEvolution: GknCxEvolution) => {
                return cxEvolution?.communities?.sort((a, b) => a.name.localeCompare(b.name));
            }),
        );
    }
}
