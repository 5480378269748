import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { VerticalDividerComponent } from './vertical-divider.component';

@NgModule({
    declarations: [VerticalDividerComponent],
    imports: [CommonModule],
    exports: [VerticalDividerComponent],
})
export class VerticalDividerModule {}
