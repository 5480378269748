import { APP_BASE_HREF, CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { OKTA_CONFIG, OktaAuthModule } from '@okta/okta-angular';

import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ComponentsModule } from '@components';
import { environment } from '@environments/environment';
import { NgSelectModule } from '@ng-select/ng-select';
import { OktaAuth } from '@okta/okta-auth-js';
import { ServicesModule } from '@services';
import { DirectivesModule } from './@shared/directives/directives.module';
import { ExternalRedirectGuard } from './@shared/guards/external-redirect.guard';
import { PipesModule } from './@shared/pipes/pipes.module';
import { AppFooterModule } from './app-footer/app-footer.module';
import { AppHeaderModule } from './app-header/app-header.module';
import { ProductSelectModule } from './app-header/product-select/product-select.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeroBannerModule } from './home/hero-banner/hero-banner.module';
import { HomeModule } from './home/home.module';
import { LegacyGetStartedModule } from './legacy-get-started/legacy-get-started.module';
import { LoginModule } from './login/login.module';
import { ProfileModule } from './profile/profile.module';
import { MAT_TABS_CONFIG } from '@angular/material/tabs';

const oktaAuthFactory = () => {
    const oktaAuth = new OktaAuth({
        issuer: environment.oidc.issuer,
        clientId: environment.oidc.clientId,
        redirectUri: environment.oidc.redirectUri,
        scopes: environment.oidc.scopes,
    });
    return {
        oktaAuth,
        onAuthRequired: async (oktaAuth: OktaAuth, _: Injector) => {
            if (!oktaAuth.authStateManager.getPreviousAuthState()?.isAuthenticated) {
                await oktaAuth.signInWithRedirect();
            }
        },
    };
};

@NgModule({
    declarations: [AppComponent],
    imports: [
        AppHeaderModule,
        AppFooterModule,
        AppRoutingModule,
        BrowserModule,
        CommonModule,
        ComponentsModule,
        DirectivesModule,
        HomeModule,
        HeroBannerModule,
        HttpClientModule,
        LegacyGetStartedModule,
        LoginModule,
        NgSelectModule,
        OktaAuthModule,
        ProductSelectModule, // TODO KNOW-1110 Move to shared components module
        ProfileModule,
        PipesModule,
        ServicesModule.forRoot(),
        BrowserAnimationsModule,
    ],
    providers: [
        {
            provide: APP_BASE_HREF,
            useValue: environment.appBaseHref,
        },
        {
            provide: OKTA_CONFIG,
            useFactory: oktaAuthFactory,
        },
        ExternalRedirectGuard,
        {
            provide: MAT_TABS_CONFIG,
            useValue: {
                animationDuration: '0ms',
                dynamicHeight: true,
                disablePagination: true,
                disableRipple: true,
            },
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
