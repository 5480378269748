import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-tile-video',
    templateUrl: './tile-video.component.html',
    styleUrls: ['./tile-video.component.css'],
})
export class TileVideoComponent {
    @Input() url: string;
    @Input() isStrapiVideo = false;
    @Input() dataAnalytics?: string[];
}
