import { Component, Input } from '@angular/core';
import { DeveloperCenterCard, DeveloperCenterData } from '../../developer-center.interface';

@Component({
    selector: 'app-tools-card',
    templateUrl: './tools-card.component.html',
    styleUrls: ['./tools-card.component.css'],
})
export class ToolsCardComponent {
    @Input() card: DeveloperCenterCard;
    @Input() developerCenterData: DeveloperCenterData;
}
