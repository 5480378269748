import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@services';
import { firstValueFrom } from 'rxjs';

/**
 * Router component to handle Okta app bookmarks and automatic sign-in.
 * This component should not be used in other sign-in flows, or shown in the navbar.
 */
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
    constructor(private authService: AuthService, private router: Router) {}

    ngOnInit(): void {
        firstValueFrom(this.authService.isAuthenticated$).then((isAuthed) => {
            if (isAuthed) {
                this.router.navigate(['/home'], {
                    queryParamsHandling: 'merge',
                    preserveFragment: true,
                });
            } else {
                this.authService.redirectToOktaHostedLogin();
            }
        });
    }
}
