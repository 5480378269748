import { environment } from '@environments/environment';
import { DeveloperCenterData } from '../developer-center.interface';

export const popularTopicsData: DeveloperCenterData = {
    baseUrl: environment.api.developerCenter.site.baseUrl,
    iconBase: '/assets/icons/popular-topics/',
    cards: [
        {
            title: 'Analytics & Data Management',
            icon: 'analytics-icon.svg',
            url: 'analyticsdatamanagement/',
        },
        {
            title: 'Authorization',
            icon: 'authorization-icon.svg',
            url: 'authorization/',
        },
        {
            title: 'Billing',
            icon: 'billing-icon.svg',
            url: 'billing/',
        },
        {
            title: 'Communication & Digital Channels',
            icon: 'communication-icon.svg',
            url: 'commdigital/',
        },
        {
            title: 'GDPR & Privacy',
            icon: 'gdpr-privacy-icon.svg',
            url: 'gdprprivacy/',
        },
        {
            title: 'Notifications & Alerts',
            icon: 'notifications-icon.svg',
            url: 'notificationsalerts/',
        },
        {
            title: 'Organization',
            icon: 'organization-icon.svg',
            url: 'organization/',
        },
        {
            title: 'Platform',
            icon: 'platform-icon.svg',
            url: 'platform/',
        },
        {
            title: 'Routing & Conversation Handling',
            icon: 'routing-icon.svg',
            url: 'routing/',
        },
        {
            title: 'Telephony',
            icon: 'telephony-icon.svg',
            url: 'telephony/',
        },
        {
            title: 'User & Agent Management',
            icon: 'user-agent-icon.svg',
            url: 'useragentman/',
        },
    ],
};
