<svg
    class="icon-down-arrow"
    width="100%"
    height="100%"
    viewBox="0 0 37 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M36.5869 0.413138C36.7178 0.543766 36.8217 0.698948 36.8926 0.869793C36.9635 1.04064 37 1.22379 37 1.40876C37 1.59373 36.9635 1.77689 36.8926 1.94773C36.8217 2.11858 36.7178 2.27376 36.5869 2.40439L19.7119 19.2794C19.5812 19.4103 19.426 19.5142 19.2552 19.5851C19.0844 19.656 18.9012 19.6925 18.7162 19.6925C18.5313 19.6925 18.3481 19.656 18.1773 19.5851C18.0064 19.5142 17.8512 19.4103 17.7206 19.2794L0.845608 2.40439C0.581551 2.14033 0.433208 1.78219 0.433208 1.40876C0.433208 1.03533 0.581551 0.677192 0.845608 0.413136C1.10966 0.14908 1.4678 0.000734713 1.84123 0.000734729C2.21466 0.000734746 2.5728 0.14908 2.83686 0.413136L18.7162 16.2953L34.5956 0.413138C34.7262 0.282179 34.8814 0.178277 35.0523 0.107383C35.2231 0.0364904 35.4063 -6.96643e-08 35.5912 -6.1579e-08C35.7762 -5.34937e-08 35.9594 0.0364904 36.1302 0.107384C36.3011 0.178277 36.4562 0.282179 36.5869 0.413138Z"
        [attr.fill]="fillColor" />
</svg>
