/**
 * Removes short code markup found in Wordpress content.
 * Short codes are blocks of content that are used only by
 * Wordpress and need to be removed.
 * @param wpString - A string of content from Wordpress
 * @returns string with removed short codes
 */
export function stripShortCodes(wpString: string): string {
    return wpString.replace(/\[\/?vc_.*?\]/g, '').trim();
}
