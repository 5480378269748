import { Marker, Overlay, OverlayHtmlElement, Typography, TypographyWithLink } from '../../components';

export class CommunityOverlay extends Overlay {
    static marker: Marker = {
        start: { time: 22.0, text: 'Community' },
        end: { time: 27.0, text: 'Community end' },
    };

    static COMMUNITY_TEXT = 'Genesys Community';
    static COMMUNITY_LINK = 'https://community.genesys.com/home';
    private width = document.getElementById('welcome-video-container').offsetWidth;

    constructor(private props: { id: string }) {
        super(props.id, { animateInClass: 'fadeInDownBig', animateOutClass: 'fadeOutUpBig' });
    }

    html(): HTMLElement {
        const action = new Typography({
            text: 'Build your community',
            fontFamily: 'springwood_brush',
            fontSize: this.width * 0.04 + 'px',
            fontWeight: 'bold',
            lineHeight: '0',
            padding: this.width * 0.02 + 'px 0 0 0',
        }).html();

        const message = new TypographyWithLink(
            {
                text: 'Visit the ',
                fontSize: this.width * 0.02 + 'px',
                lineHeight: '0',
                padding: `0 0 ${this.width * 0.02}px 0`,
            },
            {
                text: CommunityOverlay.COMMUNITY_TEXT,
                fontSize: this.width * 0.02 + 'px',
                href: CommunityOverlay.COMMUNITY_LINK,
            },
        ).html();

        const overlay = new OverlayHtmlElement().buildHtml({
            id: this.props.id,
            childNodes: [action, message],
            hidden: true,
            width: this.width + 'px',
        });

        return overlay;
    }
}
