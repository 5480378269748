<app-tile-layout *desktop maxColumns="2" class="container">
    <li>
        <ng-container *ngTemplateOutlet="title"></ng-container>
        <ng-container *ngTemplateOutlet="description"></ng-container>
        <ng-container *ngTemplateOutlet="cta"></ng-container>
    </li>
    <li class="media-container">
        <ng-container *ngTemplateOutlet="thumbnail"></ng-container>
    </li>
</app-tile-layout>

<app-tile-layout *mobile maxColumns="1" class="container">
    <li>
        <ng-container *ngTemplateOutlet="title"></ng-container>
        <ng-container *ngTemplateOutlet="thumbnail"></ng-container>
        <ng-container *ngTemplateOutlet="description"></ng-container>
        <ng-container *ngTemplateOutlet="cta"></ng-container>
    </li>
</app-tile-layout>

<ng-template #title>
    <h2 class="h2-alt margin-bottom-small">{{ featuredContent.title }}</h2>
</ng-template>

<ng-template #description>
    <p class="break-paragraph p-body-large">{{ featuredContent.description }}</p>
</ng-template>

<ng-template #cta>
    <a
        *ngIf="featuredContent.type == 'newTab'"
        class="btn btn-secondary margin-top-small"
        [href]="featuredContent.url"
        target="_blank">
        {{ featuredContent.urlLabel }}
    </a>
    <button *ngIf="featuredContent.type == 'modal'" class="btn btn-secondary margin-top-small" (click)="openModal()">
        {{ featuredContent.urlLabel }}
    </button>
</ng-template>

<ng-template #thumbnail>
    <img
        *desktop
        class="full-width tile-border-radius mat-elevation-z6"
        width="auto"
        height="auto"
        [alt]="featuredContent.title"
        [src]="featuredContent.thumbnail.desktop" />
    <img
        *mobile
        class="full-width tile-border-radius mat-elevation-z6"
        width="auto"
        height="auto"
        [alt]="featuredContent.title"
        [src]="featuredContent.thumbnail.mobile" />
</ng-template>

<ng-container *ngIf="(isModalOpen$ | async) == true">
    <app-article
        [title]="featuredContent.title"
        [isCustomContent]="true"
        [customModalCss]="['light-mode-modal', 'text-black', 'auto-height']"
        [customIconCss]="['black-icon', 'close-align']"
        [customSecondaryHeaderCSS]="'header-secondary'"
        [customShareLink]="featuredContent.url"
        (closeArticle)="closeModal()">
        <div customContent>
            <iframe
                id="selectedFeaturedContentFrame"
                [src]="featuredContent.url | safe: 'resourceUrl'"
                class="article-iframe"></iframe>
        </div>
    </app-article>
</ng-container>
