import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { ComponentsModule } from '@components';
import { DirectivesModule } from '@directives';
import { AppFoundryComponent } from './app-foundry.component';
import { AppFoundryService } from './app-foundry.service';

@NgModule({
    declarations: [AppFoundryComponent],
    imports: [CommonModule, ComponentsModule, DirectivesModule, MatCardModule],
    providers: [AppFoundryService],
    exports: [AppFoundryComponent],
})
export class AppFoundryModule {}
