import { Component, OnInit } from '@angular/core';

import { BreakpointObserver } from '@angular/cdk/layout';
import { TrackByItem } from '@components';
import { UserRoleUid } from '@enums';
import { ProductService, UserService } from '@services';
import { Observable, combineLatestWith, map } from 'rxjs';
import { additionalSitesData } from './additional-sites.data';

export interface AdditionalSite {
    label: string;
    url: string;
    roles: UserRoleUid[];
}

@Component({
    selector: 'app-additional-sites',
    templateUrl: './additional-sites.component.html',
    styleUrls: ['./additional-sites.component.css'],
})
export class AdditionalSitesComponent implements OnInit, TrackByItem<AdditionalSite> {
    isMobile$: Observable<boolean>;
    additionalSites$: Observable<AdditionalSite[]>;

    constructor(
        private breakpointObserver: BreakpointObserver,
        private productService: ProductService,
        private userService: UserService,
    ) {}

    ngOnInit(): void {
        this.isMobile$ = this.breakpointObserver.observe('(max-width: 1020px)').pipe(map((state) => state.matches));
        this.additionalSites$ = this.productService.selectedProduct$.pipe(
            combineLatestWith(this.userService.userRole$),
            map(([product, userRole]) => {
                return additionalSitesData[product.uid].filter((sites) => sites.roles.includes(userRole));
            }),
        );
    }

    trackByItem(_index: number, item: AdditionalSite): NonNullable<number | string> {
        return item.label;
    }
}
