import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AnchorPointComponent } from './anchor-point.component';

@NgModule({
    declarations: [AnchorPointComponent],
    imports: [CommonModule],
    exports: [AnchorPointComponent],
})
export class AnchorPointModule {}
