import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ComponentsModule } from '@components';
import { ExpertAppsComponent } from './expert-apps.component';

@NgModule({
    declarations: [ExpertAppsComponent],
    exports: [ExpertAppsComponent],
    imports: [CommonModule, ComponentsModule],
})
export class ExpertAppsModule {}
