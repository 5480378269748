import { Injectable } from '@angular/core';
import { KeplerCoveoService, ProductService } from '@services';
import { Observable, combineLatest, firstValueFrom, map, of, switchMap } from 'rxjs';
import { SupportRecommendationsAdapter } from './support-recommendations.adapter';
import { SupportRecommendation, SupportRecommendationsByProduct } from './support-recommendations.interface';

@Injectable({
    providedIn: 'root',
})
export class SupportRecommendationsService {
    localStorageKey = 'gkn_support_recommendations';
    readonly mostPopularSearchTerm = 'Agent assist';
    private readonly maxCapacity = 3;

    constructor(
        private adapter: SupportRecommendationsAdapter,
        private keplerCoveoService: KeplerCoveoService,
        private productService: ProductService,
    ) {}

    /**
     * @returns recommendations for the currently selected product
     */
    get recommendations$(): Observable<SupportRecommendation[]> {
        return this.productService.selectedProduct$.pipe(
            map((product) => product.uid),
            switchMap((product) => {
                const recommendations: SupportRecommendationsByProduct = JSON.parse(
                    localStorage.getItem(this.localStorageKey),
                );

                if (!recommendations?.[product] || recommendations?.[product]?.length === 0) {
                    return this.fallbackRecommendations$;
                }

                if (recommendations?.[product]?.length === 1) {
                    // top three recommendations from the most recent query
                    return of([
                        recommendations[product][0].recommendations[0],
                        recommendations[product][0].recommendations[1],
                        recommendations[product][0].recommendations[2],
                    ]);
                } else if (recommendations?.[product]?.length === 2) {
                    // top two recommendations from the most recent query, top one recommendation from the next most recent query
                    return of([
                        recommendations[product][0].recommendations[0],
                        recommendations[product][0].recommendations[1],
                        recommendations[product][1].recommendations[0],
                    ]);
                } else {
                    // top one recommendation from all 3 queries
                    return of([
                        recommendations[product][0].recommendations[0],
                        recommendations[product][1].recommendations[0],
                        recommendations[product][2].recommendations[0],
                    ]);
                }
            }),
        );
    }

    private get fallbackRecommendations$(): Observable<SupportRecommendation[]> {
        return this.keplerCoveoService
            .topThreeRecommendations$(this.mostPopularSearchTerm)
            .pipe(
                map((recommendations) => recommendations.map((recommendation) => this.adapter.adapt(recommendation))),
            );
    }

    /**
     * Adds the top 3 recommendations for the given query to local storage.
     * Local storage organizes recommendations by product.
     */
    async add(query: string): Promise<void> {
        return firstValueFrom(
            combineLatest([
                this.productService.selectedProduct$,
                this.keplerCoveoService.topThreeRecommendations$(query),
            ]),
        ).then(([product, newRecommendations]) => {
            if (newRecommendations.length > 0) {
                const recommendations: SupportRecommendationsByProduct =
                    JSON.parse(localStorage.getItem(this.localStorageKey)) || {};

                if (!recommendations[product.uid]) {
                    recommendations[product.uid] = [];
                }

                const data = newRecommendations.map((recommendation) => {
                    return this.adapter.adapt(recommendation);
                });

                recommendations[product.uid].unshift({
                    query: query,
                    recommendations: data,
                });

                if (recommendations[product.uid].length > this.maxCapacity) {
                    const dropped = recommendations[product.uid].pop();
                    console.debug(`dropped support recommendation`, { productUid: product, dropped: dropped });
                }

                localStorage.setItem(this.localStorageKey, JSON.stringify(recommendations));
                console.debug(`support recommendations`, recommendations);
            }
        });
    }
}
