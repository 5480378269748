/**
 * Beyond Learning Types enums based on Docebo Learning types
 * Used for building catalog check items and filtering courses by learning type.
 */
export enum DoceboLearningType {
    E_LEARNING = 'eLearning',
    SELF_STUDY = 'Self-study',
    INSTRUCTOR_LED = 'Instructor-Led',
    INSTRUCTOR_LED_VIRTUAL = 'Instructor-Led (Virtual)',
    INSTRUCTOR_LED_IN_PERSON = 'Instructor-Led (In Person)',
    SUBSCRIPTION = 'learning_plan', // NOTE: Learning_plan is docebo learning type equivalent
    CERTIFICATION_EXAM = 'Certification Exam',
    IN_PERSON = 'In-person', // NOTE: Need this to show legacy user dashboard for pre-instructor-led courses enrolled or completed
    VIRTUAL = 'Virtual', // NOTE: Need this to show legacy user dashboard for pre-instructor-led courses enrolled or completed
}
