import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ComponentsModule } from '@components';
import { ContactsComponent } from './contacts.component';

@NgModule({
    declarations: [ContactsComponent],
    imports: [CommonModule, ComponentsModule],
    exports: [ContactsComponent],
})
export class ContactsModule {}
