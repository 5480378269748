<app-tile-layout *desktop maxColumns="2">
    <li *ngFor="let item of tipsAndTools$ | async" class="news-item tips-and-tools">
        <app-tile-vertical
            [title]="item.title"
            [titleLink]="true"
            [imageUrl]="item.imageUrl"
            [url]="item.url"
            [urlLabel]="item.urlLabel"
            [description]="item.description | stripHtml"
            [icon]="item.icon"
            [details]="item.subtitle"
            [border]="false"
            [vertical]="false"
            [hasBoxShadow]="true">
        </app-tile-vertical>
    </li>
</app-tile-layout>

<app-tile-layout *mobile maxColumns="1">
    <li *ngFor="let item of tipsAndTools$ | async" class="news-item tips-and-tools">
        <app-tile-vertical
            [title]="item.title"
            [titleLink]="true"
            [imageUrl]="item.imageUrl"
            [url]="item.url"
            [urlLabel]="item.urlLabel"
            [description]="item.description | stripHtml"
            [icon]="item.icon"
            [details]="item.subtitle"
            [border]="false"
            [vertical]="true"
            [hasBoxShadow]="true">
        </app-tile-vertical>
    </li>
</app-tile-layout>
