import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ServicesModule } from '../../services/services.module';
import { CopyLinkModule } from '../copy-link/copy-link.module';
import { FavoriteIconModule } from '../favorite-icon/favorite-icon.module';
import { SectionHeaderHyperlinkModule } from '../section-header-hyperlink/section-header-hyperlink.module';
import { AnchorPointModule } from './anchor-point/anchor-point.module';
import { SectionHeaderComponent } from './section-header.component';

@NgModule({
    declarations: [SectionHeaderComponent],
    imports: [
        CommonModule,
        AnchorPointModule,
        FavoriteIconModule,
        ServicesModule,
        CopyLinkModule,
        SectionHeaderHyperlinkModule,
    ],
    exports: [SectionHeaderComponent],
})
export class SectionHeaderModule {}
