<ng-container *ngIf="tileSmallData?.LoadsInFrame; else nonModalTile">
    <div [ngClass]="{ dark: dark, 'bg-brand-navy': dark }" class="tile-small mat-elevation-z6">
        <a class="neutral-link" rel="nofollow" (click)="openArticle(tileSmallData)">
            <ng-container *ngTemplateOutlet="tileContent"> </ng-container>
        </a>
    </div>
</ng-container>

<ng-template #nonModalTile>
    <div [ngClass]="{ dark: dark, 'bg-brand-navy': dark }" class="tile-small mat-elevation-z6">
        <a class="neutral-link" [href]="tileSmallData?.url" target="_blank" rel="nofollow">
            <ng-container *ngTemplateOutlet="tileContent"></ng-container>
        </a>
    </div>
</ng-template>

<ng-template #tileContent>
    <img
        [src]="tileSmallData?.iconUrl"
        [attr.width]="tileSmallData?.width"
        [attr.height]="tileSmallData?.height"
        [alt]="altText" />
    <h2
        class="h3 sub-title-3-alt margin-top-small"
        [ngClass]="{
            'text-white': dark,
            'text-brand-navy': !dark
        }">
        {{ tileSmallData?.label }}
    </h2>
</ng-template>

<ng-container *ngIf="selected">
    <app-article
        [title]="selected.label"
        [isCustomContent]="true"
        [customModalCss]="['light-mode-modal', 'text-black', 'auto-height']"
        [customIconCss]="['black-icon', 'close-align']"
        customSecondaryHeaderCSS="header-secondary"
        [customShareLink]="selected.url"
        (closeArticle)="closeArticle()">
        <div customContent>
            <iframe id="usefulLinkFrame" [src]="selected.url | safe: 'resourceUrl'" class="article-iframe"></iframe>
        </div>
    </app-article>
</ng-container>
