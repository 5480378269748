import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import {
    EduTrainingBadgesFilterLabel,
    EduTrainingSectionLabel,
    FavoriteId,
    GetStartedFilterLabel,
    HomeSectionLabel,
    JustForMeSectionLabel,
    ProductUid,
    ResourcesSectionLabel,
    SupportSectionLabel,
    UserRoleUid,
} from '@enums';
import { FavoritesService, Product, ProductService, UserService } from '@services';
import { Observable, Subscription, map, tap } from 'rxjs';
import { SECTION_LINKS } from 'src/app/app-header/submenu-data';
import { GknBadge } from 'src/app/edu-training/certifications-and-badges/certifications-and-badges.interface';

@Component({
    selector: 'app-just-for-me',
    templateUrl: './just-for-me.component.html',
    styleUrls: ['./just-for-me.component.css'],
})
export class JustForMeComponent implements OnInit, OnDestroy {
    currentProduct: Product;

    sectionLabel = HomeSectionLabel.JUST_FOR_ME;
    justForMeFilterLabel = {
        myFavorites: HomeSectionLabel.MY_FAVORITES,
        beyond: EduTrainingSectionLabel.BEYOND_TRAINING,
        additionalSites: JustForMeSectionLabel.ADDITIONAL_SITES,
        welcomeVideo: GetStartedFilterLabel.MY_PERSONALIZED_VIDEO,
        customerSuccess: ResourcesSectionLabel.CUSTOMER_SUCCESS,
        myCases: SupportSectionLabel.MY_CASES,
        myContacts: SupportSectionLabel.MY_CONTACTS,
        myAwards: EduTrainingBadgesFilterLabel.MY_AWARDS,
    };

    favoriteId = null;
    displayFilters: displayFilters;
    filterPrefix = SECTION_LINKS.JUST_FOR_ME;
    anchors: string[];

    private readonly subs: Subscription[] = [];
    badge$: Observable<GknBadge[]>;

    isCustomerOrPartner$: Observable<boolean>;
    isCssEmployee$: Observable<boolean>;

    showTitle = false;

    constructor(
        private changeDetector: ChangeDetectorRef,
        private productService: ProductService,
        private userService: UserService,
        private favoritesService: FavoritesService,
    ) {
        // The Just For Me section is an exception that requires an explicit Section id to be passed as
        // the Favorites mapping will think some of these other filters belong in other sections.
        this.anchors = [
            this.favoritesService.buildFragment(SECTION_LINKS.JUST_FOR_ME, FavoriteId.MY_FAVORITES),
            this.favoritesService.buildFragment(SECTION_LINKS.JUST_FOR_ME, FavoriteId.BEYOND),
            this.favoritesService.buildFragment(SECTION_LINKS.JUST_FOR_ME, FavoriteId.CERTIFICATIONS_MY_AWARDS),
            this.favoritesService.buildFragment(SECTION_LINKS.JUST_FOR_ME, FavoriteId.ADDITIONAL_SITES),
            this.favoritesService.buildFragment(
                SECTION_LINKS.JUST_FOR_ME,
                FavoriteId.GET_STARTED_MY_PERSONALIZED_VIDEO,
            ),
        ];
    }

    ngOnInit(): void {
        this.displayFilters = {
            myFavorites: true,
            beyondTraining: true,
            myAwards: true,
            myCases: true,
            myContacts: true,
            customerSuccess: true,
            additionalSites: true,
            welcomeVideo: true,
        };

        this.isCustomerOrPartner$ = this.userService.userRole$.pipe(
            map((userRole) => {
                return userRole == UserRoleUid.CUSTOMER || userRole == UserRoleUid.PARTNER;
            }),
        );
        this.isCssEmployee$ = this.userService.isCssEmployee$.pipe(
            tap((isCssEmployee) => {
                this.displayFilters = { ...this.displayFilters, welcomeVideo: isCssEmployee };
                this.changeDetector.markForCheck();
                this.favoriteId = FavoriteId.MY_FAVORITES;
                this.changeDetector.markForCheck();
            }),
        );
        const product$ = this.productService.selectedProduct$;
        this.badge$ = this.userService.badges$;

        this.subs.push(
            product$.subscribe((product) => {
                const shouldDisplayAdditionalSites = product.uid !== ProductUid.POINTILLIST;
                this.displayFilters = { ...this.displayFilters, additionalSites: shouldDisplayAdditionalSites };
                this.favoriteId = FavoriteId.MY_FAVORITES;
                this.changeDetector.markForCheck();
            }),
        );

        this.subs.push(
            this.badge$.subscribe((badges: GknBadge[]) => {
                this.displayFilters = { ...this.displayFilters, myAwards: badges.length > 0 };
                this.favoriteId = FavoriteId.MY_FAVORITES;
                this.changeDetector.markForCheck();
            }),
        );
    }

    filterUpdated(favoriteId: FavoriteId) {
        this.favoriteId = favoriteId;

        this.changeDetector.markForCheck();
    }

    /**
     * On Destroy, unsubscribe subscriptions to prevent memory leaks
     */
    ngOnDestroy(): void {
        this.subs.forEach((sub) => {
            sub?.unsubscribe();
        });
    }
}

export interface displayFilters {
    myFavorites?: boolean;
    beyondTraining?: boolean;
    myAwards?: boolean;
    myCases?: boolean;
    myContacts?: boolean;
    customerSuccess?: boolean;
    additionalSites?: boolean;
    welcomeVideo?: boolean;
}
