import { AuthService } from '@services';
import { Component } from '@angular/core';

@Component({
    selector: 'app-hero-banner',
    templateUrl: './hero-banner.component.html',
    styleUrls: ['./hero-banner.component.css'],
})
export class HeroBannerComponent {
    fillColor = '#FFFFFF';

    constructor(private authService: AuthService) {}

    openLoginModal(): void {
        this.authService.emitOpenLoginModal();
    }

    changeFillColor(color: string) {
        this.fillColor = color;
    }
}
