import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ComponentsModule } from '@components';
import { AlertsComponent } from './alerts.component';
import { AnnouncementAlertModule } from './announcement-alert/announcement-alert.module';
import { MaintenanceAlertModule } from './maintenance-alert/maintenance-alert.module';
import { OutageAlertModule } from './outage-alert/outage-alert.module';
import { ReleaseAlertModule } from './release-alert/release-alert.module';

@NgModule({
    declarations: [AlertsComponent],
    imports: [
        CommonModule,
        ComponentsModule,
        AnnouncementAlertModule,
        MaintenanceAlertModule,
        OutageAlertModule,
        ReleaseAlertModule,
    ],
    exports: [AlertsComponent],
})
export class AlertsModule {}
