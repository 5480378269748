import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ComponentsModule } from '@components';
import { ServicesModule } from '@services';
import { AlertsModule } from './alerts/alerts.module';
import { AnnouncementsModule } from './announcements/announcements.module';
import { AppHeaderComponent } from './app-header.component';
import { AppHeaderService } from './app-header.service';
import { HoverMenuModule } from './hover-menu/hover-menu.module';
import { LoginModalModule } from './login-modal/login-modal.module';
import { MobileViewportMenuModule } from './mobile-viewport-menu/mobile-viewport-menu.module';
import { ProductSelectModule } from './product-select/product-select.module';

@NgModule({
    declarations: [AppHeaderComponent],
    imports: [
        AlertsModule,
        AnnouncementsModule,
        CommonModule,
        ComponentsModule,
        FormsModule,
        HoverMenuModule,
        LayoutModule,
        LoginModalModule,
        MobileViewportMenuModule,
        ProductSelectModule,
        ReactiveFormsModule,
        RouterModule,
        ServicesModule,
    ],
    providers: [AppHeaderService],
    exports: [AppHeaderComponent],
})
export class AppHeaderModule {}
