<ul [class.mobile]="isMobile" *ngIf="steps && steps.length">
    <ng-container *ngFor="let step of steps; let i = index; trackBy: trackByItem">
        <li>
            <span>
                <app-starter-kit-step
                    [step]="step"
                    [accessible]="access && i <= largestAccessibleIndex"
                    [selected]="selectedIndex == i"
                    (click)="select(i)"
                    [attr.data-analytics]="step?.dataAnalytics.join('-')"></app-starter-kit-step>
            </span>
        </li>
        <li *ngIf="order && !isMobile && step != steps[steps.length - 1]">
            <img
                *ngIf="i < largestAccessibleIndex"
                class="arrow"
                src="/assets/icons/starter-kit/completed-arrow-steps.svg"
                alt="{{ step.icon }}" />
            <img
                *ngIf="i >= largestAccessibleIndex"
                class="arrow"
                src="/assets/icons/starter-kit/arrow-steps.svg"
                alt="{{ step.icon }}" />
        </li>
    </ng-container>
</ul>
