<app-section-header [title]="sectionLabel" [showTitle]="showTitle"></app-section-header>
<app-tile-layout maxColumns="3" *ngIf="badges?.length > 0; else signUpTemplate">
    <ng-container>
        <li *ngFor="let badge of badges; trackBy: trackByItem">
            <app-tile-vertical
                [title]="badge?.title"
                [titleLink]="true"
                [imageUrl]="badge?.image"
                imageWidth="184px"
                imageHeight="184px"
                [details]="badge?.expires ? 'EXPIRES ' + (badge?.expires | date: 'mediumDate') : 'NO EXPIRATION DATE'"
                [vertical]="false"
                [url]="badge?.url"
                [bigImage]="true"
                [border]="false"
                [hasImageAutoHeight]="true"
                [hasMarginTopAuto]="false">
            </app-tile-vertical>
        </li>
    </ng-container>
</app-tile-layout>

<ng-template #signUpTemplate>
    <div class="primary-link sub-title-3 margin-top-large text-brand-navy">
        Sign up for a {{ (selectedProduct$ | async).displayName }} certification course to earn an award!
        <div class="text-align-center">
            <a class="btn btn-secondary margin-top-large" href="{{ courseLink$ | async }}" target="_blank">
                Sign up now
            </a>
        </div>
    </div>
</ng-template>
