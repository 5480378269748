<app-section-header [title]="sectionLabel" [section]="filterPrefix" [favoriteId]="favoriteId" [anchors]="anchors">
</app-section-header>

<mat-tab-group (currentlySelectedEvent)="filterUpdated($event)" [prefix]="filterPrefix" appFilterLocation>
    <mat-tab *ngIf="displayFilters.myFavorites" [label]="justForMeFilterLabel.myFavorites">
        <app-my-favorites *ngIf="displayFilters.myFavorites"></app-my-favorites>
    </mat-tab>
    <mat-tab *ngIf="displayFilters.beyondTraining" [label]="justForMeFilterLabel.beyond">
        <app-beyond [showTitle]="showTitle" [showIcons]="false"></app-beyond>
    </mat-tab>
    <ng-container *ngIf="badge$ | async as badges">
        <mat-tab *ngIf="badges?.length > 0" [label]="justForMeFilterLabel.myAwards">
            <app-my-awards [badges]="badges" [showTitle]="showTitle"></app-my-awards>
        </mat-tab>
    </ng-container>
    <ng-container *ngIf="(isCustomerOrPartner$ | async) == true">
        <mat-tab [label]="justForMeFilterLabel.myCases">
            <app-cases
                *ngIf="displayFilters.myCases"
                pagingLimit="3"
                [showTitle]="showTitle"
                [showIcons]="false"></app-cases>
        </mat-tab>
        <mat-tab [label]="justForMeFilterLabel.myContacts">
            <app-contacts *ngIf="displayFilters.myContacts" [showTitle]="showTitle" [showIcons]="false"></app-contacts>
        </mat-tab>
    </ng-container>
    <mat-tab [label]="justForMeFilterLabel.additionalSites">
        <app-additional-sites *ngIf="displayFilters.additionalSites"></app-additional-sites>
    </mat-tab>
    <mat-tab *ngIf="(isCssEmployee$ | async) == true" [label]="justForMeFilterLabel.welcomeVideo">
        <ng-template matTabContent>
            <app-welcome-video *ngIf="displayFilters.welcomeVideo"></app-welcome-video>
        </ng-template>
    </mat-tab>
</mat-tab-group>
