import { Component } from '@angular/core';
import { TrackByItem } from '@components';
import { FavoriteId, ResourcesSectionLabel } from '@enums';
import { SECTION_LINKS } from 'src/app/app-header/submenu-data';

interface DeveloperCenterPureconnectCard {
    title: string;
    url: string;
}

@Component({
    selector: 'app-developer-portal-pureconnect',
    templateUrl: './developer-portal-pureconnect.component.html',
    styleUrls: ['./developer-portal-pureconnect.component.css'],
})
export class DeveloperPortalPureconnectComponent implements TrackByItem<DeveloperCenterPureconnectCard> {
    sectionLabel = ResourcesSectionLabel.DEV_PORTAL_PURECONNECT;
    subTitle = '(Popular)';
    externalUrl = 'http://developer.inin.com/';
    favoriteId = FavoriteId.DEVELOPER_PORTAL_PURECONNECT;
    location = SECTION_LINKS.DEVELOPER_PORTAL_PURECONNECT;
    cards: DeveloperCenterPureconnectCard[] = [
        {
            title: 'Quick Reference Guides',
            url: 'https://developer.inin.com/documentation/pages/default.aspx',
        },
        {
            title: 'Interaction Center Web Services (ICWS)',
            url: 'https://help.genesys.com/developer/cic/docs/icws/webhelp/conceptualcontent/welcome.htm',
        },
        {
            title: 'Interaction Center Extension Library (IceLib)',
            url: 'https://help.genesys.com/developer/cic/docs/icelib/webhelp/index.html',
        },
    ];

    trackByItem(_index: number, item: DeveloperCenterPureconnectCard): NonNullable<number | string> {
        return item.title;
    }
}
