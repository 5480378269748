export interface GknCxEvolution {
    excerpt: GknCxEvolutionExcerpt;
    cards: GknCxEvolutionCard[];
    communities: GknCxEvolutionCommunity[];
}

export interface GknCxEvolutionCard {
    id: number;
    title: string;
    description: string;
    cta: string;
    url: string;
    thumbnails: {
        desktop: string;
        mobile: string;
        height: string;
        width: string;
    };
    vertical: boolean;
    tab: GknCxEvolutionCardTab;
    /** contains identifiers for data anlytics platforms */
    dataAnalytics: string[];
}

export enum GknCxEvolutionCardTab {
    GET_STARTED = 'get_started',
    CONNECT = 'connect',
    LEARN_FROM_OTHERS = 'learn_from_others',
}

export interface GknCxEvolutionCommunity {
    id: number;
    name: string;
    url: string;

    /**
     * Contains identifiers for data anlytics platforms. Joined by '-' hyphen
     */
    dataAnalytics: string[];
}

export interface GknCxEvolutionExcerpt {
    primaryExcerpt: string;
    subtitle: string;
    communitiesTitle: string;
    communitiesExcerpt: string;
}
