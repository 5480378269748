import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { KeplerApiAuthorizerInterceptor } from '../../interceptors/kepler-api-authorizer.interceptor';
import { AuthService } from '../auth/auth.service';

/**
 * Sets Kepler Docebo API requests with an Authorization header
 * necessary to verify the the current user's Okta JWT.
 */
@Injectable()
export class KeplerDoceboInterceptor extends KeplerApiAuthorizerInterceptor {
    constructor(authService: AuthService) {
        super(authService, environment.api.kepler.docebo.url);
    }
}
