import { Component, OnInit } from '@angular/core';
import { FavoriteId, ResourcesSectionLabel } from '../../@shared/enums';

import { TrackByItem } from '@components';
import { environment } from '@environments/environment';
import { SECTION_LINKS } from 'src/app/app-header/submenu-data';
import { developerCenterMcCxTopicsData } from '../developer-center-mc-cx/developer-center-mc-cx-data';
import { DeveloperCenterData } from '../developer-center/developer-center.interface';

@Component({
    selector: 'app-developer-center-mc-cx',
    templateUrl: './developer-center-mc-cx.component.html',
    styleUrls: ['./developer-center-mc-cx.component.css'],
})
export class DeveloperCenterMcCxComponent implements OnInit, TrackByItem<DeveloperCenterData> {
    externalUrl = environment.api.developerCenterMcCx.site.baseUrl;
    sectionLabel = ResourcesSectionLabel.DEV_CENTER;
    favoriteId = FavoriteId.DEVELOPER_CENTER;
    developerCenterMcCxTopics: DeveloperCenterData;
    sectionLocation = SECTION_LINKS.DEVELOPER_CENTER;

    ngOnInit(): void {
        this.developerCenterMcCxTopics = developerCenterMcCxTopicsData;
    }

    trackByItem(_index: number, item: DeveloperCenterData): NonNullable<number | string> {
        return item.baseUrl;
    }
}
