<form class="signInForm" [ngClass]="'theme-' + theme" [formGroup]="loginForm" (ngSubmit)="onLogin()">
    <!--SHOW INVALID LOGIN ERROR MESSAGE-->
    <ng-container *ngIf="showSignInWithCredentials">
        <div *ngIf="showLoginError" class="login-error">
            <img
                class="error-icon margin-right-small"
                src="/assets/icons/alerts-outage-site-down-time.svg"
                alt="error icon" />
            <span>Invalid username or password</span>
        </div>
        <input
            id="know-sign-in-username"
            type="text"
            placeholder="Username"
            autocomplete="off"
            class="margin-bottom-small"
            [ngClass]="{ 'is-invalid': invalid }"
            formControlName="username" />
        <input
            id="know-sign-in-password"
            type="password"
            placeholder="Password"
            autocomplete="off"
            class="margin-bottom-small"
            [ngClass]="{ 'is-invalid': invalid }"
            formControlName="password" />
    </ng-container>
    <div class="field-row">
        <!--SUBMIT SIGN IN-->
        <!--NOTE: Sign In click opens Login Modal-->
        <input
            id="know-sign-in"
            class="btn btn-primary-inverse sign-in-button cursor"
            *ngIf="showSignInWithCredentials && !isAuthenticated"
            (click)="onLogin()"
            type="submit"
            value="Sign In" />
        <!--FORGOT PASSWORD-->
        <a
            id="know-forgot-password-link"
            class="primary-link cursor"
            href="https://apps.genesys.com/pcodebroker/resetPcode.aspx"
            target="_blank"
            rel="noopener noreferrer">
            Forgot password?
        </a>
    </div>
    <!--CUSTOM DIVIDER-->
    <div *ngIf="showSignInWithCredentials" class="separator">or</div>
    <!--END CUSTOM DIVIDER-->
    <!--SIGN IN OKTA-->
    <div class="know-sign-in-okta">
        <input
            id="sign-in-with-okta"
            class="btn btn-primary margin-top-small full-width cursor"
            *ngIf="!isAuthenticated"
            type="button"
            (click)="emitSignInWithOkta()"
            value="Sign in with Okta" />
    </div>
    <!--END SIGN IN OKTA-->
</form>
<!--SIGN-UP LINK-->
<div class="sign-up">
    <span class="new-user">
        New user?
        <a
            id="know-sign-up-link"
            class="brand-link cursor"
            href=" https://genesyspartner.force.com/customercare/RequestAccount"
            target="_blank"
            rel="noopener noreferrer">
            Sign up
        </a>
    </span>
</div>
