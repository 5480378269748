<app-section-header
    [favoriteId]="favoriteId"
    [title]="sectionLabel"
    [subTitle]="sectionSubTitle"
    [section]="sectionLocation"
    externalUrl="https://appfoundry.genesys.com/filter/genesyscloud"></app-section-header>
<app-tile-layout [maxColumns]="isMobileViewport ? 2 : 5" [multiColumnMobile]="true">
    <li *ngFor="let tile of tiles">
        <app-tile-vertical
            [imageUrl]="tile.imageUrl"
            [titleLink]="true"
            [title]="tile.title"
            [description]="isMobileViewport ? null : tile.description"
            [url]="tile.url"
            [isImageShort]="true"
            [isCustomFooterLink]="true">
            <div class="external-link-div margin-top-smaller footer-min-height" customFooterLink>
                <a
                    class="cursor link-n read-more"
                    [href]="tile?.url"
                    target="_blank"
                    rel="noopener noreferrer nofollow">
                    Learn more
                </a>
                <app-external-url [url]="tile?.url"></app-external-url>
            </div>
        </app-tile-vertical>
    </li>
</app-tile-layout>
