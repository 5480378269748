import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SearchBarComponent } from './search-bar.component';
import { SearchSuggestionsPipe } from './search-suggestions.pipe';

@NgModule({
    declarations: [SearchBarComponent, SearchSuggestionsPipe],
    imports: [CommonModule, FormsModule, ReactiveFormsModule],
    exports: [SearchBarComponent],
})
export class SearchBarModule {}
