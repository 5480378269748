import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TileLayoutComponent } from './tile-layout.component';

@NgModule({
    declarations: [TileLayoutComponent],
    imports: [CommonModule],
    exports: [TileLayoutComponent],
})
export class TileLayoutModule {}
