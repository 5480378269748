<app-tile-layout *ngIf="articles?.length > 0" maxColumns="2">
    <li class="news-item" *ngFor="let item of articles; trackBy: trackByItem">
        <app-tile-vertical
            [showImage]="false"
            [titleLink]="true"
            [bigImage]="true"
            [description]="formatExcerpt(item?.excerpt?.rendered)"
            [icon]="icon"
            [details]="label"
            [border]="false"
            [vertical]="isMobileViewport"
            [isCustomTileLink]="true"
            [isCustomTitleLink]="true"
            [isCustomFooterLink]="true"
            [dataAnalytics]="item.dataAnalytics">
            <div customTileLink>
                <div class="product-help-article" *ngIf="!isMobileViewport">
                    <div
                        class="default-image cursor tile-background-color mat-elevation-z6"
                        *ngIf="!item.openNewTab; else tileLinkNewTab">
                        <img
                            src="/assets/icons/content-tags-article-medium.svg"
                            alt="Article icon"
                            width="39px"
                            height="50px"
                            (click)="openArticle(item)"
                            [attr.data-analytics]="item.dataAnalytics?.join('-')" />
                    </div>
                    <ng-template #tileLinkNewTab>
                        <a [href]="item.link" target="_blank" rel="nofollow">
                            <div class="default-image cursor tile-background-color mat-elevation-z6">
                                <img src="/assets/icons/genesys-g.svg" width="28px" height="45px" alt="Genesys logo" />
                            </div>
                        </a>
                    </ng-template>
                </div>
            </div>
            <div customTitleLink>
                <a
                    class="cursor primary-link"
                    (click)="openArticle(item)"
                    *ngIf="!item.openNewTab; else titleLinkNewTab"
                    [attr.data-analytics]="item.dataAnalytics?.join('-')">
                    <h2 class="h3 sub-title-3 text-brand-navy margin-top-small margin-bottom-smaller cursor">
                        {{ item?.title?.rendered | stripHtml }}
                    </h2>
                </a>
                <ng-template #titleLinkNewTab>
                    <a
                        class="cursor primary-link"
                        [href]="item.link"
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                        [attr.data-analytics]="item.dataAnalytics?.join('-')">
                        <h2 class="h3 sub-title-3 text-brand-navy margin-top-small margin-bottom-smaller cursor">
                            {{ item?.title?.rendered | stripHtml }}
                        </h2>
                    </a>
                </ng-template>
            </div>
            <div class="external-link-div margin-top-smaller" customFooterLink>
                <button
                    *ngIf="!item.openNewTab; else footerLinkNewTab"
                    class="button-link cursor link-n"
                    (click)="openArticle(item)"
                    [attr.data-analytics]="item.dataAnalytics?.join('-')">
                    Read more
                </button>
                <ng-template #footerLinkNewTab>
                    <a
                        class="cursor link-n"
                        [href]="item.link"
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                        [attr.data-analytics]="item.dataAnalytics?.join('-')">
                        Read more
                    </a>
                </ng-template>
                <div class="external-icons">
                    <app-share [link]="item.link"></app-share>
                    <app-external-url [url]="item.link"></app-external-url>
                </div>
            </div>
        </app-tile-vertical>
    </li>
</app-tile-layout>

<ng-container *ngIf="selectedItem">
    <app-article
        [isCustomContent]="true"
        [customModalCss]="['light-mode-modal', 'text-black', 'auto-height']"
        [customIconCss]="['black-icon', 'close-align']"
        [customSecondaryHeaderCSS]="'header-secondary'"
        [customShareLink]="selectedItem.link"
        (closeArticle)="closeArticle()"
        [dataAnalytics]="selectedItem.dataAnalytics">
        <div customContent>
            <iframe
                id="productHelpArticleBlobFrame"
                [src]="selectedItem.link | safe: 'resourceUrl'"
                class="article-iframe"></iframe>
        </div>
    </app-article>
</ng-container>
