/**
 * Call-to-action for Beyond Training items. Can be mapped from a Docebo
 * status or manually set.
 */
export enum BeyondCallToAction {
    ENROLL = 'Enroll',
    REVIEW = 'Review',
    CONTINUE = 'Continue',
    START = 'Start',
    WAITLIST = 'Waitlist',
    WATCH = 'Watch',
    VIEW = 'View',
    VIEW_DETAILS = 'View Details',
    VIEW_SESSIONS = 'View Sessions',
}
