import { DoceboEnrollment } from '../interfaces';

export const mockDoceboEnrollments: DoceboEnrollment[] = [
    {
        id: 11690,
        user_id: 528229,
        date_last_updated: '2021-07-08 18:57:05', // prod sorts (desc) by this field
        subscribed_by: 528229,
        username: 'work.test90@shaw.ca',
        name: 'Genesys Cloud Telephony and Systems Basics',
        description:
            '<p>Stand up your organization quickly by learning basic system set-up requirements for telephony. </p><p>Topics include:</p><ul type="disc"><li>Accessing Genesys Cloud</li><li>Adding agents and assigning roles/permissions</li><li>Queue configuration for ACW and wrap-up codes</li><li>Evaluation and routing methods</li><li>Agent utilization</li><li>Telephony services and WebRTC</li></ul>',
        type: 'elearning',
        status: 'in_progress',
        location_name: null,
        webinar_tool: null,
        session_date_begin: null,
        session_time_begin: null,
        session_timezone: null,
        lang_code: 'en',
        decommissioned_at: null,
        removed_at: null,
        code: 'GU - GC-TLPHNY-BASICS', // this code represents a Beyond enrollment
        uidCourse: 'E-VR9O20',
        course_begin_date: null,
        enroll_date_of_enrollment: '2021-07-08 18:56:47',
        enroll_begin_date: null,
        course_end_date: null,
        enroll_end_date: null,
        course_complete_date: null,
        score: 0,
        enrollment_fields: null,
        url: '/learn/course/11690/genesys-cloud-telephony-and-systems-basics',
        rating_option: 'only_completed',
        rating: 5,
        can_enter: true,
        duration: 0,
        level: 'learner',
        hidden: false,
        slug: 'genesys-cloud-telephony-and-systems-basics',
        lang: 'english',
        lang_label: 'English',
        is_new: false,
        image_url:
            '//d36spl5w3z9i0o.cloudfront.net/files/g/e/genesyssandbox_docebosaas_com/assets/courselogo/original/Genesys-Cloud-Thumbnail+%282%29-2021-02-02-16-13-07.jpeg',
        total_time: 12,
        soft_deadline: false,
        subscription: {
            subscribed: false,
        },
        actions: [
            {
                type: 'AddToPlaylist',
                label: 'Add to Playlist',
                color: '#333',
                url: '#',
            },
            {
                type: 'Hide',
                label: 'Hide',
                color: '#333',
                url: '#',
            },
            {
                type: 'MarkAsOutdated',
                label: 'Mark as Outdated',
                color: '#333',
                url: '#',
            },
        ],
        outdated: {
            already_marked: false,
            total_users: null,
        },
    },
    {
        id: 11782,
        user_id: 528229,
        date_last_updated: '2022-09-20 20:11:04', // prod sorts (desc) by this field
        subscribed_by: 528229,
        username: 'work.test90@shaw.ca',
        name: 'COVID-19 Rapid Response Virtual Agent | Genesys Cloud - eLearning',
        description:
            '<p>Genesys has partnered with Google to offer a virtual agent in Genesys Cloud that can answer customers’ questions about the COVID-19 pandemic.  The COVID-19 Rapid Response Virtual Agent can answer frequently asked questions about COVID-19 and provide current advice from public health authorities. This course will show you how to create the virtual agent in Google Dialogflow, and how to integrate Dialogflow with Genesys Cloud.  Learner will also observe how the virtual agent interacts with end users.\n</p>',
        type: 'elearning',
        status: 'completed',
        location_name: null,
        webinar_tool: null,
        session_date_begin: null,
        session_time_begin: null,
        session_timezone: null,
        lang_code: 'en',
        decommissioned_at: null,
        removed_at: null,
        code: 'GCX-R-VIR', // this code represents a GenEd enrollment
        uidCourse: 'E-05YZM0',
        course_begin_date: null,
        enroll_date_of_enrollment: '2022-09-20 20:09:26',
        enroll_begin_date: null,
        course_end_date: null,
        enroll_end_date: null,
        course_complete_date: '2022-09-20 20:10:54',
        score: 0,
        enrollment_fields: null,
        url: '/learn/course/11782/covid-19-rapid-response-virtual-agent-genesys-cloud-elearning',
        rating_option: 'only_completed',
        rating: 5,
        can_enter: true,
        duration: 0,
        level: 'learner',
        hidden: false,
        slug: 'covid-19-rapid-response-virtual-agent-genesys-cloud-elearning',
        lang: 'english',
        lang_label: 'English',
        is_new: false,
        image_url:
            '//d36spl5w3z9i0o.cloudfront.net/files/g/e/genesyssandbox_docebosaas_com/assets/courselogo/original/Genesys-Cloud-Thumbnail+%282%29-2021-02-02-16-13-07.jpeg',
        total_time: 22,
        soft_deadline: false,
        subscription: {
            subscribed: false,
        },
        actions: [
            {
                type: 'AddToPlaylist',
                label: 'Add to Playlist',
                color: '#333',
                url: '#',
            },
            {
                type: 'Hide',
                label: 'Hide',
                color: '#333',
                url: '#',
            },
            {
                type: 'MarkAsOutdated',
                label: 'Mark as Outdated',
                color: '#333',
                url: '#',
            },
        ],
        outdated: {
            already_marked: false,
            total_users: null,
        },
    },
    {
        id: 12249,
        user_id: 528229,
        date_last_updated: '2022-09-20 20:11:50', // prod sorts (desc) by this field
        subscribed_by: 528229,
        username: 'work.test90@shaw.ca',
        name: 'Introduction to Engage Cloud for Administrators - eLearning',
        description:
            '<p>Introduction to Engage Cloud for Administrators offers a high-level overview of Genesys Engage Cloud, system requirements, architecture, security, roles, and demonstrates the interfaces and tasks an Administrator will use to configure and manage their contact center.</p>',
        type: 'elearning',
        status: 'in_progress',
        location_name: null,
        webinar_tool: null,
        session_date_begin: null,
        session_time_begin: null,
        session_timezone: null,
        lang_code: 'en',
        decommissioned_at: null,
        removed_at: null,
        code: '3G - GLD9-ADM-INT', // this code represents a Beyond enrollment
        uidCourse: 'E-0J62WV',
        course_begin_date: null,
        enroll_date_of_enrollment: '2022-09-20 20:11:35',
        enroll_begin_date: null,
        course_end_date: null,
        enroll_end_date: null,
        course_complete_date: null,
        score: 0,
        enrollment_fields: null,
        url: '/learn/course/12249/introduction-to-engage-cloud-for-administrators-elearning',
        rating_option: 'only_completed',
        rating: 4,
        can_enter: true,
        duration: 0,
        level: 'learner',
        hidden: false,
        slug: 'introduction-to-engage-cloud-for-administrators-elearning',
        lang: 'english',
        lang_label: 'English',
        is_new: false,
        image_url:
            '//d36spl5w3z9i0o.cloudfront.net/files/g/e/genesyssandbox_docebosaas_com/assets/courselogo/original/Genesys-Engage-Cloud-Thumbnail-2020-05-22-20-34-38.jpeg',
        total_time: 9,
        soft_deadline: false,
        subscription: {
            subscribed: false,
        },
        actions: [
            {
                type: 'AddToPlaylist',
                label: 'Add to Playlist',
                color: '#333',
                url: '#',
            },
            {
                type: 'Hide',
                label: 'Hide',
                color: '#333',
                url: '#',
            },
            {
                type: 'MarkAsOutdated',
                label: 'Mark as Outdated',
                color: '#333',
                url: '#',
            },
        ],
        outdated: {
            already_marked: false,
            total_users: null,
        },
    },
    {
        id: 16196,
        user_id: 528229,
        date_last_updated: '2021-10-28 14:16:10', // prod sorts (desc) by this field
        subscribed_by: 528229,
        username: 'work.test90@shaw.ca',
        name: 'Genesys Engage Cloud: Agent Desktop for Agents - Getting Started - eLearning',
        description:
            '<p><span style="color:rgb(0,0,0);font-family:Verdana, sans-serif;font-size:11.3333px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:left;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb(255,255,255);display:inline;float:none;">Genesys Engage Cloud: Agent Desktop for Agents – Getting Started is designed to provide you with all the information an agent needs to get started using Agent Desktop to manage the customer service experience in the contact center.</span>\n</p>',
        type: 'elearning',
        status: 'completed',
        location_name: null,
        webinar_tool: null,
        session_date_begin: null,
        session_time_begin: null,
        session_timezone: null,
        lang_code: 'en',
        decommissioned_at: null,
        removed_at: null,
        code: 'BYD - GEC-WWE9-AGS', // this code represents a Beyond enrollment
        uidCourse: 'E-VX27OP',
        course_begin_date: null,
        enroll_date_of_enrollment: '2021-10-28 14:15:37',
        enroll_begin_date: null,
        course_end_date: null,
        enroll_end_date: null,
        course_complete_date: '2021-10-28 14:16:09',
        score: 0,
        enrollment_fields: null,
        url: '/learn/course/16196/genesys-engage-cloud-agent-desktop-for-agents-getting-started-elearning',
        rating_option: 'only_completed',
        rating: 3,
        can_enter: true,
        duration: 0,
        level: 'learner',
        hidden: false,
        slug: 'genesys-engage-cloud-agent-desktop-for-agents-getting-started-elearning',
        lang: 'english',
        lang_label: 'English',
        is_new: false,
        image_url:
            '//d36spl5w3z9i0o.cloudfront.net/files/g/e/genesyssandbox_docebosaas_com/assets/courselogo/original/Genesys-Engage-Cloud-Thumbnail-2020-05-22-20-34-38.jpeg',
        total_time: 23,
        soft_deadline: false,
        subscription: {
            subscribed: false,
        },
        actions: [
            {
                type: 'AddToPlaylist',
                label: 'Add to Playlist',
                color: '#333',
                url: '#',
            },
            {
                type: 'Hide',
                label: 'Hide',
                color: '#333',
                url: '#',
            },
            {
                type: 'MarkAsOutdated',
                label: 'Mark as Outdated',
                color: '#333',
                url: '#',
            },
        ],
        outdated: {
            already_marked: false,
            total_users: null,
        },
    },
];
