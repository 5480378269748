import { Component } from '@angular/core';
import { LegacyGetStartedLabel } from '../@shared/enums/label.enum';

@Component({
    selector: 'app-legacy-get-started',
    templateUrl: './legacy-get-started.component.html',
    styleUrls: ['./legacy-get-started.component.css'],
})
export class LegacyGetStartedComponent {
    sectionLabel = LegacyGetStartedLabel.GET_STARTED;
}
