import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-external-url',
    templateUrl: './external-url.component.html',
    styleUrls: ['./external-url.component.css'],
})
export class ExternalUrlComponent {
    @Input() url: string;
}
