import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from '../../pipes/pipes.module';
import { IframeTrackerDirective } from './iframe-tracker.directive';
import { VideoContainerComponent } from './video-container.component';

@NgModule({
    declarations: [VideoContainerComponent, IframeTrackerDirective],
    imports: [CommonModule, PipesModule],
    exports: [VideoContainerComponent],
})
export class VideoContainerModule {}
