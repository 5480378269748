<app-section-header
    *desktop
    [title]="(selectedProduct$ | async).displayName + ' ' + sectionLabel"
    [section]="sectionLocation"
    [favoriteId]="favoriteId">
    <app-section-header-hyperlink
        *products="[productEnum.GENESYS_CLOUD_CX]"
        url="https://login.mypurecloud.com/#/authenticate"
        [text]="sectionHyperlinkLabel">
    </app-section-header-hyperlink>
</app-section-header>
<app-section-header
    *mobile
    [title]="'Product ' + sectionLabel"
    [section]="sectionLocation"
    [favoriteId]="favoriteId"></app-section-header>

<ng-container *desktop>
    <ng-container *authed>
        <ng-container *products="[productEnum.GENESYS_CLOUD_CX]">
            <mat-grid-list cols="1" rowHeight="300px">
                <mat-grid-tile>
                    <app-roadmaps class="row" flexDirection="row"></app-roadmaps>
                </mat-grid-tile>
            </mat-grid-list>
        </ng-container>
    </ng-container>

    <mat-grid-list
        cols="3"
        rowHeight="28rem"
        [ngClass]="{
            authed: isAuthenticated$ | async,
            gc: (selectedProduct$ | async)?.uid == productEnum.GENESYS_CLOUD_CX
        }">
        <ng-container *authed>
            <ng-container *products="[productEnum.GENESYS_MULTICLOUD, productEnum.GENESYS_ENGAGE_ON_PREM]">
                <mat-grid-tile>
                    <app-roadmaps class="column" flexDirection="column"></app-roadmaps>
                </mat-grid-tile>
            </ng-container>
        </ng-container>

        <ng-container *products="[productEnum.GENESYS_CLOUD_CX]">
            <mat-grid-tile>
                <app-fundamentals-status></app-fundamentals-status>
            </mat-grid-tile>
        </ng-container>

        <mat-grid-tile>
            <app-release-notes></app-release-notes>
        </mat-grid-tile>

        <ng-container *authed>
            <ng-container
                *products="[
                    productEnum.GENESYS_CLOUD_CX,
                    productEnum.GENESYS_MULTICLOUD,
                    productEnum.GENESYS_ENGAGE_ON_PREM
                ]">
                <mat-grid-tile>
                    <app-product-ideas-lab></app-product-ideas-lab>
                </mat-grid-tile>
            </ng-container>
        </ng-container>
    </mat-grid-list>
</ng-container>

<ng-container *mobile>
    <!--CAROUSEL CONTENT START-->
    <ng-container *authed>
        <ngu-carousel
            #myCarousel
            *products="[productEnum.GENESYS_CLOUD_CX]"
            [inputs]="carouselConfig"
            [dataSource]="[roadmaps, status, releaseNotes, productIdeasLab]">
            <div *nguCarouselDef="let item" class="item">
                <div class="tile">
                    <ng-container *ngTemplateOutlet="item"></ng-container>
                </div>
            </div>

            <ng-template #roadmaps>
                <app-roadmaps flexDirection="column"></app-roadmaps>
            </ng-template>
            <ng-template #status>
                <app-fundamentals-status></app-fundamentals-status>
            </ng-template>
            <ng-template #releaseNotes>
                <app-release-notes></app-release-notes>
            </ng-template>
            <ng-template #productIdeasLab>
                <app-product-ideas-lab></app-product-ideas-lab>
            </ng-template>

            <ul class="carousel-points" NguCarouselPoint>
                <li
                    *ngFor="let j of myCarousel.pointNumbers; let i = index; trackBy: trackByItem"
                    [class.active]="j == myCarousel.activePoint"
                    (click)="myCarousel.moveTo(i)"></li>
            </ul>
        </ngu-carousel>

        <ngu-carousel
            #myCarousel
            *products="[productEnum.GENESYS_MULTICLOUD, productEnum.GENESYS_ENGAGE_ON_PREM]"
            [inputs]="carouselConfig"
            [dataSource]="[roadmaps, releaseNotes, productIdeasLab]">
            <div *nguCarouselDef="let item" class="item">
                <div class="tile">
                    <ng-container *ngTemplateOutlet="item"></ng-container>
                </div>
            </div>

            <ng-template #roadmaps>
                <app-roadmaps flexDirection="column"></app-roadmaps>
            </ng-template>
            <ng-template #releaseNotes>
                <app-release-notes></app-release-notes>
            </ng-template>
            <ng-template #productIdeasLab>
                <app-product-ideas-lab></app-product-ideas-lab>
            </ng-template>

            <ul class="carousel-points" NguCarouselPoint>
                <li
                    *ngFor="let j of myCarousel.pointNumbers; let i = index; trackBy: trackByItem"
                    [class.active]="j == myCarousel.activePoint"
                    (click)="myCarousel.moveTo(i)"></li>
            </ul>
        </ngu-carousel>

        <ngu-carousel
            #myCarousel
            *products="[productEnum.PURECONNECT]"
            [dataSource]="[releaseNotes]"
            [inputs]="carouselConfig">
            <div *nguCarouselDef="let item" class="item">
                <div class="tile">
                    <ng-container *ngTemplateOutlet="item"></ng-container>
                </div>
            </div>

            <ng-template #releaseNotes>
                <app-release-notes></app-release-notes>
            </ng-template>

            <ul class="carousel-points" NguCarouselPoint>
                <li
                    *ngFor="let j of myCarousel.pointNumbers; let i = index; trackBy: trackByItem"
                    [class.active]="j == myCarousel.activePoint"
                    (click)="myCarousel.moveTo(i)"></li>
            </ul>
        </ngu-carousel>
    </ng-container>

    <ng-container *unauthed>
        <ngu-carousel
            #myCarousel
            *products="[productEnum.GENESYS_CLOUD_CX]"
            [inputs]="carouselConfig"
            [dataSource]="[status, releaseNotes]">
            <div *nguCarouselDef="let item" class="item">
                <div class="tile">
                    <ng-container *ngTemplateOutlet="item"></ng-container>
                </div>
            </div>

            <ng-template #status>
                <app-fundamentals-status></app-fundamentals-status>
            </ng-template>
            <ng-template #releaseNotes>
                <app-release-notes></app-release-notes>
            </ng-template>

            <ul class="carousel-points" NguCarouselPoint>
                <li
                    *ngFor="let j of myCarousel.pointNumbers; let i = index; trackBy: trackByItem"
                    [class.active]="j == myCarousel.activePoint"
                    (click)="myCarousel.moveTo(i)"></li>
            </ul>
        </ngu-carousel>

        <ngu-carousel
            #myCarousel
            *products="[productEnum.GENESYS_MULTICLOUD, productEnum.GENESYS_ENGAGE_ON_PREM, productEnum.PURECONNECT]"
            [inputs]="carouselConfig"
            [dataSource]="[releaseNotes]">
            <div *nguCarouselDef="let item" class="item">
                <div class="tile">
                    <ng-container *ngTemplateOutlet="item"></ng-container>
                </div>
            </div>

            <ng-template #releaseNotes>
                <app-release-notes></app-release-notes>
            </ng-template>

            <ul class="carousel-points" NguCarouselPoint>
                <li
                    *ngFor="let j of myCarousel.pointNumbers; let i = index; trackBy: trackByItem"
                    [class.active]="j == myCarousel.activePoint"
                    (click)="myCarousel.moveTo(i)"></li>
            </ul>
        </ngu-carousel>
    </ng-container>
</ng-container>
