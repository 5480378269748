import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-tile-title',
    templateUrl: './tile-title.component.html',
    styleUrls: ['./tile-title.component.css'],
})
export class TileTitleComponent {
    /**
     * Add a minimum height to this tile's title. This is typically useful when vertically aligning
     * multi-line titles and descriptions for tile-layout of tile-vertical compoennts.
     */
    @Input() titleMinHeight = true;
}
