import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';

import { Injectable } from '@angular/core';

// https://stackoverflow.com/a/51059505
@Injectable({
    providedIn: 'root',
})
export class ExternalRedirectGuard implements CanActivate {
    canActivate(route: ActivatedRouteSnapshot): boolean {
        window.location.replace(route.data['externalUrl']);
        return true;
    }
}
