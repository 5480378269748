import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-announcement-alert',
    templateUrl: './announcement-alert.component.html',
    styleUrls: ['./announcement-alert.component.css'],
})
export class AnnouncementAlertComponent {
    @Input() fillColor = '#ffffff';
}
