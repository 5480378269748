import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ComponentsModule } from '@components';
import { ToolsCardModule } from './tools-card/tools-card.module';
import { ToolsComponent } from './tools.component';

@NgModule({
    declarations: [ToolsComponent],
    imports: [CommonModule, ComponentsModule, ToolsCardModule],
    exports: [ToolsComponent],
})
export class ToolsModule {}
