import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Input } from '@angular/core';
import { FavoriteId } from '@enums';
import { FavoritesService, Product, ToastColor, ToastNotificationService } from '@services';
import { SECTION_LINKS } from 'src/app/app-header/submenu-data';

@Component({
    selector: 'app-copy-link',
    templateUrl: './copy-link.component.html',
    styleUrls: ['./copy-link.component.css'],
})
export class CopyLinkComponent {
    @Input() section: SECTION_LINKS;
    @Input() title: string;
    @Input() favorite?: FavoriteId;
    @Input() product: Product;

    constructor(
        private clipboard: Clipboard,
        private toast: ToastNotificationService,
        private favoritesService: FavoritesService,
    ) {}

    copyDirectSectionUrl(): void {
        if (!this.section) {
            this.toast.notify({
                innerHtml: `<strong>Nothing copied! Section not found!</strong>`,
                color: ToastColor.CRITICAL,
            });
        } else if (!this.title) {
            this.toast.notify({
                innerHtml: `<strong>Nothing copied! Section Title not found!</strong>`,
                color: ToastColor.CRITICAL,
            });
        } else {
            let fragment, favoriteLabel;
            let label = this.title;
            if (this.favorite && this.favorite.toString() != '') {
                fragment = this.favoritesService.buildFragment(this.section, this.favorite);
                favoriteLabel = this.favoritesService.mapToLabel(this.favorite, this.product);
                if (favoriteLabel && this.title != favoriteLabel) {
                    label = favoriteLabel;
                }
            } else {
                fragment = this.favoritesService.buildFragment(this.section);
            }
            const copyLink = new URL(window.location.href);
            copyLink.hash = fragment;

            const copyStatus = this.clipboard.copy(copyLink.toString());
            let copyStatusText = '';
            let copyStatusColor;
            if (copyStatus) {
                copyStatusText = 'link copied.';
                copyStatusColor = ToastColor.INFO;
            } else {
                copyStatusText = 'link was not copied!';
                copyStatusColor = ToastColor.CRITICAL;
            }
            this.toast.notify({
                innerHtml: `<strong>${label}</strong> ${copyStatusText}`,
                color: copyStatusColor,
            });
        }
    }
}
