import { Directive, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserService } from '@services';
import { Subscription, tap } from 'rxjs';

/**
 * Structural directive to place on components that should only be shown to authenticated users. Works well alongside UnauthedDirective.
 * Syntactic shorthand for `*userRoles="[userRoleEnum.CUSTOMER, userRoleEnum.PARTNER, userRoleEnum.EMPLOYEE]"`
 *
 * @example
 * <p *authed>
 *   hello, {{ user.name }}! You are logged in!
 * </p>
 * <p *unauthed>
 *   hello, unauthed user!
 * </p>
 */
@Directive({
    selector: '[authed]',
})
export class AuthedDirective implements OnInit, OnDestroy {
    private sub: Subscription;

    constructor(
        private userService: UserService,
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
    ) {}

    ngOnInit(): void {
        this.sub = this.userService.isAuthenticated$
            .pipe(
                tap((isAuthenticated: boolean) => {
                    this.viewContainer.clear();
                    if (isAuthenticated) {
                        this.viewContainer.createEmbeddedView(this.templateRef);
                    }
                }),
            )
            .subscribe();
    }

    ngOnDestroy(): void {
        this.sub?.unsubscribe();
    }
}
