import { EventEmitter, Inject, Injectable, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import { OktaAuth, UserClaims } from '@okta/okta-auth-js';
import { Observable, ReplaySubject, filter, firstValueFrom, from, map, switchMap, tap } from 'rxjs';

/**
 * Service responsible for authenticating a user with Okta
 */
@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private _isAuthenticated$ = new ReplaySubject<boolean>(1);
    private _claims$: ReplaySubject<UserClaims> = new ReplaySubject(1);
    @Output() openLoginModal: EventEmitter<string> = new EventEmitter();

    constructor(
        private activatedRoute: ActivatedRoute,
        private authStateService: OktaAuthStateService,
        @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
    ) {
        this.sessionExists()
            .then((sessionExists) => {
                if (sessionExists) {
                    this.setTokensWithoutPrompt().catch((err) =>
                        console.error('Failed to set tokens without prompt.', { cause: err }),
                    );
                }
            })
            .catch((err) => console.error('Failed to determine if a session already exists.', { cause: err }));

        this.authStateService.authState$
            .pipe(
                tap((state) => this._isAuthenticated$.next(state.isAuthenticated)),
                map((state) => state.isAuthenticated),
                filter((isAuthenticated) => isAuthenticated === true),
                switchMap(() => from(this.oktaAuth.getUser())),
                tap((claims) => this._claims$.next(claims)),
            )
            .subscribe();
    }

    /**
     * Do not use. Use UserService.isAuthenticated$ to reduce injected dependencies.
     * @returns observable indicating true/false
     */
    get isAuthenticated$(): Observable<boolean> {
        return this._isAuthenticated$.asObservable();
    }

    /**
     * @returns User claims within the Okta JWT.
     */
    get claims$(): Observable<UserClaims> {
        return this._claims$;
    }

    /**
     * @returns the Okta JWT's access token
     */
    get accessToken(): string {
        return this.oktaAuth.getAccessToken();
    }

    /**
     * @returns `true` if an Okta session already exists in this browser
     */
    private async sessionExists(): Promise<boolean> {
        return this.oktaAuth.session.exists();
    }

    /**
     * Sets the user's Okta tokens into storage.
     * Recommended: ensure a {@link sessionExists()} before calling this function
     * @return a void promise
     */
    private async setTokensWithoutPrompt(): Promise<void> {
        return this.oktaAuth.token.getWithoutPrompt().then((res) => {
            this.oktaAuth.tokenManager.setTokens(res.tokens);
            return Promise.resolve();
        });
    }

    /**
     * Redirects a user to the Okta hosted login page for signing in
     * The OktaCallbackComponent setup in app-routing.module.ts handles
     * all the token management on successful authentication.
     *
     * @returns a void Promise
     */
    async redirectToOktaHostedLogin(): Promise<void> {
        return firstValueFrom(this.activatedRoute.url)
            .then((urls) => urls[0])
            .then((url) => {
                // NOTE: Redirect back to window location path name or current slug route (ex. /education-an-training)
                // and the window location search (ex. ?query=genesys%20cloud), if there was any pre-existing search.
                if (url.path.includes('/login')) {
                    // /login is only used by the Okta tile to initiate a login process from Okta.
                    return this.oktaAuth.signInWithRedirect({ originalUri: '/' });
                } else {
                    return this.oktaAuth.signInWithRedirect({
                        originalUri: window.location.pathname + window.location.search,
                    });
                }
            });
    }

    /**
     * Terminates the session with the Know Okta app, but not other Okta apps.
     * Removes JWT from local storage
     *
     * @returns a void Promise
     */
    async signOut(): Promise<void> {
        return this.oktaAuth.signOut();
    }

    /**
     * Emits an event that is used to notify login modal to open.
     */
    emitOpenLoginModal() {
        this.openLoginModal.emit();
    }
}
