import { Component, OnInit } from '@angular/core';

import { environment } from '@environments/environment';
import { Observable, ReplaySubject } from 'rxjs';
import { DeveloperCenterBlog } from './blog.interface';
import { BlogService } from './blog.service';

@Component({
    selector: 'app-blog',
    templateUrl: './blog.component.html',
    styleUrls: ['./blog.component.css'],
})
export class BlogComponent implements OnInit {
    baseUrl = environment.api.developerCenter.site.baseUrl;
    selectedUrl$: ReplaySubject<string> = new ReplaySubject(1);
    title$: ReplaySubject<string> = new ReplaySubject(1);
    blogs$: Observable<DeveloperCenterBlog[]>;

    constructor(private blogService: BlogService) {}

    ngOnInit(): void {
        this.blogs$ = this.blogService.blogs$;
    }

    openModal(blog: any): void {
        this.selectedUrl$.next(this.baseUrl.substring(0, this.baseUrl.lastIndexOf('/')) + blog.link);
        this.title$.next(blog.title);
    }

    closeModal(): void {
        this.selectedUrl$.next(undefined);
        this.title$.next(undefined);
    }
}
