<!--NOTE: 
    1. Use this if there is just logo or Genesys logo SVG and has no text or links in tile image
    2. If tile has custom tile-background-color etc, then it must be passed as part of customImageCSS-->
<div class="default-image" [ngClass]="customImageCss ? customImageCss : ''">
    <!--Custom Logo SVG like announcments, article, etc.. that centered in the tile with a css background color.-->
    <ng-container *ngTemplateOutlet="imageSVGUrl ? customSVGIcon : defaultGenesysLogoSVG"></ng-container>
</div>

<ng-template #customSVGIcon>
    <img [src]="imageSVGUrl" alt="{{ imageSVGUrl }} logo" [attr.height]="height" [attr.width]="width" [alt]="altText" />
</ng-template>

<ng-template #defaultGenesysLogoSVG>
    <app-tile-default-logo></app-tile-default-logo>
</ng-template>
