import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Observable, map } from 'rxjs';

import { NgTemplateOutlet } from '@angular/common';
import { TrackByItem } from '@components';
import { SECTION_LINKS } from 'src/app/app-header/submenu-data';
import { Tile } from '../tile-layout/tile.interface';

/**
 * A responsive container that reduces to a carousel for mobile viewports.
 *
 * This component utilizes the NgTemplateOutletContext to allow parent
 * components to use NgTemplate to define the content for the container.
 *
 * {@example}
 * // component.ts
 * interface IFoo {
 *   title: string;
 *   subtitle: string;
 *   data: [{
 *     url: string;
 *   }];
 * }
 *
 * foo$ = this.http.get<IFoo>('https://example.com/api');
 *
 * // component.html
 * <app-responsive-container
 *   [tiles]="foo$ | async"
 *   [tileTemplate]="fooTile">
 * </app-responsive-container>
 * <ng-template #fooTile let-context="$context">
 *   <!-- context is of type IFoo -->
 *   <h1>{{ context.title }}</h1>
 *   <h2>{{ context.subtitle }}</h2>
 *   <ul>
 *     <li *ngFor="let d of context.data">
 *       <span>{{ d.url }}</span>
 *     </li>
 *   </ul>
 * <ng-template>
 */
@Component({
    selector: 'app-responsive-container',
    templateUrl: './responsive-container.component.html',
    styleUrls: ['./responsive-container.component.css'],
})
export class ResponsiveContainerComponent implements OnInit, OnChanges, TrackByItem<any> {
    @Input() sectionLabel: string;
    @Input() favoriteId: string;
    @Input() externalUrl: string;
    @Input() tiles: Tile[] = [];
    @Input() tileTemplate: NgTemplateOutlet;
    @Input() maxDesktopTiles = 3;
    @Input() maxMobileTiles = 3;
    @Input() useDesktopCarousel = false;
    @Input() section: SECTION_LINKS;
    desktopTiles: Tile[][] = [];

    isMobile$: Observable<boolean>;

    constructor(private breakpointObserver: BreakpointObserver) {}

    ngOnInit(): void {
        this.isMobile$ = this.breakpointObserver
            .observe('(max-width: 1020px)')
            .pipe(map((state: BreakpointState) => state.matches));
    }

    ngOnChanges(): void {
        this.chunkifyTiles();
    }

    /**
     * Breaks tile array into sections
     */
    chunkifyTiles(): void {
        const carouselChunks = [];
        const pageSize = 3;

        for (let i = 0; i < this.tiles.length; i += pageSize) {
            carouselChunks.push(this.tiles.slice(i, i + pageSize));
        }

        const pageSizeLeftover = this.tiles.length % pageSize;
        if (pageSizeLeftover !== 0) {
            for (let i = 0; i < pageSize - pageSizeLeftover; i++) {
                carouselChunks[carouselChunks.length - 1].push(null);
            }
        }
        this.desktopTiles = carouselChunks;
    }

    trackByItem(_index: number, tile: Tile): NonNullable<number | string> {
        return tile.title;
    }
}
