<ng-container *desktop>
    <h3 class="text-brand-navy sub-title-2">
        {{ excerpt?.subtitle }}
    </h3>
    <p class="p-body-large">
        {{ excerpt?.primaryExcerpt }}
    </p>
</ng-container>
<ng-container *mobile>
    <h3 class="text-brand-navy sub-title-3">
        {{ excerpt?.subtitle }}
    </h3>
    <p class="p-body margin-top-small">
        {{ excerpt?.primaryExcerpt }}
    </p>
</ng-container>
