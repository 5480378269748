import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'pluralSingular',
})
export class PluralSingularPipe implements PipeTransform {
    /**
     * Custom PluralSingular Pipe
     * @param number - count number can be provided
     * @param singularText - singularText can be added.
     * @param pluralText - pluralText can be provided but optional for regular nouns.
     * @returns Count and Plural Singular for a text
     */
    transform(number: number, singularText: string, pluralText: string = null): string {
        const pluralWord = pluralText ? pluralText : `${singularText}s`;
        return number == 1 ? `${number} ${singularText}` : `${number} ${pluralWord}`;
    }
}
