import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CloseComponent } from './close/close.component';
import { DownArrowComponent } from './down-arrow/down-arrow.component';
import { ExternalLinkComponent } from './external-link/external-link.component';
import { RightArrowComponent } from './right-arrow/right-arrow.component';

const modules = [DownArrowComponent, RightArrowComponent, ExternalLinkComponent, CloseComponent];
@NgModule({
    declarations: modules,
    imports: [CommonModule],
    exports: modules,
})
export class IconsModule {}
