import { MarketingWpItem } from '../interfaces';

export const mockMarketingWpEvents: MarketingWpItem[] = [
    {
        id: 0,
        title: { rendered: 'foo' },
        link: 'https://example.com',
        acf: {
            event_description: 'foo',
            meta_description: 'foo',
            event_start_date: '01/01/3000',
            event_end_date: '01/01/3000',
            venue_map: { city: 'foo', state: 'foo' },
        },
        _links: {
            'wp:attachment': [
                {
                    href: 'https://example.com/image.png',
                },
            ],
        },
    },
    {
        id: 1,
        title: { rendered: 'bar' },
        link: 'https://example.com',
        acf: {
            event_description: 'bar',
            meta_description: 'bar',
            event_start_date: '01/01/3000',
            event_end_date: '01/01/3000',
            venue_map: { city: 'bar', state: 'bar' },
        },
        _links: {
            'wp:attachment': [
                {
                    href: 'https://example.com/image.png',
                },
            ],
        },
    },
    {
        id: 1,
        title: { rendered: 'baz' },
        link: 'https://example.com',
        acf: {
            event_description: 'baz',
            meta_description: 'baz',
            event_start_date: '01/01/3000',
            event_end_date: '01/01/3000',
            venue_map: { city: 'baz', state: 'baz' },
        },
        _links: {
            'wp:attachment': [
                {
                    href: 'https://example.com/image.png',
                },
            ],
        },
    },
    {
        id: 1,
        title: { rendered: 'qux' },
        link: 'https://example.com',
        acf: {
            event_description: 'qux',
            meta_description: 'qux',
            event_start_date: '01/01/3000',
            event_end_date: '01/01/3000',
            venue_map: { city: 'qux', state: 'bar' },
        },
        _links: {
            'wp:attachment': [
                {
                    href: 'https://example.com/image.png',
                },
            ],
        },
    },
];
