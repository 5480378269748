import { HttpParams } from '@angular/common/http';

/**
 * Returns cache busted params by adding a new Date string to params
 * to be attached to a given service call. Mostly used by the CMS related
 * services to cache bust CMS related content.
 * @returns HttpParams
 */
export function getCacheBustedParams(): HttpParams {
    const cacheBusted = String(Math.round(+new Date() / 1000));
    return new HttpParams().append('d', cacheBusted);
}
