import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatGridListModule } from '@angular/material/grid-list';
import { ComponentsModule } from '@components';
import { DirectivesModule } from '@directives';
import { NguCarouselModule } from '@ngu/carousel';
import { FundamentalsStatusModule } from './fundamentals-status/fundamentals-status.module';
import { FundamentalsComponent } from './fundamentals.component';
import { ProductIdeasLabModule } from './product-ideas-lab/product-ideas-lab.module';
import { ReleaseNotesModule } from './release-notes/release-notes.module';
import { RoadmapsModule } from './roadmaps/roadmaps.module';

@NgModule({
    declarations: [FundamentalsComponent],
    imports: [
        CommonModule,
        ComponentsModule,
        DirectivesModule,
        MatGridListModule,
        ProductIdeasLabModule,
        ReleaseNotesModule,
        RoadmapsModule,
        NguCarouselModule,
        FundamentalsStatusModule,
    ],
    exports: [FundamentalsComponent],
})
export class FundamentalsModule {}
