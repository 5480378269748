import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ComponentsModule } from '@components';
import { TipsAndToolsComponent } from './tips-and-tools.component';
import { DirectivesModule } from '@directives';

@NgModule({
    declarations: [TipsAndToolsComponent],
    imports: [CommonModule, ComponentsModule, DirectivesModule],
    exports: [TipsAndToolsComponent],
})
export class TipsAndToolsModule {}
