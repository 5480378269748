import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AnalyticsService } from '@services';
import { ReplaySubject } from 'rxjs';
import { BeyondTrainingTile } from '../beyond-training-tile.interface';

@Component({
    selector: 'app-beyond-video-content',
    templateUrl: './beyond-video-content.component.html',
    styleUrls: ['./beyond-video-content.component.css'],
})
export class BeyondVideoContentComponent implements OnInit, OnChanges {
    @Input() beyondTrainingTile: BeyondTrainingTile;
    subtitle$: ReplaySubject<string> = new ReplaySubject(1);

    /**
     * Passthrough properties for tile-vertical.component.ts
     */
    @Input() bigImage: boolean;
    @Input() vertical: boolean;

    showModal = false;

    /**
     * String identifiers for analytics services to track this tile
     */
    dataAnalytics: string[];
    dataAnalyticsModal: string;

    ngOnInit(): void {
        this.subtitle$.next(this.formatSubtitle(this.beyondTrainingTile.subtitle));
        this.dataAnalytics = [
            'training',
            AnalyticsService.format(this.beyondTrainingTile.subtitle[0]), // first element is expect to always be present and contain "video", "course", "subscription", etc.
            AnalyticsService.format(this.beyondTrainingTile.title),
        ];
        this.dataAnalyticsModal = this.dataAnalytics?.join('-');
    }

    private formatSubtitle(val: string[]) {
        return val.join(' | ').trim();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.beyondTrainingTile) {
            this.subtitle$.next(this.formatSubtitle(this.beyondTrainingTile.subtitle));
        }
    }

    /**
     * Opens the {@link beyondTrainingTile} in a modal
     */
    openModal(): void {
        this.showModal = true;
    }

    /**
     * Closes the modal
     */
    closeModal(): void {
        this.showModal = false;
    }
}
