import { DOCUMENT } from '@angular/common';
import { Inject, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'decodeEntities',
})
export class DecodeEntitiesPipe implements PipeTransform {
    constructor(@Inject(DOCUMENT) private document: Document) {}

    /**
     * Transform and return an encoded string from a text area and return inner html
     * string only. This keeps aniy incoming encoded strings and converts them to inner html.
     * @param encodedString - encoded string that is textarea
     * @returns - inner html string from encoded string from text area.
     */
    public transform(encodedString: string): string {
        const textArea = this.document.createElement('textarea');
        textArea.innerHTML = encodedString;
        return textArea.value;
    }
}
