import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { KeplerApiAuthorizerInterceptor } from '../../interceptors/kepler-api-authorizer.interceptor';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class KeplerPurecloudInterceptor extends KeplerApiAuthorizerInterceptor {
    constructor(authService: AuthService) {
        super(authService, environment.api.kepler.purecloud.url, {
            x_api_key: environment.api.kepler.purecloud.apiKey,
        });
    }
}
