<!--MOBILE CAROUSEL-->
<section>
    <ngu-carousel #myCarousel [inputs]="carouselMobile" [dataSource]="carouselItems">
        <!-- NOTE: Based on the current requirements, we are disabling the arrow iocns, keeping it commented for any future reference if needed.-->
        <!--LEFT ARROW START-->
        <!--NOTE: carouselLeftArrowCSS is an array of css that can be passed into this component to override default css-->
        <!-- <button NguCarouselPrev [ngClass]="carouselLeftArrowCSS" [style.opacity]="myCarousel.isFirst ? 0.5 : 0.8">
            <input
                type="image"
                src="/assets/icons/arrow-left.svg"
                alt="Left carousel arrow button with left arrow icon"
                width="16px"
                height="29px" />
        </button> -->

        <!--CAROUSEL CONTENT START-->
        <ngu-item *nguCarouselDef="let item; index as i; let ani = animate">
            <!--NOTE: We are passing the template as an input, but you must set the context to enable the input template.
            This keeps this mobile carousel generic. Item is the reference to the carousel item from the data source.
            This is needed to bind to the passed in template-->
            <ng-container *ngTemplateOutlet="carouselItemTemplate; context: { $context: item }"></ng-container>
        </ngu-item>

        <!--END CAROUSEL CONTENT-->

        <!-- NOTE: Based on the current requirements, we are disabling the arrow iocns, keeping it commented for any future reference if needed.-->
        <!--RIGHT ARROW START-->
        <!--NOTE: carouselRightArrowCSS is an array of css that can be passed into this component to override default css-->
        <!-- <button NguCarouselNext [ngClass]="carouselRightArrowCSS" [style.opacity]="myCarousel.isLast ? 0.5 : 0.8">
            <input
                type="image"
                src="/assets/icons/arrow-right.svg"
                alt="Right carousel arrow button with right arrow icon"
                width="16px"
                height="29px" />
        </button> -->

        <!--SLIDER DOTS START-->
        <ul class="myPoint cursor" NguCarouselPoint>
            <li
                *ngFor="let item of myCarousel.pointNumbers; trackBy: trackByItem"
                [ngClass]="item == myCarousel.activePoint ? carouselDotActiveCSS : ''"
                (click)="myCarousel.moveTo(item)">
            </li>
        </ul>
        <!--END SLIDER DOTS-->
    </ngu-carousel>
</section>
<!--END MOBILE CAROUSEL-->
