import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ComponentsModule } from '@components';
import { BeyondExternalContentModule } from './beyond-external-content/beyond-external-content.module';
import { BeyondVideoContentModule } from './beyond-video-content/beyond-video-content.module';
import { BeyondComponent } from './beyond.component';

@NgModule({
    declarations: [BeyondComponent],
    imports: [CommonModule, ComponentsModule, BeyondExternalContentModule, BeyondVideoContentModule],
    exports: [BeyondComponent],
})
export class BeyondModule {}
