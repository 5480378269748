import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ComponentsModule } from '@components';
import { MyAwardsModule } from 'src/app/edu-training/certifications-and-badges/my-awards/my-awards.module';
import { WelcomeVideoModule } from 'src/app/resources/get-started/welcome-video/welcome-video.module';
import { ResourcesModule } from 'src/app/resources/resources.module';
import { CasesModule } from 'src/app/support/cases/cases.module';
import { ContactsModule } from 'src/app/support/contacts/contacts.module';
import { BeyondModule } from '../../edu-training/beyond/beyond.module';
import { MyFavoritesModule } from '../my-favorites/my-favorites.module';
import { AdditionalSitesModule } from './additional-sites/additional-sites.module';
import { JustForMeComponent } from './just-for-me.component';
import { MatTabsModule } from '@angular/material/tabs';
import { DirectivesModule } from '@directives';

@NgModule({
    declarations: [JustForMeComponent],
    imports: [
        CommonModule,
        ComponentsModule,
        AdditionalSitesModule,
        DirectivesModule,
        BeyondModule,
        CasesModule,
        ContactsModule,
        MyFavoritesModule,
        MyAwardsModule,
        ResourcesModule,
        WelcomeVideoModule,
        MatTabsModule,
    ],
    exports: [JustForMeComponent],
})
export class JustForMeModule {}
