import { Component, Input } from '@angular/core';
import { GknFeaturedContent } from '@services';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-featured-content-video-embed',
    templateUrl: './featured-content-video-embed.component.html',
    styleUrls: ['./featured-content-video-embed.component.css'],
})
export class FeaturedContentVideoEmbedComponent {
    @Input() featuredContent: GknFeaturedContent;
    isModalOpen$ = new BehaviorSubject(false);

    openModal(): void {
        this.isModalOpen$.next(true);
    }

    closeModal(): void {
        this.isModalOpen$.next(false);
    }
}
