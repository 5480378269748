<app-tile-layout *ngIf="webinars$ | async as webinars" maxColumns="3">
    <ng-container *ngIf="maxItemsToLoad$ | async as maxItemsToLoad">
        <li *ngFor="let webinar of webinars | slice: 0:maxItemsToLoad; let i = index; trackBy: trackByItem">
            <ng-container *desktop>
                <ng-container
                    *ngTemplateOutlet="card; context: { webinar: webinar, thumbnail: webinar?.thumbnails?.desktop }">
                </ng-container>
            </ng-container>
            <ng-container *mobile>
                <ng-container
                    *ngTemplateOutlet="card; context: { webinar: webinar, thumbnail: webinar?.thumbnails?.mobile }">
                </ng-container>
            </ng-container>
        </li>
    </ng-container>
</app-tile-layout>

<ng-template #card let-webinar="webinar" let-thumbnail="thumbnail">
    <app-tile-vertical
        [title]="webinar?.title"
        [titleLink]="true"
        [url]="webinar?.url"
        [urlLabel]="webinar?.cta"
        [imageUrl]="thumbnail"
        [icon]="webinar?.subtitleIcon"
        [details]="webinar?.subtitle + ' | ' + webinar?.duration.rendered"
        [description]="webinar?.description"
        [border]="false"
        [hasImageAutoHeight]="true"
        [isCustomFooterLink]="true"
        [dataAnalytics]="webinar?.dataAnalytics">
        <div class="external-link-div margin-top-smaller footer-min-height" customFooterLink>
            <a
                id="webinar-cta-{{ i }}"
                class="cursor link-n read-more"
                [href]="webinar?.url"
                target="_blank"
                rel="nofollow"
                [attr.data-analytics]="webinar?.dataAnalytics?.join('-')">
                {{ webinar?.cta }}
            </a>
            <app-external-url [url]="webinar?.url"></app-external-url>
        </div>
    </app-tile-vertical>
</ng-template>

<ng-container *ngIf="webinarsLength > 6">
    <div *ngIf="showLoadMoreButton$ | async" class="text-align-center margin-top-large">
        <button class="btn btn-secondary padding-left-small padding-right-small" (click)="loadMore($event)">
            Load More
            <app-down-arrow *desktop class="margin-left-smaller" fillColor="#ffffff"></app-down-arrow>
            <app-down-arrow *mobile class="margin-left-smaller" fillColor="#3b90aa"></app-down-arrow>
        </button>
    </div>
</ng-container>
