<app-tile-layout *desktop maxColumns="2" class="container">
    <li>
        <ng-container *ngTemplateOutlet="title"></ng-container>
        <ng-container *ngTemplateOutlet="description"></ng-container>
        <ng-container *ngTemplateOutlet="cta"></ng-container>
    </li>
    <li class="media-container">
        <ng-container *ngTemplateOutlet="videoEmbed"></ng-container>
    </li>
</app-tile-layout>

<app-tile-layout *mobile maxColumns="1" class="container">
    <li>
        <ng-container *ngTemplateOutlet="title"></ng-container>
        <ng-container *ngTemplateOutlet="videoEmbed"></ng-container>
        <ng-container *ngTemplateOutlet="description"></ng-container>
        <ng-container *ngTemplateOutlet="cta"></ng-container>
    </li>
</app-tile-layout>

<ng-template #title>
    <h2 class="h2-alt margin-bottom-small">{{ featuredContent.title }}</h2>
</ng-template>

<ng-template #description>
    <p class="break-paragraph p-body-large">{{ featuredContent.description }}</p>
</ng-template>

<ng-template #cta>
    <button class="btn btn-secondary margin-top-small" (click)="openModal(featuredContent)">
        {{ featuredContent.urlLabel }}
    </button>
</ng-template>

<ng-template #videoEmbed>
    <app-video-container
        [title]="featuredContent.title"
        [embedUrl]="featuredContent.url"
        [containerCss]="['tile-border-radius', 'mat-elevation-z6']"
        iframeAllow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture'">
    </app-video-container>
</ng-template>

<ng-container *ngIf="(isModalOpen$ | async) == true">
    <app-article
        [title]="featuredContent.title"
        [isCustomContent]="true"
        [customContainerCss]="'full-width-container'"
        [customModalCss]="['dark-mode-modal', 'text-white', 'auto-height', 'half-max-width']"
        [customIconCss]="'white-icon'"
        [showShare]="true"
        [customShareLink]="featuredContent.url"
        (closeArticle)="closeModal()">
        <div customContent>
            <app-video-container
                [title]="featuredContent.title"
                [embedUrl]="featuredContent.url"
                [containerCss]="['mat-elevation-z6']"
                iframeAllow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture">
            </app-video-container>
        </div>
    </app-article>
</ng-container>
