<ng-container *ngIf="embedUrl; else videoImagePlaceHolder">
    <video
        [ngClass]="containerCss ? containerCss : ''"
        *ngIf="isStrapi; else videoIframe"
        [src]="embedUrl"
        controls
        [attr.data-analytics]="dataAnalyticsInlineVideo"></video>
</ng-container>

<!--NOTE: placeholder if no videos are found. Indicates a missing or unavailable video-->
<ng-template #videoImagePlaceHolder>
    <div class="image-placeholder">
        <p class="bold">Video Not Available!</p>
    </div>
</ng-template>

<!--VIDEO IFRAME-->
<ng-template #videoIframe>
    <div class="embed-container" [ngClass]="containerCss ? containerCss : ''">
        <!--EMBEDDED VIDEO IFRAME-->
        <iframe
            [attr.title]="title"
            [src]="embedUrl | safe: 'resourceUrl'"
            [attr.allow]="iframeAllow"
            [attr.class]="iframeClass"
            (load)="onIframeLoad($event)"
            allowtransparency="true"
            frameborder="0"
            scrolling="no"
            allowfullscreen
            mozallowfullscreen
            webkitallowfullscreen
            oallowfullscreen
            msallowfullscreen
            appIframeTracker
            (iframeClick)="onIframeClick($event)"
            [attr.data-analytics]="dataAnalyticsInlineVideo">
        </iframe>
        <!--END EMBEDDED VIDEO IFRAME-->
    </div>
</ng-template>
<!--END VIDEO IFRAME-->
