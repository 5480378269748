import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AnalyticsService } from '@services';
import { ReplaySubject, Subscription } from 'rxjs';
import { BeyondTrainingTile } from '../beyond-training-tile.interface';

@Component({
    selector: 'app-beyond-external-content',
    templateUrl: './beyond-external-content.component.html',
    styleUrls: ['./beyond-external-content.component.css'],
})
export class BeyondExternalContentComponent implements OnChanges, OnInit, OnDestroy {
    @Input() beyondTrainingTile: BeyondTrainingTile;
    subtitle$: ReplaySubject<string> = new ReplaySubject(1);

    /**
     * Passthrough properties for tile-vertical.component.ts
     */
    @Input() bigImage: boolean;
    @Input() vertical: boolean;
    isMobileView = false;
    private subs: Subscription[] = [];

    customImageCss: string[];
    customMobileImageCss: string[];

    /**
     * String identifiers for analytics services to track this tile
     */
    dataAnalytics: string[];

    constructor(private breakpointObserver: BreakpointObserver, private changeDetector: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.subs.push(
            this.breakpointObserver.observe('(max-width: 1020px)').subscribe((result: BreakpointState) => {
                this.isMobileView = result.matches;
                this.changeDetector.markForCheck();
            }),
        );

        this.subtitle$.next(this.formatSubtitle(this.beyondTrainingTile.subtitle));
        this.dataAnalytics = [
            'training',
            AnalyticsService.format(this.beyondTrainingTile.subtitle[0]), // first element is expect to always be present and contain "video", "course", "subscription", etc.
            AnalyticsService.format(this.beyondTrainingTile.title),
        ];
    }

    private formatSubtitle(val: string[]) {
        return val.join(' | ').trim();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.beyondTrainingTile) {
            this.customMobileImageCss = [
                'tile-border-radius',
                'mat-elevation-z6',
                this.beyondTrainingTile.customImageClassName,
            ];

            this.customImageCss = [
                'tile-border-radius',
                'mat-elevation-z6',
                this.beyondTrainingTile.customImageClassName,
            ];
            this.bigImage ? this.customImageCss.push('tile-large') : this.customImageCss.push('tile-small');

            this.subtitle$.next(this.formatSubtitle(this.beyondTrainingTile.subtitle));
        }
    }

    /**
     * On Destroy, unsubscribe subscriptions to prevent memory leaks
     */
    ngOnDestroy(): void {
        if (this.subs) {
            this.subs.forEach((sub) => {
                sub.unsubscribe();
            });
        }
    }
}
