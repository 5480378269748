<app-section-header [title]="sectionLabel"></app-section-header>

<div>
    <p>
        All publicly available information is available without login on this site. Login is reserved for Partners and
        Customers with active logins to the
        <!-- display: block -->
        <a class="primary-link" id="partnerPortal" href="https://genesyspartner.force.com/partner/PartnerPortalLanding">
            Partner Portal
        </a>
        or
        <!-- display: block -->
        <a
            class="primary-link"
            id="customerPortal"
            href="https://genesyspartner.force.com/customercare/GenesysCommunityLogin">
            Customer "My Support" portal
        </a>
        . To request Partner or Customer Portal access, please see the links below:
    </p>
    <ul>
        <li>
            <!-- display: block -->
            <a
                class="primary-link"
                id="partnerPortalRegistration"
                href="https://genesyspartner.force.com/partner/partnerNewUserRegistration?pageType=as">
                Partner Portal registration
            </a>
        </li>
        <li>
            <!-- display: block -->
            <a
                class="primary-link"
                id="customerPortalRegistration"
                href="https://genesyspartner.force.com/customercare/RequestAccount">
                My Support portal registration
            </a>
        </li>
    </ul>
</div>
