import { Observable, map, of } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProductUid } from '@enums';
import { StripHtmlPipe } from '@pipes';
import { AnalyticsService, Product } from '@services';
import { ProductHelpArticleData } from './product-help-article/product-article-data';
import { ProductHelpArticle } from './product-help-article/product-help-article.interface';

@Injectable({
    providedIn: 'root',
})
export class ProductHelpService {
    constructor(private http: HttpClient, private stripHtml: StripHtmlPipe) {}

    getArticles(product: Product): Observable<ProductHelpArticle[]> {
        if (product.uid == ProductUid.GENESYS_CLOUD_CX) {
            return this.http
                .get<ProductHelpArticle[]>('https://help.mypurecloud.com/wp-json/wp/v2/articles', {
                    params: {
                        _embed: true,
                        per_page: 4,
                        'filter[categories]': 'popular',
                    },
                })
                .pipe(
                    map((articles) => {
                        return articles.map((article) => {
                            const title = this.stripHtml.transform(article.title.rendered);
                            return {
                                ...article,
                                dataAnalytics: ['product', 'help', AnalyticsService.format(title)],
                            };
                        });
                    }),
                );
        } else if (
            product.uid == ProductUid.GENESYS_MULTICLOUD ||
            product.uid == ProductUid.PURECONNECT ||
            product.uid == ProductUid.GENESYS_ENGAGE_ON_PREM
        ) {
            return of(ProductHelpArticleData[product.uid]);
        } else {
            return of([]);
        }
    }
}
