import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-tile-logo',
    templateUrl: './tile-logo.component.html',
    styleUrls: ['./tile-logo.component.css'],
})
export class TileLogoComponent {
    @Input() imageSVGUrl: string; // Custom SVG with css background, defaults to Genesys Logo
    @Input() customImageCss: string;

    @Input() height: string;
    @Input() width: string;
    @Input() altText: string;
}
