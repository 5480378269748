import { StarterKitTabName } from '@enums';

export interface StarterKitTab {
    name: StarterKitTabName;
    filter?: string;
    steps: StarterKitStep[];
    id: string;
    order: boolean;
    dataAnalytics: string[];
    mobileMessage: string;
}

export interface StarterKitStep {
    id: string;
    name: string;
    icon: string; // icon svg name
    offset?: string; // px offset to visually center icon
    sizeOffset?: number; // custom offset in px for size of icon (off of 30px)
    completed?: boolean;
    articleIcon?: string;
    mobileAllowed?: boolean;
    type: StarterKitStepType;
    messages: {
        continue: string;
        replay: string;
    };
    link: string;
    title: string;
    description: string;
    dataAnalytics: string[];
}

export enum StarterKitStepType {
    VIDEO,
    ARTICLE,
    DOCEBO,
    HYPERLINK,
}
