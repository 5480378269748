import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { VideoResponse } from '@services';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class VimeoService {
    private readonly videoUrl = 'https://vimeo.com/';

    constructor(private http: HttpClient) {}

    getVimeoVideoDetails(id: number): Observable<VideoResponse> {
        return this.http.get<VideoResponse>(
            `https://vimeo.com/api/oembed.json?url=${encodeURIComponent(this.videoUrl + id)}`,
        );
    }
}
