<!--TODO: Future, refactor to use modal-dialog-->
<div
    #modalDialog
    class="login-modal"
    id="{{ containerID }}"
    (click)="closeModal($event)"
    [ngStyle]="{ display: 'none' }">
    <section class="modal-dialog" [ngClass]="modalMaxWidth ? modalMaxWidth : ''">
        <!--NOTE: Close button can be hidden in this case.-->
        <div *ngIf="!hideCloseButton" class="modal-controls">
            <input
                class="close"
                type="image"
                src="/assets/icons/close-modal.svg"
                alt="Close icon"
                width="9px"
                height="10px"
                (click)="closeModal($event)" />
        </div>
        <div class="modal-body">
            <div #modalContainer>
                <app-login-form
                    (signInWithCredentialsEmitter)="emitSignInWithCredentials($event)"
                    (signInWithOktaEmitter)="emitSignInWithOkta()"
                    [theme]="theme">
                </app-login-form>
            </div>
        </div>
    </section>
</div>
