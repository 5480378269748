<app-tile-layout maxColumns="2">
    <div>
        <h2 class="h2-alt margin-bottom-small">My Personalized Video</h2>
        <p class="p-body-large">
            We're so excited to have you on the Genesys Customer Success and Services (CSS) team! As you begin to
            navigate your journey, we wanted to share a short video with helpful resources that we've compiled just for
            you! Don’t worry, every link will be listed at the end of the video, so all you need to do is sit back and
            relax.
        </p>
    </div>
    <div class="video-column">
        <div id="welcome-video-container"></div>
        <mat-spinner *ngIf="isLoading" color="primary" diameter="50"></mat-spinner>
    </div>
</app-tile-layout>
