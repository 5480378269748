import { Component } from '@angular/core';
import { TrackByItem } from '@components';
import { DeveloperCenterData } from '../developer-center.interface';
import { popularTopicsData } from './popular-topics-data';

@Component({
    selector: 'app-popular-topics',
    templateUrl: './popular-topics.component.html',
    styleUrls: ['./popular-topics.component.css'],
})
export class PopularTopicsComponent implements TrackByItem<DeveloperCenterData> {
    popularTopics: DeveloperCenterData;
    constructor() {
        this.popularTopics = popularTopicsData;
    }

    trackByItem(_index: number, item: DeveloperCenterData): NonNullable<number | string> {
        return item.baseUrl;
    }
}
