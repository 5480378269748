export interface CustomerSuccessData {
    label: string;
    url: string;
    iconUrl: string;
    width: string;
    height: string;
}

export const customerSuccessDataAuthenticated: CustomerSuccessData[] = [
    {
        label: 'Service Catalog',
        url: 'https://customersuccess.genesys.com/',
        iconUrl: 'assets/icons/customer-success/service-catalog-icon.svg',
        width: '41px',
        height: '48px',
    },
];

export const customerSuccessDataUnauthenticated: CustomerSuccessData[] = [
    {
        label: 'Service Catalog',
        url: 'https://customersuccess.genesys.com/',
        iconUrl: 'assets/icons/customer-success/service-catalog-icon.svg',
        width: '41px',
        height: '48px',
    },
];
