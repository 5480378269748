import { CommonModule } from '@angular/common';
import { CopyLinkComponent } from './copy-link.component';
import { NgModule } from '@angular/core';

@NgModule({
    declarations: [CopyLinkComponent],
    imports: [CommonModule],
    exports: [CopyLinkComponent],
})
export class CopyLinkModule {}
