<span class="step-container" [class.accessible]="accessible" [class.selected]="selected">
    <div>
        <button class="step" style="padding: {{ step.completed ? 10 : 10 - (step.sizeOffset || 0) / 2 }}px">
            <img
                *ngIf="!step.completed"
                src="/assets/icons/starter-kit/{{ step.icon }}.svg"
                [attr.width]="30 + (step.sizeOffset || 0)"
                [attr.height]="30 + (step.sizeOffset || 0)"
                alt="{{ step.icon }}"
                style="left: {{ step.offset || '0px' }}" />
            <img
                *ngIf="step.completed"
                src="/assets/icons/starter-kit/checkmark.svg"
                [attr.width]="30"
                [attr.height]="30"
                alt="checkmark" />
        </button>
        <div class="step-name">
            {{ step.name }}
        </div>
    </div>
</span>
