import { Component } from '@angular/core';
import { ToastColor } from '@services';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-toast-notification',
    templateUrl: './toast-notification.component.html',
    styleUrls: ['./toast-notification.component.css'],
})
export class ToastNotificationComponent {
    innerHtml: string;
    color: ToastColor;

    onClose$: Subject<boolean> = new Subject();

    onClose(): void {
        this.onClose$.next(true);
    }
}
