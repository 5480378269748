<div class="root">
    <div *ngIf="showTitle" class="section-header margin-top-small margin-bottom-small">
        <h1 [ngClass]="!externalUrl ? 'no-external-icon' : ''">{{ title }}</h1>
        <h2 *ngIf="subTitle" class="h3 margin-left-smaller">{{ subTitle }}</h2>
        <app-anchor-point *ngFor="let anchor of anchors" [id]="anchor"></app-anchor-point>
    </div>

    <div class="icons" *ngIf="showIcons">
        <ng-content></ng-content>
        <app-copy-link
            *ngIf="section"
            [section]="section"
            [favorite]="favoriteId ? favoriteId : ''"
            [title]="title"
            [product]="selectedProduct$ | async"></app-copy-link>
        <ng-container *ngIf="(isAuthenticated$ | async) === true">
            <app-favorite-icon
                *ngIf="favoriteId && section"
                [favoriteId]="favoriteId"
                [location]="section"
                [title]="title"></app-favorite-icon>
        </ng-container>
        <a *ngIf="externalUrl" [href]="externalUrl" title="Open in external url" target="_blank" rel="nofollow">
            <img
                class="icon-external"
                src="/assets/icons/external-url.svg"
                width="24px"
                height="24px"
                alt="External url icon" />
        </a>
    </div>
</div>
