import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FavoriteId, ResourcesSectionLabel } from '@enums';
import {
    CustomerSuccessData,
    customerSuccessDataAuthenticated,
    customerSuccessDataUnauthenticated,
} from './customer-success-data';

import { TrackByItem } from '@components';
import { UserService } from '@services';
import { Subscription } from 'rxjs';
import { SECTION_LINKS } from 'src/app/app-header/submenu-data';

@Component({
    selector: 'app-customer-success',
    templateUrl: './customer-success.component.html',
    styleUrls: ['./customer-success.component.css'],
})
export class CustomerSuccessComponent implements OnInit, OnDestroy, TrackByItem<CustomerSuccessData> {
    sectionLabel = ResourcesSectionLabel.CUSTOMER_SUCCESS;
    favoriteId = FavoriteId.CUSTOMER_SUCCESS;
    customerSuccessCards: CustomerSuccessData[] = customerSuccessDataUnauthenticated;
    private subs: Subscription[] = [];
    isAuthenticated = false;
    sectionLocation = SECTION_LINKS.CUSTOMER_SUCCESS;

    @Input() showTitle = true;

    constructor(private changeDetector: ChangeDetectorRef, private userService: UserService) {}

    ngOnInit(): void {
        this.subs.push(
            this.userService.isAuthenticated$.subscribe((isAuthenticated) => {
                this.isAuthenticated = isAuthenticated;
                if (this.isAuthenticated) {
                    this.customerSuccessCards = customerSuccessDataAuthenticated;
                    this.changeDetector.markForCheck();
                }
            }),
        );
    }

    trackByItem(_index: number, item: CustomerSuccessData): NonNullable<number | string> {
        return item.label;
    }

    ngOnDestroy(): void {
        if (this.subs) {
            this.subs.forEach((sub) => {
                sub.unsubscribe();
            });
        }
    }
}
