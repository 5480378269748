<div
    class="container article-modal"
    [ngClass]="customContainerCss ? customContainerCss : ''"
    (click)="handleClose($event)">
    <div class="body" [ngClass]="customModalCss ? customModalCss : ''">
        <div
            class="header margin-left-small"
            [ngClass]="[
                title ? 'header-spaced' : 'header-end',
                customSecondaryHeaderCSS ? customSecondaryHeaderCSS : ''
            ]">
            <h2
                *ngIf="title"
                class="sub-title-3-alt text-white"
                [ngClass]="customFontTitleModalCss ? customFontTitleModalCss : ''"
                [innerHTML]="title"></h2>
            <div class="external-link-div">
                <app-share
                    *ngIf="showShare"
                    [link]="customShareLink ? customShareLink : article.link"
                    [ngClass]="customIconCss ? customIconCss : ''"></app-share>
                <input
                    class="close"
                    type="image"
                    [ngClass]="customIconCss ? customIconCss : ''"
                    src="/assets/icons/close.svg"
                    alt="Close icon"
                    width="25px"
                    height="25px"
                    (click)="handleClose($event)"
                    [attr.data-analytics]="dataAnalyticsClose" />
            </div>
        </div>
        <ng-container *ngIf="isCustomContent; else showArticle">
            <!--NOTE: Allow ng-content or another block of elements to be projected into this modal-->
            <ng-content select="[customContent]"></ng-content>
        </ng-container>
        <!--NOTE: Default is article iframe content-->
        <ng-template #showArticle>
            <iframe [src]="article.link + '?theme=simplified' | safe: 'resourceUrl'" frameborder="0"></iframe>
        </ng-template>
    </div>
</div>
