import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ComponentsModule } from '@components';
import { MyAwardsComponent } from './my-awards.component';

@NgModule({
    declarations: [MyAwardsComponent],
    imports: [CommonModule, ComponentsModule],
    exports: [MyAwardsComponent],
})
export class MyAwardsModule {}
