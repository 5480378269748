import { Html } from '../html/html.interface';

/**
 * Properties for an Avatar component
 */
export interface AvatarProps {
    borderRadius?: string;
    id?: string;
    margin?: string;
    maxWidth?: string;
    imageHref?: string;
}

/**
 * Generic avatar component to show an image with a 1:1 aspect ratio. Resizes with screen size.
 *
 * @param {AvatarProps} props the {@link AvatarProps} for this avatar component
 */
export class Avatar implements Html {
    private width = document.getElementById('welcome-video-container').offsetWidth;

    constructor(private props: AvatarProps) {}

    html(): HTMLElement {
        const root = document.createElement('div');
        root.id = 'avatar';
        root.style.maxWidth = this.width * 0.2 + 'px';
        root.style.height = 'auto';
        root.style.margin = this.props.margin || '';

        const avatar = document.createElement('img');
        avatar.id = this.props.id || '';
        avatar.src = this.props.imageHref || '';
        avatar.style.maxWidth = '100%';
        avatar.style.height = 'auto';
        avatar.style.border = '5px solid #eee';
        avatar.style.borderRadius = this.props.borderRadius || '';
        root.appendChild(avatar);

        return root;
    }
}
