/**
 * Uses {@link MutationObserver} to wait for an element to appear on the DOM
 * before resolving.
 * @param selector docuument query selector for the DOM element
 * @returns Promised element
 */
export async function waitForElement(selector: string): Promise<Element> {
    return new Promise((resolve) => {
        if (document.querySelector(selector)) {
            return resolve(document.querySelector(selector));
        }

        const observer = new MutationObserver((_mutations) => {
            if (document.querySelector(selector)) {
                resolve(document.querySelector(selector));
                observer.disconnect();
            }
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true,
        });
    });
}
