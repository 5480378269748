import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { DirectivesModule } from '@directives';
import { FundamentalsCardModule } from '../fundamentals-card/fundamentals-card.module';
import { ReleaseNotesComponent } from './release-notes.component';
import { ReleaseNotesService } from './release-notes.service';

@NgModule({
    declarations: [ReleaseNotesComponent],
    imports: [CommonModule, DirectivesModule, FundamentalsCardModule, MatButtonModule, MatDialogModule, MatListModule],
    exports: [ReleaseNotesComponent],
    providers: [ReleaseNotesService],
})
export class ReleaseNotesModule {}
