<!--NOTE: 
    1. Use this if there is a true image like png, svg or non-logo image
    2. Use this for any feeds, news, where the image is the main focus and has a image src link-->
<ng-container *ngTemplateOutlet="imageUrl ? tileWithImage : defaultGenesysLogoSVG"></ng-container>

<ng-template #tileWithImage>
    <img class="tile-image big-image tile-border-radius full-width" [src]="imageUrl" [alt]="altText + ' image' || ''" />
</ng-template>

<ng-template #defaultGenesysLogoSVG>
    <app-tile-default-logo></app-tile-default-logo>
</ng-template>
