import { Component, OnInit } from '@angular/core';
import { waitForElement } from '@utils';

@Component({
    selector: 'app-announcements',
    templateUrl: './announcements.component.html',
    styleUrls: ['./announcements.component.css'],
})
export class AnnouncementsComponent implements OnInit {
    ngOnInit(): void {
        waitForElement('.pendo-resource-center-badge-notification-bubble').then((el: HTMLElement) => {
            // TODO KNOW-1916 This doesn't end up working. After the first page load it reloads again and uses the default pendo styling
            el.style.width = '17px';
            el.style.height = '17px';
            el.style.borderRadius = '50%';
            el.style.padding = '';
            el.style.fontSize = '12px';
            el.style.top = '-4px';
            el.style.left = '-8px';
        });
    }
}
