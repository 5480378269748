import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ComponentsModule } from '@components';
import { CxEvolutionCardModule } from '../../component/cx-evolution-card/cx-evolution-card.module';
import { CxEvolutionCommunitiesModule } from './cx-evolution-communities/cx-evolution-communities.module';
import { CxEvolutionConnectComponent } from './cx-evolution-connect.component';

@NgModule({
    declarations: [CxEvolutionConnectComponent],
    imports: [CommonModule, ComponentsModule, CxEvolutionCardModule, CxEvolutionCommunitiesModule],
    exports: [CxEvolutionConnectComponent],
})
export class CxEvolutionConnectModule {}
