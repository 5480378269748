import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { PipesModule } from '@pipes';
import { IframeDialogComponent } from './iframe-dialog.component';

@NgModule({
    declarations: [IframeDialogComponent],
    imports: [CommonModule, MatButtonModule, MatDialogModule, MatDividerModule, MatIconModule, PipesModule],
    exports: [IframeDialogComponent],
})
export class IframeDialogModule {}
