import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FavoriteId, ProductHelpFilterLabel, ProductUid, ResourcesSectionLabel, VideoSource } from '@enums';
import { FavoritesService, Product, ProductService } from '@services';

import { Subscription } from 'rxjs';
import { productHelpVideos } from './product-help-videos/product-help-video-data';

@Component({
    selector: 'app-product-help',
    templateUrl: './product-help.component.html',
    styleUrls: ['./product-help.component.css'],
})
export class ProductHelpComponent implements OnInit, OnDestroy {
    favoriteId: FavoriteId;

    sectionLabel: string;
    subTitle = '(Popular)';
    videos = [];
    videoSource: VideoSource;
    productFilter: string;
    externalLink: string;
    private currentProduct: Product;
    label: ProductHelpFilterLabel;

    private subs: Subscription[] = [];
    productHelpFilterLabel = ProductHelpFilterLabel;

    anchors: string[];

    constructor(
        private changeDetector: ChangeDetectorRef,
        private productService: ProductService,
        private favoritesService: FavoritesService,
    ) {
        this.anchors = [
            this.favoritesService.buildFragmentFromFavorite(FavoriteId.PRODUCT_HELP_ARTICLES),
            this.favoritesService.buildFragmentFromFavorite(FavoriteId.PRODUCT_HELP_DOCUMENTATION),
            this.favoritesService.buildFragmentFromFavorite(FavoriteId.PRODUCT_HELP_USE_CASES),
            this.favoritesService.buildFragmentFromFavorite(FavoriteId.PRODUCT_HELP_VIDEOS),
        ];
    }

    ngOnInit(): void {
        this.subs.push(
            this.productService.selectedProduct$.subscribe((product: Product) => {
                this.currentProduct = product;
                this.sectionLabel = `${product.displayName} ${ResourcesSectionLabel.PRODUCT_HELP}`;
                this.favoriteId = FavoriteId.PRODUCT_HELP_ARTICLES;

                this.showProductHelpVideos(product);
                this.showUseCases(product);
                this.externalLinkSetup(product, this.favoriteId);
                this.changeDetector.markForCheck();
            }),
        );
    }

    filterUpdated(favoriteId: FavoriteId) {
        this.favoriteId = favoriteId;
        this.externalLinkSetup(this.currentProduct, this.favoriteId);
        this.changeDetector.markForCheck();
    }

    /**
     * Determines which videos should be displayed
     * or to display none, depending on the selected product.
     *
     * @param product {@link Product}
     */
    private showProductHelpVideos(product: Product): void {
        switch (product.uid) {
            case ProductUid.GENESYS_MULTICLOUD:
                this.videoSource = VideoSource.VIMEO;
                this.videos = productHelpVideos.multicloud;
                break;
            case ProductUid.GENESYS_ENGAGE_ON_PREM:
                this.videoSource = VideoSource.VIMEO;
                this.videos = productHelpVideos.engage;
                break;
            case ProductUid.GENESYS_CLOUD_CX:
                this.videoSource = VideoSource.VIMEO;
                this.videos = [];
                break;
            case ProductUid.PURECONNECT:
                this.videoSource = VideoSource.YOUTUBE;
                this.videos = productHelpVideos.pureConnect;
                break;
            default:
                this.videos = [];
                break;
        }
    }

    /**
     * Sets the external link url value based on which product is selected
     * and by which of the section filters is selected.
     */
    private externalLinkSetup(product: Product, newFilter: string): void {
        if (newFilter == FavoriteId.PRODUCT_HELP_ARTICLES) {
            switch (product.uid) {
                case ProductUid.GENESYS_CLOUD_CX:
                    this.externalLink = 'https://help.mypurecloud.com/';
                    break;
                case ProductUid.PURECONNECT:
                    this.externalLink = 'https://help.genesys.com/resource-center-cic.html';
                    break;
                case ProductUid.GENESYS_MULTICLOUD:
                    this.externalLink = 'https://docs.genesys.com/Documentation/Cloud';
                    break;
                case ProductUid.GENESYS_ENGAGE_ON_PREM:
                    this.externalLink = 'https://docs.genesys.com/Documentation/OnPremises';
                    break;
                default:
                    this.externalLink = '';
                    break;
            }
        } else if (newFilter == FavoriteId.PRODUCT_HELP_USE_CASES) {
            switch (product.uid) {
                case ProductUid.GENESYS_CLOUD_CX:
                    this.externalLink = 'https://all.docs.genesys.com/UseCases/Public/GenesysCloud';
                    break;
                case ProductUid.PURECONNECT:
                    this.externalLink = 'https://help.genesys.com/pureconnect/desktop/interaction_administrator.htm';
                    break;
                case ProductUid.GENESYS_MULTICLOUD:
                    this.externalLink = 'https://all.docs.genesys.com/UseCases/Public/GenesysEngage-cloud';
                    break;
                case ProductUid.GENESYS_ENGAGE_ON_PREM:
                    this.externalLink = 'https://all.docs.genesys.com/UseCases/Public/GenesysEngage-onpremises';
                    break;
                default:
                    this.externalLink = '';
                    break;
            }
        } else if (newFilter == FavoriteId.PRODUCT_HELP_VIDEOS) {
            if (product.uid == ProductUid.PURECONNECT) {
                this.externalLink = 'https://help.genesys.com/justintime/';
            } else {
                this.externalLink = 'https://vimeo.com/channels/1841991';
            }
        } else {
            this.externalLink = '';
        }
    }

    /**
     * Determines which filter should be passed to the use cases component.
     *
     * @param product {@link Product}
     */
    private showUseCases(product: Product): void {
        switch (product.uid) {
            case ProductUid.GENESYS_CLOUD_CX:
                this.label = ProductHelpFilterLabel.ARTICLES;
                this.productFilter = 'GenesysCloud';
                break;
            case ProductUid.PURECONNECT:
                this.label = ProductHelpFilterLabel.DOCUMENTATION;
                this.productFilter = 'PureConnect';
                break;
            case ProductUid.GENESYS_MULTICLOUD:
                this.label = ProductHelpFilterLabel.DOCUMENTATION;
                this.productFilter = 'GenesysEngage-cloud';
                break;
            case ProductUid.GENESYS_ENGAGE_ON_PREM:
                this.label = ProductHelpFilterLabel.DOCUMENTATION;
                this.productFilter = 'GenesysEngage-onpremises';
                break;
            default:
                this.label = ProductHelpFilterLabel.DOCUMENTATION;
                this.productFilter = undefined;
                break;
        }
    }

    ngOnDestroy(): void {
        this.subs.forEach((s) => s.unsubscribe());
    }
}
