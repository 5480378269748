<div class="hover-menu" (mouseenter)="open()" (mouseleave)="close()" (focus)="open()">
    <a
        [routerLink]="submenuLink.url"
        [queryParams]="{ product: (selectedProduct$ | async).uid }"
        class="navbar-item"
        routerLinkActive="is-active"
        data-testId="primary-menu-link">
        <ng-content></ng-content>
    </a>
    <ul class="submenu" [ngClass]="{ 'submenu-open': isOpen$ | async }" *ngIf="submenuLink">
        <ng-container *ngFor="let section of submenuLink.sections">
            <li *ngIf="(section.hidden$ | async) === false" class="submenu-item" data-testId="submenu-link">
                <a
                    class="submenu-link p-body"
                    [routerLink]="submenuLink.url"
                    [queryParams]="{ product: (selectedProduct$ | async).uid }"
                    [fragment]="section.location">
                    {{ section.label$ | async }}
                </a>
            </li>
        </ng-container>
    </ul>
</div>
