import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ComponentsModule } from '@components';
import { LegacyGetStartedComponent } from './legacy-get-started.component';

@NgModule({
    declarations: [LegacyGetStartedComponent],
    imports: [CommonModule, ComponentsModule],
})
export class LegacyGetStartedModule {}
