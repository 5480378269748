import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ComponentsModule } from '@components';
import { AdditionalSitesComponent } from './additional-sites.component';

@NgModule({
    declarations: [AdditionalSitesComponent],
    imports: [CommonModule, ComponentsModule],
    exports: [AdditionalSitesComponent],
})
export class AdditionalSitesModule {}
