import { KeplerPurecloudUser } from '@services';
import { Avatar, Marker, Overlay, OverlayHtmlElement, Typography } from '../../components';

export class WelcomeOverlay extends Overlay {
    static marker: Marker = {
        start: { time: 1.7, text: 'Welcome' },
        end: { time: 4.6, text: 'Welcome end' },
    };

    private avatarWidth = 20;
    private width = document.getElementById('welcome-video-container').offsetWidth;

    constructor(private props: { id: string; user?: KeplerPurecloudUser; isThumbnail: boolean }) {
        super(props.id, { animateInClass: 'fadeInRightBig', animateOutClass: 'fadeOutRightBig' });
    }

    html(): HTMLElement {
        const avatar: HTMLElement = new Avatar({
            id: 'welcome-avatar',
            borderRadius: '50%',
            margin: this.width * 0.01 + 'px',
            maxWidth: this.width * 0.2 + 'px',
            imageHref:
                this.props.user?.avatarUri ||
                'https://csd-static-assets-572708336319-us-east-1.s3.us-east-1.amazonaws.com/assets/fallback_avatar.png',
        }).html();

        let firstName = this.props.user?.name || '';
        if (this.props.user?.name?.includes(' ')) {
            firstName = this.props.user.name.split(' ')[0] || '';
        }

        let messageHtml = '';
        if (!this.props.isThumbnail) {
            // Force a line break with <br> after "Customer Success & Services"
            messageHtml = "We've prepared a Customer Success & Services<br>video just for you.";
        } else {
            // No <br>
            messageHtml =
                "We've prepared a Customer Success & Services video just for you. Sit back and relax, we'll summarize at the end.";
        }

        const messageContent: HTMLElement = document.createElement('div');
        messageContent.style.margin = this.width * 0.02 + 'px';
        messageContent.style.width = this.width * 0.8 + 'px';

        messageContent.appendChild(
            new Typography({
                id: 'welcome-greeting',
                text: this.getUserGreeting(firstName),
                fontFamily: 'springwood_brush',
                fontSize: this.width * 0.05 + 'px',
                lineHeight: '0',
            }).html(),
        );

        messageContent.appendChild(
            new Typography({
                html: messageHtml,
                fontSize: this.width * 0.025 + 'px',
            }).html(),
        );

        const overlay = new OverlayHtmlElement().buildHtml({
            id: this.props.id,
            borderRadius: '999em 0 0 999em',
            bottom: '25%',
            childNodes: [avatar, messageContent],
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            hidden: true,
            left: '5%',
            right: '0',
            top: '30%',
            width: '95%',
        });

        return overlay;
    }

    getUserGreeting(firstName: string) {
        let greeting = 'Good morning';
        const currentHourOfDay = new Date().getHours();
        if (currentHourOfDay >= 12 && currentHourOfDay < 18) {
            greeting = 'Good afternoon';
        } else if (
            (currentHourOfDay >= 18 && currentHourOfDay <= 24) ||
            (currentHourOfDay >= 1 && currentHourOfDay <= 3)
        ) {
            greeting = 'Good evening';
        }
        return (greeting += `, ${firstName}!`);
    }
}
