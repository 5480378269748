import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-right-arrow',
    templateUrl: './right-arrow.component.html',
    styleUrls: ['./right-arrow.component.css'],
})
export class RightArrowComponent {
    @Input() fillColor = 'var(--genesys-csdt-brand-navy)';
}
