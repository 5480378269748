<app-fundamentals-card
    title="Roadmaps"
    icon="/assets/icons/map.svg"
    [isLoading]="isLoading"
    [flexDirection]="flexDirection">
    <div [ngClass]="flexDirection">
        <app-roadmaps-wysiwyg [roadmaps]="roadmaps$ | async"></app-roadmaps-wysiwyg>
        <ng-container *products="[productEnum.GENESYS_CLOUD_CX]">
            <a
                *ngIf="isLoading == false"
                mat-flat-button
                class="button"
                [color]="viewAllColor"
                (mouseover)="viewAllColor = 'primary'"
                (mouseout)="viewAllColor = 'accent'"
                routerLink="archives"
                [queryParams]="{ product: (selectedProduct$ | async)?.uid }">
                View All
            </a>
        </ng-container>
    </div>
</app-fundamentals-card>
