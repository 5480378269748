export const ProductHelpArticleData = {
    pureconnect: [
        {
            title: {
                rendered: 'Technical Reference Documents',
            },
            excerpt: {
                rendered:
                    'Technical Reference Documents contains (or contain) a broad range of installation and reference documents covering various PureConnect features and components. If you cannot find a document elsewhere in the PureConnect Documentation Library, look for it here. Using the following table you can sort each column by clicking the heading. You can also use the list boxes to select filters and see only those documents matching the filters from each column.',
            },
            link: 'https://help.genesys.com/pureconnect/mergedProjects/wh_tr/desktop/technical_reference_documents.htm',
            openNewTab: true,
            dataAnalytics: ['product', 'help', 'technical', 'reference', 'documents'],
        },
        {
            title: {
                rendered: 'Interaction Administrator',
            },
            excerpt: {
                rendered:
                    'Interaction Administrator is an administrative application installed with Customer Interaction Center (CIC). Administrators use it to create, configure, customize, and maintain various aspects of the PureConnect platform.',
            },
            link: 'https://help.genesys.com/pureconnect/desktop/interaction_administrator.htm',
            openNewTab: true,
            dataAnalytics: ['product', 'help', 'interaction', 'administrator'],
        },
        {
            title: {
                rendered: 'Interaction Desktop Help',
            },
            excerpt: {
                rendered:
                    'Welcome to CIC client, a powerful interaction management application for desktop or laptop PCs. The CIC client offers more functionality than your telephone. Use it to manage your electronic communications, including phone calls, voice mail, conference calls, ACD routed email messages, and web chats.',
            },
            link: 'https://help.genesys.com/pureconnect/desktop/interaction_administrator.htm',
            openNewTab: true,
            dataAnalytics: ['product', 'help', 'interaction', 'desktop', 'help'],
        },
    ],
    'genesys-engage-on-prem': [
        {
            title: {
                rendered: 'What is Workspace Desktop Edition?',
            },
            excerpt: {
                rendered:
                    'Workspace is a service that makes the omnichannel contact center come alive for your agents and supervisors!',
            },
            link: 'https://docs.genesys.com/Documentation/IW',
            openNewTab: true,
            dataAnalytics: ['product', 'help', 'what', 'is', 'workspace', 'desktop', 'edition'],
        },
        {
            title: {
                rendered: 'Genesys Configuration Options',
            },
            excerpt: {
                rendered:
                    'Use the Quick Lookup on this page to find all options for a given product — for example, a quick hit to find valid values. Or you can click an option, section, or component to learn more. You can also find links to all Options Reference guides, listed by product in the Find a Guide section.',
            },
            link: 'https://docs.genesys.com/Documentation/Options',
            openNewTab: true,
            dataAnalytics: ['product', 'help', 'genesys', 'configuration', 'options'],
        },
        {
            title: {
                rendered: 'Genesys System-Level Guides',
            },
            excerpt: {
                rendered:
                    'Genesys system-level guides provide system-level reference information about the Genesys operating environment, supported media interfaces, product availability, interoperability, licensing, hardware sizing, database sizing, and migration. You can also find information on system security, events and models, resource capacity planning, and proactive content. You can use this information to help you plan upgrades for your Genesys software and to add new Genesys products to your environment. Unless otherwise stated, these guides are valid for the Genesys 7.x-8.x releases to date. For additional details about changing or upgrading Genesys software, you should also review your product’s documentation.',
            },
            link: 'https://docs.genesys.com/Documentation/System',
            openNewTab: true,
            dataAnalytics: ['product', 'help', 'genesys', 'system', 'level', 'guides'],
        },
    ],
    'genesys-multicloud-cx': [
        {
            title: {
                rendered: 'Agent settings overview',
            },
            excerpt: {
                rendered:
                    'Learn about the features and controls that enable an agent to handle interactions and other key tasks.',
            },
            link: 'https://all.docs.genesys.com/PEC-AS/Current/ManageCC/Agent_options_overview',
            openNewTab: false,
            dataAnalytics: ['product', 'help', 'agent', 'settings', 'overview'],
        },
        {
            title: {
                rendered: 'Genesys Widgets Deployment Guide',
            },
            excerpt: {
                rendered:
                    'Genesys Widgets is a collection of lightweight and configurable web apps, optimized for mobile browsers.',
            },
            link: 'https://all.docs.genesys.com/WID',
            openNewTab: false,
            dataAnalytics: ['product', 'help', 'genesys', 'widgets', 'deployment', 'guide'],
        },
    ],
};
