import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HeroBannerComponent } from './hero-banner.component';

@NgModule({
    declarations: [HeroBannerComponent],
    exports: [HeroBannerComponent],
    imports: [CommonModule],
})
export class HeroBannerModule {}
