import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-close',
    templateUrl: './close.component.html',
    styleUrls: ['./close.component.css'],
})
export class CloseComponent {
    @Input() fillColor = 'var(--genesys-csdt-brand-navy)';
}
