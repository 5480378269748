import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReleaseAlertComponent } from './release-alert.component';

@NgModule({
    declarations: [ReleaseAlertComponent],
    imports: [CommonModule],
    exports: [ReleaseAlertComponent],
})
export class ReleaseAlertModule {}
