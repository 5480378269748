import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from '@pipes';
import { ShareModule } from '../share/share.module';
import { ArticleComponent } from './article.component';

@NgModule({
    declarations: [ArticleComponent],
    imports: [CommonModule, PipesModule, ShareModule],
    exports: [ArticleComponent],
})
export class ArticleModule {}
