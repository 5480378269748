<!--LOADING SPINNER-->
<div
    class="lds-ring"
    [style.marginTop]="marginTop"
    [style.marginBottom]="marginBottom"
    [style.height]="size"
    [style.width]="size">
    <div [ngClass]="theme" [style.height]="size" [style.width]="size" [style.borderWidth]="borderWidth"></div>
    <div [ngClass]="theme" [style.height]="size" [style.width]="size" [style.borderWidth]="borderWidth"></div>
    <div [ngClass]="theme" [style.height]="size" [style.width]="size" [style.borderWidth]="borderWidth"></div>
    <div [ngClass]="theme" [style.height]="size" [style.width]="size" [style.borderWidth]="borderWidth"></div>
    <span class="loading-message">Loading...</span>
</div>
<!--END LOADING SPINNER-->
