import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface FilterLocationIdHistory {
    oldLocationId: string;
    newLocationId: string;
}

export interface FilterLocationInfo {
    locationId: string;
    locationName: string;
}

@Injectable({
    providedIn: 'root',
})
export class SectionScrollService {
    private scroll = new Subject<boolean>();
    private filterGroupFilterSelect = new Subject<FilterLocationIdHistory>();
    private sectionScrollFilterSelect = new Subject<boolean>();

    public filterGroupFilterSelect$ = this.filterGroupFilterSelect.asObservable();
    public scroll$ = this.scroll.asObservable();
    public sectionScrollFilterSelect$ = this.sectionScrollFilterSelect.asObservable();

    public currentFilter: Map<string, FilterLocationInfo> = new Map<string, FilterLocationInfo>();

    /**
     * Pushes next value for scroll.
     * Causes section-scroll components to listen for route and scroll accordingly.
     */
    checkForScroll() {
        this.scroll.next(true);
    }

    /**
     * Pushes next value for filter values
     * @param old old filter value
     * @param active new filter value
     * Causes favorite components to determine if favorited
     */
    alertFilterGroupFilterSelect(old: string, active: string) {
        const newLocationIdHistory: FilterLocationIdHistory = {
            oldLocationId: old,
            newLocationId: active,
        };
        this.filterGroupFilterSelect.next(newLocationIdHistory);
    }

    /**
     * Pushes next value for if a filter is selected
     * Currently used by section scroll component on filter select
     */
    alertSectionScrollFilterSelect() {
        this.sectionScrollFilterSelect.next(true);
    }
}
