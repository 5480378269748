import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-tile-icon',
    templateUrl: './tile-icon.component.html',
    styleUrls: ['./tile-icon.component.css'],
})
export class TileIconComponent {
    @Input() icon: string;
    @Input() width: string;
    @Input() height: string;
}
