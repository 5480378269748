import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { EduTrainingSectionLabel, FavoriteId, UserRoleUid } from '@enums';
import {
    BeyondRiseVideo,
    BeyondWordpressService,
    DoceboEnrollment,
    KeplerDoceboService,
    KeplerSfdcUserDetail,
    ProductService,
    UserService,
} from '@services';
import { Observable, Subscription, combineLatest, iif, map, of, switchMap, tap } from 'rxjs';

import { DatePipe } from '@angular/common';
import { TrackByItem } from '@components';
import { environment } from '@environments/environment';
import { SECTION_LINKS } from 'src/app/app-header/submenu-data';
import { BeyondTrainingTileAdapter } from './beyond-training-tile.adapter';
import { BeyondTrainingTile } from './beyond-training-tile.interface';

@Component({
    selector: 'app-beyond',
    templateUrl: './beyond.component.html',
    styleUrls: ['./beyond.component.css'],
    providers: [DatePipe],
})
export class BeyondComponent implements OnInit, OnDestroy, TrackByItem<BeyondTrainingTile> {
    sectionLabel = EduTrainingSectionLabel.BEYOND_TRAINING;
    featureFlagGenEd = environment.featureFlags.genEd;
    favoriteId = FavoriteId.BEYOND;
    private subs: Subscription[] = [];
    @Input() showTitle = true;
    sectionCta = '';
    sectionCtaUrl = '';
    isVideoMobile = false;
    @Input() showIcons = true;

    sectionLocation = SECTION_LINKS.BEYOND_TRAINING;

    /**
     * Indicates whether the section call-to-action should be
     * shown in the right column of the tile layout or on the
     * bottom of the section. Per the Figma design, it only
     * shows in the tile layout when a user has a subscription
     * but zero enrollments.
     */
    showSectionCtaAsTile = false;

    /**
     * External URL for the section header.
     * Navigates to the default beyond home page
     */
    externalLink = environment.api.beyond.training.url;

    /**
     * URL for Beyond's default video page
     */
    private videosLink = `${environment.api.beyond.training.url}/videos`;

    /**
     * URL for Beyond user's auth'ed dashboard. Used by the standard
     * cta button as well as the special cta tile.
     */
    dashboardLink = `${environment.api.beyond.training.url}/dashboard`;

    /**
     * Beyond data
     */
    beyondTrainingTiles$: Observable<BeyondTrainingTile[]>;

    constructor(
        private beyondTrainingTileAdapter: BeyondTrainingTileAdapter,
        private beyondWordpressService: BeyondWordpressService,
        private breakpointObserver: BreakpointObserver,
        private changeDetector: ChangeDetectorRef,
        private doceboService: KeplerDoceboService,
        private productService: ProductService,
        private userService: UserService,
    ) {}

    ngOnInit(): void {
        this.subs.push(
            this.breakpointObserver.observe('(max-width: 1020px)').subscribe((result: BreakpointState) => {
                this.isVideoMobile = result.matches;
                this.changeDetector.markForCheck();
            }),
        );

        this.beyondTrainingTiles$ = this.userService.userRole$.pipe(
            switchMap((userRole) => {
                console.debug('beyond/gened user role', userRole); // TODO KNOW-1586 this log should only fire once. remove once confirmed.

                const isAuthenticated = userRole !== UserRoleUid.PUBLIC;

                // TODO KNOW-552 Remove the employee check here
                return iif(
                    () => isAuthenticated && !this.userService.isEmployee(userRole),
                    this.doceboSubscription$.pipe(
                        switchMap((subscription) => {
                            return this.doceboEnrollments$.pipe(
                                map((enrollments) => enrollments?.slice(0, 3) || []),
                                map((enrollments) => {
                                    if (subscription) {
                                        return [subscription, ...enrollments.slice(0, 2)]; // if subscription is present, then only show a max of 2 enrollments
                                    } else {
                                        return enrollments;
                                    }
                                }),
                                switchMap((beyondTraining: BeyondTrainingTile[]): Observable<BeyondTrainingTile[]> => {
                                    // TODO KNOW-1586 Falling back to free videos causes a quick flash of the loading spinner in the current state of user role detection
                                    return beyondTraining?.length > 0 ? of(beyondTraining) : this.freeVideos$;
                                }),
                            );
                        }),
                        tap((beyondTraining: BeyondTrainingTile[]) => {
                            this.showSectionCtaAsTile =
                                beyondTraining?.length == 1 && beyondTraining[0].title.includes('Learning Plan');
                        }),
                    ),
                    // TODO KNOW-1586 this falsy check shouldn't be necessary, but without it there is a double loading spinner. This ticket should resolve issue.
                    this.freeVideos$,
                );
            }),
        );
    }

    /**
     * Subscribes to the kepler-docebo-api for a single Subscription.
     * - Maps the Docebo data to {@link BeyondTrainingTile}
     *
     * @returns an observable beyond training tile representing subscriptions, or null when none is present
     */
    private get doceboSubscription$(): Observable<BeyondTrainingTile | null> {
        return this.userService.sfdcUserDetail$.pipe(
            map((userDetail: KeplerSfdcUserDetail) => userDetail?.Docebo_User?.docebo_v3__UserId__c),
            switchMap((doceboUserId) => {
                return this.doceboService.subscriptions$(doceboUserId);
            }),
            map((doceboSubscriptions) => {
                console.debug('docebo subscriptions', doceboSubscriptions); // log all subscriptions before mapping
                return (
                    doceboSubscriptions?.map((doceboSubscription) =>
                        // there are only a max of 3 subscriptions, so don't worry about performance here
                        this.beyondTrainingTileAdapter.fromSubscription(doceboSubscription),
                    ) || []
                );
            }),
            switchMap((subscriptions) => {
                return iif(
                    () => subscriptions.length == 0,
                    of(null),
                    this.productService.selectedProduct$.pipe(
                        map((product) => {
                            // Note: titles do not map to ProductUids. they contain our ProductDisplayName.
                            return subscriptions.find((subscription) => {
                                return subscription.title.includes(product.displayName);
                            });
                        }),
                    ),
                );
            }),
        );
    }

    /**
     * Subscribes to the kepler-docebo-api for Enrollments
     * - Splits enrollments between Beyond (external) and GenEd (internal)
     * - Maps the Docebo data to {@link BeyondTrainingTile}
     * - Sets the {@link sectionCta} and {@link sectionCtaUrl}
     *
     * @returns an observable list of beyond training tiles representing enrollments
     */
    private get doceboEnrollments$(): Observable<BeyondTrainingTile[]> {
        return this.userService.sfdcUserDetail$.pipe(
            map((userDetail: KeplerSfdcUserDetail) => userDetail?.Docebo_User?.docebo_v3__UserId__c),
            switchMap((doceboUserId) => {
                const enrollments$ = this.doceboService.enrollments$(doceboUserId);
                const userRole$ = this.userService.userRole$;
                return combineLatest([enrollments$, userRole$]);
            }),
            map(([doceboEnrollmentsByCode, userRole]) => {
                let doceboEnrollments: DoceboEnrollment[];

                // TODO KNOW-552 Enable GenEd courses for employees
                // We bypass altogether for employees to avoid showing Beyond (external) enrollments that employees might have.
                if (!this.userService.isEmployee(userRole)) {
                    if (this.featureFlagGenEd) {
                        doceboEnrollments = doceboEnrollmentsByCode?.genEd;
                        this.sectionLabel = EduTrainingSectionLabel.GEN_ED;
                        this.sectionCtaUrl = environment.api.beyond.docebo.genedurl;
                    } else {
                        doceboEnrollments = doceboEnrollmentsByCode?.beyond;
                        this.sectionCtaUrl = this.dashboardLink;
                    }
                }

                console.debug('docebo enrollments', doceboEnrollments); // log all the enrollments before mapping

                return (
                    doceboEnrollments
                        ?.slice(0, 3) // only show a max of 3 enrollments, slice before map for performance
                        ?.map((doceboEnrollment: DoceboEnrollment) =>
                            this.beyondTrainingTileAdapter.fromEnrollment(doceboEnrollment, userRole),
                        ) || []
                );
            }),
            tap(() => {
                this.sectionCta = 'Manage my courses'; // use this cta regardless of beyond/gened
            }),
        );
    }

    /**
     * Subscribes to the free Beyond content, the Rise videos from Beyond Wordpress Service
     * - Maps the Wordpress data to {@link BeyondTrainingTile}
     * - Sets the {@link sectionCta} and {@link sectionCtaUrl}
     */
    private get freeVideos$(): Observable<BeyondTrainingTile[]> {
        return this.beyondWordpressService.getBeyondRiseVideos().pipe(
            map((beyondRiseVideos: BeyondRiseVideo[]) => {
                let featuredVideo: BeyondRiseVideo;
                const freeVideos: BeyondRiseVideo[] = []; // should only ever have 2 items max

                for (const freeVideo of beyondRiseVideos) {
                    if (environment.api.beyond.knowvideos) {
                        if (freeVideo.id === environment.api.beyond.knowvideos.featuredVideos) {
                            featuredVideo = freeVideo;
                        } else if (environment.api.beyond.knowvideos.freeVideos.some((id) => id === freeVideo.id)) {
                            freeVideos.push(freeVideo);
                        } else {
                            continue;
                        }
                    } else {
                        if (freeVideo) {
                            // KNOW-1612 If there are no video IDs in the environment files,
                            // then fall back to the 'featured' slugs in wordpress.
                            const isFeaturedVideo = freeVideo.acf?.video_category.some((i) =>
                                i.slug?.includes('featured'),
                            );
                            if (isFeaturedVideo && !featuredVideo) {
                                // set the first featured video we detect, ignore any remaining featured content
                                featuredVideo = freeVideo;
                            } else if (!isFeaturedVideo) {
                                freeVideos.push(freeVideo);
                            } else {
                                // we found a featured video but we already set one
                                // do not cache this free featured video, but keep looping
                                continue;
                            }
                        }
                    }
                }

                if (featuredVideo) {
                    // If there is a featured video, prioritize it in the zero index.
                    freeVideos.unshift(featuredVideo);
                }

                console.debug('free beyond videos', freeVideos); // log all free videos before mapping

                return freeVideos
                    ?.slice(0, 3) // only show a max of 3 free video, slice before map for performance
                    ?.map((video) => this.beyondTrainingTileAdapter.fromFreeVideo(video));
            }),
            tap(() => {
                this.sectionCta = 'See all videos';
                this.sectionCtaUrl = this.videosLink;
            }),
        );
    }

    trackByItem(_index: number, item: BeyondTrainingTile): NonNullable<number | string> {
        return item.id;
    }

    /**
     * On Destroy, unsubscribe subscriptions to prevent memory leaks
     */
    ngOnDestroy(): void {
        if (this.subs) {
            this.subs.forEach((sub) => {
                sub.unsubscribe();
            });
        }
    }
}
