import { environment } from '@environments/environment';
import { DeveloperCenterData } from '../developer-center/developer-center.interface';

export const developerCenterMcCxTopicsData: DeveloperCenterData = {
    baseUrl: environment.api.developerCenterMcCx.site.baseUrl,
    alternateBaseUrl: environment.api.developerCenterMcCx.site.alternateBaseUrl,
    iconBase: '/assets/icons/developer-center-mc-cx/',
    cards: [
        {
            title: 'API Reference',
            icon: 'api-reference-icon.svg',
            url: 'reference/',
        },
        {
            title: 'Client Libraries',
            icon: 'client-libraries-icon.svg',
            url: 'client-libraries/',
        },
        {
            title: 'Tutorials',
            icon: 'tutorials-icon.svg',
            url: 'tutorials/',
        },
    ],
};
