import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DirectivesModule } from '@directives';
import { ArticleModule } from '../../article/article.module';
import { TileLayoutModule } from '../../tile-layout/tile-layout.module';
import { VideoContainerModule } from '../../video-container/video-container.module';
import { FeaturedContentVideoEmbedComponent } from './featured-content-video-embed.component';

@NgModule({
    declarations: [FeaturedContentVideoEmbedComponent],
    imports: [CommonModule, ArticleModule, DirectivesModule, TileLayoutModule, VideoContainerModule],
    exports: [FeaturedContentVideoEmbedComponent],
})
export class FeaturedContentVideoEmbedModule {}
