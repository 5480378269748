import { ProductUid, UserRoleUid } from '@enums';
import { AdditionalSite } from './additional-sites.component';

export const additionalSitesData: Record<ProductUid, AdditionalSite[]> = {
    [ProductUid.POINTILLIST]: [], // purposefully empty
    [ProductUid.GENESYS_DX]: [], // purposefully empty
    [ProductUid.PURECONNECT]: [
        {
            label: 'Consultant Portal',
            url: 'http://genesyspartner.force.com/ConsultantPortal',
            roles: [UserRoleUid.CONSULTANT],
        },
        {
            label: 'GCAP Hub',
            url: 'https://genesys.influitive.com/join/Enroll',
            roles: [UserRoleUid.CUSTOMER, UserRoleUid.EMPLOYEE, UserRoleUid.CONSULTANT],
        },
        {
            label: 'Partner Portal',
            url: 'https://genesyspartner.force.com/GenesysPartner/s/',
            roles: [UserRoleUid.PARTNER],
        },
    ],
    [ProductUid.GENESYS_CLOUD_CX]: [
        {
            label: 'Consultant Portal',
            url: 'http://genesyspartner.force.com/ConsultantPortal',
            roles: [UserRoleUid.CONSULTANT],
        },
        {
            label: 'GCAP Hub',
            url: 'https://genesys.influitive.com/join/Enroll',
            roles: [UserRoleUid.CUSTOMER, UserRoleUid.EMPLOYEE, UserRoleUid.CONSULTANT],
        },
        {
            label: 'Partner Portal',
            url: 'https://genesyspartner.force.com/GenesysPartner/s/',
            roles: [UserRoleUid.PARTNER],
        },
    ],
    [ProductUid.GENESYS_ENGAGE_ON_PREM]: [
        {
            label: 'Consultant Portal',
            url: 'http://genesyspartner.force.com/ConsultantPortal',
            roles: [UserRoleUid.CONSULTANT],
        },
        {
            label: 'GCAP Hub',
            url: 'https://genesys.influitive.com/join/Enroll',
            roles: [UserRoleUid.CUSTOMER, UserRoleUid.EMPLOYEE, UserRoleUid.CONSULTANT],
        },
        {
            label: 'Partner Portal',
            url: 'https://genesyspartner.force.com/GenesysPartner/s/',
            roles: [UserRoleUid.PARTNER],
        },
    ],
    [ProductUid.GENESYS_MULTICLOUD]: [
        {
            label: 'Consultant Portal',
            url: 'http://genesyspartner.force.com/ConsultantPortal',
            roles: [UserRoleUid.CONSULTANT],
        },
        {
            label: 'GCAP Hub',
            url: 'https://genesys.influitive.com/join/Enroll',
            roles: [UserRoleUid.CUSTOMER, UserRoleUid.EMPLOYEE, UserRoleUid.CONSULTANT],
        },
        {
            label: 'Partner Portal',
            url: 'https://genesyspartner.force.com/GenesysPartner/s/',
            roles: [UserRoleUid.PARTNER],
        },
    ],
};
