import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ComponentsModule } from '@components';
import { DirectivesModule } from '@directives';
import { EduEventsAndWebinarsModule } from 'src/app/edu-training/edu-events-and-webinars/edu-events-and-webinars.module';
import { AppFoundryModule } from 'src/app/resources/app-foundry/app-foundry.module';
import { MyFavoritesComponent } from './my-favorites.component';

@NgModule({
    declarations: [MyFavoritesComponent],
    imports: [
        CommonModule,
        ComponentsModule,
        DirectivesModule,
        AppFoundryModule,
        EduEventsAndWebinarsModule,
        RouterModule,
    ],
    exports: [MyFavoritesComponent],
})
export class MyFavoritesModule {}
