import { Component, Input } from '@angular/core';
import { GknFeaturedContent } from '@services';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-featured-content-thumbnail',
    templateUrl: './featured-content-thumbnail.component.html',
    styleUrls: ['./featured-content-thumbnail.component.css'],
})
export class FeaturedContentThumbnailComponent {
    @Input() featuredContent: GknFeaturedContent;
    isModalOpen$ = new BehaviorSubject(false);

    openModal(): void {
        this.isModalOpen$.next(true);
    }

    closeModal(): void {
        this.isModalOpen$.next(false);
    }
}
