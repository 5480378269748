import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { ProductUid } from '@enums';
import { Product, ProductService } from '@services';
import { Subscription, tap } from 'rxjs';

/**
 * Structural directive indicating the attached element should only be shown for the products in the given list.
 *
 * @example
 * // my.component.ts
 * import { ProductUid } from '@enums'
 * export class MyComponent {
 *   productEnum = ProductUid;
 * }
 *
 * // my.component.html
 * <p *products="[productEnum.GENESYS_ENGAGE_ON_PREM, productEnum.PURECONNECT]">
 *   hello, legacy world!
 * </p>
 */
@Directive({
    selector: '[products]',
})
export class ProductsDirective implements OnInit, OnDestroy {
    /** rxjs subscription to the selected product */
    private sub: Subscription;

    /**
     * list of products for which the component should render.
     * must have the same name as the directive selector!
     */
    @Input() private products: ProductUid[];

    constructor(
        private productService: ProductService,
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
    ) {}

    ngOnInit() {
        this.sub = this.productService.selectedProduct$
            .pipe(
                tap((product: Product) => {
                    this.viewContainer.clear();
                    if (this.products?.includes(product.uid)) {
                        this.viewContainer.createEmbeddedView(this.templateRef);
                    }
                }),
            )
            .subscribe();
    }

    ngOnDestroy(): void {
        this.sub?.unsubscribe();
    }
}
