import { Pipe, PipeTransform } from '@angular/core';
import striptags from 'striptags';
import { stripShortCodes } from '../../utils/strings';

/**
 * Strips all HTML tags from a string, replaces non-breaking space characters with the empty string,
 * and removes the leading and trailing white space and line terminator characters from a string.
 * No special HTML tags are permitted and tags are replaced with an empty string.
 */
@Pipe({
    name: 'stripHtml',
})
export class StripHtmlPipe implements PipeTransform {
    private readonly allowedTags = [];
    private readonly tagReplacement = '';

    transform(htmlString: string): string {
        const formattedString = striptags(htmlString, this.allowedTags, this.tagReplacement)
            .replace(/&nbsp;/g, '')
            .trim();
        return stripShortCodes(formattedString);
    }
}
