import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ComponentsModule } from '@components';
import { ServicesModule } from '../@shared/services/services.module';
import { AppFoundryModule } from './app-foundry/app-foundry.module';
import { CustomerSuccessModule } from './customer-success/customer-success.module';
import { CxEvolutionModule } from './cx-evolution/cx-evolution.module';
import { DeveloperCenterMcCxModule } from './developer-center-mc-cx/developer-center-mc-cx.module';
import { DeveloperCenterModule } from './developer-center/developer-center.module';
import { DeveloperPortalPureconnectModule } from './developer-portal-pureconnect/developer-potral-popular.module';
import { ExpertAppsModule } from './expert-apps/expert-apps.module';
import { GetStartedModule } from './get-started/get-started.module';
import { ProductHelpModule } from './product-help/product-help.module';
import { ResourcesRoutingModule } from './resources-routing.module';
import { ResourcesComponent } from './resources.component';

@NgModule({
    declarations: [ResourcesComponent],
    imports: [
        CommonModule,
        ComponentsModule,
        AppFoundryModule,
        CxEvolutionModule,
        CustomerSuccessModule,
        DeveloperCenterModule,
        DeveloperCenterMcCxModule,
        DeveloperPortalPureconnectModule,
        ExpertAppsModule,
        GetStartedModule,
        ProductHelpModule,
        ResourcesRoutingModule,
        ServicesModule,
    ],
})
export class ResourcesModule {}
