import { ProductDisplayName, ProductUid, StarterKitFilterLabel, StarterKitTabName } from '@enums';
import { StarterKitStepType, StarterKitTab } from './starter-kit.interface';

export const starterKitMessages: Record<StarterKitStepType, { continue: string; replay: string }> = {
    [StarterKitStepType.ARTICLE]: {
        continue: 'Read to continue',
        replay: 'Read again',
    },
    [StarterKitStepType.VIDEO]: {
        continue: 'Watch to continue',
        replay: 'Watch again',
    },
    [StarterKitStepType.DOCEBO]: {
        continue: 'Start to continue',
        replay: 'Revisit course',
    },
    [StarterKitStepType.HYPERLINK]: {
        continue: 'Visit link to continue',
        replay: 'Visit again',
    },
};

/**
 * All starter kit tab and step data
 * Data is modeled specified in starter-kit.interface.ts
 * IMPORTANT: any modification of ids may result in starter kit progress breaking, requiring database changes
 */
export const starterKitData: StarterKitTab[] = [
    {
        name: StarterKitTabName.GENESYS_CLOUD_CX,
        filter: StarterKitFilterLabel.GENESYS_CLOUD_CX,
        id: 'genesys-cloud-cx',
        order: true,
        mobileMessage: 'Note: steps 2-7 are optimized for desktop view only.',
        dataAnalytics: ['starter', 'kit', 'tab', 'gc-cx'],
        steps: [
            {
                name: 'Overview Video',
                icon: 'play',
                offset: '3px',
                id: 'overview-video',
                title: 'Genesys Customer Success Program Overview',
                description:
                    "With the Genesys Customer Success program, we're here with you all the way! Here is an inside look at the customer’s experience throughout their lifecycle when working with Genesys.",
                type: StarterKitStepType.VIDEO,
                messages: starterKitMessages[StarterKitStepType.VIDEO],
                link: 'https://www.youtube.com/embed/2F3vGMtYCM8',
                mobileAllowed: true,
                dataAnalytics: ['starter', 'kit', 'step', 'gc', 'cx', 'overview'],
            },
            {
                name: 'Network Readiness Checklist',
                icon: 'network-readiness-checklist',
                id: 'network-readiness-checklist',
                title: 'Network Readiness Checklist',
                description:
                    'Make sure your company’s network is ready for voice and video by using the following checklist and guides.',
                type: StarterKitStepType.ARTICLE,
                messages: starterKitMessages[StarterKitStepType.ARTICLE],
                link: 'https://help.mypurecloud.com/articles/customer-network-readiness/',
                dataAnalytics: ['starter', 'kit', 'step', 'gc', 'cx', 'network', 'readiness'],
            },
            {
                name: 'Run Network Readiness Assessment',
                icon: 'network-readiness-assessment',
                id: 'network-readiness-assessment',
                title: 'Run the Network Readiness Assessment',
                description:
                    'In this article, you’ll find detailed instructions on how to run and interpret the results provided by the Genesys Cloud Network Readiness Assessment tool.',
                type: StarterKitStepType.ARTICLE,
                messages: starterKitMessages[StarterKitStepType.ARTICLE],
                link: 'https://help.mypurecloud.com/articles/run-the-genesys-cloud-network-readiness-assessment/',
                dataAnalytics: ['starter', 'kit', 'step', 'gc', 'cx', 'network', 'assessment'],
            },
            {
                name: 'Roles and Responsibilities',
                icon: 'roles',
                id: 'roles-and-responsibilities',
                title: `Roles and Responsibilities Required for ${ProductDisplayName[ProductUid.GENESYS_CLOUD_CX]}`,
                description: 'This article describes the roles required for setup and operation of Genesys Cloud.',
                type: StarterKitStepType.ARTICLE,
                messages: starterKitMessages[StarterKitStepType.ARTICLE],
                link: 'https://help.mypurecloud.com/articles/roles-and-responsibilities/',
                dataAnalytics: ['starter', 'kit', 'step', 'gc', 'cx', 'roles'],
            },
            {
                name: 'Add People to Your Organization',
                icon: 'add-user',
                id: 'add-user',
                title: 'Add People to Your Organization',
                description: 'Learn the ins and outs of adding users to your organization.',
                type: StarterKitStepType.ARTICLE,
                messages: starterKitMessages[StarterKitStepType.ARTICLE],
                link: 'https://help.mypurecloud.com/articles/add-people-organization/',
                dataAnalytics: ['starter', 'kit', 'step', 'gc', 'cx', 'add', 'people'],
            },
            {
                name: 'Assign Roles',
                icon: 'assign-roles',
                id: 'assign-roles',
                title: 'How to Assign Roles',
                description:
                    'Each role in Genesys Cloud contains one or more permissions. The permissions allow users with that role to do various tasks, such as create groups, set up integrations, and supervise contact center activity.',
                type: StarterKitStepType.ARTICLE,
                messages: starterKitMessages[StarterKitStepType.ARTICLE],
                link: 'https://help.mypurecloud.com/articles/about-roles-permissions/',
                dataAnalytics: ['starter', 'kit', 'step', 'gc', 'cx', 'assign', 'roles'],
            },
            {
                name: 'Training Options',
                icon: 'training',
                id: 'training-options',
                sizeOffset: 10,
                title: 'Intro to Beyond Training',
                description: `Welcome to ${
                    ProductDisplayName[ProductUid.GENESYS_CLOUD_CX]
                }! Learn more about what’s included in your Beyond learning subscription, three easy steps to register for a course, how to get started with our learning paths, and what a certification is and how to obtain it. Don’t have a Beyond learning subscription yet? <a class='cursor explore-options-link link-n' href='https://beyond.genesys.com/explore/products' target='_blank'>Explore available options.</a>`,
                type: StarterKitStepType.VIDEO,
                messages: starterKitMessages[StarterKitStepType.VIDEO],
                link: 'https://fast.wistia.net/embed/iframe/7d2vz3bqqk?videoFoam=true',
                articleIcon: '/assets/icons/starter-kit/beyond.svg',
                dataAnalytics: ['starter', 'kit', 'step', 'gc', 'cx', 'training', 'options'],
            },
        ],
    },
    {
        name: StarterKitTabName.GENESYS_CLOUD_CX_DIGITAL,
        filter: StarterKitFilterLabel.GENESYS_CLOUD_CX_DIGITAL,
        id: 'genesys-cloud-cx-digital',
        order: true,
        mobileMessage: 'Note: steps 2-7 are optimized for desktop view only.',
        dataAnalytics: ['starter', 'kit', 'tab', 'gc-cx-digital'],
        steps: [
            {
                name: 'Overview Video',
                icon: 'play',
                offset: '3px',
                id: 'overview-video',
                title: 'Genesys Customer Success Program Overview',
                description:
                    "With the Genesys Customer Success program, we're here with you all the way! Here is an inside look at the customer’s experience throughout their lifecycle when working with Genesys.",
                type: StarterKitStepType.VIDEO,
                messages: starterKitMessages[StarterKitStepType.VIDEO],
                link: 'https://www.youtube.com/embed/2F3vGMtYCM8',
                mobileAllowed: true,
                dataAnalytics: ['starter', 'kit', 'step', 'cx', 'digital', 'overview'],
            },
            {
                name: 'Network Readiness Checklist',
                icon: 'network-readiness-checklist',
                id: 'network-readiness-checklist',
                title: 'Network Readiness Checklist',
                description:
                    'Make sure your company’s network is ready for voice and video by using the following checklist and guides.',
                type: StarterKitStepType.ARTICLE,
                messages: starterKitMessages[StarterKitStepType.ARTICLE],
                link: 'https://help.mypurecloud.com/articles/customer-network-readiness/',
                dataAnalytics: ['starter', 'kit', 'step', 'cx', 'digital', 'network', 'readiness'],
            },
            {
                name: 'Run Network Readiness Assessment',
                icon: 'network-readiness-assessment',
                id: 'network-readiness-assessment',
                title: 'Run the Network Readiness Assessment',
                description:
                    'In this article, you’ll find detailed instructions on how to run and interpret the results provided by the Genesys Cloud Network Readiness Assessment tool.',
                type: StarterKitStepType.ARTICLE,
                messages: starterKitMessages[StarterKitStepType.ARTICLE],
                link: 'https://help.mypurecloud.com/articles/run-the-genesys-cloud-network-readiness-assessment/',
                dataAnalytics: ['starter', 'kit', 'step', 'cx', 'digital', 'network', 'assessment'],
            },
            {
                name: 'Roles and Responsibilities',
                icon: 'roles',
                id: 'roles-and-responsibilities',
                title: `Roles and Responsibilities Required for ${ProductDisplayName[ProductUid.GENESYS_CLOUD_CX]}`,
                description: 'This article describes the roles required for setup and operation of Genesys Cloud.',
                type: StarterKitStepType.ARTICLE,
                messages: starterKitMessages[StarterKitStepType.ARTICLE],
                link: 'https://help.mypurecloud.com/articles/roles-and-responsibilities/',
                dataAnalytics: ['starter', 'kit', 'step', 'cx', 'digital', 'roles'],
            },
            {
                name: 'Add People to Your Organization',
                icon: 'add-user',
                id: 'add-user',
                title: 'Add People to Your Organization',
                description: 'Learn the ins and outs of adding users to your organization.',
                type: StarterKitStepType.ARTICLE,
                messages: starterKitMessages[StarterKitStepType.ARTICLE],
                link: 'https://help.mypurecloud.com/articles/add-people-organization/',
                dataAnalytics: ['starter', 'kit', 'step', 'cx', 'digital', 'add', 'people'],
            },
            {
                name: 'Assign Roles',
                icon: 'assign-roles',
                id: 'assign-roles',
                title: 'How to Assign Roles',
                description:
                    'Each role in Genesys Cloud contains one or more permissions. The permissions allow users with that role to do various tasks, such as create groups, set up integrations, and supervise contact center activity.',
                type: StarterKitStepType.ARTICLE,
                messages: starterKitMessages[StarterKitStepType.ARTICLE],
                link: 'https://help.mypurecloud.com/articles/about-roles-permissions/',
                dataAnalytics: ['starter', 'kit', 'step', 'cx', 'digital', 'assign', 'roles'],
            },
            {
                name: 'Training Course',
                icon: 'training',
                id: 'training-course',
                sizeOffset: 10,
                title: `${ProductDisplayName[ProductUid.GENESYS_CLOUD_CX]}: Digital – Onboarding Guide`,
                description: `This guide will introduce you to the online resources and training that are available as you begin your journey into ${
                    ProductDisplayName[ProductUid.GENESYS_CLOUD_CX]
                } Digital.`,
                type: StarterKitStepType.HYPERLINK,
                messages: starterKitMessages[StarterKitStepType.HYPERLINK],
                link: 'https://beyond.genesys.com/explore/course/genesys-cloud-cx-digital-onboarding-guide',
                articleIcon: '/assets/icons/starter-kit/beyond.svg',
                dataAnalytics: ['starter', 'kit', 'step', 'cx', 'digital', 'training', 'course'],
            },
        ],
    },
    {
        name: StarterKitTabName.GENESYS_CLOUD_WEM_EX,
        filter: StarterKitFilterLabel.GENESYS_CLOUD_WEM_EX,
        id: 'genesys-cloud-wem-ex',
        order: false,
        mobileMessage: 'Note: all steps are optimized for desktop view only.',
        dataAnalytics: ['starter', 'kit', 'tab', 'gc-wem-ex'],
        steps: [
            {
                name: 'WEM Community',
                icon: 'roles',
                id: 'wem-community',
                title: 'WEM Community',
                description: 'A community to discuss Workforce Engagement Management.',
                type: StarterKitStepType.HYPERLINK,
                messages: starterKitMessages[StarterKitStepType.HYPERLINK],
                articleIcon: '/assets/icons/starter-kit/roles.svg',
                link: 'https://community.genesys.com/communities/workforce-optimization?CommunityKey=f6e20f73-2c94-4b44-be95-118216aafb4f',
                dataAnalytics: ['starter', 'kit', 'step', 'wem', 'ex', 'community'],
            },
            {
                name: 'Resource Center – WEM',
                icon: 'article',
                id: 'recourse-center-wem',
                title: 'About Workforce Engagement Management',
                description:
                    'With its Workforce Engagement Management (WEM) offering, Genesys provides a comprehensive workforce optimization solution that simplifies the agent experience.',
                type: StarterKitStepType.ARTICLE,
                messages: starterKitMessages[StarterKitStepType.ARTICLE],
                link: 'https://help.mypurecloud.com/articles/about-workforce-engagement-management-wem',
                dataAnalytics: ['starter', 'kit', 'step', 'wem', 'ex', 'resource', 'wem'],
            },
            {
                name: 'Resource Center – Genesys Cloud EX',
                icon: 'article',
                id: 'resource-center-ccex',
                title: 'About Genesys Cloud EX',
                description:
                    'Genesys Cloud EX (employee experience) enables organizations to transform their approach to employee engagement and to deliver higher levels of efficiency and effectiveness.',
                type: StarterKitStepType.ARTICLE,
                messages: starterKitMessages[StarterKitStepType.ARTICLE],
                link: 'https://help.mypurecloud.com/articles/about-genesys-cloud-ex/',
                dataAnalytics: ['starter', 'kit', 'step', 'wem', 'ex', 'resource', 'ex'],
            },
            {
                name: 'Training – Creator Adventures, WEM Universe',
                icon: 'training',
                id: 'training-ca-wem-universe',
                title: 'Explore the WEM Universe',
                sizeOffset: 10,
                description:
                    'In this video, we’ll show you how to get the most from your Genesys Cloud Workforce Management solution, covering a wide range of topics from both agent and administrative perspectives.',
                type: StarterKitStepType.VIDEO,
                messages: starterKitMessages[StarterKitStepType.VIDEO],
                link: 'https://fast.wistia.net/embed/iframe/8ysp1cyb3g?videoFoam=true',
                dataAnalytics: ['starter', 'kit', 'step', 'wem', 'ex', 'wem', 'universe'],
            },
            {
                name: 'Training – Agent Learning Path, WEM',
                icon: 'training',
                id: 'training-alp-wem',
                title: 'Agent Learning Path, WEM',
                sizeOffset: 10,
                articleIcon: '/assets/icons/starter-kit/training.svg',
                description: `The Agent Learning Path for ${
                    ProductDisplayName[ProductUid.GENESYS_CLOUD_CX]
                } is ideal for front-line contact center staff responsible for handling customer interactions for ${
                    ProductDisplayName[ProductUid.GENESYS_CLOUD_CX]
                }  through a variety of channels, such as voice, chat, SMS, email, and social media.`,
                type: StarterKitStepType.HYPERLINK,
                messages: starterKitMessages[StarterKitStepType.HYPERLINK],
                link: 'https://beyond.genesys.com/explore/learning-path/agent-learning-path#wem',
                dataAnalytics: ['starter', 'kit', 'step', 'wem', 'ex', 'wem', 'agent', 'learn'],
            },
            {
                name: 'TAM Studios',
                icon: 'folder',
                id: 'tam-studios',
                title: 'TAM Studios',
                offset: '1px',
                articleIcon: '/assets/icons/starter-kit/folder.svg',
                description: 'A library of resources at your fingertips to help you on your Genesys journey',
                type: StarterKitStepType.HYPERLINK,
                messages: starterKitMessages[StarterKitStepType.HYPERLINK],
                link: 'https://community.genesys.com/communities/community-home1/librarydocuments?attachments=&communitykey=bab95e9c-6bbe-4a13-8ade-8ec0faf733d4&defaultview=&folder=716efc68-43ae-48dc-a057-0186794e285f&libraryfolderkey=&pageindex=0&pagesize=12&search=&sort=most_recent&viewtype=row',
                dataAnalytics: ['starter', 'kit', 'step', 'wem', 'ex', 'tam', 'studios'],
            },
            {
                name: 'Events & Webinars',
                icon: 'calendar',
                id: 'events-and-webinars',
                title: 'Events & Webinars',
                articleIcon: '/assets/icons/starter-kit/calendar.svg',
                description: 'Learn more about Workforce Engagement Management during these events and webinars.',
                type: StarterKitStepType.HYPERLINK,
                messages: starterKitMessages[StarterKitStepType.HYPERLINK],
                link: 'https://community.genesys.com/events/calendar?CalendarEventKey=5e804324-43ed-42c3-b4e3-018747e4a464&CommunityKey=f6e20f73-2c94-4b44-be95-118216aafb4f',
                dataAnalytics: ['starter', 'kit', 'step', 'wem', 'ex', 'event', 'webinar'],
            },
        ],
    },
];
