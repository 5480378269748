import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-outage-alert',
    templateUrl: './outage-alert.component.html',
    styleUrls: ['./outage-alert.component.css'],
})
export class OutageAlertComponent {
    @Input() fillColor = '#ffffff';
}
