import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ComponentsModule } from '@components';
import { DeveloperPortalPureconnectComponent } from './developer-portal-pureconnect.component';

@NgModule({
    declarations: [DeveloperPortalPureconnectComponent],
    imports: [CommonModule, ComponentsModule],
    exports: [DeveloperPortalPureconnectComponent],
})
export class DeveloperPortalPureconnectModule {}
