import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { FundamentalsCardComponent } from './fundamentals-card.component';

@NgModule({
    declarations: [FundamentalsCardComponent],
    imports: [
        CommonModule,
        MatCardModule,
        MatDialogModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        RouterModule,
    ],
    exports: [FundamentalsCardComponent],
})
export class FundamentalsCardModule {}
