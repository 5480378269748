import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ContentService, GknFundamentalCardLink } from '@services';
import { Observable, tap } from 'rxjs';

@Component({
    selector: 'app-product-ideas-lab',
    templateUrl: './product-ideas-lab.component.html',
    styleUrls: ['./product-ideas-lab.component.css'],
})
export class ProductIdeasLabComponent implements OnInit {
    links$: Observable<GknFundamentalCardLink[]>;
    isLoading = true;
    colors: string[] = [];

    constructor(private cdr: ChangeDetectorRef, private contentService: ContentService, private dialog: MatDialog) {}

    ngOnInit(): void {
        this.links$ = this.contentService.productIdeasLab$.pipe(
            tap(() => {
                this.isLoading = false;
                this.cdr.detectChanges();
            }),
        );
    }
}
