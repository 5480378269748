export const genesysCloudReleaseNotes = {
    url: 'https://help.mypurecloud.com/wp-json/wp/v2/releasenotes',
    textLinks: [
        { label: 'View All', url: 'https://help.mypurecloud.com/release-notes/' },
        {
            label: 'Features Coming Soon',
            url: 'https://help.mypurecloud.com/features-coming-soon/',
        },
    ],
};
