import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ComponentsModule } from '@components';
import { DirectivesModule } from '@directives';
import { PipesModule } from '@pipes';
import { EduEventsAndWebinarsComponent } from './edu-events-and-webinars.component';

@NgModule({
    declarations: [EduEventsAndWebinarsComponent],
    imports: [CommonModule, ComponentsModule, DirectivesModule, PipesModule],
    exports: [EduEventsAndWebinarsComponent],
})
export class EduEventsAndWebinarsModule {}
