import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { sortByDate } from '@utils';
import { map } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { DeveloperCenterBlog } from './blog.interface';

@Injectable({
    providedIn: 'root',
})
export class BlogService {
    private blogUrl = `${environment.api.developerCenter.blog.baseUrl}${environment.api.developerCenter.blog.blogFeed}`;
    private imageUrl = `${environment.api.developerCenter.blog.baseUrl}`;

    constructor(private http: HttpClient) {}

    /**
     * Return Observable array of blogs, sorted by date ascending
     * @returns Observable array blogs from developer center
     */
    get blogs$(): Observable<DeveloperCenterBlog[]> {
        return this.http.get<DeveloperCenterBlog[]>(`${this.blogUrl}`).pipe(
            map((blogs) => {
                return blogs.map((blog) => {
                    blog.customImgUrl = `${this.imageUrl}${blog.link.substring(0, blog.link.lastIndexOf('/'))}/${
                        blog.image
                    }`;
                    blog.link = blog.link.substring(0, blog.link.lastIndexOf('/'));
                    return blog;
                });
            }),
            map((blogs) => sortByDate(blogs, 'date')),
        );
    }
}
