<app-section-header
    [title]="sectionLabel"
    [favoriteId]="favoriteId"
    [showTitle]="showTitle"
    [section]="sectionLocation"></app-section-header>
<app-tile-layout maxColumns="4">
    <li *ngFor="let card of customerSuccessCards; trackBy: trackByItem">
        <app-tile-small [altText]="card.name + ' icon'" [tileSmallData]="card" [dark]="true"> </app-tile-small>
    </li>
</app-tile-layout>
