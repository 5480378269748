<div class="hero-banner">
    <div class="hero-content">
        <img
            class="hero-image"
            src="/assets/images/gkn-animation.gif"
            width="1200px"
            height="auto"
            alt="Welcome to the Genesys Knowledge Network animated image" />
        <h1>
            Welcome to the Genesys Knowledge Network!
            <br />
            Your all-in-one access point for Genesys resources.
        </h1>
        <div class="cta-container">
            <button (click)="openLoginModal()" class="cta-button">
                <span class="cta-content">
                    Get started
                    <span class="right-arrow-container">
                        <img
                            src="/assets/icons/original-message-right-arrow.svg"
                            width="13px"
                            height="20px"
                            alt="chevron right icon" />
                    </span>
                </span>
            </button>
        </div>
    </div>
</div>
