import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
    BackToTopComponent,
    ButtonComponent,
    CookieConsentComponent,
    ExternalUrlComponent,
    InfoTooltipComponent,
    LoadingSpinnerComponent,
    LoginFormComponent,
    MobileCarouselComponent,
    ResponsiveContainerComponent,
    TileBodyComponent,
    TileComponent,
    TileDefaultLogoComponent,
    TileFooterComponent,
    TileHeaderComponent,
    TileIconComponent,
    TileImageComponent,
    TileLogoComponent,
    TileSmallComponent,
    TileSubtitleComponent,
    TileTitleComponent,
    TileVerticalComponent,
    TileVideoComponent,
    ToastNotificationComponent,
} from '@components';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NguCarouselModule } from '@ngu/carousel';
import { PipesModule } from '@pipes';
import { ServicesModule } from '../services/services.module';
import { ArticleModule } from './article/article.module';
import { CarouselComponent } from './carousel/carousel.component';
import { CopyLinkModule } from './copy-link/copy-link.module';
import { IframeDialogModule } from './dialogs';
import { VimeoPlayerDialogModule } from './dialogs/vimeo-player-dialog/vimeo-player-dialog.module';
import { FavoriteIconModule } from './favorite-icon/favorite-icon.module';
import { FeaturedContentModule } from './featured-content/featured-content.module';
import { IconsModule } from './icons/icons.module';
import { SearchBarModule } from './search-bar/search-bar.module';
import { SectionHeaderHyperlinkModule } from './section-header-hyperlink/section-header-hyperlink.module';
import { SectionHeaderModule } from './section-header/section-header.module';
import { SelectModule } from './select/select.module';
import { ShareModule } from './share/share.module';
import { TileLayoutModule } from './tile-layout/tile-layout.module';
import { VideoContainerModule } from './video-container/video-container.module';
import { VimeoPlayerModule } from './vimeo-player/vimeo-player.module';

const declarations = [
    BackToTopComponent,
    ButtonComponent,
    CarouselComponent,
    CookieConsentComponent,
    ExternalUrlComponent,
    LoadingSpinnerComponent,
    LoginFormComponent,
    MobileCarouselComponent,
    ResponsiveContainerComponent,
    TileComponent,
    TileBodyComponent,
    TileFooterComponent,
    TileHeaderComponent,
    TileIconComponent,
    TileDefaultLogoComponent,
    TileImageComponent,
    TileLogoComponent,
    TileTitleComponent,
    TileVideoComponent,
    TileVerticalComponent,
    TileSmallComponent,
    TileSubtitleComponent,
    ToastNotificationComponent,
    InfoTooltipComponent,
];

const modules = [
    ArticleModule,
    CopyLinkModule,
    FavoriteIconModule,
    FeaturedContentModule,
    IconsModule,
    IframeDialogModule,
    PipesModule,
    SearchBarModule,
    SectionHeaderModule,
    SectionHeaderHyperlinkModule,
    SelectModule,
    ShareModule,
    TileLayoutModule,
    VideoContainerModule,
    VimeoPlayerModule,
    VimeoPlayerDialogModule,
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NguCarouselModule,
        ServicesModule,
        RouterModule,
        ...modules,
    ],
    declarations: declarations,
    exports: [...declarations, ...modules],
})
export class ComponentsModule {}
